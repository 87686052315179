import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setBusiness, setBusinessId } from "../../redux/ducks/business.duck";
import { setSetupStatus } from "../../redux/ducks/setup.duck";
import {
  initialSiteConfig,
  setSiteConfiguration,
} from "../../redux/ducks/siteconfig.duck";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  diffBetweenTwoObj,
  upgradePlan,
  validateValues,
} from "../../util/helpers";
import { siteConfigSchema } from "../account/AccountPage";
import { SiteConfigStartGuide } from "../account/components/SiteConfigStartGuide";
import { UploadLogo } from "../account/components/UploadLogo";
import {
  SiteConfiguration,
  UpdateSiteConfigurationRequest,
} from "../account/models/SiteConfigurationModel";
import { updateSiteConfiguration } from "../account/requests/SiteConfigurationsRequests";
import ReminderDelivery from "./components/ReminderDelivery";
import { SendReminder } from "./components/SendReminder";
import { getBusiness, postGoLive } from "../guides/requests/BusinessRequests";
import SenderInfo from "./components/SenderInfo";
import EmailReminderFlow from "./components/EmailReminderFlow";
import {
  ReminderDesktopView,
  ReminderMobileView,
} from "./components/ReminderView";
import { MentionsInput, Mention } from "react-mentions";
import UnlockFeature from "../../components/UnlockFeature";
import _ from "lodash";
import { ReminderTemplate } from "./components/ReminderTemplate";
import Button from "../../components/Button";
import WhiteLabel from "./components/WhiteLabel";
import { Template } from "./models/Customization";
import {
  getEmailTemplate,
  updateEmailTemplate,
} from "./requests/ReminderTemplateRequests";
import {
  initialTemplateField,
  setSelectedEmailTemlate,
  setSelectedSmsTemplate,
  setTemplates,
} from "../../redux/ducks/template.duck";
const GeneralTab = ({
  siteConfigInitials,
  setSiteConfigData,
  setFieldValueSiteConfig,
  businessId,
  setSiteErrors,
  siteErrors,
}: {
  siteConfigInitials: SiteConfiguration;
  setSiteConfigData: (data: SiteConfiguration) => void;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
  businessId: number;
  setSiteErrors: (errors: any) => void;
  siteErrors: any;
}) => {
  return (
    <div className="mt-8 pb-7 w-full mb-7 bg-white rounded-lg">
      <div className="px-6 message-type-wrapper py-6 border-b border-light-grey">
        <div className="flex justify-between flex-row">
          <div className="flex flex-col">
            <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
              Setup your brand identity
            </p>
          </div>
        </div>
      </div>
      <div className="py-4 px-6 border-b border-light-grey">
        <SiteConfigStartGuide
          siteErrors={siteErrors}
          setSiteErrors={setSiteErrors}
          siteConfigInitials={siteConfigInitials}
          setFieldValueSiteConfig={setFieldValueSiteConfig}
          setSiteConfigData={setSiteConfigData}
          businessId={businessId}
          customization={true}
        />
        <UploadLogo
          imageUrl={siteConfigInitials?.imageUrl}
          onUploadSuccess={(url) => {
            setFieldValueSiteConfig("imageUrl", url);
          }}
          businessId={businessId}
        />
      </div>
      <WhiteLabel
        siteErrors={siteErrors}
        siteConfigInitials={siteConfigInitials}
        setFieldValueSiteConfig={setFieldValueSiteConfig}
      />
    </div>
  );
};

const ReminderSettings = ({
  siteConfigInitials,
  setFieldValueSiteConfig,
  siteErrors,
}: {
  siteConfigInitials: SiteConfiguration;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
  siteErrors: any;
}) => {
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );

  return (
    <>
      {!upgradePlan(permission, "customization > reminder_settings") ? (
        <UnlockFeature subscription={subscription} />
      ) : (
        <>
          <SendReminder
            siteConfigInitials={siteConfigInitials}
            setFieldValueSiteConfig={setFieldValueSiteConfig}
          />
          <EmailReminderFlow
            siteConfigInitials={siteConfigInitials}
            setFieldValueSiteConfig={setFieldValueSiteConfig}
          />
          <ReminderDelivery
            siteConfigInitials={siteConfigInitials}
            setFieldValueSiteConfig={setFieldValueSiteConfig}
            siteErrors={siteErrors}
          />
          <SenderInfo
            siteConfigInitials={siteConfigInitials}
            setFieldValueSiteConfig={setFieldValueSiteConfig}
            showSenderInfo={
              siteConfigInitials.emailDeliveryMethod === "klaviyo_email" ||
              siteConfigInitials.smsDeliveryMethod === "klaviyo_sms"
            }
          />
        </>
      )}
    </>
  );
};

const ReminderOptForm = ({
  siteConfigInitials,
  setFieldValueSiteConfig,
}: {
  siteConfigInitials: SiteConfiguration;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
}) => {
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );
  const [reminderView, setReminderView] = useState("mobile");
  const [selectTab, setSelectTab] = useState("No Discount");

  const renderUserSuggestion = (entry: any) => {
    return (
      <div className="font-AvenirLTPro_Medium text-xs">
        <span>{entry.display}</span>
      </div>
    );
  };

  return (
    <>
      {!upgradePlan(permission, "customization > reminder_opt_in_form") ? (
        <UnlockFeature subscription={subscription} />
      ) : (
        <div className="mt-8 pb-8 w-full h-[866px] mb-7 bg-white rounded-lg">
          <div className="px-6 message-type-wrapper py-6 border-b border-light-grey">
            <div className="flex justify-between flex-row">
              <div className="flex flex-col">
                <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                  Customize the reminder opt-in form prompt
                </p>
                <p className="text-10 tracking-tracking_001 text-dark-grey text-opacity-75 w-[478px]">
                  Customize the email reminders sent to your customers
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap pt-7">
            <div className="w-2/5">
              <div className="px-6">
                <div className="border-b-[1px] border-[#EBEFF2] max-w-full xl:max-w-[301px]">
                  <ul className="flex flex-wrap justify-center -mb-px font-normal text-center relative -bottom-[1px]">
                    <li
                      onClick={() => setSelectTab("No Discount")}
                      className={`tab cursor-pointer mr-7 py-1 px-2.5 text-xs tracking-wide_0.01 ${
                        selectTab === "No Discount"
                          ? "active font-medium text-blue_default border-b-4 border-blue_default"
                          : " text-black_33/50"
                      }`}
                    >
                      No Discount
                    </li>
                    <li
                      onClick={() => setSelectTab("With Discount")}
                      className={`tab cursor-pointer py-1 px-2.5 text-xs tracking-wide_0.01 ${
                        selectTab === "With Discount"
                          ? "active font-medium text-blue_default border-b-4 border-blue_default"
                          : " text-black_33/50"
                      }`}
                    >
                      With Discount
                    </li>
                  </ul>
                </div>
              </div>
              <div className="px-6">
                <div className="mt-2 w-full py-3">
                  <span className="text-xs font-normal tracking-tracking_001 text-black_shade mr-4 xl:mb-0 min-w-[140px]">
                    Headline:
                  </span>
                  <div className="flex justify-between text-xs  pt-4">
                    <div className="w-full mb-2 font-AvenirLTPro_Heavy">
                      {selectTab === "No Discount" ? (
                        <textarea
                          className="rounded-[10px] font-AvenirLTPro_Heavy border border-border_gray text-[#333333] w-full xl:!w-[301px] h-[66px] px-3 py-4 leading-[19px] text-base"
                          placeholder="Set reminders to never run out"
                          name="body"
                          value={siteConfigInitials?.withOutDiscountHeading}
                          onChange={(event) =>
                            setFieldValueSiteConfig(
                              "withOutDiscountHeading",
                              event.target.value
                            )
                          }
                        ></textarea>
                      ) : (
                        <MentionsInput
                          value={siteConfigInitials?.withDiscountHeading}
                          placeholder="Set reminders to never run out"
                          className="mentions rounded-[10px] font-AvenirLTPro_Heavy border border-border_gray text-[#333333] w-full xl:!w-[301px] h-[66px] leading-[19px] text-base"
                          onChange={(event) =>
                            setFieldValueSiteConfig(
                              "withDiscountHeading",
                              event.target.value
                            )
                          }
                        >
                          <Mention
                            trigger="{{"
                            data={[
                              {
                                id: "1",
                                display: "Discount",
                              },
                            ]}
                            markup="#__display__#"
                            displayTransform={(id, display) => `{{${display}}}`}
                            renderSuggestion={renderUserSuggestion}
                          />
                        </MentionsInput>
                      )}

                      {/* <textarea
                        className="rounded-[10px] font-AvenirLTPro_Heavy border border-border_gray text-[#333333] w-[301px] h-[66px] px-3 py-4 leading-[19px] text-base"
                        // placeholder="Set reminders to never run out"
                        name="body"
                        value={"Set reminders to never run out"}
                        // onChange={(e) => onChange(e)}
                      ></textarea> */}
                    </div>
                  </div>
                </div>
                <div className="mt-2 w-full py-3">
                  <span className="text-xs font-normal tracking-tracking_001 text-black_shade mr-4 xl:mb-0 min-w-[140px]">
                    Subheadline:
                  </span>
                  <div className="flex justify-between text-xs pt-4">
                    <div className="w-full">
                      <textarea
                        className="rounded-[10px] font-AvenirLTPro_Light w-full xl:!w-[301px] h-[125px] font-[350] border border-border_gray px-3 py-4 text-xs leading-[14px] text-opacity-50 tracking-wide"
                        // placeholder="We will get your order ready and send you a reminder. You confirm it with one tap. No need to enter your information again."
                        rows={7}
                        name="body"
                        value={
                          selectTab === "No Discount"
                            ? siteConfigInitials?.withOutDiscountSubheading ||
                              ""
                            : siteConfigInitials?.withDiscountSubheading || ""
                        }
                        onChange={(e) =>
                          setFieldValueSiteConfig(
                            selectTab === "No Discount"
                              ? "withOutDiscountSubheading"
                              : "withDiscountSubheading",
                            e.target.value
                          )
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-3/5 xl:pr-0 pr-2.5">
              <div
                className={`${
                  reminderView === "mobile"
                    ? "max-w-[311px] mx-auto"
                    : "2xl:max-w-[570px] xl:max-w-[500px] lg:max-w-[440px] mx-auto"
                }`}
              >
                <div className="flex items-center justify-center mb-6 relative mt-1">
                  <span className="text-10 leading-[15px] text-black_shade/75 absolute left-0 top-0 tracking-tracking_001">
                    Preview
                  </span>
                  <div className="line h-[1px] w-full absolute bg-[#EBEFF2]"></div>
                  <div
                    className={`w-10 h-10 z-[1] mr-[7px] bg-white border ${
                      reminderView === "mobile"
                        ? " border-blue_default"
                        : " border-gray1 shadow-lg"
                    }  rounded-full cursor-pointer flex items-center justify-center`}
                    onClick={() => setReminderView("mobile")}
                  >
                    <img
                      src={`${
                        reminderView === "mobile"
                          ? "/svg/ico_mobile_active.svg"
                          : "/svg/ico_mobile.svg"
                      }`}
                      alt=""
                    />
                  </div>

                  <div
                    className={`w-10 h-10 z-[1] ml-[7px] bg-white border ${
                      reminderView === "desktop"
                        ? " border-blue_default"
                        : " border-gray1 shadow-lg"
                    }  rounded-full cursor-pointer flex items-center justify-center`}
                    onClick={() => setReminderView("desktop")}
                  >
                    <img
                      src={`${
                        reminderView === "desktop"
                          ? "/svg/ico_desktop_active.svg"
                          : "/svg/ico_desktop.svg"
                      }`}
                      alt=""
                    />
                  </div>
                </div>
                {reminderView === "mobile" && (
                  <ReminderMobileView
                    selectTab={selectTab}
                    siteConfigInitials={siteConfigInitials}
                  />
                )}
                {reminderView === "desktop" && (
                  <ReminderDesktopView
                    selectTab={selectTab}
                    siteConfigInitials={siteConfigInitials}
                  />
                )}
                {/* <ReminderMobileView /> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const tabs = [
  { name: "General", path: "general" },
  { name: "Reminders settings", path: "reminders-settings" },
  { name: "Reminder opt-in form", path: "reminder-opt-in-form" },
  { name: "Reminders template", path: "reminders-template" },
];

const Customization = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const siteConfig: SiteConfiguration = useAppSelector(
    (state) => state.site.siteConfiguration
  );
  const [cancelChanges, setCancelChanges] = useState<boolean>(false);

  let tempActive: { name: string; path: string } | undefined = tabs?.find(
    (tab) => tab.path === location?.hash?.slice(1)
  );

  const [activeTab, setActiveTab] = useState<string>(
    tempActive?.name ?? tabs[0].name
  );

  // states that are helpful in submitting site config data
  const [siteErrors, setSiteErrors] = useState<any>({});
  // end
  // states that are helpful in submitting site config data

  const [siteConfigInitials, setSiteConfigInitials] =
    useState<SiteConfiguration>(initialSiteConfig);
  const [oldsiteConfigInitialsData, setOldsiteConfigInitialsData] =
    useState<SiteConfiguration>(initialSiteConfig);

  // reminder templates
  const { templates, selectedEmail, selectedSms }: any = useAppSelector(
    (state) => state.templates
  );
  const [selectedTemplate, setSelectedTemplate] =
    useState<Template>(initialTemplateField);
  // const [templateFields, setTemplateFields] = useState<{
  //   sms: Template;
  //   email: Template;
  // }>({ sms: initialTemplateField, email: initialTemplateField });
  const [smsTemplate, setSmsTemplate] =
    useState<Template>(initialTemplateField);
  const [changeTemplate, setChangeTemplate] = useState<boolean>(false);

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [save, setSave] = useState<boolean>(false);

  const setFieldValueSiteConfig = (fieldName: string, value: any) => {
    setSiteConfigInitials({ ...siteConfigInitials, [fieldName]: value });
  };

  const setFieldValue = (fieldName: string, value: any) => {
    setSelectedTemplate((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const { businessId }: any = useAppSelector((state) => state.business);
  // const colorRegex = /^#[A-Fa-f0-9]{6}$/;
  const setupStatus = useAppSelector((state) => state.setup.setupStatus);

  useEffect(() => {
    if (location.hash === "") {
      navigate(`/customization#${tabs[0].path}`);
      setActiveTab(tabs[0].name);
    }
    //eslint-disable-next-line
  }, [location]);

  const checkSMSTemplate = () => {
    return (
      smsTemplate?.templateType !== selectedSms?.templateType ||
      smsTemplate?.smsReminderTemplateWithNoDiscount !==
        selectedSms?.smsReminderTemplateWithNoDiscount ||
      smsTemplate?.smsReminderTemplateWithDiscount !==
        selectedSms?.smsReminderTemplateWithDiscount
    );
  };

  const checkEmailTemplate = () => {
    return (
      selectedTemplate?.emailNoDiscountPreheader !==
        selectedEmail?.emailNoDiscountPreheader ||
      selectedTemplate?.emailNoDiscountSubject !==
        selectedEmail?.emailNoDiscountSubject ||
      selectedTemplate?.emailWithDiscountPreheader !==
        selectedEmail?.emailWithDiscountPreheader ||
      selectedTemplate?.emailWithDiscountSubject !==
        selectedEmail?.emailWithDiscountSubject ||
      selectedTemplate?.templateType !== selectedEmail?.templateType ||
      changeTemplate
    );
  };

  useEffect(() => {
    if (
      !_.isEqual(siteConfigInitials, oldsiteConfigInitialsData) ||
      checkEmailTemplate() ||
      checkSMSTemplate()
    ) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
    // eslint-disable-next-line
  }, [
    siteConfigInitials,
    oldsiteConfigInitialsData,
    smsTemplate,
    changeTemplate,
    selectedTemplate,
    selectedEmail,
    selectedSms,
  ]);

  const saveSiteConfig = async () => {
    const checkSms =
      siteConfigInitials?.smsDeliveryMethod !== "assistalong_sms";
    const hasErrors = validateValues(
      siteConfigInitials,
      siteConfigSchema(siteConfigInitials?.isWhiteLabel, checkSms),
      setSiteErrors
    );
    if (!hasErrors) {
      try {
        if (siteConfigInitials && siteConfigInitials.imageUrl) {
          setSaveDisabled(true);
          let request: UpdateSiteConfigurationRequest = await diffBetweenTwoObj(
            siteConfigInitials,
            oldsiteConfigInitialsData
          );

          if (request && request?.smsDeliveryMethod === "assistalong_sms") {
            request = {
              ...request,
              termsOfServiceLink: "",
              privacyPolicyLink: "",
            };
          }

          const { data } = await updateSiteConfiguration(
            siteConfigInitials.id,
            request
          );
          setSaveDisabled(false);
          if (data["error"]) {
            setSiteErrors({ brandName: data["error"] });
          } else {
            dispatch(setSiteConfiguration(data));
            if (setupStatus.goLive.status === "completed") {
              postGoLive(businessId).then(() => {
                getBusiness().then((res) => {
                  if (res.data) {
                    dispatch(setSetupStatus(res.data.setupStatus));
                    dispatch(setBusiness(res.data));
                    dispatch(setBusinessId(res.data.id));
                  }
                });
              });
            } else {
              getBusiness().then((res) => {
                if (res.data) {
                  dispatch(setSetupStatus(res.data.setupStatus));
                  dispatch(setBusiness(res.data));
                  dispatch(setBusinessId(res.data.id));
                }
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    setSave(false);
  };

  useEffect(() => {
    if (Object.keys(siteErrors).length > 0) {
      if (siteErrors.brandName || siteErrors.merchantDomain) {
        navigate("/customization#general");
        setActiveTab(tabs[0].name);
      } else if (
        siteErrors.termsOfServiceLink ||
        siteErrors.privacyPolicyLink
      ) {
        navigate("/customization#reminders-settings");
        setActiveTab(tabs[1].name);
      }
      setTimeout(() => {
        const ele = document.querySelector(".scroll__error");
        (ele?.parentElement ?? ele)?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 400);
    }
    // eslint-disable-next-line
  }, [siteErrors]);

  const updateEmailTemplateRequest = async () => {
    let noDiscountHtml = "";
    let discountHtml = "";

    if (selectedTemplate.emailNoDiscountBody) {
      noDiscountHtml = selectedTemplate.emailNoDiscountBody;
      noDiscountHtml = noDiscountHtml.replaceAll(
        siteConfigInitials.primaryColor,
        "#085C3F"
      );

      noDiscountHtml = noDiscountHtml.replaceAll(
        siteConfigInitials.secondaryColor,
        "#F5FAF5"
      );
    }

    if (selectedTemplate.emailWithDiscountBody) {
      discountHtml = selectedTemplate.emailWithDiscountBody;
      discountHtml = discountHtml.replaceAll(
        siteConfigInitials.primaryColor,
        "#085C3F"
      );

      discountHtml = discountHtml.replaceAll(
        siteConfigInitials.secondaryColor,
        "#F5FAF5"
      );
    }

    const res = await updateEmailTemplate(
      selectedTemplate?.id,
      {
        reminder_template: {
          business_id: businessId,
          template_type: selectedTemplate?.templateType,
          ...(selectedTemplate.emailNoDiscountSubject && {
            email_no_discount_subject: selectedTemplate.emailNoDiscountSubject,
          }),
          ...(selectedTemplate.emailWithDiscountSubject && {
            email_with_discount_subject:
              selectedTemplate.emailWithDiscountSubject,
          }),
          ...(selectedTemplate.emailNoDiscountPreheader && {
            email_no_discount_preheader:
              selectedTemplate.emailNoDiscountPreheader,
          }),
          ...(selectedTemplate.emailWithDiscountPreheader && {
            email_with_discount_preheader:
              selectedTemplate.emailWithDiscountPreheader,
          }),
          ...(noDiscountHtml && {
            email_no_discount_body: noDiscountHtml,
          }),
          ...(discountHtml && {
            email_with_discount_body: discountHtml,
          }),
          ...(selectedTemplate.emailWithDiscountJsonBody && {
            email_with_discount_json_body:
              selectedTemplate.emailWithDiscountJsonBody,
          }),
          ...(selectedTemplate.emailWithNoDiscountJsonBody && {
            email_with_no_discount_json_body:
              selectedTemplate.emailWithNoDiscountJsonBody,
          }),
        },
      },
      businessId
    );

    if (res?.data) {
      dispatch(setSelectedEmailTemlate(res?.data));
      dispatch(
        setTemplates(
          templates?.map((obj: any) =>
            obj?.templateType === res?.data?.templateType
              ? {
                  ...obj,
                  ...res.data,
                }
              : obj
          )
        )
      );
      setChangeTemplate(false);
    }
    setSave(false);
  };

  const updateSmsTemplate = async () => {
    const res = await updateEmailTemplate(
      smsTemplate?.id,
      {
        reminder_template: {
          business_id: businessId,
          template_type: smsTemplate?.templateType,
          ...(smsTemplate?.smsReminderTemplateWithNoDiscount && {
            sms_reminder_template_with_no_discount:
              smsTemplate?.smsReminderTemplateWithNoDiscount,
          }),
          ...(smsTemplate?.smsReminderTemplateWithDiscount && {
            sms_reminder_template_with_discount:
              smsTemplate?.smsReminderTemplateWithDiscount,
          }),
        },
      },
      businessId
    );
    if (res?.data) {
      dispatch(setSelectedSmsTemplate(res.data));
      dispatch(
        setTemplates(
          templates?.map((obj: any) =>
            obj?.templateType === res?.data?.templateType
              ? {
                  ...obj,
                  ...res.data,
                }
              : obj
          )
        )
      );
    }
    setSave(false);
  };

  useEffect(() => {
    if (cancelChanges) {
      setSiteConfigInitials(oldsiteConfigInitialsData);
      if (checkSMSTemplate()) {
        setSmsTemplate(selectedSms);
      }
      if (checkEmailTemplate()) {
        setSelectedTemplate(selectedEmail);
        setChangeTemplate(false);
      }
      setCancelChanges(false);
    } else {
      setCancelChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelChanges]);

  useEffect(() => {
    if (siteConfig) {
      setSiteConfigInitials({
        ...siteConfig,
        senderEmail: siteConfig.senderEmail
          ? siteConfig.senderEmail
          : getFormattedEmail(siteConfig.defaultSenderEmail),
        senderName: siteConfig.defaultSenderName,
      });
      setOldsiteConfigInitialsData({
        ...siteConfig,
        senderEmail: siteConfig.senderEmail
          ? siteConfig.senderEmail
          : getFormattedEmail(siteConfig.defaultSenderEmail),
        senderName: siteConfig.defaultSenderName,
      });
    }
    // eslint-disable-next-line
  }, [siteConfig]);

  useEffect(() => {
    if (businessId) {
      getAllTemplates(businessId);
    }
    // eslint-disable-next-line
  }, [businessId]);

  const getAllTemplates = async (bId: string) => {
    const res = await getEmailTemplate(bId);
    if (res?.data && res?.data.length > 0) {
      const smsOption = res?.data?.filter((item: any) =>
        item?.templateType?.includes("sms")
      )?.[0];

      const emailOption = res?.data?.filter(
        (item: any) => !item?.templateType?.includes("sms")
      )?.[0];

      setSelectedTemplate(emailOption);
      setSmsTemplate(smsOption);
      dispatch(setSelectedSmsTemplate(smsOption));
      dispatch(setSelectedEmailTemlate(emailOption));
      dispatch(setTemplates(res?.data));
    }
  };

  const getFormattedEmail = (defaultEmail: string) => {
    if (defaultEmail.includes("<") && defaultEmail.includes(">")) {
      return defaultEmail.slice(
        defaultEmail.indexOf("<") + 1,
        defaultEmail.indexOf(">")
      );
    }
    return defaultEmail;
  };

  return (
    <div className="flex flex-col w-full">
      <div className="title flex justify-between mb-6">
        <h1 className="text-2xl text-black_shade font-medium">Customization</h1>
        <div className="items-end flex gap-2">
          <Button
            text="Cancel changes"
            className={`h-10 px-4 rounded-md text-dark-grey border border-dark-grey flex items-center text-sm tracking-wide font-medium `}
            onClick={() => setCancelChanges(true)}
          />
          <Button
            text="Save"
            className={`h-10 px-6 rounded-md text-white flex items-center text-13 bg-[#00AE65] font-medium 
              ${save || saveDisabled ? "bg-gray-500" : "bg-[#00AE65]"} }
            `}
            disabled={save || saveDisabled}
            loading={save}
            onClick={() => {
              new Promise((res, _rej) => {
                setSave(true);
                if (!_.isEqual(siteConfigInitials, oldsiteConfigInitialsData)) {
                  res(saveSiteConfig());
                }
                if (checkSMSTemplate()) {
                  res(updateSmsTemplate());
                }
                if (checkEmailTemplate()) {
                  res(updateEmailTemplateRequest());
                }
              });
            }}
          />
        </div>
      </div>

      <div className="rounded border-b-[1.5px] border-[#ACB7C4]">
        <ul className="flex flex-wrap -mb-px font-normal text-center">
          {tabs.map((tabName, index) => (
            <Link key={index} to={`/customization#${tabName.path}`}>
              <li
                className={`tab cursor-pointer px-2 mr-1 xl:!mr-10 lg:!mr-2 py-3 lg:!px-4 xl:!px-8 text-sm tracking-wide_0.01 ${
                  activeTab === tabName.name
                    ? "active font-medium text-blue_default border-b-4 border-blue_default"
                    : "text-[#334D6E]"
                }`}
                key={tabName.name}
                onClick={() => setActiveTab(tabName.name)}
              >
                {tabName.name}
              </li>
            </Link>
          ))}
        </ul>
      </div>
      {activeTab === "General" && (
        <GeneralTab
          siteConfigInitials={siteConfigInitials}
          setFieldValueSiteConfig={setFieldValueSiteConfig}
          businessId={businessId}
          setSiteConfigData={setSiteConfigInitials}
          setSiteErrors={setSiteErrors}
          siteErrors={siteErrors}
        />
      )}
      {activeTab === "Reminders settings" && (
        <ReminderSettings
          siteConfigInitials={siteConfigInitials}
          setFieldValueSiteConfig={setFieldValueSiteConfig}
          siteErrors={siteErrors}
        />
      )}
      {activeTab === "Reminder opt-in form" && (
        <ReminderOptForm
          siteConfigInitials={siteConfigInitials}
          setFieldValueSiteConfig={setFieldValueSiteConfig}
        />
      )}
      {activeTab === "Reminders template" && (
        <ReminderTemplate
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          setFieldValue={setFieldValue}
          setChangeTemplate={setChangeTemplate}
          setSmsTemplate={setSmsTemplate}
          smsTemplate={smsTemplate}
          checkTempChange={{
            sms: checkSMSTemplate(),
            email: checkEmailTemplate(),
          }}
          cancelChanges={cancelChanges}
        />
      )}
    </div>
  );
};

export default Customization;

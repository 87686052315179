import { ShopifyProduct } from "../../smart_reminders/models/ShopifyProduct";
import { TimeState } from "../InsightsPage";
import { ProductInsightChart } from "../models/DashboardModel";

export const AddButton = () => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1031_900)">
        <circle
          cx="33.9411"
          cy="33.9412"
          r="24"
          transform="rotate(-45 33.9411 33.9412)"
          fill="#FAFBFF"
        />
      </g>
      <path
        d="M25.4561 33.9412H42.4266"
        stroke="#2E5ED1"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M33.9414 25.4559L33.9414 42.4265"
        stroke="#2E5ED1"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_d_1031_900"
          x="7.94141"
          y="8.94116"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1031_900"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1031_900"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const AddProductQueryCard = ({
  products,
  state,
  handleUpdate,
}: {
  products: ShopifyProduct[];
  state: TimeState;
  handleUpdate: (updates: ProductInsightChart) => void;
}) => {
  return (
    <div className="flex items-center justify-center border border-dashed border-gray_planBox h-full w-full rounded">
      <div className="flex items-center flex-col">
        <button
          onClick={() =>
            handleUpdate({
              id: undefined,
              tempId: Math.random(),
              shopifyProductId: products[0]["id"],
              insightType: "order_count",
            })
          }
        >
          <AddButton />
        </button>
        <p className="text-blue_default text-xs tracking-tracking_001 font-medium">
          Add Product
        </p>
      </div>
    </div>
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLock, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { TextInput } from "flowbite-react";
import { resetPasswordRequest } from "../login/requests/AuthRequests";

library.add(faLock, faEye, faEyeSlash);
const ResetFormInput = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const params = new URLSearchParams(window.location.search);
  const confirmation_token = params.get("confirmation_token");
  const [error, setError] = useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("password doesn't match");
    } else {
      setError("");
      sendResetRequest();
    }
  };

  const sendResetRequest = () => {
    setError("");
    resetPasswordRequest(password, confirmPassword, confirmation_token)
      .then((response: any) => {
        if (response.status === 200) {
          window.location.href = "/login";
        } else {
          if (response.error) {
            setError(response.error);
          }
        }
      })
      .catch((e: any) => {
        if (e.response && e.response.data && e.response.data.error) {
          setError(
            "Your reset password link has expired. Click <a href='/forgot_password' class='text-red-700 font-bold'>here</a> to get new link."
          );
        }
      });
  };

  return (
    <form
      className="flex flex-col gap-4 "
      id="reset-form"
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className="reset-form-main mb-4 mt-5">
        <TextInput
          id="inlineFormInputGroupUserpassword"
          placeholder="password"
          required={true}
          addon={<FontAwesomeIcon icon="lock" />}
          type={passwordType}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <span className="password-visibility">
          {passwordType === "password" ? (
            <FontAwesomeIcon icon="eye" onClick={togglePassword} />
          ) : (
            <FontAwesomeIcon icon="eye-slash" onClick={togglePassword} />
          )}
        </span>
      </div>

      <div className="reset-form-main mb-4">
        <TextInput
          id="inlineFormInputGroupUserConfirmPassword"
          placeholder="confirm password"
          required={true}
          addon={<FontAwesomeIcon icon="lock" />}
          type={confirmPasswordType}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <span className="password-visibility">
          {confirmPasswordType === "password" ? (
            <FontAwesomeIcon icon="eye" onClick={toggleConfirmPassword} />
          ) : (
            <FontAwesomeIcon icon="eye-slash" onClick={toggleConfirmPassword} />
          )}
        </span>
      </div>
      {error && (
        <div className="text-center">
          <span
            className="text-red-700"
            dangerouslySetInnerHTML={{ __html: error }}
          ></span>
        </div>
      )}
    </form>
  );
};
export default ResetFormInput;

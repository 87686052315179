import { useState, useEffect } from "react";
import { AddProduct } from "./AddProduct";
import { useGetShopifyProducts } from "../requests/ShopifyProductRequests";
import { APIError } from "../../../layout/Error";
import { ShopifyProduct } from "../models/ShopifyProduct";
import { Switch } from "./Switch";
import { QueryObject } from "../models/ProductPageConfigurationModel";
import { IsAdmin } from "../../../util/helpers";
import { AdminProtectedContainer } from "../../../components/RoleProtectedContainer";
import { MassEditHeader } from "./MassEditHeader";
import { useLocation, useNavigate } from "react-router";
import { massUpdateUpsellProducts } from "../requests/ProductPageConfigurationRequests";

const MassEditUpsellProduct = () => {
  const [active, setActive] = useState(true);
  const [upsellText, setUpsellText] = useState<string>("Recommended For You");
  const [upsellProducts, setUpsellProducts] = useState<ShopifyProduct[]>([]);
  const [showProductModal, setShowProductModal] = useState<boolean>(false);
  const [shopifyProductList, setShopifyProductList] = useState<
    ShopifyProduct[] | null
  >(null);
  // const [addedProducts, setAddedProducts] = useState<ShopifyProduct[]>([]);
  const [queryObj, setQueryObj] = useState<QueryObject>({
    has_next_page: false,
    next_page_info: "",
    search: "",
  });
  const [saveError, setSaveError] = useState<boolean>(false);
  const {
    loading: shopifyLoading,
    error: shopifyError,
    refresh: shopifyRefresh,
    data: shopifyProducts,
  } = useGetShopifyProducts({
    search: queryObj.search,
    next_page_info: queryObj.next_page_info,
  });
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [search, setSearch] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const [productConfigIds, setProductConfigIds] = useState<string[]>([]);

  useEffect(() => {
    try {
      const qParams = new URLSearchParams(location.search);
      if (qParams.get("ids")) {
        const decrypted = atob(qParams.get("ids") ?? "");
        setProductConfigIds(decrypted.split(","));
      } else {
        throw Error();
      }
    } catch (e) {
      navigate("/smart-reminders");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shopifyProducts) {
      let tempProducts: ShopifyProduct[] = [...shopifyProducts.products];
      if (shopifyProductList) {
        if (isLoadMore) {
          tempProducts = [...shopifyProductList, ...shopifyProducts.products];
        }
      }
      setShopifyProductList(tempProducts);
    }
    setQueryObj({
      ...queryObj,
      next_page_info: shopifyProducts ? shopifyProducts.nextPageInfo : "",
      has_next_page: shopifyProducts ? shopifyProducts.hasNextPage : false,
    });
    // eslint-disable-next-line
  }, [shopifyProducts]);

  const closeModal = () => {
    setShowProductModal(false);
    setShopifyProductList([]);
  };

  const onClickAddProduct = (product: ShopifyProduct) => {
    try {
      setUpsellProducts([...upsellProducts, product]);
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveProduct = async (product: ShopifyProduct) => {
    try {
      if (
        upsellProducts.some((productInner) => productInner.id === product.id)
      ) {
        setUpsellProducts([
          ...upsellProducts.filter(
            (productInner) => productInner.id !== product.id
          ),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (shopifyError) {
    return (
      <APIError
        error={shopifyError}
        consentText="Consent to reading Customers"
        refresh={shopifyRefresh}
      />
    );
  }

  const onSave = () => {
    return new Promise((resolve, reject) => {
      const upsellUpdateRequest = {
        productPageRevisionsAttributes: [
          {
            upsellText: upsellText,
            shopifyProductIds: upsellProducts.map((product) => product.id),
          },
        ],
        upsellProductFlag: active,
        productPageConfigurationIds: productConfigIds ?? [],
      };
      massUpdateUpsellProducts(upsellUpdateRequest)
        .then((res: any) => {
          if (res.data.errorMessage) {
            setSaveError(true);
            setTimeout(() => {
              setSaveError(false);
            }, 3000);
            reject();
          } else {
            resolve(true);
          }
        })
        .catch(() => {
          setSaveError(true);
          setTimeout(() => {
            setSaveError(false);
          }, 5000);
          reject();
        });
    });
  };

  return (
    <MassEditHeader
      onSave={onSave}
      newValue={{
        active: active,
        upsellProducts: upsellProducts,
        upsellText: upsellText,
      }}
      defaultValue={{
        active: true,
        upsellProducts: [],
        upsellText: "Recommended For You",
      }}
    >
      {saveError && (
        <APIError
          error={"Something went wrong! Please try again"}
          consentText=""
          refresh={() => {}}
        />
      )}
      <div className="mb-7 w-full bg-white rounded-lg">
        <div className="message-type-wrapper py-6 px-6 border-b border-light-grey">
          <div className="flex justify-between flex-row">
            <div>
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                Upsell Products
              </p>
              <p className="text-10 text-dark-grey text-opacity-75">
                Which products do you want to upsell to customers?
              </p>
            </div>
            {/* <button
              onClick={() => setShowProductModal(true)}
              className="tracking-tracking_001 text-blue-600 text-xs font-medium"
            >
              Add new
            </button> */}
            <div className="flex flex-row pr-5 items-center">
              <span className="text-xs tracking-wider text-dark-grey text-opacity-75 mr-3">
                Active
              </span>
              <div
                className={!IsAdmin() ? "opacity-50 pointer-events-none" : ""}
              >
                <Switch state={active} onClick={() => setActive(!active)} />
              </div>
              <span className="text-xs tracking-wider text-dark-grey text-opacity-75 ml-3">
                Off
              </span>
            </div>
          </div>
        </div>
        <div>
          {upsellProducts.map((upsellProduct) => {
            return (
              <div
                key={upsellProduct.id}
                className="py-4 pr-7 ml-7 border-b border-light-grey flex justify-between items-center"
              >
                <div className="flex items-center">
                  <img
                    className="w-6 h-6 mr-2"
                    src={upsellProduct.imageUrl}
                    alt={upsellProduct.title}
                  ></img>
                  <h3 className="text-xs text-dark-grey tracking-tracking_001">
                    {upsellProduct.title}{" "}
                  </h3>
                </div>
                <button
                  onClick={() => onRemoveProduct(upsellProduct)}
                  className="text-blue_default text-xs tracking-tracking_001 flex items-center"
                >
                  <img
                    className="w-4 h-4 mr-2"
                    src="/delete-icon.svg"
                    alt="trash icon"
                  ></img>
                </button>
              </div>
            );
          })}
        </div>
        <div className="py-6 px-6 pb-7">
          <AdminProtectedContainer>
            <button
              onClick={() => setShowProductModal(true)}
              className="tracking-tracking_001 text-blue-600 text-xs font-medium mb-6"
            >
              Add Product
            </button>
          </AdminProtectedContainer>
          <p className="mb-4 tracking-tracking_001 text-xs text-dark-grey font-medium">
            Upsell Prompt
          </p>

          <div>
            <input
              disabled={!IsAdmin()}
              type="text"
              placeholder="Complete your regimen"
              value={upsellText}
              onChange={(e) => setUpsellText(e.target.value)}
              className="tracking-tracking_001 w-full px-3 py-3 border-dark-grey border-opacity-30 bg-white  border rounded text-xs font-light"
            />
          </div>
        </div>
      </div>

      {showProductModal && (
        <AddProduct
          onClose={() => closeModal()}
          show={showProductModal}
          shopifyProducts={shopifyProductList}
          onClickAddProduct={(product: ShopifyProduct) =>
            onClickAddProduct(product)
          }
          addedProducts={upsellProducts ?? []}
          setQueryObj={setQueryObj}
          shopifyLoading={shopifyLoading}
          queryObj={queryObj}
          refreshProducts={shopifyRefresh}
          setIsLoadMore={setIsLoadMore}
          search={search}
          setSearch={setSearch}
          isUpsell={true}
        />
      )}
    </MassEditHeader>
  );
};

export { MassEditUpsellProduct };

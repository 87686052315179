export const quantityOptions = () => {
  let options = [];
  for (let i = 1; i <= 20; i++) {
    options.push({
      label: i.toString(),
      value: i,
    });
  }
  return options;
};

export const shippingOptions = [
  {
    label: "Percentage",
    value: "percentage",
  },
  {
    label: "Fixed",
    value: "fixed_amount",
  },
];

export const discountMinPurchaseOptions = [
  {
    label: "Quantity",
    value: "quantity",
  },
  {
    label: "Amount",
    value: "amount",
  },
];

export const discountShipMinPurchaseOptions = [
  {
    label: "Quantity",
    value: "ship_quantity",
  },
  {
    label: "Amount",
    value: "ship_amount",
  },
];

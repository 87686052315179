import axios from "axios";
import { useApi, UseAPI } from "../../../use-api";
import {
  AllUserRoles,
  InviteUserRole,
  User,
  UserRole,
} from "../models/UserModel";

const API_URL = process.env.REACT_APP_API_URL;

const baseUrl = (businessId: number) =>
  `${API_URL}/businesses/${businessId}/users`;

const userUrl = (email: string) => `${API_URL}/users/${email}`;

export function getSelf(email: string) {
  return axios.get<User>(userUrl(email));
}

export function useGetUsers(businessId: number): UseAPI<User[]> {
  return useApi({
    method: "get",
    url: baseUrl(businessId),
  });
}

export function inviteUser(
  inviterEmail: string,
  email: string,
  role: AllUserRoles | InviteUserRole | UserRole,
  businessId: number
) {
  return axios.post<User>(`${userUrl(inviterEmail)}/invite`, {
    user: { email, role, businessId },
  });
}

export function addRoleToUser(
  inviterEmail: string,
  userId: number,
  role: AllUserRoles | InviteUserRole | UserRole
) {
  return axios.post<User>(`${userUrl(inviterEmail)}/add_role`, {
    user: { id: userId, role },
  });
}

export function reinviteUser(email: string, userId: number) {
  return axios.post(`${userUrl(email)}/send_invite?user_id=${userId}`);
}

export function deleteUser(email: string) {
  return axios.delete(`${userUrl(email)}`);
}

export function contactUs(email: string, subject: string, body: string) {
  return axios.post(`${userUrl(email)}/contact_us`, {
    message: { subject, body },
  });
}

import { Order } from "./models/OrderModel";
import { useGetOrders } from "./requests/OrdersRequests";
import DataTable, { TableColumn } from "react-data-table-component";
import { CSVLink } from "react-csv";
import { APIError } from "../../layout/Error";
import { PageContentLoader } from "../../layout/Loaders";
import { formatNumber, recordPerPage, upgradePlan } from "../../util/helpers";
// import { GoLiveButton } from "../../components/GoLiveButton";
import { useAppSelector } from "../../redux/hooks";
import { useState } from "react";
import UnlockFeature from "../../components/UnlockFeature";
import Button from "../../components/Button";

const OrdersPage = () => {
  const businessId = useAppSelector((state) => state.business.businessId);

  const { loading, error, refresh, data: orders } = useGetOrders(businessId);

  if (error) {
    return (
      <APIError
        error={error}
        consentText="Consent to reading Orders"
        refresh={refresh}
      />
    );
  }

  return (
    <>
      {loading && <PageContentLoader pageTitle="Orders" />}

      {orders && <OrdersTable orders={orders} />}
    </>
  );
};

const OrdersTable = ({ orders }: { orders: Order[] }) => {
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );
  const [rowPerPage, setRowPerPage] = useState<number>(() => {
    try {
      return JSON.parse(localStorage.getItem("pagination") || "{order:10}")
        .order;
    } catch (error) {
      return 10;
    }
  });

  const setDataRowPerPage = (page: number) => {
    const data = localStorage.getItem("pagination");
    if (data) {
      const orderPage = JSON.parse(data);
      localStorage.setItem(
        "pagination",
        JSON.stringify({ ...orderPage, order: page })
      );
      setRowPerPage(page);
    } else {
      localStorage.setItem("pagination", JSON.stringify({ order: page }));
      setRowPerPage(page);
    }
  };

  const onRowClick = (order: Order) => {
    window.open(order.url);
  };

  const columns: TableColumn<Order>[] = [
    {
      name: "Order No",
      selector: (row) => row.orderName,
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
    },
    {
      name: "Customer",
      selector: (row) => row.customer.name,
    },
    {
      name: "Total",
      selector: (row) => `$${formatNumber(row.totalPrice)}`,
    },
    {
      name: "Items",
      selector: (row) => row.lineItems.length,
    },
    {
      name: "Delivery Method",
      selector: (row) => row.shippingMethod,
    },
    {
      cell: (row) => {
        return (
          <a
            href={row.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue_default text-xs font-medium "
          >
            View Details
          </a>
        );
      },
    },
    // {
    //   style: {
    //     padding: "9px",
    //   },
    //   cell: (row) => {
    //     return (
    //       <>
    //         {row.refund === null ? (
    //           <AdminProtectedContainer>
    //             <button
    //               onClick={() => {
    //                 setOpen(true);
    //                 setRefund(row);
    //               }}
    //               className="text-blue_default text-xs font-medium"
    //             >
    //               Refund Order
    //             </button>
    //           </AdminProtectedContainer>
    //         ) : (
    //           <p className="text-dark-grey text-xs font-medium bg-gray-200 p-2 px-3 rounded-sm cursor-default">
    //             Refunded
    //           </p>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  const headers = [
    { label: "Order No", key: "orderName" },
    { label: "Date", key: "createdAt" },
    { label: "Customer", key: "customer.name" },
    { label: "Total", key: "totalPrice" },
    { label: "Items", key: "lineItems.length" },
    { label: "Delivery Method", key: "shippingMethod" },
  ];

  return (
    <>
      {!upgradePlan(permission, "orders") ? (
        <>
          <h1 className="text-2xl text-black_shade font-medium mr-10">
            Orders
          </h1>
          <UnlockFeature subscription={subscription} />
        </>
      ) : (
        <div className="flex flex-col w-full">
          <div className="title flex justify-between mb-6">
            <div className="flex items-center">
              <h1 className="text-2xl text-black_shade font-medium mr-10">
                Orders
              </h1>

              <CSVLink data={orders} headers={headers} filename="orders">
                <Button
                  text="Export CSV"
                  className="h-10 px-4 text-blue_default text-xs addproduct-button flex items-center justify-center"
                  icon={
                    <img
                      className="mr-2"
                      src="/svg/export-blue.svg"
                      alt="back arrow"
                    ></img>
                  }
                />
              </CSVLink>
            </div>
            {/* <div className="flex gap-2">
              <GoLiveButton />
            </div> */}
          </div>
          {/* {refund && (
      <RefundOrderModal order={refund} isOpen={open} setOpen={setOpen} />
    )} */}
          <DataTable
            pagination={true}
            paginationPerPage={rowPerPage}
            paginationRowsPerPageOptions={recordPerPage}
            customStyles={{
              table: {
                style: { "border-radius": "4px", overflow: "hidden" },
              },
              rows: {
                style: { cursor: "pointer" },
              },
              headCells: {
                style: { padding: "25px" },
              },
              cells: {
                style: { padding: "25px" },
              },
            }}
            className="table-class"
            columns={columns}
            onRowClicked={(data) => onRowClick(data)}
            data={orders}
            onChangeRowsPerPage={(page) => setDataRowPerPage(page)}
          />
        </div>
      )}
    </>
  );
};

export { OrdersPage };

const CheckoutSlug = ({
  slug,
  setSlug,
  errors,
}: {
  slug: string;
  setSlug: (val: string) => void;
  errors: any;
}) => {
  return (
    <div className="mb-7 w-full bg-white rounded-lg">
      <div className="message-type-wrapper py-[30px] px-6">
        <div className="flex justify-between flex-row">
          <div>
            <p className="text-10 text-dark-grey text-opacity-75">
              The link will be found under this name in your Email and SMS
              marketing platform:
            </p>
            <div className="flex py-[10px]">
              <input
                type="text"
                value={slug}
                className="tracking-tracking_001 font-AvenirLTPro_Medium w-[368px] h-12 px-6 py-3 !border-border_gray bg-white border rounded text-xs"
                onChange={(e) => {
                  setSlug(e?.target?.value.trim());
                }}
              />
            </div>
            {errors?.slug !== "" && (
              <p className="text-danger pt-1 text-red-500 text-xs">
                {errors?.slug}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default CheckoutSlug;

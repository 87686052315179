import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { APIError } from "../../../layout/Error";
import { MassUpdateSubOptionRequest } from "../models/ProductPageConfigurationModel";
import { massUpdateSubOptions } from "../requests/ProductPageConfigurationRequests";
import AddSubscriptionCart from "./AddSubscriptionCart";
import { MassEditHeader } from "./MassEditHeader";

const MassEditSubscription = () => {
  const [saveError, setSaveError] = useState<boolean>(false);
  const [subscriptionActive, setSubscriptionActive] = useState<boolean>(false);
  const [defaultSubscriptionActive, setDefaultSubscriptionActive] =
    useState<boolean>(false);
  const [productIds, setProductIds] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const qParams = new URLSearchParams(location.search);
      if (qParams.get("ids")) {
        const decryptedPids = atob(qParams.get("ids") ?? "");
        setProductIds(decryptedPids.split(","));
      } else {
        throw Error();
      }
    } catch (e) {
      navigate("/smart-reminders");
    }
    // eslint-disable-next-line
  }, []);

  const onSave = () => {
    return new Promise((resolve, reject) => {
      const updateRequest: MassUpdateSubOptionRequest = {
        productConfigurationIds: productIds,
        isSubscriptionEnabled: subscriptionActive,
        isDefaultSubscription: defaultSubscriptionActive,
      };
      massUpdateSubOptions(updateRequest)
        .then((res: any) => {
          if (res.data.errorMessage) {
            setSaveError(true);
            setTimeout(() => {
              setSaveError(false);
            }, 3000);
            reject();
          } else {
            resolve(true);
          }
        })
        .catch(() => {
          setSaveError(true);
          setTimeout(() => {
            setSaveError(false);
          }, 5000);
          reject();
        });
    });
  };

  return (
    <MassEditHeader
      onSave={onSave}
      newValue={{
        subscriptionActive: subscriptionActive,
        defaultSubscriptionActive: defaultSubscriptionActive,
      }}
      defaultValue={{
        subscriptionActive: false,
        defaultSubscriptionActive: false,
      }}
    >
      {saveError && (
        <APIError
          error={"Something went wrong! Please try again"}
          consentText=""
          refresh={() => {}}
        />
      )}
      <AddSubscriptionCart
        active={subscriptionActive}
        setActive={setSubscriptionActive}
        activeDefaultSubscription={defaultSubscriptionActive}
        setDefaultSubscriptionActive={setDefaultSubscriptionActive}
      />
    </MassEditHeader>
  );
};

export default MassEditSubscription;

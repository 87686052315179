import Select from "react-select";
import {
  OptionValueContainer,
  SingleValueContainer,
} from "../../../components/OptionValueContainer";
import { useAppSelector } from "../../../redux/hooks";
import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";
import { useNavigate } from "react-router";

const ReminderDelivery = ({
  siteConfigInitials,
  setFieldValueSiteConfig,
  siteErrors,
}: {
  siteConfigInitials: SiteConfiguration;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
  siteErrors: any;
}) => {
  const navigate = useNavigate();
  const siteConfig: SiteConfiguration = useAppSelector(
    (state) => state.site.siteConfiguration
  );
  const getExtraSMSOptions = () => {
    const options = [];
    if (siteConfig.klaviyoConnected) {
      options.push({
        label: "Klaviyo",
        value: "klaviyo_sms",
      });
    }

    if (siteConfig.postscriptConnected) {
      options.push({
        label: "Postscript",
        value: "postscript",
      });
    }

    options.push({
      label: "Connect your sms platform",
      value: "connect_your_sms_platform",
      custom: true,
    });
    return options;
  };

  const smsOptions = [
    {
      label: "AssistAlong",
      value: "assistalong_sms",
    },
    ...getExtraSMSOptions(),
  ];

  const emailOptions = [
    {
      label: "AssistAlong",
      value: "assistalong_email",
    },
    ...(siteConfig.klaviyoConnected
      ? [
          {
            label: "Klaviyo",
            value: "klaviyo_email",
          },
        ]
      : []),
    {
      label: "Connect your email platform",
      value: "connect_your_email_platform",
      custom: true,
    },
  ];

  return (
    <>
      <div className="pb-5 w-full mb-7 bg-white rounded-lg reminder-delivery-section">
        <div className="px-6 message-type-wrapper py-6 border-b border-light-grey">
          <div className="flex justify-between flex-row">
            <div className="flex flex-col">
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                Reminder Delivery
              </p>
              <p className="text-10 tracking-tracking_001 text-dark-grey text-opacity-75 w-[478px]">
                Select the service for delivering reminders to customers. If you
                wish to use a third party service to send reminders, first you
                need to link your account under the “integration” page.
              </p>
            </div>
          </div>
        </div>
        <div className="px-6">
          <div className="flex w-full py-5 border-b border-light-grey">
            <span className="text-sm mt-2 tracking-tracking_001 font-medium text-black_shade mr-10 xl:mb-0 max-w-[114px]">
              Email reminder Flows
            </span>
            <div className="flex justify-between text-xs w-[277px]">
              <div className="dropdown-select-wrap w-full">
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,
                      boxShadow: "none",
                    }),
                  }}
                  options={emailOptions}
                  placeholder="DELIVER USING"
                  value={emailOptions.filter(
                    (data) =>
                      data.value === siteConfigInitials.emailDeliveryMethod
                  )}
                  isSearchable={false}
                  className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
                  components={{
                    SingleValue: SingleValueContainer,
                    Option: OptionValueContainer,
                  }}
                  onChange={(e: any) => {
                    if (e.custom) {
                      navigate("/integrations");
                    } else {
                      setFieldValueSiteConfig("emailDeliveryMethod", e.value);
                    }
                  }}
                />
              </div>
            </div>
            {siteConfigInitials.emailDeliveryMethod !== "assistalong_email" && (
              <div className="pl-[103px]">
                <a
                  href="https://assistalong.notion.site/Klaviyo-Setup-Guide-40a6fee68f6b46ec9df512f04016ceea#953e7d8f16d34f73a29d52eedd6c5486"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="rounded-md text-blue_default text-[13px] addproduct-button font-medium py-[9.5px] px-[26px] tracking-tracking_001">
                    See Instruction
                  </button>
                </a>
              </div>
            )}
          </div>
          <div className="flex w-full py-5">
            <span className="text-sm mt-2 tracking-tracking_001 font-medium text-black_shade mr-10 xl:mb-0 max-w-[114px]">
              Recurring SMS reminders
            </span>
            <div className="flex justify-between text-xs w-[277px]">
              <div className="dropdown-select-wrap w-full mb-2">
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,
                      boxShadow: "none",
                    }),
                  }}
                  value={smsOptions.filter(
                    (obj) => obj.value === siteConfigInitials.smsDeliveryMethod
                  )}
                  options={smsOptions}
                  placeholder="DELIVER USING"
                  isSearchable={false}
                  className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
                  components={{
                    SingleValue: SingleValueContainer,
                    Option: OptionValueContainer,
                  }}
                  onChange={(e: any) => {
                    if (e.custom) {
                      navigate("/integrations");
                    } else {
                      setFieldValueSiteConfig("smsDeliveryMethod", e.value);
                    }
                  }}
                />
              </div>
            </div>
            {siteConfigInitials.smsDeliveryMethod !== "assistalong_sms" && (
              <div className="pl-[103px]">
                <a
                  href="https://assistalong.notion.site/Klaviyo-Setup-Guide-40a6fee68f6b46ec9df512f04016ceea#953e7d8f16d34f73a29d52eedd6c5486"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="rounded-md text-blue_default text-[13px] addproduct-button font-medium py-[9.5px] px-[26px] tracking-tracking_001">
                    See Instruction
                  </button>
                </a>
              </div>
            )}
          </div>

          {siteConfigInitials.smsDeliveryMethod !== "assistalong_sms" && (
            <>
              <div className="w-full pt-6 pb-7">
                <div className="flex xl:flex-row flex-row xl:items-center items-center w-full">
                  <label className="tracking-wide_0.01 text-black_shade w-[135px] text-xs">
                    Terms of Service:
                  </label>
                  <div className="flex items-center">
                    <input
                      className={`rounded border text-xs tracking-tracking_001 ${
                        siteErrors.termsOfServiceLink
                          ? "border-red-600"
                          : "!border-gray2"
                      }  !py-[15px] px-3 w-[525px] bg-white text-black_shade focus:ring-0`}
                      type="text"
                      value={siteConfigInitials.termsOfServiceLink}
                      onChange={(e) => {
                        setFieldValueSiteConfig(
                          "termsOfServiceLink",
                          e?.target?.value
                        );
                      }}
                    />
                  </div>
                </div>
                {siteErrors.termsOfServiceLink && (
                  <p className="scroll__error text-danger pt-1 text-red-500 text-xs pl-[138px]">
                    {siteErrors["termsOfServiceLink"]}
                  </p>
                )}
              </div>
              <div className="w-full pb-9">
                <div className="flex xl:flex-row flex-row xl:items-center items-center w-full">
                  <div className="tracking-wide_0.01 text-black_shade w-[135px] text-xs">
                    Privacy policy:
                  </div>
                  <div className="flex items-center">
                    <input
                      className={`rounded border text-xs tracking-tracking_001 ${
                        siteErrors.privacyPolicyLink
                          ? "border-red-600"
                          : "!border-gray2"
                      } !py-[15px] px-3 w-[525px] bg-white text-black_shade focus:ring-0`}
                      type="text"
                      value={siteConfigInitials.privacyPolicyLink}
                      onChange={(e) => {
                        setFieldValueSiteConfig(
                          "privacyPolicyLink",
                          e.target.value
                        );
                      }}
                    />
                  </div>
                </div>
                {siteErrors.privacyPolicyLink && (
                  <p className="scroll__error text-danger pt-1 text-red-500 text-xs pl-[138px]">
                    {siteErrors["privacyPolicyLink"]}
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ReminderDelivery;

import { useState, useEffect } from "react";
import { AddProduct } from "./AddProduct";
import { useGetShopifyProducts } from "../requests/ShopifyProductRequests";
import { APIError } from "../../../layout/Error";
import { ShopifyProduct } from "../models/ShopifyProduct";
import { Switch } from "./Switch";
import { QueryObject } from "../models/ProductPageConfigurationModel";
import { IsAdmin } from "../../../util/helpers";
import { AdminProtectedContainer } from "../../../components/RoleProtectedContainer";
import { SelectProduct } from "../../smart_checkouts/models/SmartCheckout";

const UpsellProduct = ({
  upsellText,
  setUpsellText,
  upsellProducts,
  setUpsellProducts,
  initialUpsellProducts,
  upsellProductToAdd,
  setUpsellProductToAdd,
  upsellProductToDelete,
  setUpsellProductToDelete,
  mainProduct,
  active,
  setActive,
  save,
  staticProducts,
}: {
  setUpsellText: (upsellText: string) => void;
  upsellText: string;
  upsellProducts: ShopifyProduct[];
  setUpsellProducts: (products: ShopifyProduct[]) => void;
  initialUpsellProducts?: ShopifyProduct[];
  upsellProductToAdd: ShopifyProduct[];
  setUpsellProductToAdd: (product: ShopifyProduct[]) => void;
  upsellProductToDelete: ShopifyProduct[];
  setUpsellProductToDelete: (product: ShopifyProduct[]) => void;
  mainProduct?: ShopifyProduct;
  active: boolean;
  setActive: (flag: boolean) => void;
  save: boolean;
  staticProducts?: SelectProduct[];
}) => {
  const [showProductModal, setShowProductModal] = useState<boolean>(false);
  const [shopifyProductList, setShopifyProductList] = useState<
    ShopifyProduct[] | null
  >(null);
  // const [addedProducts, setAddedProducts] = useState<ShopifyProduct[]>([]);
  const [queryObj, setQueryObj] = useState<QueryObject>({
    has_next_page: false,
    next_page_info: "",
    search: "",
  });
  const {
    loading: shopifyLoading,
    error: shopifyError,
    refresh: shopifyRefresh,
    data: shopifyProducts,
  } = useGetShopifyProducts({
    search: queryObj.search,
    next_page_info: queryObj.next_page_info,
  });
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (shopifyProducts) {
      let tempProducts: ShopifyProduct[];
      if (staticProducts && staticProducts?.length > 0) {
        tempProducts = [...shopifyProducts.products].filter(
          (item) =>
            !staticProducts.some(
              (staticItem) => staticItem?.product?.id === +item.id
            )
        );
      } else {
        tempProducts = [...shopifyProducts.products];
      }
      if (shopifyProductList) {
        if (isLoadMore) {
          tempProducts = [...shopifyProductList, ...shopifyProducts.products];
        }
      }
      // removing main product from upsell products list
      setShopifyProductList(
        tempProducts.filter((item) => item.productId !== mainProduct?.productId)
      );
    }
    setQueryObj({
      ...queryObj,
      next_page_info: shopifyProducts ? shopifyProducts.nextPageInfo : "",
      has_next_page: shopifyProducts ? shopifyProducts.hasNextPage : false,
    });
    // eslint-disable-next-line
  }, [shopifyProducts]);

  useEffect(() => {
    if (showProductModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showProductModal]);

  const closeModal = () => {
    setShowProductModal(false);
    setShopifyProductList([]);
  };

  const onClickAddProduct = (product: ShopifyProduct) => {
    try {
      setUpsellProductToAdd([...upsellProductToAdd, product]);
      setUpsellProducts([...upsellProducts, product]);
      if (!active) {
        setActive(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveProduct = async (product: ShopifyProduct) => {
    try {
      if (
        upsellProductToAdd.some(
          (productInner) => productInner.id === product.id
        )
      ) {
        setUpsellProductToAdd([
          ...upsellProductToAdd.filter(
            (productInner) => productInner.id !== product.id
          ),
        ]);
      }
      if (
        upsellProducts.some((productInner) => productInner.id === product.id)
      ) {
        setUpsellProducts([
          ...upsellProducts.filter(
            (productInner) => productInner.id !== product.id
          ),
        ]);
      }

      if (
        initialUpsellProducts?.some(
          (productInner) => product.id === productInner.id
        ) &&
        !upsellProductToDelete.some(
          (productInner) => productInner.id !== product.id
        )
      ) {
        setUpsellProductToDelete([...upsellProductToDelete, product]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (shopifyError) {
    return (
      <APIError
        error={shopifyError}
        consentText="Consent to reading Customers"
        refresh={shopifyRefresh}
      />
    );
  }

  return (
    <>
      <div className="mb-7 w-full bg-white rounded-lg">
        <div className="message-type-wrapper py-6 px-6 border-b border-light-grey">
          <div className="flex justify-between flex-row">
            <div>
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                Upsell Products
              </p>
              <p className="text-10 text-dark-grey text-opacity-75">
                Which products do you want to upsell to customers?
              </p>
            </div>
            {/* <button
              onClick={() => setShowProductModal(true)}
              className="tracking-tracking_001 text-blue-600 text-xs font-medium"
            >
              Add new
            </button> */}
            <div className="flex flex-row pr-5 items-center">
              <span className="text-xs tracking-wider text-dark-grey text-opacity-75 mr-3">
                Active
              </span>
              <div
                className={!IsAdmin() ? "opacity-50 pointer-events-none" : ""}
              >
                <Switch state={active} onClick={() => setActive(!active)} />
              </div>
              <span className="text-xs tracking-wider text-dark-grey text-opacity-75 ml-3">
                Off
              </span>
            </div>
          </div>
        </div>
        <div>
          {upsellProducts.map((upsellProduct) => {
            return (
              <div
                key={upsellProduct.id}
                className="py-4 pr-7 ml-7 border-b border-light-grey flex justify-between items-center"
              >
                <div className="flex items-center">
                  <img
                    className="w-6 h-6 mr-2"
                    src={upsellProduct.imageUrl}
                    alt={upsellProduct.title}
                  ></img>
                  <h3 className="text-xs text-dark-grey tracking-tracking_001">
                    {upsellProduct.title}
                  </h3>
                </div>
                <button
                  onClick={() => onRemoveProduct(upsellProduct)}
                  className="text-blue_default text-xs tracking-tracking_001 flex items-center"
                >
                  <img
                    className="w-4 h-4 mr-2"
                    src="/delete-icon.svg"
                    alt="trash icon"
                  ></img>
                </button>
              </div>
            );
          })}
        </div>
        <div className="py-6 px-6 pb-7">
          <AdminProtectedContainer>
            <button
              onClick={() => setShowProductModal(true)}
              className="tracking-tracking_001 text-blue-600 text-xs font-medium mb-6"
            >
              Add Product
            </button>
          </AdminProtectedContainer>
          <p className="mb-4 tracking-tracking_001 text-xs text-dark-grey font-medium">
            Upsell Prompt
          </p>

          <div>
            <input
              disabled={!IsAdmin()}
              type="text"
              placeholder="Complete your regimen"
              value={upsellText}
              onChange={(e) => setUpsellText(e.target.value)}
              className="tracking-tracking_001 w-full px-3 py-3 border-dark-grey border-opacity-30 bg-white  border rounded text-xs font-light"
            />
          </div>
        </div>
      </div>

      {showProductModal && (
        <AddProduct
          onClose={() => closeModal()}
          show={showProductModal}
          shopifyProducts={shopifyProductList}
          onClickAddProduct={(product: ShopifyProduct) =>
            onClickAddProduct(product)
          }
          addedProducts={upsellProducts ?? []}
          setQueryObj={setQueryObj}
          shopifyLoading={shopifyLoading}
          queryObj={queryObj}
          refreshProducts={shopifyRefresh}
          setIsLoadMore={setIsLoadMore}
          search={search}
          setSearch={setSearch}
          isUpsell={true}
        />
      )}
    </>
  );
};

export { UpsellProduct };

import { ModalContainer } from "../../../components/ModalContainer";
import * as yup from "yup";
import {
  getClass,
  onInputBlur,
  onInputFocus,
  validateValues,
} from "../../../util/helpers";
import { useEffect, useState } from "react";
import {
  AllUserRoles,
  InviteUserRole,
  User as Auth0User,
  UserRole,
} from "../models/UserModel";
import Select from "react-select";
import { addRoleToUser, inviteUser } from "../requests/UserRequests";
import { SelectValueContainer } from "../../../components/SelectValueContainer";
import { useAppSelector } from "../../../redux/hooks";
import Button from "../../../components/Button";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  role: yup.string().required(),
});

interface State {
  email: string;
  role: AllUserRoles | InviteUserRole | UserRole;
}

interface SelectOption {
  label: string;
  value: InviteUserRole;
}

const InviteUserForm = ({
  inviter,
  state,
  setState,
  onSubmit,
  toUpdate,
  onClose,
  addError,
  setAddError,
}: {
  inviter: Auth0User;
  setState: (value: State) => void;
  state: State;
  onSubmit: () => void;
  toUpdate?: Auth0User;
  onClose: () => void;
  addError: string;
  setAddError: (error: string) => void;
}) => {
  const [focusedFields, setFocusedFields] = useState<string[]>([]);
  const businessId = useAppSelector((state) => state.business.businessId);

  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState(false);
  const [updating] = useState(toUpdate !== undefined);
  const submit = (e: any) => {
    e.preventDefault();
    const hasErrors = validateValues(state, schema, setErrors);

    if (!hasErrors && updating && toUpdate && inviter.email) {
      setDisabled(true);
      addRoleToUser(inviter.email, toUpdate.id, state?.role)
        .then(() => {
          setDisabled(false);
          onSubmit();
          onClose();
        })
        .catch((error) => {
          setAddError(error?.response?.data?.error || error.message);
          setDisabled(false);
        });
    }
    if (!hasErrors && !toUpdate && inviter.email) {
      setDisabled(true);
      inviteUser(inviter.email, state.email, state?.role, businessId)
        .then(() => {
          setDisabled(false);
          onSubmit();
          onClose();
        })
        .catch((error) => {
          setAddError(error?.response?.data?.error || error.message);
          setDisabled(false);
        });
    }
  };

  const options: InviteUserRole[] = ["reader", "admin"];

  const configOptions: SelectOption[] = options.map((name) => {
    const label = name === "admin" ? "Admin" : "Reader";
    return { label, value: name };
  });

  return (
    <div className="addUserModal">
      <h2 className="py-6 px-8 text-xs text-dark-grey font-medium border-b border-light-grey">
        {updating ? "Update User" : "Add New User"}
      </h2>
      <form onSubmit={(e) => submit(e)} className="form-wrap p-8 flex flex-col">
        <div
          className={`field-wrap mt-2 ${getClass(
            "email",
            state,
            focusedFields
          )}`}
        >
          <label
            htmlFor="txtEmail"
            className="input-label cursor-text top-0 transition-all duration-500 z-20"
          >
            Email address
          </label>
          <input
            required
            id="txtEmail"
            type="text"
            className="input-field pl-2.5 border-transparent rounded text-xs"
            placeholder=""
            disabled={toUpdate?.email !== undefined}
            value={state.email}
            onChange={(e) => {
              setState({ ...state, email: e.target.value });
              setAddError("");
            }}
            onFocus={() =>
              onInputFocus(focusedFields, setFocusedFields, "email")
            }
            onBlur={() => onInputBlur(focusedFields, setFocusedFields, "email")}
          />
        </div>
        <p className="text-danger pt-1 text-red-500 text-xs">
          {errors["email"]}
        </p>
        <div className="dropdown-select-wrap mt-3">
          <Select
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
                // border: "1px solid #808080",
              }),
            }}
            options={configOptions}
            placeholder="SET PERMISSION"
            onChange={(e) => {
              setState({ ...state, role: e!.value });
              setAddError("");
            }}
            isSearchable={false}
            value={configOptions.find((obj) => obj.value === state.role)}
            className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs mb-8 border-opacity-50"
            components={{ ValueContainer: SelectValueContainer }}
          />
        </div>
        <p className="text-danger pt-1 text-red-500 text-xs">
          {errors["role"]}
        </p>
        <div>
          <p className="text-danger pt-1 text-red-500 text-xs">{addError}</p>
        </div>
        <Button
          text={updating ? "Update" : "Invite"}
          className={`py-3 font-medium tracking-wide_0.01 ${
            disabled ? "px-3" : "px-7"
          } text-13px mt-6 flex max-w-max text-white rounded-md float-right self-end bg-blue_default`}
          loading={disabled}
          disabled={disabled}
        />
      </form>
    </div>
  );
};

export const InviteUserModal = ({
  user,
  show,
  onClose,
  onSubmit,
  existing,
  toUpdate,
}: {
  user: Auth0User | undefined;
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  existing?: State;
  toUpdate?: Auth0User;
}) => {
  const [state, setState] = useState<State>({
    role: toUpdate?.role || "reader",
    email: toUpdate?.email || "",
  });
  const [addError, setAddError] = useState<string>("");

  const handleClose = () => {
    setState({ email: "", role: "reader" });
    setAddError("");
    onClose();
  };

  useEffect(() => {
    if (toUpdate) {
      setState({
        email: toUpdate.email,
        role: toUpdate.role === "Admin" ? "admin" : "reader",
      });
    } else {
      setState({ email: "", role: "reader" });
    }

    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <ModalContainer show={show} onClose={handleClose}>
      {user && (
        <InviteUserForm
          inviter={user}
          // {...existing}
          onClose={onClose}
          onSubmit={onSubmit}
          toUpdate={toUpdate}
          state={state}
          setState={setState}
          setAddError={setAddError}
          addError={addError}
        />
      )}
    </ModalContainer>
  );
};

import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";
import { SpinningLoader } from "../../layout/Loaders";
import { Login } from "../login/Login";
import FormInput from "../login/FormInput";

const SkeletonLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="flex flex-grow items-center justify-center px-8 py-8 bg-[#F5F5F5] min-h-screen">
      {children}
    </div>
  );
};

const RequiresAuth = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [authorized, setAauthorized] = useState(false);
  const [modalShow, setModalShow] = React.useState(true);

  useEffect(() => {
    const token = localStorage.getItem("auth_jwt");
    let user: any = localStorage.getItem("user");
    if (token && user) {
      user = JSON.parse(user);
      if (!user?.email) {
        setAauthorized(false);
        setLoading(false);
      }

      setAauthorized(true);
      setLoading(false);
    } else {
      navigate("/login");
      setAauthorized(false);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return (
      <SkeletonLayout>
        <SpinningLoader fullHeight={false} />
      </SkeletonLayout>
    );
  }

  if (!loading && !authorized) {
    return (
      <>
        {/* <SkeletonLayout>
          <button className="default-button" onClick={() => setModalShow(true)}>
            Log in
          </button>
        </SkeletonLayout> */}
        <Login
          show={modalShow}
          onHide={() => setModalShow(false)}
          title="Log In"
          buttonText="Log In"
          formData={<FormInput />}
        />
      </>
    );
  }

  return <Outlet />;
};

export { RequiresAuth };

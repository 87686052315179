import * as React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  BarElement,
  ArcElement,
  LineController,
} from "chart.js";
import { useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { useGetShopifyProducts } from "../smart_reminders/requests/ShopifyProductRequests";
import { ProductQueryCard } from "./components/ProductQueryCard";
import { OrdersChart } from "./components/OrdersChart";
import { updateDashboard, useGetDashboard } from "./requests/DashboardRequests";
import {
  Dashboard,
  OrdersInsightChart,
  ProductInsightChart,
  RangeTypes,
  UpdateDashboardRequest,
} from "./models/DashboardModel";
import { AddProductQueryCard } from "./components/AddProductQueryCard";
// import { AddQrCodeQueryCard } from "./components/AddQrCodeQueryCard";
import { SelectValueContainer } from "../../components/SelectValueContainer";
import { AdminProtectedContainer } from "../../components/RoleProtectedContainer";
import { ResponsiveButton } from "../../components/ResponsiveButton";
import { AddInsightQueryCard } from "./components/AddInsightQueryCard";
import { useAppSelector } from "../../redux/hooks";
import { QueryObject } from "../smart_reminders/models/ProductPageConfigurationModel";
import { ShopifyProduct } from "../smart_reminders/models/ShopifyProduct";
import UnlockFeature from "../../components/UnlockFeature";
import { upgradePlan } from "../../util/helpers";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  BarElement,
  ArcElement,
  LineController
);

export interface TimeState {
  from: Date;
  to: Date;
  rangeType: RangeTypes;
}
const initialUpdateRequest: UpdateDashboardRequest = {
  productInsightChartsAttributes: [],
  rangeType: "custom",
};

const TimeInput = ({
  setQuery,
  from,
  to,
  defaultRange,
}: {
  from: Date;
  to: Date;
  defaultRange: Number;
  setQuery: (newState: TimeState) => void;
}) => {
  const [startDate, setStartDate] = useState<Date | null>(from);
  const [endDate, setEndDate] = useState<Date | null>(to);
  const [rangeType, setRangeType] = useState<RangeTypes>("custom");
  const [labelValue, setLabelValue] = useState<Number | null>(
    defaultRange || 30
  );

  // const getMinDate = (): Date | null => {
  //   if (startDate && !endDate) {
  //     const minDate = new Date(startDate);
  //     minDate.setDate(minDate.getDate());
  //     return minDate;
  //   }
  //   return null;
  // };

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    const currentDate = new Date();

    if (currentDate.getDate() === start?.getDate()) {
      setStartDate(start);
      setEndDate(currentDate);
    } else {
      setStartDate(start);
      setEndDate(end);
    }

    if (start && end) {
      setQuery({ from: start, to: end, rangeType });
    } else if (start && !end && currentDate.getDate() === start?.getDate()) {
      setQuery({ from: start, to: currentDate, rangeType });
    }
  };

  const dateFormate = (date: Date) => {
    return (
      (date?.getMonth() ? date?.getMonth() : 0) +
      1 +
      "/" +
      date?.getDate() +
      "/" +
      date?.getFullYear()
    );
  };

  const handleSelect = (value: number) => {
    setLabelValue(value);
    const today = new Date();
    const minusDays = new Date();
    minusDays.setDate(today.getDate() - value);

    setStartDate(minusDays);
    setEndDate(today);

    var newRangeType: RangeTypes = "custom";
    if (value === 30) {
      newRangeType = "last_30";
    } else if (value === 60) {
      newRangeType = "last_60";
    } else if (value === 7) {
      newRangeType = "last_week";
    }
    setRangeType(newRangeType);

    setQuery({ from: minusDays, to: today, rangeType: newRangeType });
  };

  const CustomCalendarContainer = ({
    // className,
    children,
  }: {
    className?: string;
    children: React.ReactNode[];
  }) => {
    const options = [
      { label: "Last 30 days", value: 30 },
      { label: "Last 60 days", value: 60 },
      { label: "Last week", value: 7 },
    ];

    return (
      <CalendarContainer className={`${"className"} custom-calendar-wrapper`}>
        <div className="dropdown-select-wrap mb-3">
          <Select
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
                border: "1px solid #dcdfe4",
              }),
              valueContainer: (base) => ({
                ...base,
                marginTop: "auto",
                display: "flex",
                justifyContent: "center",
                alignContent: "left",
              }),
              menuPortal: (base) => ({ ...base, zIndex: 1 }),
            }}
            menuPortalTarget={document.body}
            options={options}
            placeholder="DATE RANGE"
            onChange={(e) => handleSelect(e!.value)}
            value={options.filter((option) => {
              return option.value === labelValue;
            })}
            components={{ ValueContainer: SelectValueContainer }}
            isSearchable={false}
            className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-30"
          />
        </div>
        <div className="grid grid-cols-2 gap-4 justify-between">
          <div>
            <div className="field-wrap mt-2 has-value">
              <label className="input-label top-0 transition-all duration-500">
                STARTING
              </label>
              <input
                required
                type=""
                className="input-field p-2.5"
                placeholder=""
                value={dateFormate(startDate ? startDate : from)}
                disabled
              />
            </div>
          </div>
          <div>
            <div className="field-wrap mt-2 has-value">
              <label className="input-label">ENDING</label>
              <input
                required
                type=""
                className="input-field p-2.5"
                placeholder="ENDING"
                value={dateFormate(endDate ? endDate : to)}
                disabled
              />
            </div>
          </div>
        </div>
        <div className="relative my-3">{children}</div>
      </CalendarContainer>
    );
  };

  const CalendarInput = React.forwardRef<
    HTMLButtonElement,
    React.HTMLProps<HTMLButtonElement>
  >(({ onClick, value }, ref) => (
    <button
      className="flex items-center border gap-2 bg-white p-2 h-10 rounded border-gray-300"
      onClick={onClick}
      ref={ref}
    >
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.00001 0.75C6.41423 0.75 6.75001 1.08579 6.75001 1.5V2.24998H11.25V1.5C11.25 1.08579 11.5858 0.75 12 0.75C12.4142 0.75 12.75 1.08579 12.75 1.5V2.24998H14.25C15.4926 2.24998 16.5 3.25734 16.5 4.49998V15C16.5 16.2426 15.4926 17.25 14.25 17.25H3.75C2.50736 17.25 1.5 16.2426 1.5 15V4.49998C1.5 3.25734 2.50736 2.24998 3.75 2.24998H5.25001V1.5C5.25001 1.08579 5.5858 0.75 6.00001 0.75ZM5.25001 3.74998H3.75C3.33579 3.74998 3 4.08576 3 4.49998V6.74998H15V4.49998C15 4.08576 14.6642 3.74998 14.25 3.74998H12.75V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.5858 5.25 11.25 4.91421 11.25 4.5V3.74998H6.75001V4.5C6.75001 4.91421 6.41423 5.25 6.00001 5.25C5.5858 5.25 5.25001 4.91421 5.25001 4.5V3.74998ZM15 8.24998H3V15C3 15.4142 3.33579 15.75 3.75 15.75H14.25C14.6642 15.75 15 15.4142 15 15V8.24998Z"
          fill="#1D1D1B"
        />
        <rect x="11.7002" y="12.6002" width="1.8" height="1.8" fill="#1D1D1B" />
      </svg>
      <span className="text-xs text-designer_grey tracking-tracking_001 font-medium">
        {value}
      </span>
    </button>
  ));

  return (
    <DatePicker
      popperClassName={`${
        startDate?.getDate() === endDate?.getDate() ? "same-day" : ""
      }`}
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      monthsShown={2}
      // minDate={getMinDate()}
      customInput={<CalendarInput />}
      calendarContainer={(props) => <CustomCalendarContainer {...props} />}
      maxDate={new Date()}
      placeholderText="Select a date range that is a minimum of 1 week"
    />
  );
};

const InsightsPage = () => {
  const businessId = useAppSelector((state) => state.business.businessId);
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );

  const [queryObj, setQueryObj] = useState<QueryObject>({
    has_next_page: false,
    next_page_info: "",
    search: "",
  });

  const { data: dashboard, refresh } = useGetDashboard(businessId);

  const [state, setState] = useState<TimeState>();

  const {
    data: products,
    refresh: shopifyRefresh,
    loading: shopifyLoading,
  } = useGetShopifyProducts({
    search: queryObj.search,
    next_page_info: queryObj.next_page_info,
  });

  const [updateRequest, setUpdateRequest] = useState<UpdateDashboardRequest>({
    ...initialUpdateRequest,
  });

  const [shopifyProductList, setShopifyProductList] = useState<
    ShopifyProduct[] | null
  >(null);

  const [productCharts, setProductCharts] = useState<ProductInsightChart[]>([]);

  const [deletedProductCharts, setDeletedProductCharts] = useState<
    ProductInsightChart[]
  >([]);
  const [orderCharts, setOrderCharts] = useState<OrdersInsightChart[]>([]);

  const [deletedOrderCharts, setDeletedOrderCharts] = useState<
    OrdersInsightChart[]
  >([]);

  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);

  const changeDate = (newState: TimeState) => {
    setUpdateRequest({
      ...updateRequest,
      from: newState.from,
      to: newState.to,
      rangeType: newState.rangeType,
    });
    setState(newState);
  };

  const setProductInsightChartAttributes = (
    product: ProductInsightChart,
    index?: number
  ) => {
    const tempProductCharts = [...productCharts];
    const deleted = [...deletedProductCharts];
    if (index !== undefined) {
      if (product._destroy) {
        tempProductCharts.splice(index, 1);
        if (product.id) {
          setDeletedProductCharts([...deleted, product]);
        }
      } else {
        tempProductCharts[index] = product;
      }
    } else {
      tempProductCharts.push(product);
    }
    setProductCharts(tempProductCharts);
  };

  const setOrderInsightChartAttributes = (
    order: OrdersInsightChart,
    index?: number
  ) => {
    const tempOrderCharts = [...orderCharts];
    const deleted = [...deletedOrderCharts];
    if (index !== undefined) {
      if (order._destroy) {
        tempOrderCharts.splice(index, 1);
        if (order.id) {
          setDeletedOrderCharts([...deleted, order]);
        }
      } else {
        tempOrderCharts[index] = order;
      }
    } else {
      tempOrderCharts.push(order);
    }
    setOrderCharts(tempOrderCharts);
  };

  // const setOrderInsightChartAttributes = (
  //   updates: OrdersInsightChartAttributes
  // ) => {
  //   if (state) {
  //     setUpdateRequest({
  //       ...updateRequest,
  //       from: state.from,
  //       to: state.to,
  //       ordersInsightChartAttributes: [updates],
  //     });
  //   }
  // };

  const saveDashboard = async (
    dash: Dashboard,
    req: UpdateDashboardRequest
  ) => {
    await updateDashboard(dash.id, {
      ...req,
      productInsightChartsAttributes: [
        ...[...productCharts].map((item) => {
          if (item.tempId) {
            delete item.tempId;
          }
          return item;
        }),
        ...deletedProductCharts,
      ],
      ordersInsightChartsAttributes: [
        ...[...orderCharts].map((item) => {
          if (item.tempId) {
            delete item.tempId;
          }
          return item;
        }),
        ...deletedOrderCharts,
      ],
    });
    setUpdateRequest({ ...initialUpdateRequest });
    refresh();
  };

  React.useEffect(() => {
    if (dashboard) {
      const from = dashboard?.from ? new Date(dashboard.from) : new Date();
      const to = dashboard?.to ? new Date(dashboard.to) : new Date();
      if (!dashboard.from && !dashboard.to) from.setDate(to.getDate() - 30);
      setState({ from, to, rangeType: dashboard.rangeType });
      setProductCharts(dashboard.productInsightCharts);
      setOrderCharts(dashboard.ordersInsightChart);
    }
  }, [dashboard]);

  React.useEffect(() => {
    if (products) {
      let tempProducts: ShopifyProduct[] = [...products.products];
      if (shopifyProductList) {
        if (isLoadMore) {
          tempProducts = [...shopifyProductList, ...products.products];
        }
      }
      setShopifyProductList(tempProducts);
    }
    setQueryObj({
      ...queryObj,
      next_page_info: products ? products.nextPageInfo : "",
      has_next_page: products ? products.hasNextPage : false,
    });
    // eslint-disable-next-line
  }, [products]);

  const getDefaultRange = (rt: RangeTypes) => {
    switch (rt) {
      case "last_30":
        return 30;
      case "last_60":
        return 60;
      case "last_week":
        return 7;
      default:
        return 0;
    }
  };

  return (
    <>
      {!upgradePlan(permission, "insights") ? (
        <>
          <h1 className="text-2xl text-black_shade font-medium mr-10">
            Insights
          </h1>
          <UnlockFeature subscription={subscription} />
        </>
      ) : (
        <div className="flex flex-col w-full">
          <div className="title flex justify-between mb-8 relative">
            <h1 className="text-2xl text-black_shade font-medium w-5/12">
              Insights
            </h1>
            <div className="flex space-between w-7/12 h-10">
              {state && (
                <TimeInput
                  from={state.from}
                  to={state.to}
                  setQuery={(newState) => changeDate(newState)}
                  defaultRange={getDefaultRange(state.rangeType)}
                />
              )}

              <AdminProtectedContainer>
                {dashboard && (
                  <ResponsiveButton
                    classNames={`text-white tracking-tracking_001 font-medium bg-green-500
                px-5 py-2 text-sm rounded-md`}
                    text="Save"
                    onClick={(e) => saveDashboard(dashboard, updateRequest)}
                  />
                )}
              </AdminProtectedContainer>
            </div>
          </div>

          <div className="flex flex-col wrap gap-2">
            {businessId && state && dashboard && (
              <div className="mt-5 flex flex-col">
                <h3 className="mb-5 text-base text-designer_grey tracking-tracking_001 font-semibold">
                  Overall
                </h3>
                {orderCharts.map((item, index) => (
                  <div className="bg-white rounded mb-5" key={index}>
                    <OrdersChart
                      orderChart={item}
                      index={index}
                      defaultType={item.insightType}
                      businessId={businessId}
                      state={state}
                      handleUpdate={setOrderInsightChartAttributes}
                    />
                  </div>
                ))}
                <AdminProtectedContainer>
                  <div className={`flex mt-6 max-h-chartCard`}>
                    <AddInsightQueryCard
                      handleUpdate={setOrderInsightChartAttributes}
                      insights={orderCharts}
                      state={state}
                    />
                  </div>
                </AdminProtectedContainer>
              </div>
            )}
            {products && state && dashboard && (
              <>
                <div className="mt-10 flex flex-col">
                  <h3 className="text-base text-designer_grey tracking-tracking_001 font-semibold">
                    Products
                  </h3>
                  <div className="rounded flex flex-wrap productGraph">
                    {productCharts
                      .filter((item) => !item._destroy)
                      .map((productChart, index) => (
                        <div
                          className={`w-1/2 flex ${
                            (index + 1) % 2 === 0 ? "pl-3" : "pr-3"
                          } mt-6`}
                          key={`${index}_${productChart.id}_${productChart.tempId}`}
                        >
                          <ProductQueryCard
                            productChart={productChart}
                            handleUpdate={setProductInsightChartAttributes}
                            products={shopifyProductList ?? []}
                            queryObj={queryObj}
                            refreshProducts={shopifyRefresh}
                            loadingProducts={shopifyLoading}
                            setIsLoadMore={setIsLoadMore}
                            state={state}
                            index={index}
                          />
                        </div>
                      ))}
                    <AdminProtectedContainer>
                      <div
                        className={`w-1/2 flex ${
                          productCharts.length % 2 ? "mt-6 pl-3" : "pr-3"
                        } min-h-chartCard mt-6 max-h-chartCard`}
                      >
                        <AddProductQueryCard
                          handleUpdate={setProductInsightChartAttributes}
                          products={products.products}
                          state={state}
                        />
                      </div>
                    </AdminProtectedContainer>
                  </div>
                </div>
              </>
            )}
            {/* {businessId && state && dashboard && (
            <>
              <div className="mt-10 flex-wrap">
                <h3 className="mb-6 text-base text-designer_grey tracking-tracking_001 font-semibold">
                  Reminders
                </h3>
                <div className="bg-white rounded">
                  <RemindersChart businessId={businessId} state={state} />
                </div>
              </div>
            </>
          )} */}
          </div>
        </div>
      )}
    </>
  );
};

export { InsightsPage };

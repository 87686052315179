import * as yup from "yup";
import { validateValues } from "../util/helpers";
import { ModalContainer } from "../components/ModalContainer";
import React, { useEffect, useState } from "react";
import { contactUs } from "../pages/account/requests/UserRequests";
import { User } from "../pages/account/models/UserModel";
import Button from "../components/Button";

export const ContactUsModal = ({
  user,
  isContactUsModalOpen,
  setIsContactUsModalOpen,
  setIsSubmit,
}: {
  user: User | undefined;
  isContactUsModalOpen: boolean;
  setIsContactUsModalOpen: (value: boolean) => void;
  setIsSubmit: (value: boolean) => void;
}) => {
  return (
    <>
      <div className="z-50">
        <ModalContainer
          show={isContactUsModalOpen}
          onClose={() => setIsContactUsModalOpen(false)}
        >
          {user && (
            <ContactUsForm
              email={user.email}
              setIsContactUsModalOpen={setIsContactUsModalOpen}
              setIsSubmit={setIsSubmit}
            />
          )}
        </ModalContainer>
      </div>
    </>
  );
};
const ContactUsForm = ({
  email,
  setIsContactUsModalOpen,
  setIsSubmit,
}: {
  email: string;
  setIsContactUsModalOpen: (value: boolean) => void;
  setIsSubmit: (value: boolean) => void;
}) => {
  const [contactUsData, setContactUsData] = useState<{
    subject: string;
    body: string;
  }>({
    subject: "",
    body: "",
  });
  const [contactFormErrors, setContactFormErrors] = React.useState<any>({});
  const [isButtonDisabled, setIsButtonDisabled] =
    React.useState<boolean>(false);

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setContactUsData({
      ...contactUsData,
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };

  const contactUsSchema = yup.object().shape({
    subject: yup
      .string()
      .trim()
      .max(80, "Subject must be at most 80 characters")
      .required("Subject is required"),
    body: yup
      .string()
      .trim()
      .max(400, "Body must be at most 400 characters")
      .required("Body is required"),
  });

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const hasErrors = validateValues(
      contactUsData,
      contactUsSchema,
      setContactFormErrors
    );
    setIsButtonDisabled(true);
    if (!hasErrors) {
      contactUs(email, contactUsData.subject, contactUsData.body).then(() => {
        setIsButtonDisabled(false);
        setIsContactUsModalOpen(false);
        setIsSubmit(true);
      });
    }
  };

  useEffect(() => {
    if (isButtonDisabled) {
      const timer = setInterval(() => {
        setIsButtonDisabled(false);
      }, 3000);

      return () => clearInterval(timer);
    }
  }, [isButtonDisabled]);

  const hasValues = () => {
    return contactUsData.body.trim() && contactUsData.subject.trim();
  };

  return (
    <>
      <div className="z-50">
        <h2 className="py-6 px-8 text-xs text-dark-grey font-medium border-b border-light-grey">
          Contact Us
        </h2>
        <form onSubmit={(e) => onSubmit(e)}>
          <div className="form-wrap p-8 flex flex-col contactus-modal">
            <input
              className="rounded border border-border_gray px-3 py-4 text-xs font-medium text-opacity-50 tracking-wide"
              type="text"
              name="subject"
              value={contactUsData.subject}
              onChange={(e) => onChange(e)}
              placeholder="Subject"
            ></input>
            <p className="mb-5 text-danger pt-1 text-red-500 text-xs">
              {contactFormErrors["subject"]}
            </p>

            <textarea
              className="rounded border border-border_gray px-3 py-4 text-xs font-medium text-opacity-50 tracking-wide"
              placeholder="Tell us how we can best help you..."
              rows={7}
              name="body"
              value={contactUsData.body}
              onChange={(e) => onChange(e)}
            ></textarea>
            <p className="text-danger pt-1 text-red-500 text-xs">
              {contactFormErrors["body"]}
            </p>
            <Button
              text="Submit"
              type="submit"
              className={`text-white text-13px leading-0-1em font-medium cursor-pointer ${
                isButtonDisabled || !hasValues()
                  ? "bg-blue-100"
                  : "!bg-blue_default"
              } py-3 px-6 mt-6 flex max-w-max rounded-md`}
              disabled={isButtonDisabled || !hasValues()}
            />
          </div>
        </form>
      </div>
    </>
  );
};

import { User } from "../models/UserModel";
import { deleteUser } from "../requests/UserRequests";

const DeleteUserModal = ({
  user,
  onClose,
  refresh,
}: {
  user: User | null;
  onClose: () => void;
  refresh: () => void;
}) => {
  const handleDelete = async () => {
    if (user) {
      deleteUser(user.email).then(() => {
        refresh();
        onClose();
      });
    }
  };

  return (
    <div
      className={` transition ease-linear duration-100 relative z-20 ${
        user ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div
        className={`min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover transition ease-in-out duration-500 ${
          user ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <div
          className={`absolute bg-black  inset-0 z-0 transition ease-in-out duration-500 ${
            user ? "opacity-50" : "opacity-0"
          }`}
        ></div>
        <div
          className={`w-full max-w-[400px] relative mx-auto my-auto rounded-lg shadow-lg bg-white transition ease-in-out duration-500  ${
            user ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <button
            className="cursor-pointer bg-off_white w-8 h-8 shadow-btn_shadow flex items-center justify-center rounded-full absolute -left-3 -top-3"
            onClick={() => onClose()}
          >
            <img src="/close-btn.svg" alt="close button"></img>
          </button>
          <div className="product-list overflow-y-auto max-h-84vh">
            <div className="p-8">
              <div className="mt-4 mb-[54px]">
                <div className="text-base">
                  Are you sure you want to delete this user?
                </div>
              </div>
              <div className="flex justify-between items-center">
                <button
                  className="py-[9.5px] font-medium border border-gray-800 tracking-wide_0.01 px-[40px] min-w-[124px] w-[124px] text-13px  flex max-w-max text-black rounded-md float-left self-end"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
                <button
                  className="py-[10px] font-medium border border-btnRed tracking-wide_0.01 px-[50px] min-w-[124px] w-[124px] text-13px  flex max-w-max text-white rounded-md float-left self-end bg-btnRed"
                  onClick={handleDelete}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteUserModal;

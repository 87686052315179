import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserHistory } from "history";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

const Auth0ProviderWithHistory = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  const domain: string = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const audience: string = process.env.REACT_APP_API_AUDIENCE || "";
  const history = createBrowserHistory();

  const onRedirectCallback = (appState?: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      cacheLocation="localstorage"
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      useRefreshTokens={true}
    >
      <HistoryRouter history={history}>{children}</HistoryRouter>
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;

import { useState } from "react";
import { User } from "./models/UserModel";
import { reinviteUser, useGetUsers } from "./requests/UserRequests";
import DataTable, { TableColumn } from "react-data-table-component";
import { InviteUserModal } from "./components/InviteUserModal";
import { PlansAndPricing } from "./components/PlansAndPricing";
import { AdminProtectedContainer } from "../../components/RoleProtectedContainer";
import * as yup from "yup";
import { useSearchParams } from "react-router-dom";
// import { GoLiveButton } from "../../components/GoLiveButton";
import Legal from "./components/Legal";
import { useAppSelector } from "../../redux/hooks";
import DeleteUserModal from "./components/DeleteUserModal";
import UnlockFeature from "../../components/UnlockFeature";
import { upgradePlan } from "../../util/helpers";
import { setDisConnectAccount } from "./requests/DisconnectAccountRequest";
import Button from "../../components/Button";

export const siteConfigSchema = (
  isWhitelabel?: boolean,
  checkTermsPolicy?: boolean
) => {
  return yup.object().shape({
    brandName: yup
      .string()
      .trim()
      .matches(/^[a-z\d\s]+$/i, "Only alphanumeric values are allowed.")
      .required("Brand name is required"),
    primaryColor: yup.string().trim().required("Primary color is required"),
    secondaryColor: yup.string().trim().required("Secondary color is required"),
    ...(checkTermsPolicy && {
      termsOfServiceLink: yup
        .string()
        .trim()
        .required("Terms of service is required"),
      privacyPolicyLink: yup
        .string()
        .trim()
        .required("Privacy policy is required"),
    }),
    ...(isWhitelabel && {
      merchantDomain: yup
        .string()
        .trim()
        .required("Merchant domain is required"),
    }),
  });
};

const Pending = ({
  currentUser,
  toInvite,
}: {
  currentUser: User | undefined;
  toInvite: User;
}) => {
  if (toInvite.auth0UserId !== null || currentUser?.id === toInvite.id) {
    return <p>{toInvite.email}</p>;
  }

  return (
    <div className="flex items-center gap-2">
      <p>{toInvite.email}</p>
      <div className="bg-gray-200 p-2 rounded-sm flex items-center gap-2">
        <p className="font-bold">Pending</p>
        {currentUser && (
          <button
            className="text-blue_default"
            onClick={() => reinviteUser(currentUser.email, toInvite.id)}
          >
            Resend Invite
          </button>
        )}
      </div>
    </div>
  );
};

const UpdateUser = ({
  currentUser,
  toUpdate,
  onSubmit,
  refresh,
  setDeleteUser,
}: {
  currentUser: User | undefined;
  toUpdate: User;
  onSubmit: () => void;
  refresh: () => void;
  setDeleteUser: (u: User | null) => void;
}) => {
  const [show, setShow] = useState(false);

  if (currentUser?.id === toUpdate.id) {
    return null;
  }

  const removeUser = (u: User) => {
    setDeleteUser(u);
    // deleteUser(u.email).then(() => {
    //   refresh();
    // });
  };

  return (
    <>
      <AdminProtectedContainer>
        <div className="flex items-center ml-auto gap-2">
          <div className="text-blue_default text-xs px-3 cursor-pointer">
            <button className="font-medium" onClick={() => setShow(true)}>
              Update
            </button>
          </div>
          <button
            className="px-3 cursor-pointer"
            onClick={() => removeUser(toUpdate)}
          >
            <svg
              width="16"
              height="18"
              viewBox="0 0 16 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.50001 2.25C6.3011 2.25 6.11033 2.32902 5.96968 2.46967C5.82903 2.61032 5.75001 2.80109 5.75001 3V3.74999H10.25V3C10.25 2.80109 10.171 2.61032 10.0303 2.46967C9.88969 2.32902 9.69893 2.25 9.50001 2.25H6.50001ZM11.75 3.74999V3C11.75 2.40326 11.513 1.83097 11.091 1.40901C10.669 0.987053 10.0968 0.75 9.50001 0.75H6.50001C5.90328 0.75 5.33098 0.987053 4.90902 1.40901C4.48707 1.83097 4.25001 2.40326 4.25001 3V3.74999L1.25 3.74999C0.835786 3.74999 0.5 4.08577 0.5 4.49999C0.5 4.9142 0.835786 5.24999 1.25 5.24999H2L2.00001 15C2.00001 15.5967 2.23706 16.169 2.65902 16.591C3.08098 17.0129 3.65327 17.25 4.25001 17.25H11.75C12.3468 17.25 12.919 17.0129 13.341 16.591C13.763 16.169 14 15.5967 14 15V5.24999H14.75C15.1642 5.24999 15.5 4.9142 15.5 4.49999C15.5 4.08577 15.1642 3.74999 14.75 3.74999H11.75ZM5.00389 5.24999C5.0026 5.25 5.00131 5.25 5.00001 5.25C4.99872 5.25 4.99742 5.25 4.99613 5.24999L3.5 5.24999L3.50001 15C3.50001 15.1989 3.57903 15.3897 3.71968 15.5303C3.86033 15.671 4.0511 15.75 4.25001 15.75H11.75C11.9489 15.75 12.1397 15.671 12.2803 15.5303C12.421 15.3897 12.5 15.1989 12.5 15V5.25L11.002 5.25C11.0014 5.25 11.0007 5.25 11 5.25C10.9993 5.25 10.9987 5.25 10.998 5.25L5.00389 5.24999Z"
                fill="#2E5ED1"
              />
            </svg>
          </button>
        </div>
      </AdminProtectedContainer>
      <InviteUserModal
        user={currentUser}
        onClose={() => setShow(false)}
        onSubmit={() => onSubmit()}
        show={show}
        toUpdate={toUpdate}
      />
    </>
  );
};

const AccountTab = ({
  users,
  onInvite,
}: {
  users: User[];
  onInvite: () => void;
}) => {
  const [deleteUser, setDeleteUser] = useState<User | null>(null);
  const owner = users.find(({ role }) => role === "Billing Admin");
  const others = users.filter(({ role }) => role !== "Billing Admin");

  const user: any = useAppSelector((state) => state.user.user);
  const [show, setShow] = useState(false);

  const onSubmit = () => {
    onInvite();
    setShow(false);
  };

  const columns: TableColumn<User>[] = [
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Role",
      selector: (row) => row.role,
    },
    {
      name: "Email",
      grow: 3,
      cell: (row) => <Pending currentUser={user} toInvite={row} />,
    },
    {
      cell: (row) => {
        return (
          <UpdateUser
            setDeleteUser={setDeleteUser}
            currentUser={user}
            toUpdate={row}
            onSubmit={onSubmit}
            refresh={onInvite}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="pt-8 px-8 pb-6">
        <div className="tab-title pb-8">
          <div className="flex items-center mt-2 w-full">
            <span className="text-xs tracking-wide_0.01 text-black_shade mr-4 xl:mb-0">
              Account Owner
            </span>
            <div className=" flex items-center justify-between border rounded border-gray-300 px-4 py-3 text-xs h-11 w-60">
              <p>{owner?.name}</p>
              <img alt="lock" className="ml-2" src="/lock.png"></img>
            </div>
            <AdminProtectedContainer>
              <div className="ml-8 btn-wrap">
                <button
                  onClick={() => setShow(true)}
                  className="text-blue_default text-xs addproduct-button font-normal py-3 px-6"
                >
                  Add New User
                </button>
              </div>
            </AdminProtectedContainer>
          </div>
        </div>
        <InviteUserModal
          user={user}
          onClose={() => setShow(false)}
          onSubmit={() => onSubmit()}
          show={show}
        />
        {others.length > 0 && (
          <DataTable
            customStyles={{
              table: {
                style: { "border-radius": "4px", overflow: "hidden" },
              },
              headCells: {
                style: {
                  padding: "15px",
                },
              },
              cells: {
                style: {
                  padding: "15px",
                },
              },
            }}
            className="table-class"
            columns={columns}
            data={others}
          />
        )}
        <DeleteUserModal
          user={deleteUser}
          onClose={() => setDeleteUser(null)}
          refresh={onInvite}
        />
      </div>
    </>
  );
};

const tabs = ["Users", "Plans and Pricing", "Legal"];

const AccountPage = () => {
  const params: any = useSearchParams();
  const businessId = useAppSelector((state) => state.business.businessId);
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );

  let tempActive = "Users";
  if (params[0].get("t") && tabs.includes(params[0].get("t"))) {
    tempActive = params[0].get("t");
    window.history.pushState({}, document.title, "/settings");
  }
  const [activeTab, setActiveTab] = useState<string>(tempActive);

  const { data: users, refresh } = useGetUsers(businessId);

  const DisConnectAccount = async () => {
    const res = await setDisConnectAccount(businessId);
    if (res && res?.status === 200) {
      window.location.href = "/";
    }
  };

  return (
    <>
      {!upgradePlan(permission, "account") ? (
        <>
          <h1 className="text-2xl text-black_shade font-medium mr-10">
            Account
          </h1>
          <UnlockFeature subscription={subscription} />
        </>
      ) : (
        <div className="flex flex-col w-full">
          <div className="title flex gap-2 items-center justify-between mb-10">
            <h1 className="text-2xl text-black_shade font-medium">Account</h1>
            <div className="items-end flex gap-2">
              <Button
                text="Disconnect"
                className={`text-blue_default text-xs addproduct-button font-normal h-10 px-7 rounded-md flex items-center text-13`}
                onClick={() => DisConnectAccount()}
              />
            </div>
          </div>
          <ul className="flex border-b border-[#ACB7C4] px-8">
            {tabs.map((tabName) => (
              <li
                className={`tab cursor-pointer mr-10 py-5 px-2 text-13px tracking-wide_0.01 ${
                  activeTab === tabName
                    ? " active font-medium text-blue_default border-b-2 border-blue_default"
                    : "text-dark-blue"
                }`}
                key={tabName}
                onClick={() => setActiveTab(tabName)}
              >
                {tabName}
              </li>
            ))}
          </ul>
          <div className="bg-white rounded mt-7">
            {activeTab === "Users" && (
              <AccountTab users={users || []} onInvite={refresh} />
            )}
            {activeTab === "Plans and Pricing" && (
              <PlansAndPricing subscription={subscription} />
            )}
            {activeTab === "Legal" && <Legal />}
          </div>
        </div>
      )}
    </>
  );
};

export { AccountPage };

import {
  ChartArea,
  ChartData,
  Chart as ChartJS,
  ChartOptions,
  TimeScale,
} from "chart.js";
import { useEffect, useRef, useState } from "react";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
ChartJS.register(TimeScale);

export interface ChartDataset<T> {
  data: T[];
  label: string;
}

// interface ChartColor {
//   backgroundColor: string;
//   borderColor: string;
// }

function createGradient(ctx: CanvasRenderingContext2D, area: ChartArea) {
  const gradient = ctx.createLinearGradient(0, area.bottom, 0, area.top);

  gradient.addColorStop(0, "#ffffff");
  gradient.addColorStop(1, "#2E5ED1");
  return gradient;
}

export const LineChart = ({
  title,
  labels,
  datasets,
  average,
}: {
  title: string;
  labels: string[];
  datasets: ChartDataset<any>[];
  average: ChartDataset<any>;
}) => {
  const options: ChartOptions<any> = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem: any) {
            const label = tooltipItem?.[0]?.label?.split(",")[0];
            return label;
          },
          label: function (context: any) {
            let label = context?.dataset?.label
              ?.split(/[_ ]/g)
              .map(
                (lbl: string) =>
                  lbl.charAt(0).toUpperCase() + lbl.substring(1, lbl.length)
              )
              .join(" ");

            return label + " : " + context.formattedValue;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: title,
      },
      filler: {
        propagate: false,
      },
      interaction: {
        intersect: false,
      },
    },
    scales: {
      xAxis: {
        display: true,
        type: "time",
        time: {
          unit: "day",
        },
        grid: {
          display: false,
        },
      },
      yAxis: {
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            if (value >= 0 && value % 1 === 0) {
              return value;
            }
          },
        },
      },
    },
  };

  const chartRef = useRef<ChartJS>(null);
  const [chartData, setChartData] = useState<ChartData<"bar">>({
    datasets: [],
  });

  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const mapped = datasets.map(({ label, data }) => {
      return {
        label,
        data,
        backgroundColor: createGradient(chart.ctx, chart.chartArea),
        borderColor: "#2E5ED1",
        fill: "start",
      };
    });

    const data = {
      labels,
      datasets: [average, ...mapped],
    };

    const chartData = {
      ...data,
      datasets: data.datasets,
    };

    setChartData(chartData);
  }, [labels, datasets, average]);

  return (
    <Chart
      type="line"
      width={1050}
      height={196}
      ref={chartRef}
      data={chartData}
      options={options}
    />
  );
};

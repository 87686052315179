import { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { Business, SetupStatus } from "../pages/guides/models/Business";
import Select from "react-select";
import axios from "axios";
import { useAppSelector } from "../redux/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { setGlobalLoading } from "../redux/ducks/business.duck";
// import { User } from "../pages/settings/models/UserModel";

const Menu = ({
  setIsNotificationModalOpen,
  setIsContactUsModalOpen,
  setupStatus,
  stores,
}: {
  setIsNotificationModalOpen: (value: boolean) => void;
  setIsContactUsModalOpen: (value: boolean) => void;
  setupStatus: SetupStatus;
  stores: Array<Business>;
}) => {
  const [user, setUser] = useState<any>();
  const [selectedStore, setSelectedStore] = useState<any>({
    value: -1,
    label: "",
  });
  const { logout } = useAuth0();

  const _user: any = useAppSelector((state) => state.user.user);
  const dispatch = useDispatch();
  useEffect(() => {
    setUser(_user);

    const _selectedStore = parseInt(
      localStorage.getItem("selected_store") || "-1"
    );
    if (_selectedStore !== -1) {
      const ownerStore = stores.find(
        (store: Business) => store.id === _selectedStore
      );
      if (ownerStore) {
        setSelectedStore({ value: ownerStore.id, label: ownerStore.name });
      }
    }
    // eslint-disable-next-line
  }, [stores]);

  const middleLinkClassName = "text-dark-blue text-sm mt-5";
  const sidebarMenu = [
    {
      title: "Smart Reminders",
      image: `/svg/ico_smart_reminder.svg`,
      activeImage: `/svg/ico_smart_reminder_active.svg`,
      redirect: "smart-reminders",
      enabled:
        setupStatus.site.status === "completed" ||
        setupStatus.reminderStep.status === "completed",
    },
    {
      title: "Smart Checkouts",
      image: `/svg/ico_smart-checkouts.svg`,
      activeImage: `/svg/ico_smart-checkouts_active.svg`,
      redirect: "smart-checkouts",
      enabled:
        setupStatus.site.status === "completed" ||
        setupStatus.reminderStep.status === "completed",
    },
    {
      title: "Customization",
      image: "/svg/ico_settings.svg",
      activeImage: "/svg/ico_settings_active.svg",
      redirect: "customization",
      enabled: true,
    },
    {
      title: "Orders",
      image: "/svg/ico_orders.svg",
      activeImage: "/svg/ico_orders_active.svg",
      redirect: "orders",
      enabled: setupStatus.reminderStep.status === "completed",
    },
    {
      title: "Customers",
      image: "/svg/ico_customers.svg",
      activeImage: "/svg/ico_customers_active.svg",
      redirect: "customers",
      enabled: setupStatus.reminderStep.status === "completed",
    },
    {
      title: "Insights",
      image: "/svg/ico_insights.svg",
      activeImage: "/svg/ico_insights_active.svg",
      redirect: "insights",
      enabled: setupStatus.reminderStep.status === "completed",
    },
    {
      title: "Integrations",
      image: "/svg/ico_integrations.svg",
      activeImage: "/svg/ico_integrations_active.svg",
      redirect: "integrations",
      enabled: true,
    },
    {
      title: "Account",
      image: "/svg/ico_account.svg",
      activeImage: "/svg/ico_account_active.svg",
      redirect: "accounts",
      enabled: true,
    },
  ];

  const updateSelectedStore = async (e: any) => {
    if (!user) return;
    const request = { default_business: e.value };
    dispatch(setGlobalLoading(true));
    await axios
      .put(`${process.env.REACT_APP_API_URL}/users/${user.id}`, request)
      .catch((e) => {
        dispatch(setGlobalLoading(false));
      });
    const storedUser = JSON.parse(localStorage.getItem("user") || "{}");

    localStorage.setItem(
      "user",
      JSON.stringify({ ...storedUser, defaultBusiness: e.value })
    );
    window.location.href = "/";
  };

  const handleLogout = () => {
    localStorage.clear();
    logout();
    window.location.reload();
  };

  const customStyles = {
    menuList: (provided: any, state: any) => ({
      padding: 6,
    }),

    option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#1d4ed8"
          : isFocused
          ? "#fff"
          : undefined,
        color: isDisabled
          ? "#ccc"
          : isSelected
          ? "#ccc"
            ? "white"
            : "black"
          : data.color,
        cursor: isDisabled ? "not-allowed" : "pointer",
        borderRadius: 4,
      };
    },
  };

  const getProgressImage = () => {
    const { site, reminderStep, reminderStatus, smartCheckoutStatus, goLive } =
      setupStatus;
    const completedCount = [
      site,
      reminderStatus,
      smartCheckoutStatus,
      reminderStep,
      goLive,
    ].filter((item) => item.status === "completed").length;
    switch (completedCount) {
      case 1:
        return "/progress_20.svg";
      case 2:
        return "/progress_40.svg";
      case 3:
        return "/progress_60.svg";
      case 4:
        return "/progress_80.svg";
      case 5:
        return "/progress_100.svg";
      default:
        return "/progress_0.svg";
    }
  };

  return (
    <>
      <nav className="bg-white shadow-custom min-h-screen z-10">
        {/* <div className="container flex flex-col min-h-screen min-w-xxs"> */}
        <div className="container flex flex-col min-h-screen w-sidemenu">
          <div className="flex items-center justify-between w-full pt-5 px-4">
            {/* {siteConfiguration && siteConfiguration.imageUrl ? (
            <img
              src={siteConfiguration.imageUrl}
              alt="brand-logo"
              className="mr-3 w-16 h-full "
            />
          ) : (
            <img
              src="/blue_aa_logo.svg"
              alt="assist-along-logo"
              className="mr-3 w-6 h-full"
            />
          )} */}
            {selectedStore.value !== -1 ? (
              <Select
                options={stores.map((store: Business) => ({
                  value: store.id,
                  label: store.name,
                }))}
                defaultValue={selectedStore}
                value={selectedStore}
                className="text-xs font-normal"
                styles={customStyles}
                isSearchable={false}
                onChange={(e) => {
                  localStorage.setItem("selected_store", `${e.value}`);
                  setSelectedStore(e);
                  updateSelectedStore(e);
                }}
              />
            ) : null}
            <div
              className="ml-3 relative"
              onClick={() => setIsNotificationModalOpen(true)}
            >
              <svg
                width="20"
                height="22"
                viewBox="0 0 20 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.22709 2.22706C6.49296 0.961189 8.20985 0.250031 10.0001 0.250031C11.7903 0.250031 13.5072 0.961189 14.773 2.22706C16.0389 3.49293 16.7501 5.20982 16.7501 7.00003C16.7501 10.3895 17.4754 12.5135 18.1585 13.7659C18.5011 14.394 18.8371 14.8105 19.0758 15.0623C19.1953 15.1885 19.2909 15.2739 19.3513 15.3243C19.3815 15.3494 19.403 15.3659 19.4142 15.3743L19.4229 15.3806C19.6931 15.565 19.8129 15.9037 19.7179 16.2174C19.6221 16.5337 19.3306 16.75 19.0001 16.75H1.00006C0.669558 16.75 0.378013 16.5337 0.282239 16.2174C0.187256 15.9037 0.307033 15.565 0.57726 15.3806L0.585892 15.3743C0.597159 15.3659 0.618606 15.3494 0.648826 15.3243C0.709246 15.2739 0.804831 15.1885 0.924344 15.0623C1.16298 14.8105 1.49904 14.394 1.84164 13.7659C2.52476 12.5135 3.25006 10.3895 3.25006 7.00003C3.25006 5.20982 3.96122 3.49293 5.22709 2.22706ZM2.68988 15.25H17.3102C17.1568 15.0265 16.9987 14.7721 16.8416 14.4842C16.0248 12.9866 15.2501 10.6105 15.2501 7.00003C15.2501 5.60764 14.6969 4.27229 13.7124 3.28772C12.7278 2.30315 11.3924 1.75003 10.0001 1.75003C8.60767 1.75003 7.27231 2.30315 6.28775 3.28772C5.30318 4.27229 4.75006 5.60764 4.75006 7.00003C4.75006 10.6105 3.97536 12.9866 3.15848 14.4842C3.0014 14.7721 2.84336 15.0265 2.68988 15.25ZM7.89373 19.3513C8.25202 19.1434 8.71097 19.2654 8.91881 19.6237C9.02869 19.8131 9.18641 19.9703 9.37617 20.0796C9.56593 20.1889 9.78107 20.2465 10.0001 20.2465C10.219 20.2465 10.4342 20.1889 10.6239 20.0796C10.8137 19.9703 10.9714 19.8131 11.0813 19.6237C11.2891 19.2654 11.7481 19.1434 12.1064 19.3513C12.4647 19.5591 12.5866 20.018 12.3788 20.3763C12.1371 20.7931 11.7901 21.139 11.3726 21.3794C10.9551 21.6199 10.4818 21.7465 10.0001 21.7465C9.51829 21.7465 9.04497 21.6199 8.6275 21.3794C8.21002 21.139 7.86305 20.7931 7.62131 20.3763C7.41347 20.018 7.53544 19.5591 7.89373 19.3513Z"
                  fill="#334D6E"
                />
              </svg>
              <div className="w-2 h-2 bg-red-500 absolute right-0 top-0 rounded-full"></div>
            </div>
          </div>
          <div className="w-full border-b mt-4" />
          <div className="mt-6 flex items-center px-5 flex-wrap">
            <div className="sm:h-11 sm:w-11 w-9 h-9 text-lg rounded-full from-[#A2B5E4] bg-gradient-to-tl text-[#1D4095] flex items-center justify-center overflow-hidden">
              {user && user.nickname && (
                <p className="uppercase text-[#1D4095]">
                  {user && user.nickname.slice(0, 2)}
                </p>
              )}
            </div>
            <div className="sm:pl-3 pl-1 w-3/4">
              <p className="text-sm font-medium truncate">{user?.email}</p>
              <p className="text-xs tracking-wide_0.01">
                <button
                  className="text-blue-700"
                  onClick={() => handleLogout()}
                >
                  Log out
                </button>
              </p>
            </div>
          </div>

          <ul className="mt-6">
            <li className="px-6 bg-blue-700 py-4 {middleLinkClassName}">
              <div className="flex justify-between">
                <div>
                  <Link className="text-white flex items-center" to="/">
                    <img src="/svg/ico_startguide.svg" alt=""></img>{" "}
                    <span className="text-xs font-medium ml-3 tracking-wide_0.01">
                      Startguide
                    </span>
                  </Link>
                </div>
                <div>
                  <img src={getProgressImage()} width="21" alt=""></img>
                </div>
              </div>
            </li>
          </ul>
          <ul className="flex flex-col justify-center mt-8 px-6">
            {sidebarMenu.map((item, index) => (
              <li
                className={
                  item.title !== "Smart Reminders"
                    ? middleLinkClassName
                    : "active"
                }
                key={index}
              >
                <NavLink
                  to={!item.enabled ? "#" : item.redirect}
                  className={`text-sm flex items-center tracking-wide_0.01 font-medium`}
                  aria-current="page"
                >
                  {({ isActive }) => (
                    <>
                      <img
                        className="w-4"
                        alt=""
                        src={`${
                          isActive && item.enabled
                            ? item.activeImage
                            : item.image
                        }`}
                      ></img>

                      <span
                        className={`ml-3 text-13px ${
                          isActive && item.enabled
                            ? "text-blue-700"
                            : "text-dark-blue"
                        }`}
                      >
                        {item.title}
                      </span>
                    </>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="w-full border-b mt-5" />
          <ul className="flex justify-center flex-col px-5">
            <li className={middleLinkClassName}>
              <NavLink
                className="text-dark-blue text-sm flex items-center hover:text-blue-700 tracking-wide_0.01 font-medium"
                to="/faqs"
              >
                <img className="w-4" src="/svg/ico_faqs.svg" alt=""></img>
                <span className="ml-3 text-13px">FAQs</span>
              </NavLink>
            </li>
            <li className={middleLinkClassName}>
              <a
                className="text-dark-blue text-sm flex items-center hover:text-blue-700 tracking-wide_0.01 font-medium"
                href="https://www.assistalong.com/company"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="w-4" src="/svg/ico_abouts.svg" alt=""></img>
                <span className="ml-3 text-13px">About Us</span>
              </a>
            </li>
            <li
              className={middleLinkClassName}
              onClick={() => setIsContactUsModalOpen(true)}
            >
              <p className="cursor-pointer text-dark-blue text-sm flex items-center hover:text-blue-700 tracking-wide_0.01 font-medium">
                <img className="w-4" src="/svg/ico_mail.svg" alt=""></img>
                <span className="ml-3 text-13px">Contact Us</span>
              </p>
            </li>
          </ul>
          <div className="flex-grow flex items-center mt-10 px-5">
            <svg
              width="91"
              height="24"
              viewBox="0 0 91 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1705_205)">
                <path
                  d="M27.7939 13.6396H27.9824C28.0237 13.647 28.066 13.6462 28.1069 13.6372C28.1479 13.6282 28.1866 13.6112 28.221 13.5872C28.2553 13.5631 28.2846 13.5325 28.3071 13.4971C28.3295 13.4617 28.3447 13.4222 28.3519 13.3809L30.8314 6.57812H32.4461L34.9182 13.3809C34.9253 13.4222 34.9405 13.4617 34.963 13.4971C34.9855 13.5325 35.0147 13.5631 35.0491 13.5872C35.0834 13.6112 35.1222 13.6282 35.1631 13.6372C35.2041 13.6462 35.2464 13.647 35.2877 13.6396H35.4761V14.9477H34.5523C34.3309 14.9875 34.1027 14.9379 33.9178 14.8098C33.7328 14.6816 33.6062 14.4854 33.5658 14.264L33.0448 12.786H30.2179L29.6969 14.264C29.6782 14.3732 29.638 14.4776 29.5788 14.5711C29.5195 14.6646 29.4423 14.7455 29.3516 14.8091C29.2609 14.8726 29.1585 14.9176 29.0503 14.9414C28.9422 14.9652 28.8304 14.9673 28.7214 14.9477H27.7939V13.6396ZM32.6937 11.5296L31.9547 9.43078C31.8032 8.9726 31.6258 8.18184 31.6258 8.18184H31.5999C31.5999 8.18184 31.4226 8.9726 31.2711 9.43078L30.569 11.5296H32.6937Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M36.3115 13.037C36.7732 13.5508 37.415 13.8671 38.1037 13.9201C38.5619 13.9201 38.9166 13.7316 38.9166 13.3326C38.9166 12.4827 35.7462 12.4938 35.7462 10.5501C35.7462 9.34919 36.8288 8.79492 38.0778 8.79492C38.8907 8.79492 40.1878 9.06467 40.1878 10.0439V10.6684H38.8797V10.3728C38.8797 10.092 38.4547 9.95151 38.1406 9.95151C37.6085 9.95151 37.2427 10.14 37.2427 10.491C37.2427 11.4333 40.439 11.23 40.439 13.2513C40.439 14.382 39.4376 15.0989 38.1295 15.0989C37.6604 15.1082 37.1946 15.0174 36.7633 14.8326C36.3319 14.6479 35.9449 14.3733 35.6279 14.0273L36.3115 13.037Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M41.5326 13.037C41.9915 13.5483 42.6289 13.8643 43.3136 13.9201C43.7718 13.9201 44.1266 13.7316 44.1266 13.3326C44.1266 12.4827 40.9561 12.4938 40.9561 10.5501C40.9561 9.34919 42.0388 8.79492 43.2879 8.79492C44.1009 8.79492 45.3978 9.06467 45.3978 10.0439V10.6684H44.0898V10.3728C44.0898 10.092 43.6646 9.95151 43.3505 9.95151C42.8185 9.95151 42.4527 10.14 42.4527 10.491C42.4527 11.4333 45.6489 11.23 45.6489 13.2513C45.6489 14.382 44.6478 15.0989 43.3393 15.0989C42.8705 15.1082 42.4046 15.0174 41.9732 14.8326C41.5419 14.6479 41.1548 14.3733 40.8379 14.0273L41.5326 13.037Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M46.7755 10.4691C46.7802 10.434 46.7772 10.3982 46.7657 10.3646C46.7545 10.331 46.7357 10.3004 46.7104 10.2754C46.6855 10.2503 46.6551 10.2314 46.6212 10.2201C46.5878 10.2089 46.5518 10.2056 46.5167 10.2105H46.0439V8.93565H47.4702C48.0244 8.93565 48.2609 9.1832 48.2609 9.73747V13.4327C48.2567 13.4678 48.2605 13.5033 48.2721 13.5368C48.2837 13.5701 48.3025 13.6006 48.3274 13.6257C48.3522 13.6509 48.3822 13.6702 48.4157 13.6822C48.4491 13.6942 48.4842 13.6985 48.5194 13.695H48.9925V14.955H47.5662C47.0121 14.955 46.7755 14.7185 46.7755 14.1532V10.4691ZM46.8458 6.57812H48.1427V7.9601H46.8458V6.57812Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M49.9834 13.037C50.4441 13.55 51.0844 13.8662 51.7718 13.9201C52.2334 13.9201 52.5882 13.7316 52.5882 13.3326C52.5882 12.4827 49.4181 12.4938 49.4181 10.5501C49.4181 9.34919 50.5007 8.79492 51.7495 8.79492C52.5625 8.79492 53.8594 9.06467 53.8594 10.0439V10.6684H52.5514V10.3728C52.5514 10.092 52.1262 9.95151 51.8125 9.95151C51.2802 9.95151 50.9142 10.14 50.9142 10.491C50.9142 11.4333 54.1105 11.23 54.1105 13.2513C54.1105 14.382 53.1094 15.0989 51.7975 15.0989C51.3291 15.1079 50.8637 15.017 50.4329 14.8322C50.0022 14.6474 49.6161 14.373 49.2998 14.0273L49.9834 13.037Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M55.2048 10.1236H54.4248V8.9338H55.2378V7.29688H56.716V8.9338H58.0831V10.1236H56.716V12.5625C56.7095 12.7156 56.7357 12.8683 56.7935 13.0103C56.851 13.1523 56.9388 13.2802 57.0502 13.3854C57.1617 13.4905 57.2945 13.5705 57.4394 13.6198C57.5847 13.669 57.7385 13.6865 57.8911 13.671C57.9815 13.6724 58.072 13.6637 58.1607 13.6451V14.9532C58.0094 14.9789 57.856 14.9912 57.7025 14.9902C56.8638 14.9902 55.2048 14.7426 55.2048 12.725V10.1236Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M58.3975 13.6396H58.586C58.6272 13.647 58.6696 13.6462 58.7103 13.6372C58.7515 13.6282 58.79 13.6112 58.8247 13.5872C58.859 13.5631 58.8882 13.5325 58.9105 13.4971C58.9332 13.4617 58.9482 13.4222 58.9555 13.3809L61.4425 6.57812H63.0569L65.5328 13.3809C65.5396 13.4225 65.5546 13.4622 65.5769 13.4979C65.5992 13.5335 65.6283 13.5643 65.663 13.5884C65.6973 13.6126 65.7363 13.6295 65.7775 13.6383C65.8186 13.6471 65.861 13.6475 65.9022 13.6396H66.0908V14.9477H65.1706C64.5796 14.9477 64.3688 14.7925 64.1806 14.264L63.6633 12.786H60.8326L60.3153 14.264C60.2965 14.3736 60.2562 14.4783 60.1966 14.5721C60.1375 14.6659 60.0599 14.747 59.9686 14.8106C59.8773 14.8742 59.7749 14.9191 59.666 14.9426C59.5576 14.9661 59.4453 14.9678 59.336 14.9477H58.4047L58.3975 13.6396ZM63.3012 11.5296L62.5619 9.43078C62.4106 8.9726 62.2332 8.18184 62.2332 8.18184H62.2109C62.2109 8.18184 62.0335 8.9726 61.8783 9.43078L61.1613 11.5296H63.3012Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M66.8585 8.13011C66.8637 8.09497 66.8602 8.05918 66.8491 8.02558C66.8379 7.99194 66.8191 7.96138 66.7938 7.93631C66.769 7.91124 66.7381 7.89234 66.7047 7.88107C66.6708 7.8698 66.6352 7.8665 66.6001 7.87143H66.127V6.57812H67.5532C68.1078 6.57812 68.3551 6.82571 68.3551 7.37998V13.4252C68.3512 13.4603 68.3551 13.4959 68.3667 13.5293C68.3782 13.5628 68.3971 13.5932 68.4219 13.6184C68.4468 13.6435 68.4768 13.6628 68.5098 13.6748C68.5432 13.6868 68.5788 13.6911 68.6139 13.6876H69.0759V14.9477H67.6604C67.0951 14.9477 66.8585 14.7112 66.8585 14.1458V8.13011Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M72.7157 8.79492C74.5632 8.79492 76.0157 10.103 76.0157 11.9432C76.0157 13.7834 74.556 15.0878 72.7157 15.0878C70.8758 15.0878 69.4307 13.7908 69.4307 11.9432C69.4307 10.0956 70.8904 8.79492 72.7157 8.79492ZM72.7157 13.8167C72.9582 13.8174 73.1982 13.7689 73.4215 13.674C73.6448 13.579 73.8462 13.4397 74.0138 13.2645C74.1814 13.0893 74.3117 12.8817 74.3965 12.6546C74.4818 12.4275 74.5195 12.1854 74.508 11.9432C74.5238 11.6992 74.4891 11.4545 74.4068 11.2243C74.3241 10.9941 74.1951 10.7834 74.028 10.605C73.8604 10.4266 73.6585 10.2844 73.434 10.1872C73.2098 10.09 72.9677 10.0398 72.7234 10.0398C72.4787 10.0398 72.2365 10.09 72.0124 10.1872C71.7878 10.2844 71.586 10.4266 71.4188 10.605C71.2512 10.7834 71.1222 10.9941 71.04 11.2243C70.9572 11.4545 70.923 11.6992 70.9384 11.9432C70.9264 12.1843 70.9637 12.4254 71.0472 12.6517C71.1312 12.8781 71.2602 13.0851 71.4261 13.2603C71.5924 13.4354 71.7925 13.575 72.0141 13.6707C72.2357 13.7663 72.4744 13.8159 72.7157 13.8167Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M77.0792 10.4678C77.0835 10.4325 77.0797 10.3966 77.0681 10.3631C77.0561 10.3295 77.0368 10.299 77.0115 10.2741C76.9862 10.2491 76.9554 10.2302 76.9219 10.219C76.8881 10.2077 76.8521 10.2043 76.8169 10.2091H76.3477V8.93429H77.7259C78.2578 8.93429 78.5167 9.18188 78.5167 9.60681V9.78419C78.5154 9.87098 78.5068 9.95751 78.4909 10.0428H78.5167C78.7164 9.65644 79.0207 9.33419 79.3952 9.11322C79.7698 8.89229 80.1992 8.78159 80.6338 8.79389C81.9641 8.79389 82.7291 9.48857 82.7291 11.0812V13.4239C82.7257 13.4592 82.7299 13.4949 82.7419 13.5282C82.7539 13.5616 82.7732 13.592 82.7985 13.617C82.8234 13.6421 82.8538 13.6614 82.8872 13.6733C82.9207 13.6853 82.9562 13.6897 82.9914 13.6863H83.4607V14.9463H82.0382C81.4729 14.9463 81.2364 14.7098 81.2364 14.1444V11.3879C81.2364 10.6488 81.0478 10.1611 80.2939 10.1611C79.9267 10.1563 79.5684 10.2744 79.2757 10.4967C78.9829 10.719 78.7729 11.0326 78.6791 11.3879C78.5981 11.6473 78.5582 11.9179 78.5608 12.1897V14.9463H77.0831L77.0792 10.4678Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M86.4246 8.7947C87.8141 8.7947 88.2722 9.6446 88.2722 9.6446H88.2945V9.48937C88.2945 9.21963 88.4831 8.9351 88.9558 8.9351H90.3452V10.2099H89.8871C89.8536 10.205 89.8198 10.2075 89.7872 10.2173C89.7551 10.227 89.7251 10.2438 89.7002 10.2664C89.6749 10.289 89.6552 10.3169 89.6424 10.348C89.6291 10.3791 89.6231 10.4127 89.6248 10.4464V14.5813C89.6248 16.6432 88.0245 17.4229 86.4542 17.4229C85.7154 17.4157 84.9881 17.2374 84.3294 16.9019L84.7914 15.7231C85.3074 15.9801 85.8744 16.1177 86.4504 16.1259C87.3594 16.1259 88.1505 15.7564 88.1505 14.6478V14.367C88.1505 14.2229 88.1505 14.0455 88.1505 14.0455H88.1244C87.9384 14.3109 87.6855 14.5226 87.3919 14.6595C87.0979 14.7963 86.7735 14.8535 86.4504 14.8252C84.7322 14.8252 83.7529 13.4358 83.7529 11.7841C83.7529 10.1324 84.6842 8.79102 86.4174 8.79102L86.4246 8.7947ZM88.1724 11.7952C88.1724 10.4279 87.4888 10.0622 86.6942 10.0622C85.7852 10.0622 85.2902 10.7236 85.2902 11.736C85.2902 12.7485 85.8225 13.5651 86.7906 13.5651C87.5072 13.5651 88.1908 13.1513 88.1908 11.7952"
                  fill="#2E5ED1"
                />
                <path
                  d="M12.0819 24.0037C18.7104 24.0037 24.0838 18.6303 24.0838 12.0018C24.0838 5.37343 18.7104 0 12.0819 0C5.45351 0 0.0800781 5.37343 0.0800781 12.0018C0.0800781 18.6303 5.45351 24.0037 12.0819 24.0037Z"
                  fill="#2E5ED1"
                />
                <path
                  d="M12.0781 12.0072L12.0815 11.9902L12.0781 12.0072Z"
                  fill="white"
                />
                <path
                  d="M11.8154 12.7186L12.3439 11.2812L11.8154 12.7186Z"
                  fill="white"
                />
                <path
                  d="M19.8459 12.3279H10.5711L11.905 8.63267L13.1023 11.7662H14.5582L12.6441 6.54492H11.4358L9.54014 11.6738L9.31844 12.2909V12.3093L8.77891 13.7468L8.29489 15.0512C8.05776 14.5675 7.74181 14.1267 7.36 13.7468H8.20986L8.73829 12.3093H4.30411C4.28563 12.4276 4.30411 13.2553 4.30411 13.5916C4.4584 13.6289 4.6102 13.6757 4.75861 13.732C5.44039 13.9818 6.0577 14.3808 6.56534 14.9C7.07299 15.4191 7.4581 16.0452 7.69257 16.7325C7.771 16.9642 7.83271 17.2013 7.87733 17.4419H8.7235L8.78633 17.2756L10.0575 13.7468H13.8598L15.2787 17.4419H16.3023C16.3377 17.246 16.3846 17.0523 16.4427 16.8618C16.6626 16.1484 17.0436 15.4952 17.5561 14.9525C18.0687 14.4098 18.6991 13.9922 19.3988 13.732C19.5577 13.6766 19.7166 13.6248 19.8792 13.5842L19.8459 12.3279ZM15.8182 15.1768L15.2898 13.7468H16.8011C16.3894 14.1598 16.0563 14.6444 15.8182 15.1768Z"
                  fill="white"
                />
                <path
                  d="M12.1932 11.6914L11.9678 12.3085L12.1932 11.6914Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1705_205">
                  <rect width="90.4286" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </nav>
    </>
  );
};

export { Menu };

import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { APIError } from "../../../layout/Error";
import { MassUpdateBannerRequest } from "../models/ProductPageConfigurationModel";
import { BannerFields } from "../ProductDetailsPage";
import { massUpdateBanner } from "../requests/ProductPageConfigurationRequests";
import BannerCart from "./BannerCart";
import { MassEditHeader } from "./MassEditHeader";

const MassEditBanner = () => {
  const [saveError, setSaveError] = useState<boolean>(false);
  const [bannerFields, setBannerFields] = useState<BannerFields>({
    isActive: false,
    text: "",
  });
  const [productIds, setProductIds] = useState<string[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const qParams = new URLSearchParams(location.search);
      if (qParams.get("ids")) {
        const decryptedPids = atob(qParams.get("ids") ?? "");
        setProductIds(decryptedPids.split(","));
      } else {
        throw Error();
      }
    } catch (e) {
      navigate("/smart-reminders");
    }
    // eslint-disable-next-line
  }, []);

  const onSave = () => {
    return new Promise((resolve, reject) => {
      const updateRequest: MassUpdateBannerRequest = {
        productConfigurationIds: productIds,
        bannerMessage: bannerFields.text,
        addBannerFlag: bannerFields.isActive,
        // applyDiscountToFullCart: allDiscountActive,
      };
      massUpdateBanner(updateRequest)
        .then((res: any) => {
          if (res.data.errorMessage) {
            setSaveError(true);
            setTimeout(() => {
              setSaveError(false);
            }, 3000);
            reject();
          } else {
            resolve(true);
          }
        })
        .catch(() => {
          setSaveError(true);
          setTimeout(() => {
            setSaveError(false);
          }, 5000);
          reject();
        });
    });
  };
  return (
    <MassEditHeader
      onSave={onSave}
      newValue={{
        bannerFields: bannerFields,
      }}
      defaultValue={{
        bannerFields: { isActive: false, text: "" },
      }}
    >
      {saveError && (
        <APIError
          error={"Something went wrong! Please try again"}
          consentText=""
          refresh={() => {}}
        />
      )}
      <BannerCart
        bannerFields={bannerFields}
        setBannerFields={setBannerFields}
      />
    </MassEditHeader>
  );
};

export default MassEditBanner;

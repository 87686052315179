import { useEffect, useMemo, useState } from "react";
import Button from "../../../components/Button";

import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";
import { PostscriptType } from "../models/IntegrationModel";

const ConnectPostscriptModal = ({
  show,
  onClose,
  siteConfig,
  setSiteConfig,
  onSubmit,
  loading,
  error,
  setError,
}: {
  show: boolean;
  onClose: () => void;
  siteConfig: SiteConfiguration;
  setSiteConfig: (data: SiteConfiguration) => void;
  onSubmit: (values: PostscriptType) => void;
  loading: boolean;
  error: string;
  setError: (error: string) => void;
}) => {
  const [values, setValues] = useState<PostscriptType>({
    postscriptXshopToken: siteConfig.postscriptXshopToken,
    postscriptKeyword: siteConfig.postscriptKeyword,
  });

  const canEnableSave = useMemo(() => {
    if (
      siteConfig.postscriptXshopToken === "" &&
      siteConfig.postscriptKeyword === ""
    ) {
      if (
        values.postscriptXshopToken !== siteConfig.postscriptXshopToken &&
        values.postscriptKeyword !== siteConfig.postscriptKeyword &&
        !error
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      if (
        values.postscriptXshopToken !== siteConfig.postscriptXshopToken ||
        values.postscriptKeyword !== siteConfig.postscriptKeyword ||
        !error
      ) {
        return false;
      } else {
        return true;
      }
    }
    // eslint-disable-next-line
  }, [values, error]);

  useEffect(() => {
    if (siteConfig) {
      setValues({
        postscriptXshopToken: siteConfig.postscriptXshopToken,
        postscriptKeyword: siteConfig.postscriptKeyword,
      });
    }
  }, [siteConfig]);

  return (
    <div
      className={` transition ease-linear duration-100 relative z-20 ${
        show ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div
        className={`min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover transition ease-in-out duration-500 ${
          show ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <div
          className={`absolute bg-black  inset-0 z-0 transition ease-in-out duration-500 ${
            show ? "opacity-50" : "opacity-0"
          }`}
        ></div>
        <div
          className={`w-full max-w-[778px] relative mx-auto my-auto rounded-lg shadow-lg bg-white transition ease-in-out duration-500  ${
            show ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <button
            className="cursor-pointer bg-off_white w-8 h-8 shadow-btn_shadow flex items-center justify-center rounded-full absolute -left-3 -top-3"
            onClick={() => {
              onClose();
              setValues({
                postscriptXshopToken: siteConfig.postscriptXshopToken,
                postscriptKeyword: siteConfig.postscriptKeyword,
              });
              setError("");
            }}
          >
            <img src="/close-btn.svg" alt="close button"></img>
          </button>
          <div className="flex justify-between items-center py-6 pl-[70px] pr-[60px] text-xs text-dark-grey font-medium border-b border-light-grey">
            <span className="text-base font-medium text-black_shade tracking-tracking_001">
              Enter you Postscript credentials to connect your account
            </span>
            <a
              href="https://assistalong.notion.site/Klaviyo-Setup-Guide-40a6fee68f6b46ec9df512f04016ceea#ac0a39ea78c6480e9e81612cd6b591b3"
              target="_blank"
              rel="noreferrer"
            >
              <button className="rounded-md text-blue_default text-[13px] addproduct-button font-medium py-[9.5px] px-[26px] tracking-tracking_001">
                See Instruction
              </button>
            </a>
          </div>
          <div className="px-[70px] py-[30px]">
            <div className="flex items-center mb-8">
              <div className="w-[156px]">
                <span className="text-xs text-charcol_gray font-normal tracking-[0.4px]">
                  Postscript Xshop token:
                </span>
              </div>
              <div>
                <input
                  type="password"
                  value={values.postscriptXshopToken}
                  className="h-12 w-[293px] border rounded !border-border_gray !border-opacity-50 text-xs "
                  onChange={(e) => {
                    setValues({
                      ...values,
                      postscriptXshopToken: e.target.value,
                    });
                    setError("");
                  }}
                />
              </div>
            </div>
            <div className="flex items-center mb-8">
              <div className="w-[156px]">
                <span className="text-xs text-charcol_gray font-normal tracking-[0.4px]">
                  Postscript Keyword:
                </span>
              </div>
              <div>
                <input
                  type="text"
                  value={values.postscriptKeyword}
                  className="h-12 w-[293px] border rounded !border-border_gray !border-opacity-50 text-xs "
                  onChange={(e) => {
                    setValues({ ...values, postscriptKeyword: e.target.value });
                    setError("");
                  }}
                />
              </div>
            </div>

            {true && (
              <div className="flex items-center justify-center pb-4">
                <p className="text-danger pt-1 text-red-500 text-xs">{error}</p>
              </div>
            )}
            <div className="flex items-center pt-1 pb-4">
              <div className="w-[156px]"></div>
              <div className="">
                <Button
                  text="Connect"
                  disabled={canEnableSave}
                  className={`flex items-center justify-center py-2.5 px-4 mr-11 tracking-tracking_001 rounded-md text-white text-13 ${
                    loading ? "w-[137px]" : "w-[127px]"
                  } font-medium ${
                    canEnableSave ? "bg-gray-500" : "bg-blue_default"
                  }`}
                  onClick={() => onSubmit(values)}
                  loading={loading}
                />
              </div>
              <button
                className="text-blue_default tracking-tracking_001 text-13 addproduct-button font-medium w-[127px] py-2.5 px-4 mr-11 rounded-md"
                onClick={() => {
                  onClose();
                  setValues({
                    postscriptXshopToken: siteConfig.postscriptXshopToken,
                    postscriptKeyword: siteConfig.postscriptKeyword,
                  });
                  setError("");
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConnectPostscriptModal;

import { useState, useEffect } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { Link, useSearchParams } from "react-router-dom";
import { APIError } from "../../layout/Error";
import { PageContentLoader } from "../../layout/Loaders";
import { AddProduct } from "./components/AddProduct";
import { ShopifyProduct } from "./models/ShopifyProduct";
import {
  ProductPageConfiguration,
  QueryObject,
} from "./models/ProductPageConfigurationModel";
import {
  massDeleteProducts,
  ProductEnumType,
  useGetProductPageConfigurations,
} from "./requests/ProductPageConfigurationRequests";
import { useGetShopifyProducts } from "./requests/ShopifyProductRequests";
import { createProductPageConfiguration } from "./requests/ProductPageConfigurationRequests";
import { useLocation, useNavigate } from "react-router";
import { AdminProtectedContainer } from "../../components/RoleProtectedContainer";
import { useAppSelector } from "../../redux/hooks";
import { getBusiness } from "../guides/requests/BusinessRequests";
import { setSetupStatus } from "../../redux/ducks/setup.duck";
import { setBusiness } from "../../redux/ducks/business.duck";
import { useDispatch } from "react-redux";
import MassDeleteProductModal from "./components/MassDeleteProductModel";
import Button from "../../components/Button";
import { recordPerPage } from "../../util/helpers";

type filters = "all" | "active" | "inactive";

type modifyTypes =
  | "reminder"
  | "discount"
  | "upsell"
  | "subscription"
  | "banner"
  | "followup-reminder";

const filterOptions: { label: string; value: filters }[] = [
  { label: "All", value: "all" },
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const SmartRemindersPage = () => {
  const businessId = useAppSelector((state) => state.business.businessId);
  const [queryObj, setQueryObj] = useState<QueryObject>({
    has_next_page: false,
    next_page_info: "",
    search: "",
  });
  const {
    loading,
    error,
    refresh,
    data: products,
  } = useGetProductPageConfigurations(businessId, ProductEnumType.PRODUCT);

  const {
    loading: shopifyLoading,
    error: shopifyError,
    refresh: shopifyRefresh,
    data: shopifyProducts,
  } = useGetShopifyProducts({
    search: queryObj.search,
    next_page_info: queryObj.next_page_info,
  });

  const dispatch = useDispatch();

  const [shopifyProductList, setShopifyProductList] = useState<
    ShopifyProduct[] | null
  >(null);
  const [originalProducts, setOriginalProducts] = useState<
    ProductPageConfiguration[]
  >([]);
  const [filteredProductList, setFilteredProductList] = useState<
    ProductPageConfiguration[]
  >([]);
  const [currentFilter, setCurrentFilter] = useState<filters>("all");
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [search, setSearch] = useState("");
  const [checkList, setCheckList] = useState<ProductPageConfiguration[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const [massDeleteMessage, setMassDeleteMessage] = useState("");
  const [massDiscountMessage, setMassDiscountMessage] = useState<string | null>(
    ""
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const location = useLocation();
  const state: any = location.state;

  useEffect(() => {
    if (searchParams.has("discount_success")) {
      setMassDiscountMessage(searchParams.get("discount_success"));
      searchParams.delete("discount_success");
      setSearchParams(searchParams);
      setTimeout(() => {
        setMassDiscountMessage("");
      }, 5000);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Deleted Product
    if (state?.productDeleted === "deleted") {
      if (products?.length === 0) {
        getBusiness().then((res) => {
          if (res.data) {
            dispatch(setSetupStatus(res.data.setupStatus));
            dispatch(setBusiness(res.data));
          }
        });
      }
    }

    // Add product
    if (products?.length === 1) {
      getBusiness().then((res) => {
        if (res.data) {
          dispatch(setSetupStatus(res.data.setupStatus));
          dispatch(setBusiness(res.data));
        }
      });
    }

    const sorted = products
      ?.filter((item) => item.product)
      .map((product) => {
        if (product?.originalTitle === "") {
          product.originalTitle = product?.product?.title;
        }
        return product;
      })
      ?.sort((a: ProductPageConfiguration, b: ProductPageConfiguration) =>
        a?.originalTitle < b?.originalTitle ? -1 : 1
      );
    setFilteredProductList(sorted ?? []);
    setOriginalProducts(sorted ?? []);
    // eslint-disable-next-line
  }, [products]);

  const onRowClick = (product: ProductPageConfiguration) => {
    navigate(`/smart-reminders/${product.id}`);
  };

  const onCheck = (checkbox: any, isChecked: boolean) => {
    let checkedBoxes = [...checkList];

    if (checkbox === "all") checkedBoxes = [];

    const productsToCheck = filteredProductList.slice(
      (currentPage - 1) * rowPerPage,
      rowPerPage * currentPage
    );
    productsToCheck?.forEach((obj) => {
      if (isChecked) {
        if (checkbox === "all") {
          checkedBoxes.push(obj);
        } else if (checkbox.id === obj.id) {
          checkedBoxes.push(checkbox);
        }
      } else {
        if (checkbox === "all") {
          const index = checkedBoxes.findIndex((ch) => ch.id === obj.id);
          if (index !== -1) checkedBoxes.splice(index, 1);
        } else if (checkbox.id === obj.id) {
          const index = checkedBoxes.findIndex((ch) => ch.id === checkbox.id);
          if (index !== -1) checkedBoxes.splice(index, 1);
        }
      }
    });

    const checkedBoxesIds = checkedBoxes.map((item) => item.id);
    setCheckAll(
      productsToCheck.every((item) => checkedBoxesIds.includes(item.id))
    );
    setCheckList(checkedBoxes);
  };

  useEffect(() => {
    if (shopifyProducts) {
      let tempProducts: ShopifyProduct[] = [...shopifyProducts.products];
      if (shopifyProductList) {
        if (isLoadMore) {
          tempProducts = [...shopifyProductList, ...shopifyProducts.products];
        }
      }
      setShopifyProductList(tempProducts);
    }
    setQueryObj({
      ...queryObj,
      next_page_info: shopifyProducts ? shopifyProducts.nextPageInfo : "",
      has_next_page: shopifyProducts ? shopifyProducts.hasNextPage : false,
    });
    // eslint-disable-next-line
  }, [shopifyProducts]);

  const [showProductModal, setShowProductModal] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [rowPerPage, setRowPerPage] = useState<number>(() => {
    try {
      return JSON.parse(localStorage.getItem("pagination") || "{product:10}")
        .product;
    } catch (error) {
      return 10;
    }
  });

  const closeModal = () => {
    setShowProductModal(false);
    setShopifyProductList([]);
  };

  const setDataRowPerPage = (page: number) => {
    const data = localStorage.getItem("pagination");
    if (data) {
      const productPage = JSON.parse(data);
      localStorage.setItem(
        "pagination",
        JSON.stringify({ ...productPage, product: page })
      );
      setRowPerPage(page);
    } else {
      localStorage.setItem("pagination", JSON.stringify({ product: page }));
      setRowPerPage(page);
    }
  };

  const onClickAddProduct = async (product: ShopifyProduct) => {
    try {
      await createProductPageConfiguration(businessId, {
        configurationType: ProductEnumType.PRODUCT,
        shopifyProductId: product.id,
        productPageRevisionsAttributes: [
          {
            shopifyProductIds: [],
            upsellText: "",
          },
        ],
      });
      refresh();
    } catch (error) {
      console.log(error);
    }
  };

  const AddProductColumnHeader = () => {
    return (
      <AdminProtectedContainer>
        <Button
          text="Add Product"
          className={`h-10 px-4 text-blue_default text-xs addproduct-button font-normal `}
          onClick={() => setShowProductModal(true)}
        />
      </AdminProtectedContainer>
    );
  };

  const toggleModify = () => {
    const element = document.getElementById("mass-modify");
    if (element) {
      if (element.style.display === "none") {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const toggleCheck = () => {
    const element = document.getElementById("mass-check");
    if (element) {
      if (element.style.display === "none") {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  };

  const onFilterChange = (type: filters) => {
    if (type === currentFilter) {
      return;
    }
    setCheckAll(false);
    setCheckList([]);
    setCurrentFilter(type);
    switch (type) {
      case "active":
        setFilteredProductList(
          originalProducts.filter((item) => item.reminderFlag)
        );
        break;
      case "inactive":
        setFilteredProductList(
          originalProducts.filter((item) => !item.reminderFlag)
        );
        break;
      default:
        setFilteredProductList([...originalProducts]);
    }
    toggleCheck();
  };

  const onMassDelete = (): Promise<boolean> => {
    return new Promise((resolve) => {
      massDeleteProducts({
        businessId,
        productIds: checkList.map((item) =>
          item.originalShopifyProductId
            ? item.originalShopifyProductId
            : item.gid
        ),
      })
        .then((res: any) => {
          resolve(true);
          if (res.data && res.data.data && res.data.data !== "Deleted") {
            setMassDeleteMessage(res.data.data);
            setTimeout(() => {
              setMassDeleteMessage("");
            }, 3000);
          }
          setDeleteProduct(false);
          refresh();
          setCheckAll(false);
          setCheckList([]);
          getBusiness().then((res) => {
            if (res.data) {
              dispatch(setSetupStatus(res.data.setupStatus));
              dispatch(setBusiness(res.data));
            }
          });
        })
        .catch((e) => {
          resolve(true);
          setDeleteProduct(false);
        });
    });
  };

  const onMassEdit = (editType: modifyTypes) => {
    switch (editType) {
      case "reminder":
        navigate(
          `/smart-reminders/bulk-edit/reminders?ids=${btoa(
            checkList.map((item) => item.reminderFreqId).join(",")
          )}&pIds=${btoa(checkList.map((item) => item.id).join(","))}`
        );
        break;
      case "discount":
        navigate(
          `/smart-reminders/bulk-edit/discounts?ids=${btoa(
            checkList.map((item) => item.id).join(",")
          )}`
        );
        break;
      case "subscription":
        navigate(
          `/smart-reminders/bulk-edit/subscription?ids=${btoa(
            checkList.map((item) => item.id).join(",")
          )}`
        );
        break;
      case "banner":
        navigate(
          `/smart-reminders/bulk-edit/banner?ids=${btoa(
            checkList.map((item) => item.id).join(",")
          )}`
        );
        break;
      // case "followup-reminder":
      //   navigate(
      //     `/smart-reminders/bulk-edit/followup-reminder?ids=${btoa(
      //       checkList.map((item) => item.id).join(",")
      //     )}`
      //   );
      //   break;
      default:
        navigate(
          `/smart-reminders/bulk-edit/upsell-products?ids=${btoa(
            checkList.map((item) => item.id).join(",")
          )}`
        );
        break;
      // will be for upsell product mass edit
    }
  };

  useEffect(() => {
    document &&
      document.addEventListener("click", (e) => {
        let event = e?.target as HTMLElement;
        let classList = event.className;
        if (
          typeof classList === "string" &&
          !classList.includes("mass-check")
        ) {
          const element = document.getElementById("mass-check") as HTMLElement;
          if (element) {
            element.style.display = "none";
          }
        }

        if (
          typeof classList === "string" &&
          !classList.includes("mass-modify")
        ) {
          const element: any = document.getElementById(
            "mass-modify"
          ) as HTMLElement;
          if (element) {
            element.style.display = "none";
          }
        }
      });

    return () => {
      document.removeEventListener("click", () => {});
    };
  }, []);

  useEffect(() => {
    if (showProductModal || deleteProduct) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showProductModal, deleteProduct]);

  const columns: TableColumn<ProductPageConfiguration>[] = [
    {
      // name: "Description",
      name: (
        <>
          <div className="inline-block relative min-w-[32px]">
            <div className="flex items-center h-3 cursor-pointer">
              <input
                type="checkbox"
                name="chkAll"
                id="chkHeader"
                checked={checkAll}
                onChange={(e: any) => {
                  onCheck("all", e.target.checked);
                }}
              />
              <div
                className="pl-[6px] h-full flex items-center"
                // onClick={() => {
                //   setCheckboxDropdown(!checkboxDropdown);
                // }}
              >
                <div>
                  <img
                    src="/down-arrow-product.svg"
                    alt="down-arrow"
                    onClick={toggleCheck}
                    className="mass-check w-[12px] h-[8px]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="absolute top-10 z-50 bg-white custom-dropdown p-3 w-36"
            id="mass-check"
            style={{ display: "none" }}
          >
            <ul>
              {filterOptions.map(({ label, value }) => (
                <li
                  key={label}
                  className={`px-3 py-2 cursor-pointer ${
                    currentFilter === value && "text-blue_default"
                  }`}
                  onClick={() => onFilterChange(value)}
                >
                  {label}
                </li>
              ))}
            </ul>
          </div>
        </>
      ),
      cell: (row) => {
        return (
          <div className="flex items-center cursor-pointer" key={Math.random()}>
            <input
              type="checkbox"
              name={`chk${row.id}`}
              id={`chk${row.id}`}
              checked={checkList.map((item) => item.id)?.includes(row.id)}
              onChange={(e: any) => onCheck(row, e.target.checked)}
            />
          </div>
        );
      },
      width: "3%",
    },
    {
      name: (
        <div className="h-4">
          {checkList.length > 0 && (
            <>
              <div className="flex items-center h-full cursor-pointer">
                <div
                  className="mass-modify text-blue_default font-normal"
                  onClick={toggleModify}
                >
                  Modify
                </div>
                <div
                  className="pl-[6px] h-full flex items-center"
                  onClick={toggleModify}
                >
                  <div>
                    <img
                      src="/modify-arrow.svg"
                      alt="down-arrow"
                      className="mass-modify w-[12px] h-[8px]"
                    />
                  </div>
                </div>
              </div>
              <div
                className="absolute top-10 z-50 bg-white custom-dropdown p-3"
                id="mass-modify"
                style={{ display: "none" }}
              >
                <ul className="text-blue_default">
                  <li
                    className="p-2 cursor-pointer"
                    onClick={() => onMassEdit("reminder")}
                  >
                    Set Reminders
                  </li>
                  <li
                    className="p-2 cursor-pointer"
                    onClick={() => onMassEdit("discount")}
                  >
                    Add Discount
                  </li>
                  <li
                    className="p-2 cursor-pointer"
                    onClick={() => onMassEdit("upsell")}
                  >
                    Add Upsell Products
                  </li>
                  {/* <li
                    className="p-2 cursor-pointer"
                    onClick={() => onMassEdit("followup-reminder")}
                  >
                    Add Followup Reminder
                  </li> */}
                  <li
                    className="p-2 cursor-pointer"
                    onClick={() => onMassEdit("subscription")}
                  >
                    Subscription Option
                  </li>
                  <li
                    className="p-2 cursor-pointer"
                    onClick={() => onMassEdit("banner")}
                  >
                    Add Banner
                  </li>
                </ul>
              </div>
            </>
          )}
        </div>
      ),
      cell: (row) => {
        return (
          <div className="flex items-center cursor-pointer">
            <img
              src={
                row?.originalImageUrl
                  ? row.originalImageUrl
                  : row?.product?.imageUrl
              }
              className="w-7"
              alt=""
              data-tag="allowRowEvents"
            />
            <p
              className={`ml-2 ${
                row?.reminderFlag === false &&
                "text-black_shade text-opacity-50"
              }`}
              data-tag="allowRowEvents"
            >
              {row?.originalTitle}
            </p>
          </div>
        );
      },
    },
    {
      name: checkList.length > 0 && (
        <button
          onClick={() => setDeleteProduct(true)}
          className="absolute right-10"
        >
          <img
            className="text-blue_default cursor-pointer"
            src="/delete-icon.svg"
            alt="close button"
          ></img>
        </button>
      ),
      cell: (row) => {
        return (
          <AdminProtectedContainer>
            <Link
              to={`${row.id}`}
              className="text-blue_default text-xs font-normal"
            >
              Modify
            </Link>
          </AdminProtectedContainer>
        );
      },
      right: true,
    },
  ];

  if (error) {
    return <APIError error={error} consentText="" refresh={refresh} />;
  } else if (shopifyError) {
    return (
      <APIError error={shopifyError} consentText="" refresh={shopifyRefresh} />
    );
  }

  return (
    <>
      {loading && !products ? (
        <PageContentLoader pageTitle="Smart Reminders" />
      ) : (
        <div className="flex flex-col w-full">
          <div className="title flex items-center justify-between mb-6">
            <div className="flex items-center">
              <h1 className="text-2xl text-black_shade font-medium mr-8">
                Smart Reminders
              </h1>
              <AddProductColumnHeader />
            </div>

            {/* <div className="flex gap-2 w-[7.25rem] h-[2.5rem]">
              <GoLiveButton />
            </div> */}
          </div>
          {massDeleteMessage && (
            <div className="mb-3">
              <p className="text-13px text-blue_default">{massDeleteMessage}</p>
            </div>
          )}
          {massDiscountMessage && (
            <>
              {/* <div className="mb-3">
              <p className="text-13px text-blue_default">
                Please allow a few minutes for the discounts to be updated.
              </p>
            </div> */}
            </>
          )}
          <div className="table-container relative product-tbl-main">
            <DataTable
              pagination={true}
              paginationPerPage={rowPerPage}
              paginationRowsPerPageOptions={recordPerPage}
              customStyles={{
                tableWrapper: {
                  style: { overflow: "visible !important" },
                },
                rows: {
                  style: {
                    cursor: "pointer",
                  },
                },
                headRow: {
                  style: {
                    paddingLeft: "25px",
                  },
                },
                headCells: {
                  style: {
                    padding: "25px",
                  },
                },
                cells: {
                  style: {
                    padding: "19px",
                  },
                },
              }}
              onRowClicked={(product) => onRowClick(product)}
              className="table-class"
              columns={columns}
              data={filteredProductList}
              persistTableHead={originalProducts.length > 0 ? true : false}
              onChangeRowsPerPage={(page, other) => {
                setDataRowPerPage(page);
                setCheckAll(false);
                setCheckList([]);
              }}
              onChangePage={(p) => {
                setCurrentPage(p);
                setCheckAll(false);
                setCheckList([]);
              }}
            />
          </div>

          {/* {showProductModal && shopifyLoading === false && ( */}
          {showProductModal && shopifyProductList && (
            <AddProduct
              show={showProductModal}
              onClose={() => closeModal()}
              shopifyProducts={shopifyProductList}
              onClickAddProduct={(product: ShopifyProduct) =>
                onClickAddProduct(product)
              }
              addedProducts={originalProducts ?? []}
              setQueryObj={setQueryObj}
              shopifyLoading={shopifyLoading}
              queryObj={queryObj}
              refreshProducts={shopifyRefresh}
              setIsLoadMore={setIsLoadMore}
              search={search}
              setSearch={setSearch}
              isUpsell={false}
            />
          )}
          <MassDeleteProductModal
            show={deleteProduct}
            onClose={() => setDeleteProduct(false)}
            handleDelete={() => onMassDelete()}
          />
        </div>
      )}
    </>
  );
};

export { SmartRemindersPage };

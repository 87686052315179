import Select from "react-select";
import { SelectValueContainer } from "../../../components/SelectValueContainer";
import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";

export const ReminderMobileView = ({
  selectTab,
  siteConfigInitials,
}: {
  selectTab: string;
  siteConfigInitials: SiteConfiguration;
}) => {
  const configOptions = [
    {
      label: "{{Frequency options}}",
      value: "{{Frequency options}}",
    },
  ];
  return (
    <div className="border border-[#D9D9D9] p-[15px] rounded-[4px]">
      <p className="font-AvenirLTPro_Heavy text-base leading-[19px] mb-2 text-black_33">
        {selectTab === "No Discount"
          ? siteConfigInitials?.withOutDiscountHeading
          : siteConfigInitials?.withDiscountHeading}
        {/* Set reminders to never run out & save 10% */}
      </p>
      <p className="text-xs leading-[14px] font-AvenirLTPro_Light mb-6 text-black_33">
        {selectTab === "No Discount"
          ? siteConfigInitials?.withOutDiscountSubheading
          : siteConfigInitials?.withDiscountSubheading}
        {/* We will get your order ready and send you a reminder. You confirm it
        with one tap. No need to enter your information again. */}
      </p>

      <div className="product-box">
        <div className="flex pb-6 justify-between flex-wrap">
          <div className="flex w-full mb-3">
            <div className="w-8 h-8 min-h-[32px] min-w-[32px] bg-[#FCFAFA] product-img rounded-md">
              <img
                className="w-full object-cover"
                src="/svg/product.svg"
                alt=""
              />
            </div>
            <div className="product-content pl-2 w-full flex justify-between">
              <div className="flex flex-wrap">
                <p className="font-AvenirLTPro_Heavy text-xs !leading-[16px] w-full">
                  {"{{ProductName}}"}
                </p>
                {/* <p className="text-[#727984] font-AvenirLTPro_Light leading-[14px] text-[10px]">
                  5oz
                </p> */}
              </div>
              {selectTab === "With Discount" && (
                <div>
                  <p
                    className="text-[9px] font-AvenirLTPro_Light leading-[14px] text-[#085C3F]"
                    style={{ color: siteConfigInitials?.primaryColor }}
                  >
                    <span className="font-AvenirLTPro_Heavy text-[12px] block">
                      XX% off
                    </span>
                    next time
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="w-full dropdown-select-wrap">
            <Select
              styles={{
                control: (base) => ({
                  ...base,
                  boxShadow: "none",
                }),
              }}
              value={configOptions.filter(
                (obj) => obj.value === "{{Frequency options}}"
              )}
              options={configOptions}
              placeholder="SEND REMINDER"
              isSearchable={false}
              className="rounded w-full font-AvenirLTPro_Heavy border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
              components={{ ValueContainer: SelectValueContainer }}
              //   onChange={(e) =>
              //     setReminderDelivery({
              //       ...remindDeliver,
              //       smsReminder: e?.label,
              //     })
              //   }
            />
          </div>
        </div>
        <div className="line border-t border-[#D9D9D9] -mx-[15px]"></div>

        <div className="flex flex-wrap justify-between items-center  mt-5 mb-4">
          <div className="text-xs w-full font-AvenirLTPro_Black !leading-[16px] pb-2.5">
            Enter your phone number
          </div>
          <div className="w-[277PX] h-10 flex rounded-md border border-borderGrey-greyBorder font-AvenirLTPro_Book">
            <input
              type="text"
              className="w-[45px] text-sm text-center  bg-gray-100 text-gray-500 border-0 rounded-l-md"
              disabled
              value="+1"
            />
            <input
              className="text-gray-900 font-medium tracking-wide rounded-md focus:outline-none text-sm block w-full p-2.5 placeholder-[#272727] pl-14 dark:text-white "
              // onChange={(e: ChangeEvent<HTMLInputElement>) =>
              //   setTo(e.target.value)
              // }
              inputMode="tel"
              autoComplete="tel-national"
              pattern="[0-9]{10}"
              title="10 digit phone number"
              placeholder="Add phone number"
            />
            {/* <Select
              styles={{
                control: (base) => ({
                  ...base,
                  boxShadow: "none",
                }),
              }}
              //   value={configOptions.filter(
              //     (obj) => obj.value === remindDeliver.smsReminder
              //   )}
              options={configOptions}
              placeholder="Replenishment cycles"
              isSearchable={false}
              className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
              components={{ ValueContainer: SelectValueContainer }}
              //   onChange={(e) =>
              //     setReminderDelivery({
              //       ...remindDeliver,
              //       smsReminder: e?.label,
              //     })
              //   }
            /> */}
          </div>
        </div>
        <div>
          <p className="text-[8px] font-AvenirLTPro_Light text-[#727984] leading-[9.6px] text-justify mb-4">
            {siteConfigInitials?.smsDeliveryMethod === "assistalong_sms" ? (
              <>
                By “Starting reminder” you agree to receive recurring automated
                promotional text messages on behalf of our merchant partners
                from AssistAlong at the number provided. Consent is not
                condition of any purchase. Reply HELP for help and STOP to
                cancel. Msg frequency varies. Msg & data rates may apply. By
                “Starting reminder”, you also agree to our
              </>
            ) : (
              <>
                By clicking submit, you agree to receive marketing text messages
                from {siteConfigInitials?.brandName}. at the number provided.
                Consent is not a condition of any purchase. Message and data
                rates may apply. Message frequency varies. Reply HELP for help
                or STOP to cancel. View our
              </>
            )}
            <span className="text-[#007dbd] font-AvenirLTPro_Light pl-1">
              Privacy Policy{" "}
            </span>{" "}
            and
            <span className="text-[#007dbd] font-AvenirLTPro_Light pl-1">
              Terms of Use.
            </span>
          </p>

          <span
            className="bg-greenColor font-AvenirLTPro_Black text-white rounded p-3 text-xs text-center flex items-center justify-center tracking-[0.4px] mb-[15px]"
            style={{ backgroundColor: siteConfigInitials?.primaryColor }}
          >
            <img
              className="mr-2.5 w-[13px]"
              src="/svg/bell_icon.svg"
              alt="start reminder"
            />
            Start reminders
          </span>
        </div>
      </div>
    </div>
  );
};

export const ReminderDesktopView = ({
  selectTab,
  siteConfigInitials,
}: {
  selectTab: string;
  siteConfigInitials: SiteConfiguration;
}) => {
  const configOptions = [
    {
      label: "{{Frequency options}}",
      value: "{{Frequency options}}",
    },
  ];
  return (
    <div className="border border-[#D9D9D9] xl:!p-6 p-3 rounded-[4px]">
      <p className="text-lg mb-2 text-black_33 !leading-[22px] font-AvenirLTPro_Heavy">
        {selectTab === "No Discount"
          ? siteConfigInitials?.withOutDiscountHeading
          : siteConfigInitials?.withDiscountHeading}
        {/* Set reminders to never run out & save 10% */}
      </p>
      <p className="text-sm mb-11 !leading-[17px] text-black_33 font-AvenirLTPro_Light">
        {selectTab === "No Discount"
          ? siteConfigInitials?.withOutDiscountSubheading
          : siteConfigInitials?.withDiscountSubheading}
        {/* We will get your order ready and send you a reminder. You confirm it
        with one tap. No need to enter your information again. */}
      </p>

      <div className="product-box reminder__desktop">
        <div className="flex pb-5 justify-between">
          <div className="flex">
            <div className="w-12 h-12 bg-[#FCFAFA] product-img">
              <img
                className="w-full object-cover"
                src="/svg/product.svg"
                alt=""
              />
            </div>
            <div className="product-content pl-5">
              <p className="font-AvenirLTPro_Heavy text-sm !leading-[17px]">
                {"{{ProductName}}"}
              </p>
              {/* <p className="text-[#727984] !leading-[16px] text-xs">5oz</p> */}
              {selectTab === "With Discount" && (
                <p
                  className="text-xs !leading-[14.5px] text-[#085C3F] pt-1"
                  style={{ color: siteConfigInitials?.primaryColor }}
                >
                  <span className="font-AvenirLTPro_Heavy !leading-[14.5px] text-sm tracking-[0.4px] pr-1">
                    XX% off
                  </span>
                  next time
                </p>
              )}
            </div>
          </div>

          <div className="dropdown-select-wrap w-[197px]">
            <Select
              styles={{
                control: (base) => ({
                  ...base,
                  boxShadow: "none",
                }),
              }}
              value={configOptions.filter(
                (obj) => obj.value === "{{Frequency options}}"
              )}
              options={configOptions}
              placeholder="SEND REMINDER"
              isSearchable={false}
              className="rounded w-full font-AvenirLTPro_Heavy border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
              components={{ ValueContainer: SelectValueContainer }}
              //   onChange={(e) =>
              //     setReminderDelivery({
              //       ...remindDeliver,
              //       smsReminder: e?.label,
              //     })
              //   }
            />
          </div>
        </div>
        <div className="line border-t border-[#D9D9D9] xl:-mx-6 -mx-3"></div>

        <div className="flex justify-between items-center  mt-6 mb-8">
          <div className="text-sm font-AvenirLTPro_Black text-black_33">
            Enter your phone number
          </div>
          <div className="flex rounded-md border border-borderGrey-greyBorder max-w-[196px] font-AvenirLTPro_Book h-10">
            <input
              type="text"
              className="w-[46px] text-sm  text-center  bg-gray-100 text-gray-500 border-0 rounded-l-md"
              disabled
              value="+1"
            />
            <input
              className="text-gray-900 font-medium tracking-wide rounded-md focus:outline-none text-sm block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 placeholder-[#272727] pl-14 dark:text-white "
              // onChange={(e: ChangeEvent<HTMLInputElement>) =>
              //   setTo(e.target.value)
              // }
              inputMode="tel"
              autoComplete="tel-national"
              pattern="[0-9]{10}"
              title="10 digit phone number"
              placeholder="Add phone number"
            />
          </div>
        </div>
        <div>
          <p className="text-[10px] text-[#727984] leading-[12px] font-AvenirLTPro_Light text-justify mb-5">
            {siteConfigInitials?.smsDeliveryMethod === "assistalong_sms" ? (
              <>
                By “Starting reminder” you agree to receive recurring automated
                promotional text messages on behalf of our merchant partners
                from AssistAlong at the number provided. Consent is not
                condition of any purchase. Reply HELP for help and STOP to
                cancel. Msg frequency varies. Msg & data rates may apply. By
                “Starting reminder”, you also agree to our
              </>
            ) : (
              <>
                By clicking submit, you agree to receive marketing text messages
                from {siteConfigInitials?.brandName}. at the number provided.
                Consent is not a condition of any purchase. Message and data
                rates may apply. Message frequency varies. Reply HELP for help
                or STOP to cancel. View our
              </>
            )}
            <span className="text-[#007dbd] font-AvenirLTPro_Light pl-1">
              Privacy Policy{" "}
            </span>{" "}
            and
            <span className="text-[#007dbd] font-AvenirLTPro_Light pl-1">
              Terms of Use.
            </span>
          </p>

          <span
            className="bg-greenColor text-white rounded p-4 text-xs text-center flex items-center justify-center font-AvenirLTPro_Black tracking-[0.4px]"
            style={{ backgroundColor: siteConfigInitials?.primaryColor }}
          >
            <img
              className="mr-2.5"
              src="/svg/bell_icon.svg"
              alt="start reminders"
            />
            Start reminders
          </span>
        </div>
      </div>
    </div>
  );
};

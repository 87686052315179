import { components, ValueContainerProps } from "react-select";

const { ValueContainer } = components;

export const SelectValueContainer = ({
  children,
  ...props
}: ValueContainerProps | any) => {
  return (
    <ValueContainer
      {...props}
      className="value-container text-xs h-11 text-designer_grey text-opacity-50 tracking-[0.4px] font-AvenirLTPro_Black flex flex-col items-start md:justify-end justify-center relative z-0"
    >
      {props.hasValue && (
        <span
          className={`text-8 ${
            props.hasValue &&
            "has-value whitespace-pre text-designer_grey opacity-50 font-AvenirLTPro_Book uppercase tracking-[0.4px] text-8 mx-0"
          }`}
        >
          {props.selectProps.placeholder}
        </span>
      )}
      {children}
    </ValueContainer>
  );
};

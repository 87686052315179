/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useState } from "react";
import {
  ProductPageConfiguration,
  QueryObject,
} from "../models/ProductPageConfigurationModel";
import { ShopifyProduct } from "../models/ShopifyProduct";
import { SwitchProductFields } from "../ProductDetailsPage";
import { useGetShopifyProducts } from "../requests/ShopifyProductRequests";
import { AddSwitchProduct } from "./AddSwitchProduct";
import { Switch } from "./Switch";

const SwitchProductReminder = ({
  switchProductFields,
  setSwitchProductFields,
  cancelChanges,
  setCancelChanges,
  configs,
  mainProduct,
}: {
  switchProductFields: SwitchProductFields;
  setSwitchProductFields: (data: SwitchProductFields) => void;
  cancelChanges: boolean;
  setCancelChanges: (flag: boolean) => void;
  configs: ShopifyProduct[];
  mainProduct: ProductPageConfiguration;
}) => {
  const [queryObj, setQueryObj] = useState<QueryObject>({
    has_next_page: false,
    next_page_info: "",
    search: "",
  });

  const [showProductModal, setShowProductModal] = useState<boolean>(false);
  const [shopifyProductList, setShopifyProductList] = useState<
    ShopifyProduct[] | null
  >(null);
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [search, setSearch] = useState("");
  const [selectedProduct, setSelectedProduct] = useState<ShopifyProduct | null>(
    mainProduct?.switchableShopifyProductId
  );

  const {
    loading: shopifyLoading,
    refresh: shopifyRefresh,
    data: shopifyProducts,
  } = useGetShopifyProducts({
    search: queryObj.search,
    next_page_info: queryObj.next_page_info,
  });

  useEffect(() => {
    if (cancelChanges && mainProduct) {
      setSelectedProduct(mainProduct?.switchableShopifyProductId);
    }
    setCancelChanges(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelChanges]);

  useEffect(() => {
    if (shopifyProducts) {
      let tempProducts: ShopifyProduct[] = [...shopifyProducts.products];
      if (shopifyProductList) {
        if (isLoadMore) {
          tempProducts = [...shopifyProductList, ...shopifyProducts.products];
        }
      }
      // removing main product from upsell products list
      setShopifyProductList(
        tempProducts.filter(
          (item) => item.productId !== mainProduct.product.productId
        )
      );
      const productToSet = tempProducts.find(
        (item) =>
          item.id ===
          (switchProductFields.product && switchProductFields.product)
      );
      if (productToSet) {
        setSelectedProduct(productToSet);
      }
    }
    setQueryObj({
      ...queryObj,
      next_page_info: shopifyProducts ? shopifyProducts.nextPageInfo : "",
      has_next_page: shopifyProducts ? shopifyProducts.hasNextPage : false,
    });
    // eslint-disable-next-line
  }, [shopifyProducts]);

  const closeModal = () => {
    setShowProductModal(false);
    setShopifyProductList([]);
  };

  useEffect(() => {
    if (showProductModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showProductModal]);

  return (
    <>
      <div className="pb-8 w-full mb-7 bg-white rounded-lg">
        <div className="px-6 message-type-wrapper py-6 border-b border-light-grey">
          <div className="flex justify-between flex-row">
            <div className="flex flex-col">
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                Switch the product for reminders
              </p>
              <p className="text-10 text-dark-grey text-opacity-75">
                Customers who bought the product should be reminded to purchase
                a different product added below
              </p>
            </div>
            <div className="flex flex-row pr-5 mt-1.5 items-center">
              <span className="text-xs tracking-wider text-black_shade mr-3">
                Active
              </span>
              <div>
                <Switch
                  state={switchProductFields.isActive}
                  onClick={() => {
                    setSwitchProductFields({
                      ...switchProductFields,
                      isActive: !switchProductFields.isActive,
                    });
                  }}
                />
              </div>
              <span className="text-xs tracking-wider text-black_shade ml-3">
                Off
              </span>
            </div>
          </div>
        </div>
        <div>
          {selectedProduct && (
            <div className="py-4 pr-9 ml-7 border-b border-light-grey flex justify-between items-center">
              <div className="flex items-center">
                <img
                  className="w-6 h-6 mr-2"
                  src={selectedProduct.imageUrl}
                  alt="upsell product image"
                ></img>
                <h3 className="text-xs text-dark-grey tracking-tracking_001">
                  {selectedProduct.title}
                </h3>
              </div>
              <button
                onClick={() => {
                  setSelectedProduct(null);
                  setSwitchProductFields({
                    ...switchProductFields,
                    product: null,
                  });
                }}
                className="text-blue_default text-xs tracking-tracking_001 flex items-center"
              >
                <img
                  className="w-4 h-4 mr-2"
                  src="/delete-icon.svg"
                  alt="trash icon"
                ></img>
              </button>
            </div>
          )}
        </div>
        <div className="px-6 pt-5 w-7/12">
          <button
            onClick={() => setShowProductModal(true)}
            className="tracking-tracking_001 text-blue-600 text-xs font-medium"
          >
            Add Product
          </button>
        </div>
      </div>
      {showProductModal && (
        <AddSwitchProduct
          show={showProductModal}
          onClose={() => closeModal()}
          shopifyProducts={shopifyProductList}
          onClickAddProduct={(product: ShopifyProduct) => {
            setSwitchProductFields({
              ...switchProductFields,
              product: product.id,
            });
            setSelectedProduct(product);
          }}
          addedProducts={selectedProduct ? [selectedProduct] : []}
          setQueryObj={setQueryObj}
          shopifyLoading={shopifyLoading}
          queryObj={queryObj}
          refreshProducts={shopifyRefresh}
          setIsLoadMore={setIsLoadMore}
          search={search}
          setSearch={setSearch}
        />
      )}
    </>
  );
};

export default SwitchProductReminder;

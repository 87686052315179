import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Template } from "../../pages/customization/models/Customization";

export const initialTemplateField = {
  businessId: 0,
  customizeSmsReminder: "",
  emailNoDiscountBody: "",
  emailNoDiscountPreheader: "",
  emailNoDiscountSubject: "",
  emailWithNoDiscountJsonBody: "",
  emailWithDiscountBody: "",
  emailWithDiscountJsonBody: "",
  emailWithDiscountPreheader: "",
  emailWithDiscountSubject: "",
  smsReminderTemplateWithNoDiscount: "",
  smsReminderTemplateWithDiscount: "",
  id: 0,
  templateType: "",
  smsReminderTemplate: "",
  url: "",
};

export const siteSlice = createSlice({
  name: "site",
  initialState: {
    templates: [],
    selectedSms: null,
    selectedEmail: null,
  },
  reducers: {
    setTemplates: (state: any, action: PayloadAction<any[]>) => {
      state.templates = action.payload;
    },
    setSelectedSmsTemplate: (state: any, action: PayloadAction<Template>) => {
      state.selectedSms = action.payload;
    },
    setSelectedEmailTemlate: (state: any, action: PayloadAction<Template>) => {
      state.selectedEmail = action.payload;
    },
  },
});

const { actions, reducer } = siteSlice;

export const { setTemplates, setSelectedSmsTemplate, setSelectedEmailTemlate } =
  actions;

export default reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  plans: null,
  permission: [],
};

export const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {
    setPlans: (state: any, action: PayloadAction<any>) => {
      state.plans = action.payload;
    },
    setPermission: (state: any, action: PayloadAction<any>) => {
      state.permission = action.payload;
    },
  },
});

const { actions, reducer } = plansSlice;

export const { setPlans, setPermission } = actions;

export default reducer;

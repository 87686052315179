import { useEffect, useState } from "react";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { useAppSelector } from "./redux/hooks";

// Inspired by:
// https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#4-create-a-useapi-hook-for-accessing-protected-apis-with-an-access-token

export interface UseAPI<T> {
  data: T | null;
  loading: boolean;
  error: any | null;
  refresh: (options?: any) => Promise<boolean>;
}

export interface APIState<T> {
  data: T | null;
  loading: boolean;
  error: any | null;
}

export interface GetAccessTokenSilentlyOptions {
  audience?: string;
  scope?: string;
}

export function useApi<T, D = any>(
  config: AxiosRequestConfig<D>,
  options?: GetAccessTokenSilentlyOptions,
  client: AxiosInstance = axios
): UseAPI<T> {
  const [state, setState] = useState<APIState<T>>({
    error: null,
    loading: true,
    data: null,
  });

  const accessToken = useAppSelector((state) => state.user.token);

  // const [refreshIndex, setRefreshIndex] = useState(0);

  // useEffect(() => {
  //   setState({ ...state, loading: true });
  //   (async () => {
  //     try {
  //       client
  //         .request<T>({
  //           ...config,
  //           headers: {
  //             ...config.headers,
  //             // Add the Authorization header to the existing headers
  //             Authorization: `${accessToken}`,
  //           },
  //         })
  //         .then(({ data }) => {
  //           setState({
  //             ...state,
  //             data,
  //             error: null,
  //             loading: false,
  //           });
  //         })
  //         .catch((error) => {
  //           setState({
  //             ...state,
  //             error,
  //             loading: false,
  //           });
  //         });
  //     } catch (error: any) {
  //       if (error.response.status === 401) {
  //         window.location.href = "/login";
  //       }
  //       setState({
  //         ...state,
  //         data: null,
  //         error,
  //         loading: false,
  //       });
  //     }
  //   })();
  //   //eslint-disable-next-line
  // }, [refreshIndex]);

  useEffect(() => {
    makeApiCall();
    // eslint-disable-next-line
  }, []);

  const makeApiCall = (otherOptions: any = {}): Promise<boolean> => {
    setState({ ...state, loading: true });
    return new Promise((res) => {
      try {
        client
          .request<T>({
            ...config,
            ...otherOptions,
            headers: {
              ...config.headers,
              // Add the Authorization header to the existing headers
              Authorization: `${accessToken}`,
            },
          })
          .then(({ data }) => {
            setState({
              ...state,
              data,
              error: null,
              loading: false,
            });
            res(true);
          })
          .catch((error) => {
            if (!error.response) {
              // when request is aborted by AbortController
              setState({
                ...state,
                loading: false,
              });
              return res(true);
            }
            if (error.response.status === 401) {
              window.location.href = "/login";
            }
            setState({
              ...state,
              error,
              loading: false,
            });
            res(true);
          });
      } catch (error: any) {
        if (!error.response) {
          // when request is aborted by AbortController
          setState({
            ...state,
            loading: false,
          });
          return res(true);
        }
        if (error.response.status === 401) {
          window.location.href = "/login";
        }
        setState({
          ...state,
          data: null,
          error,
          loading: false,
        });
        res(true);
      }
    });
  };

  return {
    ...state,
    refresh: (options?: any) => {
      // setRefreshIndex((index) => index + 1);
      // setState({ ...state, loading: true });
      // return new Promise((res) => {
      //   console.log(state.loading, (new Date()).getTime());
      //   if (state.loading) {
      //     res(true);
      //   }
      // });
      return makeApiCall(options);
    },
  };
}

import Button from "./Button";

export const ModalContainer = ({
  show,
  children,
  onClose,
  width,
  height,
}: {
  show: boolean;
  children: React.ReactNode;
  onClose: () => void;
  width?: string;
  height?: string;
}) => {
  return (
    <div
      className={` transition ease-linear duration-100 relative z-20 top-4  ${
        show ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div
        className={`bg-black bg-opacity-60 min-w-screen  h-screen animated fadeIn faster fixed pt-4  left-0  bottom-4  flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover transition ease-in-out duration-500 ${
          show ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <div
          className={`${
            width ? width : "w-full max-w-lg"
          } relative my-auto rounded-lg shadow-lg bg-off_white transition ease-in-out duration-500 ${
            show ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <Button
            className={`cursor-pointer bg-off_white w-8 h-8 shadow-btn_shadow flex items-center justify-center rounded-full absolute -left-3 -top-3`}
            onClick={() => onClose()}
            icon={<img src="/close-btn.svg" alt="close button"></img>}
          />
          <div className="">{children}</div>
        </div>
      </div>
    </div>
  );
};

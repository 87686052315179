import { useEffect, useState } from "react";
import { APIError } from "../../../layout/Error";
import { SpinningLoader } from "../../../layout/Loaders";
import { ShopifyProduct } from "../../smart_reminders/models/ShopifyProduct";
import {
  ProductQueryResult,
  useQueryProduct,
} from "../../smart_reminders/requests/ProductQueryRequests";
import Select from "react-select";
import { formatNumber } from "../../../util/helpers";
import { TimeState } from "../InsightsPage";
import {
  ProductInsightType,
  ProductInsightChart,
} from "../models/DashboardModel";
import { SelectValueContainer } from "../../../components/SelectValueContainer";
import { QueryObject } from "../../smart_reminders/models/ProductPageConfigurationModel";
import {
  BarController,
  BarElement,
  Chart as ChartJS,
  ChartOptions,
  TimeScale,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import moment from "moment";
import { AdminProtectedContainer } from "../../../components/RoleProtectedContainer";
import { useAppSelector } from "../../../redux/hooks";
ChartJS.register(BarElement, BarController, TimeScale);

const DataDisplay = ({
  results,
  insightType,
}: {
  results: ProductQueryResult;
  insightType: ProductInsightType;
}) => {
  const { datasets, labels, product } = results;

  const colors = ["#00863D", "#00AE65", "#28D68D"];

  const options: ChartOptions<any> = {
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (tooltipItem: any) {
            const label = tooltipItem?.[0]?.label?.split(",")[0];
            return label;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: product,
      },
      filler: {
        propagate: false,
      },
      interaction: {
        intersect: false,
      },
    },
    scales: {
      xAxis: {
        stacked: true,
        display: true,
        type: "time",
        time: {
          unit: "day",
        },
        grid: {
          display: false,
        },
      },
      yAxis: {
        stacked: true,
        display: true,
        grid: {
          display: false,
        },
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value: any, index: any, ticks: any) {
            if (value >= 0 && value % 1 === 0) {
              return value;
            }
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: datasets.map((dataset, index) => {
      let dt = [];
      switch (insightType) {
        case "no_of_customers_enrolled_sms_count":
          dt = dataset["noOfCustomersEnrolledSmsCount"];
          break;
        case "percentage_labels":
          dt = dataset["percentageLabels"];
          break;
        case "converted_subscription_count":
          dt = dataset["noOfCustomersConvertedToSubscriptionCount"];
          break;
        case "reminder_sms_sent_count":
          dt = dataset["reminderSmsSentCount"];
          break;
        case "reminder_email_sent_count":
          dt = dataset["reminderEmailSentCount"];
          break;
        case "order_count":
          dt = dataset["orderCount"];
          break;
        default:
          dt = dataset["orderRevenue"];
          break;
      }
      return {
        label: dataset.label,
        data: dt,
        backgroundColor: colors[index % colors.length],
      };
    }),
    // datasets: [{
    //   label: 'test1',
    //   data: [1, 23, 4, 5, 6, 76, 34, 56],
    //   backgroundColor: "#00863D"
    // }, {
    //   label: 'test2',
    //   data: [1, 23, 55, 5, 6, 76, 34, 56],
    //   backgroundColor: "#00AE65"
    // }],
  };

  return (
    <div>
      <Chart
        type="bar"
        // ref={chartRef}
        data={data}
        options={options}
      />
      {/* <Bar data={data} options={options} /> */}
      {/* <ul className="mt-6">
        {datasets.map(({ label, totalRevenue }, index) => (
          <li
            key={label}
            className={`flex items-center justify-between text-xs ${index !== datasets.length - 1
              ? "border-b border-designer_grey border-opacity-30"
              : ""
              }`}
          >
            <div className="flex items-center gap-2 py-3">
              <div
                className={`rounded-full h-2 w-2`}
                style={{ backgroundColor: colors[index % colors.length] }}
              />
              <p className="text-11 text-designer_grey tracking-tracking_001">
                {label}
              </p>
            </div>
            <p className="text-11 text-designer_grey tracking-tracking_001">
              ${formatNumber(totalRevenue)}
            </p>
          </li>
        ))}
      </ul> */}
    </div>
  );
};

interface InsightTypeOption {
  label: string;
  value: ProductInsightType;
}

export const ProductQueryCard = ({
  productChart,
  products,
  state,
  handleUpdate,
  queryObj,
  setIsLoadMore,
  refreshProducts,
  index,
  loadingProducts,
}: {
  productChart: ProductInsightChart;
  products: ShopifyProduct[];
  state: TimeState;
  queryObj: QueryObject;
  refreshProducts: () => void;
  setIsLoadMore: (isLoadMore: Boolean) => void;
  handleUpdate: (updates: ProductInsightChart, index: number) => void;
  index: number;
  loadingProducts: boolean;
}) => {
  const { shopifyProductId, insightType } = productChart;
  const [selectedProduct, setSelectedProduct] = useState(
    shopifyProductId || products[0].id
  );
  const businessId = useAppSelector((state) => state.business.businessId);

  const [insight, setInsight] = useState(insightType);
  const [prevAc, setPrevAc] = useState<AbortController | null>(
    new AbortController()
  );
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  const { error, data, refresh, loading } = useQueryProduct(
    selectedProduct,
    state.from,
    state.to,
    businessId,
    prevAc
  );

  const selectOptions = products.map(({ id, title }) => {
    return { label: title, value: id };
  });

  const defaultOption = selectOptions.find(
    (pro) => pro.value === shopifyProductId
  );

  const insightOptions: InsightTypeOption[] = [
    {
      label: "No of customers set SMS reminders",
      value: "no_of_customers_enrolled_sms_count",
    },
    {
      label: "Percentage of customers set SMS reminders",
      value: "percentage_labels",
    },
    {
      label: "No of customers converted to subscribers",
      value: "converted_subscription_count",
    },
    { label: "No of sms reminders sent", value: "reminder_sms_sent_count" },
    { label: "No of email reminders sent", value: "reminder_email_sent_count" },
    { label: "No of orders", value: "order_count" },
    { label: "Total sales", value: "order_revenue" },
  ];

  useEffect(() => {
    setInitiallyLoaded(true);
  }, []);

  const defaultInsight = insightOptions.find((opt) => opt.value === insight);
  useEffect(() => {
    // only refresh the chartdata when it's not fetching already
    // if (!loading) {
    //   refresh();
    // }
    if (initiallyLoaded) {
      // only refresh the graphs if data is already fetched once
      if (prevAc) {
        prevAc.abort();
      }
      setPrevAc(new AbortController());
    }
    //eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (data) {
      setPrevAc(null);
    }
  }, [data]);

  useEffect(() => {
    if (initiallyLoaded && prevAc) {
      refresh();
    }
    // eslint-disable-next-line
  }, [prevAc]);

  const onScrollProducts = (e: any) => {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
      if (queryObj?.next_page_info && queryObj?.has_next_page) {
        setIsLoadMore(true);
        refreshProducts();
      }
    }
  };

  const getCount = () => {
    if (data?.datasets) {
      switch (insight) {
        case "order_revenue":
          return (
            "$" +
            formatNumber(
              data?.datasets.reduce((p, c) => {
                p = p + +c.totalRevenue;
                return p;
              }, 0)
            )
          );
        case "order_count":
          return data?.datasets.reduce((p, c) => {
            p = p + +c.totalOrders;
            return p;
          }, 0);
        case "converted_subscription_count":
          return data?.datasets.reduce((p, c) => {
            p = p + +c.totalNoOfCustomersConvertedToSubscription;
            return p;
          }, 0);
        case "no_of_customers_enrolled_sms_count":
          return data?.datasets.reduce((p, c) => {
            p = p + +c.noOfCustomersEnrolledSms;
            return p;
          }, 0);
        case "percentage_labels":
          return data?.datasets.reduce((p, c) => {
            p = p + +c.totalPercentageOfCustomersEnrolled;
            return p;
          }, 0);
        case "reminder_sms_sent_count":
          return data?.datasets.reduce((p, c) => {
            p = p + +c.numberOfSmsReminderSent;
            return p;
          }, 0);
        case "reminder_email_sent_count":
          return data?.datasets.reduce((p, c) => {
            p = p + +c.numberOfEmailReminderSent;
            return p;
          }, 0);
        default:
          return data?.datasets.reduce((p, c) => {
            p = p + +c.totalCustomers;
            return p;
          }, 0);
      }
    }
    return 0;
  };

  const getTotalLabel = () => {
    if (data) {
      switch (insight) {
        case "no_of_customers_enrolled_sms_count":
          return "Total Customers Enrolled";
        case "converted_subscription_count":
          return "Total converted to subscribers";
        case "percentage_labels":
          return "Total Percentage of Customers Enrolled";
        case "reminder_sms_sent_count":
          return "Total SMS Reminder Sent";
        case "reminder_email_sent_count":
          return "Total Email Reminder Sent";
        case "order_count":
          return "Total Orders";
        default:
          return "Total Revenue";
      }
    }
    return "";
  };

  const refetchData = (newProductId: number) => {
    const url = new URL(
      `${process.env.REACT_APP_API_URL}/shopify_products/${newProductId}/query`
    );

    url.searchParams.append(
      "from",
      moment(state.from).utc().format("YYYY-MM-DDT00:00:00.000") + "Z"
    );
    url.searchParams.append(
      "to",
      moment(state.to).utc().format("YYYY-MM-DDT23:59:59.000") + "Z"
    );
    url.searchParams.append(
      "tz_difference",
      (new Date().getTimezoneOffset() * -1).toString()
    );
    url.searchParams.append(
      "timezone_name",
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
    refresh({ url });
  };

  return (
    <div className="p-8 bg-white rounded relative w-full ">
      {loading && (
        <div className="absolute left-0 top-1/2 right-0 m-auto">
          <SpinningLoader fullHeight={false} />
        </div>
      )}
      <div className="dropdown-select-wrap">
        <div className="flex items-center gap-2">
          {products.length && (
            <Select
              styles={{
                control: (base) => ({
                  ...base,
                  boxShadow: "none",
                }),
                valueContainer: (base) => ({
                  width: "150px",
                  display: "flex",
                }),
                // singleValue: (base) => ({
                //   ...base,
                //   padding: "12px 10px",
                //   fontWeight: "500",
                // }),
              }}
              options={selectOptions}
              placeholder="SELECT PRODUCT"
              onMenuScrollToBottom={(e) => onScrollProducts(e)}
              onChange={(e) => {
                setSelectedProduct(e!.value);
                handleUpdate(
                  { ...productChart, shopifyProductId: e!.value },
                  index
                );
                refetchData(e!.value);
              }}
              isSearchable={false}
              defaultValue={
                defaultOption || {
                  label: products[0].title,
                  value: products[0].id,
                }
              }
              isLoading={loadingProducts}
              components={{ ValueContainer: SelectValueContainer }}
              className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs mb-4 border-opacity-50"
            />
          )}
          <Select
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
              }),
              valueContainer: (base) => ({
                width: "150px",
                display: "flex",
              }),
              // singleValue: (base) => ({
              //   ...base,
              //   padding: "12px 10px",
              //   fontWeight: "500",
              // }),
            }}
            options={insightOptions}
            placeholder="DATA TYPE"
            onChange={(e) => {
              setInsight(e!.value);
              handleUpdate(
                {
                  ...productChart,
                  insightType: e!.value,
                },
                index
              );
            }}
            isSearchable={false}
            defaultValue={defaultInsight || insightOptions[0]}
            components={{ ValueContainer: SelectValueContainer }}
            className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs mb-4 border-opacity-50"
          />
        </div>
        {/* {data?.datasets.map((item, index) => ( */}
        <div className="flex justify-between mb-4">
          <div>
            <h2 className="text-base text-designer_grey tracking-tracking_001 font-semibold">
              {getTotalLabel()}
            </h2>
          </div>
          <div>
            <h2 className="text-base text-designer_grey tracking-tracking_001 font-semibold">
              {getCount()}
            </h2>
          </div>
        </div>
        {/* ))} */}
        {error && (
          <APIError
            error={error}
            refresh={refresh}
            consentText="Consent to API access"
          />
        )}
        {data && (
          <div>
            <AdminProtectedContainer>
              <button
                onClick={() => {
                  handleUpdate({ ...productChart, _destroy: true }, index);
                }}
                className="cursor-pointer bg-off_white w-8 h-8 shadow-btn_shadow flex items-center justify-center rounded-full absolute -top-3 -right-3"
              >
                <img src="/close-btn.svg" alt="close button"></img>
              </button>
            </AdminProtectedContainer>
            <DataDisplay results={data} insightType={insight} />
          </div>
        )}
      </div>
    </div>
  );
};

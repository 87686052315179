import { TimeState } from "../InsightsPage";
import { AddButton } from "./AddProductQueryCard";

export const AddInsightQueryCard = ({
  insights,
  state,
  handleUpdate,
}: {
  insights: any[];
  state: TimeState;
  // handleUpdate: (updates: QrCodeInsightChart) => void;
  handleUpdate: (updates: any) => void;
}) => {
  return (
    <>
      <div className="flex items-center justify-center border border-dashed border-gray_planBox h-full w-full rounded">
        <div className="flex items-center">
          <button
            onClick={() =>
              handleUpdate({
                id: undefined,
                tempId: Math.random(),
                insightType: "order_revenue",
              })
            }
          >
            <AddButton />
          </button>
          <p className="text-blue_default text-xs tracking-tracking_001 font-medium">
            Add Insight
          </p>
        </div>
      </div>
    </>
  );
};

import { useEffect, useState } from "react";
import Button from "./Button";

const defaultClassnames = `text-white tracking-tracking_001 font-medium bg-green-500 bg-gray-300 px-5 py-2 text-sm rounded-md`;
export const ResponsiveButton = ({
  onClick,
  text = "Save",
  classNames = defaultClassnames,
  disabled = false,
}: {
  onClick: (event: any) => Promise<any>;
  text?: string;
  classNames?: string;
  disabled?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [error, setError] = useState(false);

  const handeClick = (e: any) => {
    setLoading(true);
    onClick(e)
      .then(() => {
        //setSuccess(true)
        setUpdated(true);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (updated || error) {
      const timer = setInterval(() => {
        setUpdated(false);
        setLoading(false);
        setError(false);
      }, 2500);

      return () => clearInterval(timer);
    }
  }, [updated, error]);

  const MainButton = () => {
    return (
      <Button
        text={text}
        onClick={(e) => handeClick(e)}
        className={`${classNames} items-center text-sm text-white gap-2 w-32`}
        disabled={disabled ? disabled : loading}
      />
    );
  };

  const Updating = () => {
    return (
      <Button
        text="Updating"
        className={`${classNames} items-center gap-2 w-36 flex text-sm text-white`}
        loading={true}
      />
    );
  };

  const Updated = () => {
    return (
      <Button
        text="Updated"
        className={`${classNames} flex items-center text-sm text-white gap-2 w-36`}
        icon={
          <img src="/svg/ico_check_white.svg" alt="updated checkmark"></img>
        }
      />
    );
  };

  const Errored = () => {
    return (
      <Button
        text="Error"
        className={`${classNames} flex items-center gap-2 w-32 text-sm text-white`}
      />
    );
  };

  return (
    <>
      {!loading && !updated && !error && <MainButton />}
      {loading && !updated && !error && <Updating />}
      {error && <Errored />}
      {updated && <Updated />}
    </>
  );
};

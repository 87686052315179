import { recordPerPage } from "../util/helpers";

const Pagination = ({
  onPageChange,
  totalPages,
  currentPage,
  limit,
  onChangeRecordsPerPage,
  totalCount = 0,
}: {
  onPageChange: (x: number) => void;
  totalPages: number;
  currentPage: number;
  limit: number;
  totalCount: number;
  onChangeRecordsPerPage: (x: number) => void;
}) => {
  const isInFirstPage = () => {
    return currentPage === 1;
  };

  const isInLastPage = () => {
    if (totalPages === 0) {
      return true;
    }
    return currentPage === totalPages;
  };

  const onClickFirstPage = () => {
    if (isInFirstPage()) {
      return false;
    }
    onPageChange(1);
  };

  const onClickPreviousPage = () => {
    if (isInFirstPage()) {
      return false;
    }
    onPageChange(currentPage - 1);
  };

  const onClickNextPage = () => {
    if (isInLastPage()) {
      return false;
    }
    onPageChange(currentPage + 1);
  };

  const onClickLastPage = () => {
    if (isInLastPage()) {
      return false;
    }
    onPageChange(totalPages);
  };

  const getStarting = () => {
    return currentPage > 1 ? (currentPage - 1) * limit + 1 : 1;
  };

  const getEnding = () => {
    return currentPage >= 1
      ? currentPage * limit > totalCount
        ? totalCount
        : currentPage * limit
      : limit;
  };

  return (
    <div className="rdt_Pagination bg-white flex justify-end items-center text-[13px] px-5 py-2 text-black/50">
      <ul className="showItems">
        <li>
          Rows per page:
          <select
            value={limit}
            className="text-[13px] border-0 pr-6"
            onChange={(e) => {
              onChangeRecordsPerPage(+e?.target?.value || 10);
            }}
          >
            {recordPerPage?.length > 0 &&
              recordPerPage.map((optionValue) => (
                <option value={`${optionValue}`}>{optionValue}</option>
              ))}
          </select>
        </li>
      </ul>
      <span className="mx-[24px]">
        {getStarting()} - {getEnding()} of {totalCount}
      </span>
      <ul className="pagination flex">
        <li className="pagination-item">
          <button
            onClick={() => onClickFirstPage()}
            className={`p-2 ${isInFirstPage() ? "disabled" : ""}`}
            disabled={isInFirstPage()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
              fill="rgba(0, 0, 0, 0.54)"
            >
              <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
              <path fill="none" d="M24 24H0V0h24v24z"></path>
            </svg>
          </button>
        </li>
        <li className="pagination-item">
          <button
            onClick={() => onClickPreviousPage()}
            className={`p-2 ${isInFirstPage() ? "disabled" : ""}`}
            disabled={isInFirstPage()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
              fill="rgba(0, 0, 0, 0.54)"
            >
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </button>
        </li>

        <li className="pagination-item">
          <button
            onClick={() => onClickNextPage()}
            className={`p-2 ${isInLastPage() ? "disabled" : ""}`}
            disabled={isInLastPage()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
              fill="rgba(0, 0, 0, 0.54)"
            >
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
              <path d="M0 0h24v24H0z" fill="none"></path>
            </svg>
          </button>
        </li>
        <li className="pagination-item">
          <button
            onClick={() => onClickLastPage()}
            className={`p-2 ${isInLastPage() ? "disabled" : ""}`}
            disabled={isInLastPage()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
              fill="rgba(0, 0, 0, 0.54)"
            >
              <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
              <path fill="none" d="M0 0h24v24H0V0z"></path>
            </svg>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;

import { PresignedS3FileInput } from "../../../components/PresignedS3FileInput";

const UploadLogo = ({
  imageUrl,
  onUploadSuccess,
  businessId,
}: {
  imageUrl: string;
  onUploadSuccess: (url: string) => void;
  businessId: number;
}) => {
  const updateConfig = async (url: string) => {
    onUploadSuccess(url);
  };

  return (
    <>
      <div className="py-[28px]">
        <div className="title">
          <h3 className="text-black_shade text-xs tracking-wide_0.01 mb-1 font-medium">
            Upload Logo
          </h3>
          <p className="text-10 text-black_shade tracking-wide_0.01 text-opacity-75">
            Branding image should be high resolution and will display best if
            rectangle (It is .svg or .png only)
          </p>
        </div>
        <PresignedS3FileInput
          defaultImageURL={imageUrl ?? ""}
          onSubmit={(url) => updateConfig(url)}
          businessId={businessId}
        />
      </div>
    </>
  );
};

export { UploadLogo };

import { UseAPI, useApi } from "../../../use-api";
import { Customer } from "../models/CustomerModel";

const API_URL = process.env.REACT_APP_API_URL;

export function useGetCustomers(
  businessId: number,
  queryObj?: { page: number; limit: number } | null
): UseAPI<Customer> {
  let qs = "";
  if (queryObj) {
    qs = new URLSearchParams(queryObj as any).toString();
  }
  return useApi<Customer>({
    method: "get",
    url: `${API_URL}/businesses/${businessId}/customers?${qs}`,
  });
}

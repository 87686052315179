import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MassEditBanner from "./components/MassEditBanner";
import { MassEditReminderSection } from "./components/MassEditReminders";
import MassEditSubscription from "./components/MassEditSubscription";
import { MassEditUpsellProduct } from "./components/MassEditUpsellProduct";
import { MassEditFollowupReminders } from "./components/MassEditFollowupReminders";
import { MassEditDiscount } from "./components/MassEditDiscount";

const MassEditProductDetailsPage = () => {
  const { title } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (
      title &&
      ![
        "reminders",
        "discounts",
        "upsell-products",
        "subscription",
        "banner",
        "followup-reminder",
      ].includes(title)
    ) {
      navigate("/404");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <>
      {title === "reminders" && <MassEditReminderSection />}
      {title === "discounts" && <MassEditDiscount />}
      {title === "upsell-products" && <MassEditUpsellProduct />}
      {title === "subscription" && <MassEditSubscription />}
      {title === "banner" && <MassEditBanner />}
      {title === "followup-reminder" && <MassEditFollowupReminders />}
    </>
  );
};

export default MassEditProductDetailsPage;

import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { MassUpdateFollowReminderRequest } from "../models/ProductPageConfigurationModel";
import { massUpdateFollowupReminder } from "../requests/ProductPageConfigurationRequests";
import { MassEditHeader } from "./MassEditHeader";
import { APIError } from "../../../layout/Error";
import FollowupReminder from "./FollowupReminder";

export const MassEditFollowupReminders = () => {
  const { businessId } = useAppSelector((state) => state.business);
  const [followup, setFollowup] = useState<{ days: number; isActive: boolean }>(
    { days: 7, isActive: true }
  );
  const [productConfigIds, setProductConfigIds] = useState<string[]>([]);
  const [saveError, setSaveError] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    try {
      const qParams = new URLSearchParams(location.search);
      if (qParams.get("ids")) {
        const decrypted = atob(qParams.get("ids") ?? "");
        setProductConfigIds(decrypted.split(","));
      } else {
        throw Error();
      }
    } catch (e) {
      navigate("/smart-reminders");
    }
    // eslint-disable-next-line
  }, []);

  const onSave = () => {
    return new Promise((resolve, reject) => {
      const updateRequest: MassUpdateFollowReminderRequest = {
        productConfigurationIds: productConfigIds,
        followUpReminderDays: followup.days,
        followUpReminderFlag: followup.isActive,
      };

      massUpdateFollowupReminder(updateRequest, businessId)
        .then((res: any) => {
          if (res.data.errorMessage) {
            setSaveError(true);
            setTimeout(() => {
              setSaveError(false);
            }, 3000);
            reject();
          } else {
            resolve(true);
          }
        })
        .catch(() => {
          setSaveError(true);
          setTimeout(() => {
            setSaveError(false);
          }, 5000);
          reject();
        });
    });
  };

  return (
    <MassEditHeader
      onSave={onSave}
      newValue={{
        ...followup,
      }}
      defaultValue={{
        days: 7,
        isActive: true,
      }}
    >
      {saveError && (
        <APIError
          error={"Something went wrong! Please try again"}
          consentText=""
          refresh={() => {}}
        />
      )}
      <FollowupReminder followUp={followup} setFollowUp={setFollowup} />
    </MassEditHeader>
  );
};

import Button from "./Button";

export const ContactSuccessModel = ({
  show,
  onClose,
}: {
  show: boolean;
  onClose: () => void;
}) => {
  return (
    <div
      className={` transition ease-linear duration-100 relative z-20 ${
        show ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div
        className={`min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover transition ease-in-out duration-500 ${
          show ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <div
          className={`absolute bg-black  inset-0 z-0 transition ease-in-out duration-500 ${
            show ? "opacity-50" : "opacity-0"
          }`}
        ></div>
        <div
          className={`w-full max-w-[444px] relative mx-auto my-auto rounded-lg shadow-lg bg-white transition ease-in-out duration-500  ${
            show ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <Button
            className={`cursor-pointer bg-off_white w-8 h-8 shadow-btn_shadow flex items-center justify-center rounded-full absolute -left-3 -top-3`}
            onClick={() => onClose()}
            icon={<img src="/close-btn.svg" alt="close button"></img>}
          />
          <div className="product-list overflow-y-auto max-h-84vh">
            <div className="p-8">
              <div className="pb-5 flex justify-center items-center ">
                <div className="flex items-center">
                  <img
                    className="w-[85px] h-[85px] mr-2 object-center"
                    src="/success-pic.png"
                    alt="error"
                  ></img>
                </div>
              </div>
              <div className="mt-4 mb-[54px] text-center">
                <div className="text-base font-bold">Your message is sent.</div>
                <div className="text-xs pt-4">
                  Our team will get back to you shortly.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

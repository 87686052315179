import { components, ValueContainerProps } from "react-select";

const { Option, SingleValue } = components;

export const OptionValueContainer = ({
  children,
  ...props
}: ValueContainerProps | any) => {
  const { label, subTitle, custom } = props.data;

  return (
    <Option
      {...props}
      className="value-container text-xs h-8 text-designer_grey text-opacity-50 tracking-[0.4px] font-AvenirLTPro_Black flex flex-col items-start md:justify-end justify-center relative z-0"
    >
      <div className="flex">
        {custom ? (
          <span className="text-blue_default">{label}</span>
        ) : (
          <>
            <span>{label}</span>
            {subTitle && (
              <span
                className={`text-blue_default pl-1 hover:text-white ${
                  props.isSelected && "text-white"
                }`}
              >
                {subTitle}
              </span>
            )}
          </>
        )}
      </div>
    </Option>
  );
};

export const SingleValueContainer = ({
  children,
  ...props
}: ValueContainerProps | any) => {
  const { label, subTitle } = props.getValue()[0];

  return (
    <SingleValue
      {...props}
      className="py-1 px-1 text-xs h-11 text-designer_grey text-opacity-50 tracking-[0.4px] font-AvenirLTPro_Black flex flex-col items-start md:justify-end justify-center relative z-0"
    >
      {props.hasValue && (
        <span
          className={`text-8 ${
            props.hasValue &&
            "has-value whitespace-pre text-designer_grey opacity-50 font-AvenirLTPro_Book uppercase tracking-[0.4px] text-8 mx-0"
          }`}
        >
          {props.selectProps.placeholder}
        </span>
      )}
      <div className="flex">
        <span>{label}</span>
        <span className="text-blue_default pl-1">{subTitle}</span>
      </div>
    </SingleValue>
  );
};

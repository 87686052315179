import React from "react";
import ResetPasswordModal from "./ResetPasswordModal";
const ResetPasswordPage = () => {
  const [modalShow, setModalShow] = React.useState(true);

  return (
    <ResetPasswordModal show={modalShow} onHide={() => setModalShow(false)} />
  );
};

export default ResetPasswordPage;

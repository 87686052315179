import _ from "lodash";
import { useState, useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import { AdminProtectedContainer } from "../../../components/RoleProtectedContainer";
import { useAppSelector } from "../../../redux/hooks";
import { postGoLive } from "../../guides/requests/BusinessRequests";

export const MassEditHeader = ({
  onSave,
  children,
  defaultValue,
  newValue,
}: {
  children: React.ReactNode;
  onSave: () => Promise<any>;
  defaultValue?: any;
  newValue?: any;
}) => {
  const { businessId } = useAppSelector((state) => state.business);
  const [saving, setSaving] = useState<boolean>(false);
  const [goingLive, setGoingLive] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [productConfigIds, setProductConfigIds] = useState<string[]>([]);
  const setupStatus = useAppSelector((state) => state.setup.setupStatus);
  const [oldState, setOldState] = useState({});

  useEffect(() => {
    try {
      const qParams = new URLSearchParams(location.search);
      if (qParams.get("ids")) {
        const decrypted = atob(qParams.get("ids") ?? "");
        setProductConfigIds(decrypted.split(","));
      } else {
        throw Error();
      }
    } catch (e) {
      navigate("/smart-reminders");
    }
    // eslint-disable-next-line
  }, []);

  const handleGoLive = (message = "") => {
    // setGoLive(false);
    setGoingLive(true);
    postGoLive(businessId)
      .then(() => {
        setGoingLive(false);
        // navigate(`/smart-reminders${message}`);
      })
      .catch(() => {
        setGoingLive(false);
      });
  };

  const canEnableSave = useMemo(() => {
    if (!_.isEqual(oldState, newValue)) {
      return false;
    } else {
      return true;
    }
  }, [oldState, newValue]);

  return (
    <div className="flex flex-col w-full">
      <div className="text-xs text-blue_default tracking-tracking_001 font-medium">
        <Link className="flex items-center" to="/smart-reminders">
          <img className="mr-1" src="/back-arrow.svg" alt="back arrow" />
          Smart reminders
        </Link>
      </div>
      <div className="title flex justify-between py-4 mb-6">
        <h1 className="text-2xl text-black_shade font-medium max-w-largeProductName">
          {productConfigIds.length} products selected
        </h1>
        <AdminProtectedContainer>
          <div className="flex items-center gap-3">
            <Button
              text="Save"
              disabled={canEnableSave || saving || goingLive}
              className={`py-2 px-4 rounded-md text-white flex items-center text-sm tracking-wide ${
                canEnableSave || saving || goingLive
                  ? "bg-gray-500"
                  : "bg-green-500"
              }`}
              onClick={() => {
                setSaving(true);
                setOldState(newValue);
                onSave()
                  .then((res) => {
                    setSaving(false);
                    if (setupStatus.goLive.status === "completed") {
                      handleGoLive(res);
                    }
                  })
                  .catch(() => setSaving(false));
              }}
              loading={saving || goingLive}
            />
          </div>
        </AdminProtectedContainer>
      </div>
      {children}
    </div>
  );
};

import { UserRole } from "../pages/account/models/UserModel";
import { useAppSelector } from "../redux/hooks";

export const RoleProtectedContainer = ({
  matchesRole = [],
  children,
}: {
  matchesRole: UserRole[];
  children: React.ReactNode;
}) => {
  const user: any = useAppSelector((state) => state.user.user);
  if (matchesRole.find((role) => role === user?.role)) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};

export const AdminProtectedContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <RoleProtectedContainer matchesRole={["Admin", "Billing Admin"]}>
      {children}
    </RoleProtectedContainer>
  );
};

export const BillingAdminProtectedContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <RoleProtectedContainer matchesRole={["Billing Admin"]}>
      {children}
    </RoleProtectedContainer>
  );
};

import Select from "react-select";
import { connectOptions } from "../IntegrationsPage";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";
import { getSyncConnection } from "../requests/IntegrationRequest";
import { updateSiteConfigIntegration } from "../../account/requests/SiteConfigurationsRequests";
import { useDispatch } from "react-redux";
import { setSiteConfiguration } from "../../../redux/ducks/siteconfig.duck";
import Button from "../../../components/Button";
import ConnectKlaviyoModal from "./ConnectKlaviyoModal";
import { KlaviyoType } from "../models/IntegrationModel";
import WarningModal from "../../customization/components/WarningModal";

export interface RequestConfig {
  invitationEmailDeliveryMethod?: string;
  smsDeliveryMethod?: string;
  emailDeliveryMethod?: string;
}

export const KlaviyoConnect = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { businessId } = useAppSelector((state) => state.business);

  const siteConfig: SiteConfiguration = useAppSelector(
    (state) => state.site.siteConfiguration
  );
  const [show, setShow] = useState<boolean>(false);
  const [isDisconnect, setIsDisconnect] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show]);
  const dispatch = useDispatch();
  const onDisconnectKlaviyo = async () => {
    setLoading(true);
    if (siteConfig) {
      let request: RequestConfig = {};

      if (siteConfig?.invitationEmailDeliveryMethod === "klaviyo_invitation") {
        request.invitationEmailDeliveryMethod = "assistalong_invitation";
      }
      if (siteConfig?.smsDeliveryMethod === "klaviyo_sms") {
        request.smsDeliveryMethod = "assistalong_sms";
      }
      if (siteConfig?.emailDeliveryMethod === "klaviyo_email") {
        request.emailDeliveryMethod = "assistalong_email";
      }

      await updateSiteConfigIntegration(
        siteConfig?.id,
        {
          klaviyoConnected: false,
          klaviyoPrivateKey: "",
          klaviyoPublicKey: "",
          klaviyoListId: "",
        },
        request
      )
        .then((res) => {
          dispatch(setSiteConfiguration(res?.data));
          setLoading(false);
          setIsDisconnect(false);
        })
        .catch((error) => {
          console.log(error?.response?.data?.error || error.message);
          setLoading(false);
          setIsDisconnect(false);
        });
    }
  };

  const onSubmitKlaviyo = async (values: KlaviyoType) => {
    setLoading(true);
    if (siteConfig) {
      await updateSiteConfigIntegration(siteConfig?.id, {
        klaviyoConnected: true,
        klaviyoPrivateKey: values.klaviyoPrivateKey,
        klaviyoPublicKey: values.klaviyoPublicKey,
      })
        .then((res) => {
          if (res.data && res.data.error) {
            setError(res.data.error);
          } else {
            dispatch(
              setSiteConfiguration({
                ...siteConfig,
                klaviyoConnected: true,
                klaviyoPrivateKey: values.klaviyoPrivateKey,
                klaviyoPublicKey: values.klaviyoPublicKey,
              })
            );
            setShow(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setShow(false);
        });
    }
  };

  return (
    <>
      <div className="w-full mb-9 bg-white rounded-lg">
        <div className="px-6 message-type-wrapper py-8">
          <div className="flex justify-between flex-row">
            <div className="flex flex-col">
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-2">
                Klayvio
              </p>
              <p className="text-10 text-dark-grey text-opacity-75">
                You can send your email reminders using Klayvio
              </p>
            </div>
            <div className="arrow__blue items-right">
              <div>
                {siteConfig?.klaviyoConnected && (
                  <>
                    <Select
                      styles={{
                        control: (base) => ({
                          ...base,
                          boxShadow: "none",
                          borderColor: "#2E5ED1",
                        }),
                        option: (base, state) => ({
                          ...base,
                          color: state.isSelected ? "white" : "#2E5ED1",
                        }),
                        singleValue: (base) => ({
                          ...base,
                          color: "#2E5ED1",
                        }),
                      }}
                      value={connectOptions[0]}
                      options={connectOptions}
                      isSearchable={false}
                      className="rounded w-[148px] border-0.5 border-blue_default text-xs border-opacity-50 font-medium"
                      isLoading={loading}
                      onChange={async (e: any) => {
                        if (e.value === "sync") {
                          setLoading(true);
                          await getSyncConnection(businessId)
                            .then(() => setLoading(false))
                            .catch((error) => setLoading(false));
                        }
                        if (e.value === "edit_credentials") {
                          setShow(true);
                        }
                        if (e.value === "disconnect") {
                          setIsDisconnect(true);
                        }
                      }}
                    />
                  </>
                )}
                {!siteConfig?.klaviyoConnected && (
                  <>
                    <Button
                      text="Connect"
                      className={`py-2.5 px-4 rounded-md flex items-center justify-center text-white bg-blue_default text-13 ${
                        loading ? "w-[137px]" : "w-[127px]"
                      } font-medium`}
                      disabled={loading}
                      loading={loading}
                      onClick={() => setShow(true)}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {siteConfig && (
        <ConnectKlaviyoModal
          onSubmit={onSubmitKlaviyo}
          show={show}
          onClose={() => setShow(false)}
          siteConfig={siteConfig}
          setSiteConfig={(updated) => dispatch(setSiteConfiguration(updated))}
          loading={loading}
          error={error}
          setError={setError}
        />
      )}
      <WarningModal
        show={isDisconnect}
        onClose={() => setIsDisconnect(false)}
        onSubmit={onDisconnectKlaviyo}
        message="Disconnecting klaviyo account will revert back delivery medium to AssistAlong if klaviyo is selected there, Are you sure you want to disconnect ?"
      />
    </>
  );
};

export const SpinningLoader = ({
  fullHeight = true,
  sizeClasses = "w-16 h-16",
  color = "#60a5fa",
}: {
  fullHeight?: Boolean;
  sizeClasses?: string;
  color?: string;
}) => (
  <div
    className={`flex h-full ${
      fullHeight && "min-h-screen"
    } justify-center items-center`}
  >
    <div
      style={{ borderTopColor: "transparent" }}
      className={`border-4 border-blue-400 border-solid rounded-full animate-spin ${sizeClasses}`}
    ></div>
  </div>
);

export const PageContentLoader = ({ pageTitle }: { pageTitle: string }) => {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="title flex justify-between mb-6">
        <h1 className="text-2xl text-black_shade font-medium">{pageTitle}</h1>
      </div>
      <SpinningLoader fullHeight={false} />
    </div>
  );
};

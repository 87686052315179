import { useEffect, useState } from "react";
import UpgradePlanModel from "../../../components/UpgradePlanModel";
import { useAppSelector } from "../../../redux/hooks";
import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";
import { isStaging } from "../../../util/helpers";
import { useNavigate } from "react-router";
// import { useParams } from "react-router-dom";

const CheckoutLink = ({
  slug,
  selectCheckoutProductFields,
}: {
  slug: string;
  selectCheckoutProductFields: string;
}) => {
  const navigate = useNavigate();
  const [copyText, setCopyText] = useState({
    klaviyo: false,
    direct: false,
  });
  const siteConfig: SiteConfiguration = useAppSelector(
    (state) => state.site.siteConfiguration
  );
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );
  const [showPlans, setShowPlans] = useState(false);

  const getStaticUrl = () => {
    return `{% if person|lookup:'Assistalong-UUID' %}https://${
      isStaging ? "stage.assistalong.app" : "assistalong.app"
    }/${
      siteConfig?.brandSlug
    }/{{ person|lookup:'Assistalong-UUID' }}/${slug}{% else %}{{ organization.url }}{% endif %}`;
  };

  const getDynamicUrl = () => {
    return `{% if person|lookup:'Assistalong-UUID' %}https://${
      isStaging ? "stage.assistalong.app" : "assistalong.app"
    }/${
      siteConfig?.brandSlug
    }/{{ person|lookup:'Assistalong-UUID' }}/${slug}/{{event.ProductID}}{% else %}{{ event.URL }}{% endif %}`;
  };

  // const { id } = useParams();
  const [link, setLink] = useState(getDynamicUrl());
  // const [directLink, setDirectLink] = useState(
  //   `https://assistalong.app/${siteConfig?.brandSlug}/${slug}`
  // );

  const canSee = () => {
    return permission?.includes("integration");
  };

  useEffect(() => {
    if (siteConfig && slug) {
      setLink(
        selectCheckoutProductFields === "static"
          ? getStaticUrl()
          : getDynamicUrl()
      );
      // setDirectLink(
      //   `https://assistalong.app/${id}/${siteConfig?.brandSlug}/${slug}`
      // );
    }
    // eslint-disable-next-line
  }, [siteConfig, slug, selectCheckoutProductFields]);

  useEffect(() => {
    if (copyText) {
      window.setTimeout(() => {
        setCopyText({
          klaviyo: false,
          direct: false,
        });
      }, 500);
    }
  }, [copyText]);

  return (
    <>
      <div className="mb-7 w-full bg-white rounded-lg">
        <div className="message-type-wrapper py-6 px-6 border-b border-light-grey">
          <div className="flex justify-between flex-row">
            <div>
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                Checkout link
              </p>
              <p className="text-10 text-dark-grey text-opacity-75">
                Follow instruction to use the checkout link in your Email and
                SMS marketing
              </p>
            </div>
            <div className="flex flex-row pr-5 items-center">
              {!canSee() && (
                <span
                  className="text-xs text-blue_default pl-11 cursor-pointer"
                  onClick={() => setShowPlans(true)}
                >
                  Upgrade
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={`py-[35px] px-6 ${!canSee() && "opacity-50"} `}>
          <div className="flex items-center justify-between text-xs font-medium tracking-tracking_001 text-black_shade">
            <div className="flex items-center w-[calc(100%_-_154px)] pr-5">
              <span className="w-20">Klaviyo:</span>
              {siteConfig.klaviyoConnected ? (
                <>
                  <div className="text-10 tracking-tracking_001 font-normal text-black_shade truncate">
                    {link}
                  </div>
                  <div
                    className="cursor-pointer text-xs font-normal tracking-tracking_001 text-blue_default ml-[70px]"
                    onClick={() => {
                      navigator.clipboard.writeText(link);
                      setCopyText({
                        ...copyText,
                        klaviyo: true,
                      });
                    }}
                  >
                    {copyText.klaviyo ? "copied" : "copy"}
                  </div>
                </>
              ) : (
                <div
                  className="cursor-pointer text-xs tracking-[0.4px] text-AvenirLTPro-Black text-blue_default truncate"
                  onClick={() => {
                    if (canSee()) {
                      navigate("/integrations");
                    }
                  }}
                >
                  Connect your Klaviyo platform
                </div>
              )}
            </div>
            {siteConfig.klaviyoConnected && (
              <div className="w-[154px]">
                <a
                  href="https://assistalong.notion.site/Klaviyo-Setup-Guide-40a6fee68f6b46ec9df512f04016ceea?pvs=4#27641a27e4e14eac814fb91317fa6856"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="rounded-md text-blue_default text-[13px] addproduct-button font-medium py-[9.5px] px-[26px] tracking-tracking_001">
                    See Instruction
                  </button>
                </a>
              </div>
            )}
          </div>

          {/*          <div className="flex pt-3 items-center justify-between text-xs font-medium tracking-tracking_001 text-black_shade">
            <div className="flex items-center w-[calc(100%_-_154px)] pr-5">
              <span className="w-20">Direct:</span>
              <>
                <div className="text-10 tracking-tracking_001 font-normal text-black_shade truncate">
                  {directLink}
                </div>
                <div
                  className="cursor-pointer text-xs font-normal tracking-tracking_001 text-blue_default ml-[70px]"
                  onClick={() => {
                    navigator.clipboard.writeText(directLink);
                    setCopyText({
                      ...copyText,
                      direct: true,
                    });
                  }}
                >
                  {copyText.direct ? "copied" : "copy"}
                </div>
              </>
            </div>
          </div>*/}
        </div>
      </div>
      {subscription && (
        <UpgradePlanModel
          show={showPlans}
          onClose={() => setShowPlans(false)}
          subscription={subscription}
        />
      )}
    </>
  );
};

export default CheckoutLink;

import { Switch } from "./Switch";

const FollowupReminder = ({
  followUp,
  setFollowUp,
}: {
  followUp: { days: number; isActive: boolean };
  setFollowUp: (value: { days: number; isActive: boolean }) => void;
}) => {
  return (
    <div className="mb-7 w-full bg-white rounded-lg">
      <div className="message-type-wrapper py-6 px-6 border-b border-light-grey">
        <div className="flex justify-between flex-row">
          <div>
            <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
              Send a followup reminder
            </p>
            <p className="text-10 text-dark-grey text-opacity-75">
              Send a follow up email reminder in each replenishment cycle if
              customers do not reorder or reschedule their reminders
            </p>
          </div>
          <div className="flex flex-row pr-5 items-center">
            <span className="text-xs tracking-wider text-dark-grey text-opacity-75 mr-3">
              Active
            </span>
            <div>
              <Switch
                state={followUp.isActive}
                onClick={() =>
                  setFollowUp({ ...followUp, isActive: !followUp.isActive })
                }
              />
            </div>
            <span className="text-xs tracking-wider text-dark-grey text-opacity-75 ml-3">
              Off
            </span>
          </div>
        </div>
      </div>
      <div className="py-4 pr-7 ml-7">
        <div className="flex items-center">
          <input
            value={followUp?.days}
            onChange={(e) => {
              const val = Number(e?.target?.value);
              if (isNaN(val)) {
                setFollowUp({ ...followUp, days: 0 });
              } else {
                setFollowUp({ ...followUp, days: val });
              }
            }}
            type="text"
            className="tracking-tracking_001 font-AvenirLTPro_Black w-[73px] h-12 px-6 py-3 !border-border_gray bg-white border rounded text-xs"
          />
          <span className="pl-3 text-xs font-normal">
            days after the first reminder{" "}
          </span>
        </div>
        {/* <div className="pt-[30px] pb-3">
      <input
        type="checkbox"
        className="!h-[13px] !w-[13px] !border-border_gray"
        name="discountAll"
        id="chkDiscount"
        checked={allDiscountActive}
        onChange={(e) => setAllDiscountActive(e.target.checked)}
      />
      <span className="pl-2 text-10 font-normal text-black_shade text-opacity-75">
        Apply the discount to all products in the cart including upsell
        products.
      </span>
    </div> */}
      </div>
    </div>
  );
};

export default FollowupReminder;

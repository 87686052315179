const Legal = () => {
  return (
    <div className="px-8 pb-4">
      <div className="py-6 flex 2xl:gap-8 xl:gap-5 gap-4 justify-between pb-8 border-b border-gray1">
        <div className="flex xl:items-center items-center mt-2 w-full ">
          <div className="tracking-wide_0.01 text-black_shade w-[230px] text-xs">
            Terms of Service:
          </div>
          <div className="flex items-center">
            <a
              rel="noreferrer"
              className="py-2 font-medium border border-gray-800 tracking-wide_0.01 px-8 text-13px flex max-w-max text-black rounded-md self-end"
              target="_blank"
              href="https://www.assistalong.com/terms-of-use"
            >
              Open
            </a>
          </div>
        </div>
      </div>
      <div className="py-6 flex 2xl:gap-8 xl:gap-5 gap-4 justify-between pb-8 border-b border-gray1">
        <div className="flex xl:items-center items-center mt-2 w-full ">
          <div className="tracking-wide_0.01 text-black_shade w-[230px] text-xs">
            Service agreement:
          </div>
          <div className="flex items-center">
            <a
              rel="noreferrer"
              className="py-2 font-medium border border-gray-800 tracking-wide_0.01 px-8 text-13px flex max-w-max text-black rounded-md self-end"
              target="_blank"
              href="https://www.assistalong.com/service-agreement"
            >
              Open
            </a>
          </div>
        </div>
      </div>
      <div className="py-6 flex 2xl:gap-8 xl:gap-5 gap-4 justify-between pb-8">
        <div className="flex xl:items-center items-center mt-2 w-full ">
          <div className="tracking-wide_0.01 text-black_shade w-[230px] text-xs">
            Privacy Policy:
          </div>
          <div className="flex items-center">
            <a
              rel="noreferrer"
              className="py-2 font-medium border border-gray-800 tracking-wide_0.01 px-8 text-13px flex max-w-max text-black rounded-md self-end"
              target="_blank"
              href="https://www.assistalong.com/privacy-policy"
            >
              Open
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legal;

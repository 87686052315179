import axios from "axios";
import { Template, TemplateRequestBody } from "../models/Customization";

const API_URL = process.env.REACT_APP_API_URL;

// created temporarily for testing only
export function createEmailTemplate(
  body: TemplateRequestBody,
  businessId: string
) {
  return axios.post(
    `${API_URL}/businesses/${businessId}/reminder_templates`,
    body
  );
}

export function updateEmailTemplate(
  id: number,
  body: TemplateRequestBody,
  businessId: string
) {
  return axios.put(
    `${API_URL}/businesses/${businessId}/reminder_templates/${id}`,
    body
  );
}

export function getEmailTemplate(businessId: string) {
  return axios.get<Template[]>(
    `${API_URL}/businesses/${businessId}/reminder_templates`
  );
}

import Select from "react-select";
import { connectOptions } from "../IntegrationsPage";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";
import { getSyncConnection } from "../requests/IntegrationRequest";
import { updateSiteConfigIntegration } from "../../account/requests/SiteConfigurationsRequests";
import { useDispatch } from "react-redux";
import { setSiteConfiguration } from "../../../redux/ducks/siteconfig.duck";
import Button from "../../../components/Button";
import ConnectPostscriptModal from "./ConnectPostscriptModal";
import { PostscriptType } from "../models/IntegrationModel";
import WarningModal from "../../customization/components/WarningModal";
import { RequestConfig } from "./KlaviyoConnect";

export const PostscriptConnect = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { businessId } = useAppSelector((state) => state.business);

  const siteConfig: SiteConfiguration = useAppSelector(
    (state) => state.site.siteConfiguration
  );
  const [show, setShow] = useState<boolean>(false);
  const [isDisconnect, setIsDisconnect] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show]);
  const dispatch = useDispatch();
  const onDisconnect = async () => {
    setLoading(true);
    if (siteConfig) {
      const request: RequestConfig = {};
      if (siteConfig?.smsDeliveryMethod === "postscript") {
        request.smsDeliveryMethod = "assistalong_sms";
      }
      await updateSiteConfigIntegration(
        siteConfig?.id,
        {
          postscriptConnected: false,
          postscriptXshopToken: "",
          postscriptKeyword: "",
        },
        request
      )
        .then((res) => {
          dispatch(setSiteConfiguration(res?.data));
          setLoading(false);
          setIsDisconnect(false);
        })
        .catch((error) => {
          console.log(error?.response?.data?.error || error.message);
          setLoading(false);
          setIsDisconnect(false);
        });
    }
  };

  const onSubmit = async (values: PostscriptType) => {
    setLoading(true);
    if (siteConfig) {
      await updateSiteConfigIntegration(siteConfig?.id, {
        postscriptConnected: true,
        postscriptXshopToken: values.postscriptXshopToken,
        postscriptKeyword: values.postscriptKeyword,
      })
        .then((res) => {
          if (res.data && res.data.error) {
            setError(res.data.error);
          } else {
            dispatch(
              setSiteConfiguration({
                ...siteConfig,
                postscriptConnected: true,
                postscriptXshopToken: values.postscriptXshopToken,
                postscriptKeyword: values.postscriptKeyword,
              })
            );
            setShow(false);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setShow(false);
        });
    }
  };

  return (
    <>
      <div className="w-full mb-9 bg-white rounded-lg">
        <div className="px-6 message-type-wrapper py-8">
          <div className="flex justify-between flex-row">
            <div className="flex flex-col">
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-2">
                Postscript
              </p>
              <p className="text-10 text-dark-grey text-opacity-75">
                You can send your SMS reminders using Postscript
              </p>
            </div>
            <div className="arrow__blue items-right">
              <div>
                {siteConfig?.postscriptConnected && (
                  <>
                    <Select
                      styles={{
                        control: (base) => ({
                          ...base,
                          boxShadow: "none",
                          borderColor: "#2E5ED1",
                        }),
                        option: (base, state) => ({
                          ...base,
                          color: state.isSelected ? "white" : "#2E5ED1",
                        }),
                        singleValue: (base) => ({
                          ...base,
                          color: "#2E5ED1",
                        }),
                      }}
                      value={connectOptions[0]}
                      options={connectOptions}
                      isSearchable={false}
                      className="rounded w-[148px] border-0.5 border-blue_default text-xs border-opacity-50 font-medium"
                      isLoading={loading}
                      onChange={async (e: any) => {
                        if (e.value === "sync") {
                          setLoading(true);
                          await getSyncConnection(businessId)
                            .then(() => setLoading(false))
                            .catch((error) => setLoading(false));
                        }
                        if (e.value === "edit_credentials") {
                          setShow(true);
                        }
                        if (e.value === "disconnect") {
                          setIsDisconnect(true);
                        }
                      }}
                    />
                  </>
                )}
                {!siteConfig?.postscriptConnected && (
                  <>
                    <Button
                      text="Connect"
                      className={`py-2.5 px-4 rounded-md flex items-center justify-center text-white bg-blue_default text-13 ${
                        loading ? "w-[137px]" : "w-[127px]"
                      } font-medium`}
                      disabled={loading}
                      loading={loading}
                      onClick={() => setShow(true)}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {siteConfig && (
        <ConnectPostscriptModal
          onSubmit={onSubmit}
          show={show}
          onClose={() => setShow(false)}
          siteConfig={siteConfig}
          setSiteConfig={(updated) => dispatch(setSiteConfiguration(updated))}
          loading={loading}
          error={error}
          setError={setError}
        />
      )}
      <WarningModal
        show={isDisconnect}
        onClose={() => setIsDisconnect(false)}
        onSubmit={onDisconnect}
        message="Disconnecting postscript account will revert back SMS delivery medium to AssistAlong if postscript is selected there, Are you sure you want to disconnect ?"
      />
    </>
  );
};

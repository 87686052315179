import { AxiosInstance } from "axios";
import { decamelizeKeys, camelizeKeys } from "humps";

export function setupAxios(axios: AxiosInstance, accessToken?: string) {
  axios.interceptors.request.use(
    (config: any) => {
      config.headers.Accept = "application/json";

      if (accessToken) {
        config.headers.Authorization = `${accessToken}`;
      }

      if (config.data) {
        config.data = decamelizeKeys(config.data);
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (config: any) => {
      if (config.data) {
        config.data = camelizeKeys(config.data);
      }

      return config;
    },
    (err: any) => Promise.reject(err)
  );
}

import axios from "axios";
import { UseAPI, useApi } from "../../../use-api";
import { Business, UpdateBusiness } from "../models/Business";

const API_URL = process.env.REACT_APP_API_URL;

const GET_BUSINESS_URL = `${API_URL}/businesses`;

export function useGetBusiness(): UseAPI<Business> {
  return useApi<Business>({
    method: "get",
    url: GET_BUSINESS_URL,
  });
}

export function getBusiness() {
  return axios.get<Business>(GET_BUSINESS_URL);
}

export function postGoLive(businessId: number) {
  return axios.post(`${GET_BUSINESS_URL}/${businessId}/go_live`);
}

export function updateBusiness(businessId: number, req: UpdateBusiness) {
  return axios.put<Business>(`${GET_BUSINESS_URL}/${businessId}`, {
    business: req,
  });
}

export function updateBusinessDnsVerification(businessId: number) {
  return axios.post(`${GET_BUSINESS_URL}/${businessId}/verify_dns`);
}

export function updateStepStatus(
  businessId: number,
  data: { [key: string]: string }
) {
  return axios.post(`${GET_BUSINESS_URL}/${businessId}/complete_step`, data);
}

import axios from "axios";
import { useApi, UseAPI } from "../../../use-api";
import { PresignedPost } from "../models/SiteConfigurationModel";

const API_URL = process.env.REACT_APP_API_URL;

const baseUrl = (businessId: number) =>
  `${API_URL}/businesses/${businessId}/file_uploads`;

export function useGetFileUpload(businessId: number): UseAPI<PresignedPost> {
  return useApi(
    {
      method: "get",
      url: baseUrl(businessId),
    },
    undefined,
    axios.create()
  );
}

import Select from "react-select";
import { Switch } from "../../smart_reminders/components/Switch";
import {
  discountMinPurchaseOptions,
  discountShipMinPurchaseOptions,
  shippingOptions,
} from "../constants/options";
import { DiscountShippingInterface } from "../models/SmartCheckout";
import MinPurchase from "./MinPurchase";

const AddShippingDiscount = ({
  active,
  setActive,
  discountOption,
  setDiscountValue,
  errors,
  setError,
}: {
  active: boolean;
  setActive: (flag: boolean) => void;
  discountOption: DiscountShippingInterface;
  setDiscountValue: (
    fieldName: string,
    value: string | boolean | number
  ) => void;
  errors: any;
  setError: (error: { [key: string]: string }) => void;
}) => {
  return (
    <div className="mb-7 w-full bg-white rounded-lg">
      <div className="message-type-wrapper py-6 px-6 border-b border-light-grey">
        <div className="flex justify-between flex-row">
          <div>
            <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
              Add discount
            </p>
            <p className="text-10 text-dark-grey text-opacity-75">
              Add incentive by offering discount. The discount will be
              automatically applied to the reorder cart.
            </p>
          </div>
          <div className="flex flex-row pr-5 items-center">
            <span className="text-xs tracking-wider text-dark-grey text-opacity-75 mr-3">
              Active
            </span>
            <div>
              <Switch state={active} onClick={() => setActive(!active)} />
            </div>
            <span className="text-xs tracking-wider text-dark-grey text-opacity-75 ml-3">
              Off
            </span>
          </div>
        </div>
      </div>
      <div className="pb-4 pt-6 pr-7 ml-7">
        <div className="flex items-center">
          <div className="flex items-center w-1/2 flex-wrap">
            <Switch
              state={discountOption?.discountTypeFlag}
              onClick={() => {
                setDiscountValue(
                  "discountTypeFlag",
                  !discountOption.discountTypeFlag
                );
              }}
            />

            <div className="flex justify-center">
              <div className="px-5 dropdown-select-wrap">
                <div className="w-[137px]">
                  <Select
                    styles={{
                      control: (base) => ({
                        ...base,
                        boxShadow: "none",
                        height: "48px",
                      }),
                      valueContainer: (base) => ({
                        ...base,
                        height: "48px",
                        paddingLeft: "15px",
                      }),
                    }}
                    value={shippingOptions?.filter(
                      (option) => option.value === discountOption?.discountType
                    )}
                    options={shippingOptions}
                    isSearchable={false}
                    className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
                    onChange={(e: any) => {
                      setError({ discountTypeValue: "" });
                      setDiscountValue("discountType", e?.value);
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center">
                <input
                  className={`text-gray-900 w-[100px] rounded-l border border-r-0 text-xs  tracking-tracking_001 bg-white focus:ring-0 !py-[15px] px-3 block dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none`}
                  inputMode="numeric"
                  type={"text"}
                  value={discountOption?.discountTypeValue}
                  onChange={(e) => {
                    setDiscountValue(
                      "discountTypeValue",
                      !isNaN(Number(e?.target?.value))
                        ? Number(e?.target?.value)
                        : 0
                    );
                  }}
                />
                <input
                  type="text"
                  className={`w-9 !py-[15px] px-1 border border-l-0 text-xs text-center rounded-r bg-gray-100 text-gray-500`}
                  disabled
                  value={
                    discountOption?.discountType === "percentage" ? "%" : "$"
                  }
                />
              </div>
            </div>

            {errors?.discountTypeValue && (
              <p className="text-danger pt-1 text-red-500 text-xs text-left w-full pl-[60px]">
                {errors?.discountTypeValue}
              </p>
            )}
          </div>

          {discountOption?.discountTypeFlag && (
            <MinPurchase
              discountOption={discountOption}
              setDiscountValue={setDiscountValue}
              minPurchaseoptions={discountMinPurchaseOptions}
              shipMinPurchase={false}
              errors={errors}
              setError={setError}
            />
          )}
        </div>
      </div>
      <div className="pt-4 pb-12 pr-7 ml-7">
        <div className="flex items-center">
          <div className="flex items-center w-1/2 h-12">
            <Switch
              state={discountOption?.discountShippingFlag}
              onClick={() => {
                setDiscountValue(
                  "discountShippingFlag",
                  !discountOption?.discountShippingFlag
                );
              }}
            />
            <span className="px-5 text-xs font-normal tracking-tracking_001 text-black_shade">
              Free Shipping
            </span>
          </div>
          {discountOption?.discountShippingFlag && (
            <MinPurchase
              discountOption={discountOption}
              setDiscountValue={setDiscountValue}
              minPurchaseoptions={discountShipMinPurchaseOptions}
              shipMinPurchase={true}
              errors={errors}
              setError={setError}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddShippingDiscount;

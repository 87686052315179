const Button = ({
  onClick,
  text,
  type = undefined,
  className = "",
  disabled = false,
  loading = false,
  icon = <></>,
}: {
  onClick?: (e?: any) => void;
  text?: string;
  type?: "button" | "reset" | "submit" | undefined;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={className}
      onClick={onClick}
    >
      {loading && (
        <div
          style={{ borderTopColor: "transparent" }}
          className={`border-2 border-white border-solid rounded-full animate-spin h-4 w-4 mr-2`}
        ></div>
      )}
      {icon}
      {text}
    </button>
  );
};

export default Button;

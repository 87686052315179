import { ReminderFreqOption } from "../ProductDetailsPage";
import { Switch } from "./Switch";

const SetReminderOption = ({
  setReminderFreqType,
  reminderFreqType,
}: {
  setReminderFreqType: (feq: ReminderFreqOption) => void;
  reminderFreqType: ReminderFreqOption;
}) => {
  return (
    <div className="pb-8 w-full mb-7 bg-white rounded-lg">
      <div className="px-6 message-type-wrapper py-6 border-b border-light-grey">
        <div className="flex justify-between flex-row">
          <div className="flex flex-col">
            <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
              Set a default reminder option
            </p>
            <p className="text-10 text-dark-grey text-opacity-75">
              Set reorder frequency for customers who did not select any options
            </p>
          </div>
          <div className="flex flex-row pr-5 items-center">
            <span className="text-xs tracking-wider text-black_shade mr-3">
              Active
            </span>
            <div>
              <Switch
                state={reminderFreqType?.isActive}
                onClick={() => {
                  setReminderFreqType({
                    ...reminderFreqType,
                    isActive: !reminderFreqType?.isActive,
                  });
                }}
              />
            </div>
            <span className="text-xs tracking-wider text-black_shade ml-3">
              Off
            </span>
          </div>
        </div>
      </div>
      <div className="px-6 pt-5 ">
        <div className="flex flex-wrap items-center pt-3 pr-6 pb-3 ">
          <input
            type="radio"
            className="mt-1"
            id="fequency"
            name="reminder_option"
            checked={reminderFreqType?.type === "default_frequency"}
            onChange={() => {
              setReminderFreqType({
                ...reminderFreqType,
                type: "default_frequency",
              });
            }}
          />
          <label
            htmlFor="fequency"
            className="cursor-pointer pl-6 text-xs font-normal tracking-tracking_001 leading-4 text-dark-grey"
          >
            Default frequency for all customers
          </label>
        </div>
        <div className="flex flex-wrap pt-3 pr-6 pb-[35px] items-center">
          <input
            type="radio"
            className="mt-1"
            id="ai_reminder"
            name="reminder_option"
            checked={reminderFreqType?.type === "ai_frequency"}
            onChange={() => {
              setReminderFreqType({
                ...reminderFreqType,
                type: "ai_frequency",
              });
            }}
          />
          <label
            htmlFor="ai_reminder"
            className="cursor-pointer pl-6 text-xs font-normal tracking-tracking_001 leading-4 text-dark-grey"
          >
            Let AI choose for each customer
            <span className="text-10 tracking-tracking_001 font-normal text-[#2E5ED1] pl-1.5">
              (recommended)
            </span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default SetReminderOption;

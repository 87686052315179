import Select from "react-select";
import {
  OptionValueContainer,
  SingleValueContainer,
} from "../../../components/OptionValueContainer";
import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";

const EmailReminderFlow = ({
  siteConfigInitials,
  setFieldValueSiteConfig,
}: {
  siteConfigInitials: SiteConfiguration;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
}) => {
  const configOptions = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
      // subTitle: "(recommended)",
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
  ];

  const getValue = () => {
    if (siteConfigInitials?.emailReminderCount) {
      return configOptions.filter(
        (obj) => obj.value === siteConfigInitials.emailReminderCount
      );
    }
    return {
      label: "5",
      value: 5,
    };
  };

  return (
    <div className="pb-8 w-full mb-7 bg-white rounded-lg">
      <div className="px-6 message-type-wrapper py-6 border-b border-light-grey">
        <div className="flex justify-between flex-row">
          <div className="flex flex-col">
            <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
              Email Reminder Flows
            </p>
            <p className="text-10 tracking-tracking_001 text-dark-grey text-opacity-75">
              How many replenishment cycles should email flow last if a customer
              doesn’t reorder?
            </p>
          </div>
          {/* <div className="flex flex-row pr-5 items-center">
            <span className="text-xs tracking-wider text-black_shade mr-3">
              Active
            </span>
            <div>
              <Switch
                state={siteConfigInitials.isEmailFlowEnabled}
                onClick={() => {
                  setFieldValueSiteConfig(
                    "isEmailFlowEnabled",
                    !siteConfigInitials.isEmailFlowEnabled
                  );
                }}
              />
            </div>
            <span className="text-xs tracking-wider text-black_shade ml-3">
              Off
            </span>
          </div> */}
        </div>
      </div>
      <div className="px-6 pt-5 dropdown-select-wrap">
        <div className="w-[220px]">
          <Select
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
              }),
              placeholder: (base) => ({
                ...base,
                height: "44px",
                display: "flex",
                flexDirection: "column",
                position: "relative",
                zIndex: 0,
                fontFamily: "AvenirLTPro-Black",
                justifyContent: "center",
                fontSize: "12px",
                alignItems: "start",
                letterSpacing: "0.4px",
              }),
            }}
            value={getValue()}
            options={configOptions}
            placeholder="REPLENISHMENT CYCLES"
            isSearchable={false}
            className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
            components={{
              SingleValue: SingleValueContainer,
              Option: OptionValueContainer,
            }}
            onChange={(e: any) => {
              setFieldValueSiteConfig("emailReminderCount", e?.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailReminderFlow;

import axios from "axios";
import moment from "moment";
import { UseAPI, useApi } from "../../../use-api";
import { Order, OrderRefund } from "../models/OrderModel";
import { OrderQueryResults } from "../models/OrderQueryModel";

const API_URL = process.env.REACT_APP_API_URL;

export function useGetOrders(businessId: number): UseAPI<Order[]> {
  return useApi<Order[]>({
    method: "get",
    url: `${API_URL}/businesses/${businessId}/orders`,
  });
}

export type RefundReason = "fradulent" | "duplicate" | "requested_by_customer";

export function refundOrder(uuid: string, reason: RefundReason) {
  return axios.post<OrderRefund>(`${API_URL}/orders/${uuid}/refund`, {
    reason: reason,
  });
}
export function useQueryOrders(
  businessId: number,
  from: Date,
  to: Date,
  channels?: number[],
  products?: number[],
  codes?: number[],
  prevAc?: AbortController | null
): UseAPI<OrderQueryResults> {
  // controller.abort();
  const url = new URL(`${API_URL}/businesses/${businessId}/orders/query`);

  url.searchParams.append(
    "from",
    moment(from).utc().format("YYYY-MM-DDT00:00:00.000") + "Z"
  );
  url.searchParams.append(
    "to",
    moment(to).utc().format("YYYY-MM-DDT23:59:59.000") + "Z"
  );
  url.searchParams.append(
    "tz_difference",
    (new Date().getTimezoneOffset() * -1).toString()
  );
  url.searchParams.append(
    "timezone_name",
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  if (channels) {
    url.searchParams.append(
      "channels",
      channels.map((ch) => String(ch)).join(",")
    );
  }

  if (products) {
    url.searchParams.append(
      "products",
      products.map((ch) => String(ch)).join(",")
    );
  }

  if (codes) {
    url.searchParams.append("codes", codes.map((ch) => String(ch)).join(","));
  }

  return useApi<OrderQueryResults>({
    method: "get",
    url: url.toString(),
    signal: prevAc?.signal,
  });
}

import axios from "axios";
import { useApi, UseAPI } from "../../../use-api";
import {
  CreateShopifyConfigurationRequest,
  ShopifyConfiguration,
  UpdateShopifyConfigurationRequest,
} from "../models/ShopifyConfigurationModel";

const API_URL = process.env.REACT_APP_API_URL;

const baseUrl = (businessId: number) =>
  `${API_URL}/businesses/${businessId}/shopify_configurations`;

function configUrl(id: number) {
  return `${API_URL}/shopify_configurations/${id}`;
}

export function getShopifyConfiguration(businessId: number) {
  return axios.get<ShopifyConfiguration>(baseUrl(businessId));
}

export function useGetShopifyConfiguration(
  businessId: number
): UseAPI<ShopifyConfiguration> {
  return useApi({
    method: "get",
    url: baseUrl(businessId),
  });
}

export function updateShopifyConfiguration(
  id: number,
  request: UpdateShopifyConfigurationRequest
) {
  return axios.patch<ShopifyConfiguration>(configUrl(id), { shopify: request });
}

export function createShopifyConfiguration(
  businessId: number,
  request: CreateShopifyConfigurationRequest
) {
  return axios.post<ShopifyConfiguration>(baseUrl(businessId), {
    shopify: request,
  });
}

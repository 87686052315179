import { OrderQueryResults } from "../../orders/models/OrderQueryModel";
import { useQueryOrders } from "../../orders/requests/OrdersRequests";
import { LineChart } from "./LineChart";
import { APIError } from "../../../layout/Error";
import Select from "react-select";
import { useEffect, useState } from "react";
import { SpinningLoader } from "../../../layout/Loaders";
import { TimeState } from "../InsightsPage";
import { formatNumber } from "../../../util/helpers";
import { OrderInsightType, OrdersInsightChart } from "../models/DashboardModel";
import { AdminProtectedContainer } from "../../../components/RoleProtectedContainer";

type OrderQuerySelector =
  | "order_revenue"
  | "order_count"
  | "reminder_count"
  | "customer_count"
  | "customer_enrolled"
  | "converted_subscription_count"
  | "customer_percentage"
  | "sms_sent_count"
  | "email_sent_count";

interface DisplayTypeSelector {
  label: string;
  value: OrderQuerySelector;
}
const DataDisplay = ({
  data,
  type,
}: {
  data: OrderQueryResults;
  type: OrderQuerySelector;
}) => {
  let ds = [],
    averageDs = [];
  switch (type) {
    case "order_revenue":
      ds = data.revenue;
      averageDs = data.averageOrderRevenue;
      break;
    case "order_count":
      ds = data.count;
      averageDs = data.averageOrderCount;
      break;
    case "converted_subscription_count":
      ds = data.noOfCustomersConvertedToSubscription;
      averageDs = data.avgNoOfCustomersConvertedToSubscriptionCount;
      break;
    case "reminder_count":
      ds = data.reminderCount;
      averageDs = data.averageReminderCount;
      break;
    case "customer_enrolled":
      ds = data.customersEnrolledSmsCount;
      averageDs = data.avgNoOfCustomersEnrolledSms;
      break;
    case "customer_percentage":
      ds = data.percentageOfCustomersEnrolledCount;
      averageDs = data.avgPercentageOfCustomersEnrolled;
      break;
    case "sms_sent_count":
      ds = data.reminderSmsCount;
      averageDs = data.avgSmsReminderCount;
      break;
    case "email_sent_count":
      ds = data.reminderEmailCount;
      averageDs = data.avgEmailReminderCount;
      break;
    default:
      ds = data.customerCount;
      averageDs = data.averageCustomerCount;
      break;
  }
  const revenueDataset = {
    label: type,
    data: ds,
  };

  const averageDataset = {
    label: `Average ${type}`,
    data: averageDs,
    borderColor: "#000000",
    fill: false,
    borderDash: [5, 5],
  };

  return (
    <LineChart
      title={`Order ${type}`}
      labels={data.labels}
      datasets={[revenueDataset]}
      average={averageDataset}
    />
  );
};
export const OrdersChart = ({
  orderChart,
  businessId,
  state,
  handleUpdate,
  defaultType,
  index,
}: {
  orderChart: OrdersInsightChart;
  businessId: number;
  state: TimeState;
  defaultType: OrderInsightType;
  handleUpdate: (updates: OrdersInsightChart, index: number) => void;
  index: number;
}) => {
  const [prevAc, setPrevAc] = useState<AbortController | null>(
    new AbortController()
  );
  const [initiallyLoaded, setInitiallyLoaded] = useState(false);

  const { loading, refresh, data, error } = useQueryOrders(
    businessId,
    state.from,
    state.to,
    [],
    [],
    [],
    prevAc
  );

  const [displayType, setDisplayType] =
    useState<OrderQuerySelector>(defaultType);

  const selectOptions: DisplayTypeSelector[] = [
    { label: "No of customers set SMS reminders", value: "customer_enrolled" },
    {
      label: "Percentage of customers set SMS reminders",
      value: "customer_percentage",
    },
    {
      label: "No of customers converted to subscribers",
      value: "converted_subscription_count",
    },
    { label: "No of sms reminders sent", value: "sms_sent_count" },
    { label: "No of email reminders sent", value: "email_sent_count" },
    { label: "No of orders", value: "order_count" },
    { label: "Total sales", value: "order_revenue" },
  ];

  const defaultOption = selectOptions.find(
    (item) => item.value === displayType
  );

  useEffect(() => {
    setInitiallyLoaded(true);
  }, []);

  useEffect(() => {
    if (initiallyLoaded) {
      // only refresh the graphs if data is already fetched once
      if (prevAc) {
        prevAc.abort();
      }
      setPrevAc(new AbortController());
    }
    //eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    if (data) {
      setPrevAc(null);
    }
  }, [data]);

  useEffect(() => {
    if (initiallyLoaded && prevAc) {
      refresh();
    }
    // eslint-disable-next-line
  }, [prevAc]);

  const getCount = () => {
    if (data) {
      switch (displayType) {
        case "order_revenue":
          return "$" + formatNumber(data.totalRevenue);
        case "order_count":
          return data.totalOrders;
        case "reminder_count":
          return data.totalReminders;
        case "customer_enrolled":
          return data.totalCustomersEnrolledSms;
        case "converted_subscription_count":
          return data.totalNoOfCustomersConvertedToSubscription;
        case "customer_percentage":
          return data.totalPercentageOfCustomersEnrolled;
        case "sms_sent_count":
          return data.totalSmsReminders;
        case "email_sent_count":
          return data.totalEmailReminders;
        default:
          return data.totalCustomers;
      }
    }
    return 0;
  };

  return (
    <div className="w-full p-8 relative">
      <div className="flex gap-3 items-center mb-6">
        <div className="dropdown-select-wrap">
          <Select
            styles={{
              control: (base) => ({
                ...base,
                boxShadow: "none",
              }),
              valueContainer: (base) => ({
                width: "150px",
                display: "flex",
              }),
              singleValue: (base) => ({
                ...base,
                padding: "12px 10px",
                fontWeight: "500",
              }),
              dropdownIndicator: (base) => ({
                ...base,
                color: "#1D252C",
                "&:hover": {
                  color: " #1D252C",
                },
              }),
            }}
            options={selectOptions}
            placeholder="Select Product"
            onChange={(e) => {
              setDisplayType(e!.value);
              handleUpdate({ ...orderChart, insightType: e!.value }, index);
            }}
            isSearchable={false}
            defaultValue={defaultOption}
            className="rounded border-0.5 border-borderGrey-greyBorder text-xs border-opacity-30"
          />
        </div>
        {!error && !loading && data && (
          <p className="text-[#2E5ED1] text-2xl font-medium tracking-tracking_001">
            {getCount()}
          </p>
        )}
      </div>
      {error && (
        <APIError
          error={error}
          refresh={refresh}
          consentText="Consent to API access"
        />
      )}
      {loading ? (
        <SpinningLoader />
      ) : (
        data && (
          <div>
            <AdminProtectedContainer>
              <button
                onClick={() => {
                  handleUpdate({ ...orderChart, _destroy: true }, index);
                }}
                className="cursor-pointer bg-off_white w-8 h-8 shadow-btn_shadow flex items-center justify-center rounded-full absolute -top-3 -right-3"
              >
                <img src="/close-btn.svg" alt="close button"></img>
              </button>
            </AdminProtectedContainer>
            <DataDisplay data={data} type={displayType} />
          </div>
        )
      )}
    </div>
  );
};

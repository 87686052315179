import { Modal, Button } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
library.add(faChevronRight);

const Login = (props: any) => {
  const { title, buttonText, formData } = props;
  return (
    <div className="login-modal">
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={props.show}
        size="md"
        close={false}
      >
        <Modal.Header className="text-center">
          <div className="login-modal-title">
            <img src="https://i.ibb.co/ZBtnrdw/company-logo.png" alt="" />
            <h6>{title}</h6>
          </div>
        </Modal.Header>
        <Modal.Body className="space-y-6 p-6">{formData}</Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button className="login-button" form="login-form" type="submit">
            {buttonText}
            <FontAwesomeIcon className="login-icon pl-3" icon="chevron-right" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export { Login };

import { showHoursOption } from "../../../util/helpers";

export interface Frequency {
  quantity: number;
  quality: "day" | "week" | "month" | "hour";
}
export interface RenderableStep {
  frequency: Frequency;
  id?: number;
  defaultStep?: boolean;
  orderNumber?: number;
  _destroy?: boolean;
}

export interface AddStepAction {
  type: "add";
  step: {
    frequency: Frequency;
  };
}

export interface UpdateQualityAction {
  type: "updateQuality";
  index: number;
  step: {
    quality: "day" | "week" | "month" | "hour";
  };
}

export interface UpdateQuantityAction {
  type: "updateQuantity";
  index: number;
  step: {
    quantity: number;
  };
}

export interface UpdateDefaultAction {
  type: "updateDefault";
  index: number;
  step: {
    defaultStep: boolean;
  };
}

export interface DeleteStepAction {
  type: "delete";
  index: number;
}

export interface ResetStepAction {
  type: "reset";
  newState: StepState;
}

export interface StepState {
  steps: RenderableStep[];
  hasDuplicates: boolean;
}

export interface OptionType {
  value: string;
  label: string;
}

export const qualityOptions = [
  { label: "days", value: "day" },
  { label: "weeks", value: "week" },
  { label: "months", value: "month" },
];

if (showHoursOption) {
  qualityOptions.splice(0, 0, { label: "hours", value: "hour" });
}

export const quantityOptions = Array(31)
  .fill(0, 1)
  .map((_, i) => ({ label: `${i}`, value: `${i}` }));
quantityOptions.push({ label: "45", value: "45" });
quantityOptions.push({ label: "60", value: "60" });
quantityOptions.push({ label: "90", value: "90" });

import { BannerFields } from "../ProductDetailsPage";
import { Switch } from "./Switch";

const BannerCart = ({
  bannerFields,
  setBannerFields,
}: {
  bannerFields: BannerFields;
  setBannerFields: (data: BannerFields) => void;
}) => {
  return (
    <>
      <div className="pb-8 w-full mb-7 bg-white rounded-lg">
        <div className="px-6 message-type-wrapper py-6 border-b border-light-grey">
          <div className="flex justify-between flex-row">
            <div className="flex flex-col">
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                Add banner to the cart
              </p>
              <p className="text-10 text-dark-grey text-opacity-75">
                show a banner in the cart with the message below
              </p>
            </div>
            <div className="flex flex-row pr-5 items-center">
              <span className="text-xs tracking-wider text-black_shade mr-3">
                Active
              </span>
              <div>
                <Switch
                  state={bannerFields["isActive"]}
                  onClick={() => {
                    setBannerFields({
                      ...bannerFields,
                      isActive: !bannerFields.isActive,
                    });
                  }}
                />
              </div>
              <span className="text-xs tracking-wider text-black_shade ml-3">
                Off
              </span>
            </div>
          </div>
        </div>
        <div className="px-6 pt-5 ">
          <input
            type="text"
            placeholder="Add message here"
            value={bannerFields.text ?? ""}
            onChange={(e) =>
              setBannerFields({ ...bannerFields, text: e.target.value })
            }
            className="max-w-[620px] tracking-tracking_001 w-full px-3 py-3 border-dark-grey border-opacity-30 bg-white  border rounded text-xs font-light focus:ring-0"
          />
        </div>
      </div>
    </>
  );
};

export default BannerCart;

import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";
import { Switch } from "../../smart_reminders/components/Switch";
import Select from "react-select";
import {
  OptionValueContainer,
  SingleValueContainer,
} from "../../../components/OptionValueContainer";
import { useAppSelector } from "../../../redux/hooks";
import { useNavigate } from "react-router";

export const SendReminder = ({
  siteConfigInitials,
  setFieldValueSiteConfig,
}: {
  siteConfigInitials: SiteConfiguration;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
}) => {
  const navigate = useNavigate();
  const siteConfig: SiteConfiguration = useAppSelector(
    (state) => state.site.siteConfiguration
  );

  const configOptions = [
    {
      label: "AssistAlong",
      value: "assistalong_invitation",
    },
    ...(siteConfig.klaviyoConnected
      ? [
          {
            label: "Klaviyo",
            value: "klaviyo_invitation",
          },
        ]
      : []),
    {
      label: "Connect your email platform",
      value: "connect_your_email_platform",
      custom: true,
    },
  ];

  return (
    <>
      <div className="py-6 mt-8 pb-8 w-full mb-[22px] bg-white rounded-lg">
        <div className="pl-6 pt-2">
          <div className="text-base pb-7 border-b border-gray-200 text-dark-grey tracking-tracking_001 font-medium">
            Where can customers set their recurring SMS reminders?
          </div>
        </div>
        <div className="pl-6">
          <div className=" message-type-wrapper pr-6 pt-7 pb-[13px]">
            <div className="flex justify-between flex-row">
              <div className="flex flex-col">
                <p className="tracking-tracking_001 text-sm text-dark-grey font-medium">
                  Opt-In Form on the Thank-You Page
                </p>
              </div>
              <div className="flex flex-row pr-5 mt-1.5">
                <span className="text-xs tracking-wider text-dark-grey text-opacity-75 mr-3">
                  Active
                </span>
                <div>
                  <Switch
                    state={siteConfigInitials.optInEnabled}
                    onClick={() => {
                      setFieldValueSiteConfig(
                        "optInEnabled",
                        !siteConfigInitials.optInEnabled
                      );
                    }}
                  />
                </div>
                <span className="text-xs tracking-wider text-dark-grey text-opacity-75 ml-3">
                  Off
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap pt-3 pr-6 pb-3 ">
            <input
              type="radio"
              key={siteConfigInitials.smsReminderOptInType + "1"}
              className="mt-1"
              id="embedded"
              name="sms_reminder_type"
              defaultChecked={
                siteConfigInitials.smsReminderOptInType === "embedded"
              }
              onChange={() =>
                setFieldValueSiteConfig("smsReminderOptInType", "embedded")
              }
            />
            <div className="flex flex-col pl-6 w-[450px]">
              <p className="text-sm text-dark-grey font-medium mb-1">
                Embedded
              </p>
              <p className="text-10 font-normal tracking-tracking_001 leading-4 text-dark-grey text-opacity-75">
                The form will be embedded into the Thank-you page
              </p>
            </div>
          </div>
          <div className="flex flex-wrap pt-3 pr-6 pb-[35px] border-b border-gray-200">
            <input
              type="radio"
              key={siteConfigInitials.smsReminderOptInType + "2"}
              className="mt-1"
              id="popup"
              name="sms_reminder_type"
              defaultChecked={
                siteConfigInitials.smsReminderOptInType === "popup"
              }
              onChange={() =>
                setFieldValueSiteConfig("smsReminderOptInType", "popup")
              }
            />
            <div className="flex flex-col pl-6 w-[450px]">
              <p className="text-sm text-dark-grey tracking-tracking_001 font-medium mb-1">
                Pop-Up Overlay
                <span className="text-10 tracking-tracking_001 font-normal text-[#2E5ED1] pl-1.5">
                  (recommended)
                </span>
              </p>
              <p className="text-10 font-normal tracking-tracking_001 leading-4 text-dark-grey text-opacity-75">
                The form will appear overtop of the Thank-you page
              </p>
            </div>
          </div>
        </div>
        <div className="px-6 message-type-wrapper pt-[27px]">
          <div className="flex justify-between flex-row">
            <div className="flex flex-col">
              <p className="tracking-tracking_001 text-sm text-dark-grey font-medium mb-1">
                Send an invitation email to set reminders
              </p>
              <p className="text-10 text-dark-grey text-opacity-75 w-[478px]">
                Send an email to customers who didn’t set reminders after their
                purchase and invite them to set reminders
              </p>
              <div className="flex w-full pt-7 pb-4">
                <span className="text-sm tracking-tracking_001 mt-2 font-medium text-black_shade mr-4 xl:mb-0 min-w-[140px]">
                  Invitation email
                </span>
                <div className="flex justify-between text-xs w-[277px]">
                  <div className="dropdown-select-wrap w-full mb-2">
                    <Select
                      styles={{
                        control: (base) => ({
                          ...base,
                          boxShadow: "none",
                        }),
                      }}
                      value={configOptions.filter(
                        (obj: any) =>
                          obj.value ===
                          siteConfigInitials.invitationEmailDeliveryMethod
                      )}
                      options={configOptions}
                      placeholder="DELIVER USING"
                      isSearchable={false}
                      className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
                      components={{
                        SingleValue: SingleValueContainer,
                        Option: OptionValueContainer,
                      }}
                      onChange={(e: any) => {
                        if (e.custom) {
                          navigate("/integrations");
                        } else {
                          setFieldValueSiteConfig(
                            "invitationEmailDeliveryMethod",
                            e.value
                          );
                        }
                      }}
                    />
                  </div>
                </div>
                {siteConfigInitials.invitationEmailDeliveryMethod !==
                  "assistalong_invitation" && (
                  <div className="pl-[106px]">
                    <a
                      href="https://assistalong.notion.site/Klaviyo-Setup-Guide-40a6fee68f6b46ec9df512f04016ceea#26a3905cb781403688a3967fb0681e41"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button className="rounded-md text-blue_default text-[13px] addproduct-button font-medium py-[9.5px] px-[26px] tracking-tracking_001">
                        See Instruction
                      </button>
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-row pr-5 mt-1.5">
              <span className="text-xs tracking-wider text-dark-grey text-opacity-75 mr-3">
                Active
              </span>
              <div>
                <Switch
                  state={siteConfigInitials.invitationEmailEnabled}
                  onClick={() => {
                    setFieldValueSiteConfig(
                      "invitationEmailEnabled",
                      !siteConfigInitials.invitationEmailEnabled
                    );
                  }}
                />
              </div>
              <span className="text-xs tracking-wider text-dark-grey text-opacity-75 ml-3">
                Off
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import { useEffect, useState } from "react";

const MassDeleteProductModal = ({
  show,
  onClose,
  handleDelete,
}: {
  show: boolean;
  onClose: () => void;
  handleDelete: () => Promise<boolean>;
}) => {
  const [deleting, setDeleting] = useState<boolean>(false);
  useEffect(() => {
    if (!show && deleting) {
      setDeleting(false);
    }
    // eslint-disable-next-line
  }, [show]);
  const onMassDelete = () => {
    setDeleting(true);
    handleDelete()
      .then(() => setDeleting(false))
      .catch(() => setDeleting(false));
  };
  return (
    <div
      className={` transition ease-linear duration-100 relative z-20 ${
        show ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div
        className={`min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover transition ease-in-out duration-500 ${
          show ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <div
          className={`absolute bg-black  inset-0 z-0 transition ease-in-out duration-500 ${
            show ? "opacity-50" : "opacity-0"
          }`}
        ></div>
        <div
          className={`w-full max-w-[520px] relative mx-auto my-auto rounded-lg shadow-lg bg-white transition ease-in-out duration-500  ${
            show ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <button
            className="cursor-pointer bg-off_white w-8 h-8 shadow-btn_shadow flex items-center justify-center rounded-full absolute -left-3 -top-3"
            onClick={() => onClose()}
          >
            <img src="/close-btn.svg" alt="close button"></img>
          </button>
          <div className="product-list overflow-y-auto max-h-84vh">
            <div className="p-8">
              <div className="pb-5 flex justify-center items-center ">
                <div className="flex items-center">
                  <img
                    className="w-[85px] h-[85px] mr-2 object-center"
                    src="/exclamation.png"
                    alt="error"
                  ></img>
                </div>
              </div>
              <div className="mt-4 mb-[54px]">
                <div className="text-base font-bold">
                  Are you sure you want to delete the selected products?
                </div>
                <div className="text-xs pt-4">
                  Deleting the products will remove all reminders for the
                  existing customers.
                  <br />
                  You can not undo this action.
                </div>
              </div>
              <div className="flex justify-between items-center">
                <button
                  className="py-[9.5px] font-medium border border-gray-800 tracking-wide_0.01 px-[40px] min-w-[124px] w-[124px] text-13px  flex max-w-max text-black rounded-md float-left self-end"
                  onClick={() => onClose()}
                >
                  Cancel
                </button>
                <button
                  className={`py-[9.5px] font-medium border border-btnRed tracking-wide_0.01 px-5 text-13px flex max-w-max text-white float-right rounded-md self-end bg-btnRed items-center ${
                    deleting && "opacity-60"
                  }`}
                  onClick={onMassDelete}
                  disabled={deleting}
                >
                  {deleting && (
                    <div
                      style={{ borderTopColor: "transparent" }}
                      className={`border-2 border-white border-solid rounded-full animate-spin h-4 w-4 mr-1`}
                    ></div>
                  )}
                  <p className="text-sm text-white">Delete Product</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MassDeleteProductModal;

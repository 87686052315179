import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { APIError } from "../../../layout/Error";
import { useAppSelector } from "../../../redux/hooks";
import { validateValues } from "../../../util/helpers";
import AddShippingDiscount from "../../smart_checkouts/components/AddShippingDiscount";
import { DiscountShippingInterface } from "../../smart_checkouts/models/SmartCheckout";
import { MassAttachDiscountCodeRequest } from "../models/ProductPageConfigurationModel";
import {
  discountValidationSchema,
  initialDiscountOptions,
} from "../ProductDetailsPage";
import { massAttachProductDiscounts } from "../requests/ProductPageConfigurationRequests";
import { MassEditHeader } from "./MassEditHeader";

export const MassEditDiscount = () => {
  const { businessId } = useAppSelector((state) => state.business);
  const location = useLocation();
  const navigate = useNavigate();
  const [active, setActive] = useState(true);
  const [discountOption, setDiscountOptions] =
    useState<DiscountShippingInterface>(initialDiscountOptions);
  const [errors, setErrors] = useState<any>({});
  const [saveError, setSaveError] = useState<boolean>(false);
  const [massDiscountMessage, setMassDiscountMessage] = useState<string | null>(
    ""
  );

  // eslint-disable-next-line
  const [productConfigIds, setProductConfigIds] = useState<string[]>([]);

  const setDiscountValue = (fieldName: string, value: any) => {
    setDiscountOptions((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  useEffect(() => {
    try {
      const qParams = new URLSearchParams(location.search);
      if (qParams.get("ids")) {
        const decrypted = atob(qParams.get("ids") ?? "");
        setProductConfigIds(decrypted.split(","));
      } else {
        throw Error();
      }
    } catch (e) {
      navigate("/smart-reminders");
    }
    // eslint-disable-next-line
  }, []);

  const onSave = () => {
    return new Promise((resolve, reject) => {
      const hasErrors = validateValues(
        discountOption,
        discountValidationSchema(discountOption?.discountType),
        setErrors
      );

      if (!hasErrors) {
        const updateRequest: MassAttachDiscountCodeRequest = {
          productConfigurationIds: productConfigIds,
          addDiscountFlag: active,
          discount: {
            ...discountOption,
          },
          // applyDiscountToFullCart: allDiscountActive,
        };

        massAttachProductDiscounts(updateRequest, businessId)
          .then((res: any) => {
            if (res.data.errorMessage) {
              setSaveError(true);
              setTimeout(() => {
                setSaveError(false);
              }, 3000);
              reject();
            } else {
              if (res.data.message) {
                setMassDiscountMessage(res.data.message);
                resolve(`discount_success=${res.data.message}`);
                setTimeout(() => {
                  setMassDiscountMessage("");
                }, 5000);
              } else {
                setMassDiscountMessage("");
                resolve(true);
              }
            }
          })
          .catch(() => {
            setSaveError(true);
            setTimeout(() => {
              setSaveError(false);
            }, 5000);
            reject();
          });
      }
    });
  };

  return (
    <MassEditHeader
      onSave={onSave}
      newValue={{
        active: active,
        ...discountOption,
      }}
      defaultValue={{ active: true, ...discountOption }}
    >
      {saveError && (
        <APIError
          error={"Something went wrong! Please try again"}
          consentText=""
          refresh={() => {}}
        />
      )}
      {massDiscountMessage && (
        <div className="mb-3">
          <p className="text-13px text-blue_default">
            Please allow a few minutes for the discounts to be updated.
          </p>
        </div>
      )}
      <AddShippingDiscount
        active={active}
        setActive={setActive}
        discountOption={discountOption}
        setDiscountValue={setDiscountValue}
        errors={errors}
        setError={setErrors}
      />
    </MassEditHeader>
  );
};

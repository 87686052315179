import { Plan, Subscription } from "../pages/account/models/PlansModels";
import { postSubscriptionPlans } from "../pages/account/requests/PlansRequests";
import { useAppSelector } from "../redux/hooks";
import Button from "./Button";

const SubscriptionPlans = ({
  subscription,
}: {
  subscription: Subscription;
}) => {
  const businessId = useAppSelector((state) => state.business.businessId);
  const setSubscriptionPlans = async (planId: number) => {
    const res = await postSubscriptionPlans(businessId, planId);
    if (res && res.data) {
      window.location.href = res?.data?.planConfirmationUrl;
    }
  };

  return (
    <div className="flex -mx-2 mb-7">
      {subscription?.plans?.length > 0 &&
        (subscription.plans.length > 3
          ? subscription.plans.slice(0, 3)
          : subscription.plans
        ).map((obj: Plan, index: number) => (
          <div className="w-[288px] px-2" key={index}>
            <div
              className={`p-5 ${
                obj.id === subscription?.businessCurrentActivePlan?.planId
                  ? "bg-normal-blue/75"
                  : "bg-normal-blue"
              }  rounded-2xl h-full flex flex-col`}
            >
              <div className="">
                <h3 className="text-2xl !leading-[40px] -tracking-[1px] mb-4 text-white font-bold">
                  {obj?.name}
                </h3>
                <h4 className="text-[22px] leading-[33px] -tracking-[1px] font-bold text-[#BDE0F7] mb-2">
                  {obj?.title}
                </h4>
                <p className="text-sm !leading-[30px]  font-medium text-[#F6FAFC] mb-2">
                  {obj.customerLimit} orders per month
                </p>
                <div className="bg-[#EBF4F7] w-full h-[1px] opacity-[0.2] my-6"></div>
                <ul className="text-xs leading-[18px] text-[#F6FAFC] list-disc pl-4">
                  {obj.description
                    .replace(/\n|\r/g, "")
                    .split(";")
                    .map((desc: string, index: number) => (
                      <li className="pb-2" key={index}>
                        {desc}
                      </li>
                    ))}
                </ul>
              </div>
              <div className="mt-auto">
                <Button
                  disabled={
                    obj.id === subscription?.businessCurrentActivePlan?.planId
                  }
                  text={
                    obj.id === subscription?.businessCurrentActivePlan?.planId
                      ? "Your Plan"
                      : obj.id <
                          subscription?.businessCurrentActivePlan?.planId &&
                        subscription?.businessCurrentActivePlan?.planId !== 4
                      ? "Downgrade"
                      : "Upgrade"
                  }
                  onClick={() => setSubscriptionPlans(obj?.id)}
                  className={`py-[18px] px-[48px] bg-white text-normal-blue/75 font-Gilroy_Light rounded-lg text-base !leading-[20px] inline-block`}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SubscriptionPlans;

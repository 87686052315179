import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../pages/account/models/UserModel";

const initialState = {
  token: null,
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state: any, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    setToken: (state: any, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    resetUserAndToken: (state: any) => {
      state.token = null;
      state.user = null;
    },
    removeToken: (state: any) => {
      state.token = null;
    },
  },
});

const { actions, reducer } = userSlice;

export const { setUser, setToken, removeToken, resetUserAndToken } = actions;

export default reducer;

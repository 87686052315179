import { TextInput } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLock,
  faEnvelope,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { loginUser } from "./requests/AuthRequests";

library.add(faLock, faEnvelope, faEye, faEyeSlash);
const FormInput = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [error, setError] = useState("");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    sendLoginRequest();
  };

  const sendLoginRequest = () => {
    setError("");
    loginUser(email, password)
      .then((response: any) => {
        if (response.status === 200) {
          localStorage.setItem("auth_jwt", response.data.accessToken);
          localStorage.setItem("user", JSON.stringify(response.data.user));
          localStorage.setItem(
            "selected_store",
            `${response.data.user.defaultBusiness}`
          );
          window.location.href = "/";
        } else {
          if (response.error) {
            setError(response.error);
          }
        }
      })
      .catch((e: any) => setError("Email or password do not match"));
    // axios({
    //   method: "post",
    //   url: `${process.env.REACT_APP_API_URL}/auth/login`,
    //   data: {
    //     email: email,
    //     password: password,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // }).then((response) => {
    //   if (response.status === 200) {
    //     localStorage.setItem("auth_jwt", response.data.accessToken);
    //     localStorage.setItem("user", JSON.stringify(response.data.user));
    //     localStorage.setItem("selected_store", `${response.data.user.defaultBusiness}`);
    //     window.location.href = "/";
    //   }
    // }).then((e: any) => {
    //   console.log(e.error)
    // });
  };
  return (
    <form
      className="flex flex-col gap-4 "
      id="login-form"
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className="reset-form-main mb-4 mt-4">
        <TextInput
          id="inlineFormInputGroupUseremail"
          placeholder="your email"
          required={true}
          addon={<FontAwesomeIcon icon="envelope" />}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="reset-form-main mb-4">
        <TextInput
          id="inlineFormInputGroupUserpassword"
          placeholder="your password"
          required={true}
          addon={<FontAwesomeIcon icon="lock" />}
          type={passwordType}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className="password-visibility">
          {passwordType === "password" ? (
            <FontAwesomeIcon icon="eye" onClick={togglePassword} />
          ) : (
            <FontAwesomeIcon icon="eye-slash" onClick={togglePassword} />
          )}
        </span>
      </div>
      <div className="reset-form-main text-center">
        <a href="/forgot_password">Don't remember your password?</a>
      </div>
      {error && (
        <div className="text-center">
          <span className="text-red-700">{error}</span>
        </div>
      )}
    </form>
  );
};
export default FormInput;

import axios from "axios";
import { UseAPI, useApi } from "../../../use-api";
import { Dashboard, UpdateDashboardRequest } from "../models/DashboardModel";

const API_URL = process.env.REACT_APP_API_URL;

export function useGetDashboard(businessId: number): UseAPI<Dashboard> {
  const url = new URL(`${API_URL}/businesses/${businessId}/dashboards`);

  return useApi<Dashboard>({
    method: "get",
    url: url.toString(),
  });
}

export function updateDashboard(
  dashboardId: number,
  request: UpdateDashboardRequest
) {
  var changed: any = {
    ...request,
  };

  if (request.from) {
    changed.from = request.from.toISOString();
  }

  if (request.to) {
    changed.to = request.to.toISOString();
  }
  const url = new URL(`${API_URL}/dashboards/${dashboardId}`);

  return axios.put<Dashboard>(url.toString(), { dashboard: changed });
}

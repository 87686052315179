import axios from "axios";
import { useApi } from "../../../use-api";
import {
  AttachDiscountCodeRequest,
  CreateProductPageConfigurationRequest,
  MassAttachDiscountCodeRequest,
  MassDeleteRequestBody,
  MassUpdateBannerRequest,
  MassUpdateFollowReminderRequest,
  MassUpdateSubOptionRequest,
  MassUpdateUpsellProductsRequest,
  ProductPageConfiguration,
  RemoveDiscountCodeRequest,
  UpdateProductPageConfigurationRequest,
} from "../models/ProductPageConfigurationModel";
import { MassUpdateFrequencyRequest } from "../models/ShopifyProduct";

const API_URL = process.env.REACT_APP_API_URL;

export enum ProductEnumType {
  PRODUCT = "normal",
  SMART = "smart",
}

const baseUrl = (businessId: number, revision: RevisionType = "working") =>
  `${API_URL}/businesses/${businessId}/product_page_configurations?revision=${revision}`;

const PRODUCT_PAGES_BASE_URL = `${API_URL}/product_page_configurations`;

const pagesURL = (
  id: number,
  type?: string,
  revision: RevisionType = "working"
) => `${PRODUCT_PAGES_BASE_URL}/${id}?revision=${revision}`;

export function createProductPageConfiguration(
  businessId: number,
  request: CreateProductPageConfigurationRequest
) {
  return axios.post<ProductPageConfiguration>(baseUrl(businessId), {
    productPageConfiguration: request,
  });
}

export function useGetProductPageConfiguration(
  id: number,
  type: string,
  revision: RevisionType = "working"
) {
  return useApi<ProductPageConfiguration>({
    method: "get",
    url: pagesURL(id, type, revision),
  });
}

export const getProductPageConfiguration = (
  id: number,
  type: string,
  revision: RevisionType = "working"
) => {
  return axios.get(pagesURL(id, type, revision));
};

export function updateProductPageConfiguration(
  id: number,
  request: UpdateProductPageConfigurationRequest
) {
  return axios.put<ProductPageConfiguration>(pagesURL(id), {
    productPageConfiguration: request,
  });
}

export type RevisionType = "working" | "deployed";

export function useGetProductPageConfigurations(
  businessId: number,
  type: string,
  revision: RevisionType = "working"
) {
  return useApi<ProductPageConfiguration[]>({
    method: "get",
    url: `${API_URL}/businesses/${businessId}/product_page_configurations?type=${type}&revision=${revision}`,
  });
}

export function attachDiscountCode(
  data: AttachDiscountCodeRequest,
  businessId: Number,
  type: string = "normal"
) {
  return axios.post(
    `${API_URL}/attach_discount_code?business_id=${businessId}&type=${type}`,
    data
  );
}

export function getDiscountCode(businessId: Number) {
  return axios.post(`${API_URL}/get_discount_codes?business_id=${businessId}`);
}
export function syncDiscountCode(businessId: Number) {
  return axios.post(`${API_URL}/sync_discount_codes?business_id=${businessId}`);
}

export function removeDiscountCode(data: RemoveDiscountCodeRequest) {
  return axios.post(`${API_URL}/remove_discount_code`, data);
}

export function deleteProduct(
  productPageConfigId: Number,
  businessId: Number,
  productId: String
) {
  return axios.delete(
    `${API_URL}/product_page_configurations/${productPageConfigId}?business_id=${businessId}&product_id=${productId}`
  );
}

export function checkDiscountEligibility(
  businessId: Number,
  priceRuleId: String,
  shopifyProductId: String
) {
  return axios.post(
    `${API_URL}/verify_discount_code?business_id=${businessId}&price_rule_id=${priceRuleId}&shopify_product_id=${shopifyProductId}`
  );
}

export function massDeleteProducts(requestData: MassDeleteRequestBody) {
  return axios.post(
    `${API_URL}/product_page_configurations/mass_destroy`,
    requestData
  );
}

export function massUpdateReminderFrequency(
  requestData: MassUpdateFrequencyRequest
) {
  return axios.post(
    `${API_URL}/product_page_configurations/mass_frequencies_update`,
    requestData
  );
}

export function massUpdateUpsellProducts(
  requestData: MassUpdateUpsellProductsRequest
) {
  return axios.post(
    `${API_URL}/product_page_configurations/mass_update`,
    requestData
  );
}

export function massUpdateSubOptions(requestData: MassUpdateSubOptionRequest) {
  return axios.post(
    `${API_URL}/product_page_configurations/mass_subscription_update`,
    requestData
  );
}

export function massUpdateBanner(requestData: MassUpdateBannerRequest) {
  return axios.post(
    `${API_URL}/product_page_configurations/mass_banner_update`,
    requestData
  );
}

export function massAttachProductDiscounts(
  requestData: MassAttachDiscountCodeRequest,
  businessId: number
) {
  return axios.post(
    `${API_URL}/attach_mass_discount_code?business_id=${businessId}`,
    requestData
  );
}

export function massUpdateFollowupReminder(
  requestData: MassUpdateFollowReminderRequest,
  businessId: number
) {
  return axios.post(
    `${API_URL}/product_page_configurations/mass_followup_reminder_update?business_id=${businessId}`,
    requestData
  );
}

import "./App.css";
import { PrivateRoutes } from "./routes/PrivateRoutes";
import { setupAxios } from "./setup/axios/SetupAxios";
import axios from "axios";
import "flowbite";

function App() {
  const token = localStorage.getItem("auth_jwt") ?? "";
  setupAxios(axios, token);
  return <PrivateRoutes />;
}

export default App;

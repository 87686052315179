import { useEffect, useReducer, useState } from "react";
import { OptionTypeSelector } from "../../../components/OptionTypeSelector";
import { MassUpdateFrequencyRequest } from "../models/ShopifyProduct";
import { Switch } from "./Switch";
import {
  AddStepAction,
  DeleteStepAction,
  qualityOptions,
  quantityOptions,
  RenderableStep,
  ResetStepAction,
  StepState,
  UpdateDefaultAction,
  UpdateQualityAction,
  UpdateQuantityAction,
} from "../models/ReminderActionTypes";
import { IsAdmin } from "../../../util/helpers";
import { AdminProtectedContainer } from "../../../components/RoleProtectedContainer";
import { massUpdateReminderFrequency } from "../requests/ProductPageConfigurationRequests";
import { useLocation, useNavigate } from "react-router";
import { MassEditHeader } from "./MassEditHeader";
import { APIError } from "../../../layout/Error";
import { getBusiness } from "../../guides/requests/BusinessRequests";
import { useDispatch } from "react-redux";
import { setSetupStatus } from "../../../redux/ducks/setup.duck";
import { setBusiness } from "../../../redux/ducks/business.duck";
import SetReminderOption from "./SetReminderOption";
import { ReminderFreqOption } from "../ProductDetailsPage";

type UpdateStepAction =
  | UpdateQualityAction
  | UpdateQuantityAction
  | UpdateDefaultAction;

function frequencyReducer(
  state: StepState,
  action: AddStepAction | DeleteStepAction | UpdateStepAction | ResetStepAction
): StepState {
  switch (action.type) {
    case "add":
      let count1 = 0;

      state.steps.forEach((other) => {
        if (
          action.step.frequency.quality === other.frequency.quality &&
          action.step.frequency.quantity === other.frequency.quantity
        ) {
          count1 += 1;
        }
      });

      return {
        steps: [...state.steps, { ...action.step, defaultStep: false }],
        hasDuplicates: count1 >= 1,
      };
    case "updateQuantity":
      let copy = [...state.steps];
      const tgt = copy[action.index];

      let count = 0;
      state.steps.forEach((other) => {
        if (
          action.step.quantity === other.frequency.quantity &&
          tgt.frequency.quality === other.frequency.quality
        ) {
          count += 1;
        }
      });
      copy.splice(action.index, 1, {
        ...tgt,
        frequency: { ...tgt.frequency, ...action.step },
      });
      return { steps: copy, hasDuplicates: count >= 2 };
    case "updateQuality":
      let copy2 = [...state.steps];
      const tgt2 = copy2[action.index];

      let count2 = 0;

      state.steps.forEach((other) => {
        if (
          action.step.quality === other.frequency.quality &&
          tgt2.frequency.quantity === other.frequency.quantity
        ) {
          count2 += 1;
        }
      });

      copy2.splice(action.index, 1, {
        ...tgt2,
        frequency: { ...tgt2.frequency, ...action.step },
      });
      return { steps: copy2, hasDuplicates: count2 >= 2 };
    case "updateDefault":
      let copy3 = [...state.steps];
      const existingDefault = copy3.findIndex(
        (step) => step.defaultStep === true
      );

      if (existingDefault !== -1) {
        copy3.splice(existingDefault, 1, {
          ...copy3[existingDefault],
          defaultStep: false,
        });
      }
      copy3.splice(action.index, 1, { ...copy3[action.index], ...action.step });
      return { steps: copy3, hasDuplicates: state.hasDuplicates };
    case "delete":
      let deleteCopy = [...state.steps];
      let target = deleteCopy[action.index];

      if (target.id !== undefined) {
        deleteCopy.splice(action.index, 1, {
          ...deleteCopy[action.index],
          _destroy: true,
        });
      } else {
        deleteCopy.splice(action.index, 1);
      }
      return { steps: deleteCopy, hasDuplicates: false };
    case "reset":
      return action.newState;
    default:
      throw new Error();
  }
}

const MassEditReminderSection = () => {
  const dispatchBusiness = useDispatch();
  const [active, setActive] = useState(true);
  const [hasDuplicateReminderFrequency, setHasDuplicateReminderFrequency] =
    useState<boolean>(false);
  const [saveError, setSaveError] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [reminderFreqIds, setReminderFreqIds] = useState<string[]>([]);
  const [productIds, setProductIds] = useState<string[]>([]);
  const [reminderFreqType, setReminderFreqType] = useState<ReminderFreqOption>({
    type: "default_frequency",
    isActive: true,
  });

  useEffect(() => {
    try {
      const qParams = new URLSearchParams(location.search);
      if (qParams.get("ids")) {
        const decrypted = atob(qParams.get("ids") ?? "");
        setReminderFreqIds(decrypted.split(","));
      } else {
        throw Error();
      }

      if (qParams.get("pIds")) {
        const decryptedPids = atob(qParams.get("pIds") ?? "");
        setProductIds(decryptedPids.split(","));
      } else {
        throw Error();
      }
    } catch (e) {
      navigate("/smart-reminders");
    }
    // eslint-disable-next-line
  }, []);

  const steps: RenderableStep[] = [
    {
      frequency: { quality: "month", quantity: 1 },
      defaultStep: true,
      orderNumber: 1,
    },
    { frequency: { quality: "month", quantity: 2 }, orderNumber: 2 },
    { frequency: { quality: "month", quantity: 3 }, orderNumber: 3 },
  ];

  const [state, dispatch] = useReducer(frequencyReducer, {
    steps,
    hasDuplicates: false,
  });

  const onSave = () => {
    return new Promise((resolve, reject) => {
      if (hasDuplicateReminderFrequency) {
        return;
      }
      const req: MassUpdateFrequencyRequest = {
        reminderFreqIds,
        productConfigurationIds: productIds,
        emailFlowReminderFlag: reminderFreqType.isActive,
        reminderFrequencyType: reminderFreqType.type,
        reminderFrequency: {
          reminderFlag: active,
          workingRevisionAttributes: {
            reminderFrequencyStepsAttributes: state.steps.map((step) => {
              return {
                ...step,
                frequencyAttributes: step.frequency,
                isDefault: step.defaultStep,
              };
            }),
          },
        },
      };
      massUpdateReminderFrequency(req)
        .then((res: any) => {
          if (res.data.errorMessage) {
            setSaveError(true);
            setTimeout(() => {
              setSaveError(false);
            }, 3000);
            reject();
          } else {
            getBusiness().then((res: any) => {
              if (res.data) {
                dispatchBusiness(setSetupStatus(res.data.setupStatus));
                dispatchBusiness(setBusiness(res.data));
              }
            });
            resolve(true);
          }
        })
        .catch(() => {
          setSaveError(true);
          setTimeout(() => {
            setSaveError(false);
          }, 5000);
          reject();
        });
    });
  };
  //eslint-disable-next-line

  useEffect(() => {
    setHasDuplicateReminderFrequency(state.hasDuplicates);
    //eslint-disable-next-line
  }, [state.hasDuplicates]);

  const onAddFrequency = () => {
    dispatch({
      type: "add",
      step: {
        frequency: {
          quantity: 1,
          quality: "day",
        },
      },
    });
  };

  const onRemoveFrequency = (index: number) => {
    dispatch({ type: "delete", index });
  };

  const onValueChange = (
    e: any,
    rowIndex: number,
    type: string,
    schedulerType: string
  ) => {
    if (type === "quantity") {
      dispatch({
        type: "updateQuantity",
        index: rowIndex,
        step: { quantity: Number(e.value) },
      });
    }
    if (type === "quality") {
      dispatch({
        type: "updateQuality",
        index: rowIndex,
        step: { quality: e.value },
      });
    }
  };

  const updateDefaultStep = (index: number) => {
    dispatch({
      type: "updateDefault",
      index: index,
      step: { defaultStep: true },
    });
  };

  return (
    <MassEditHeader
      onSave={onSave}
      newValue={{
        active: active,
        steps: state.steps,
        emailFlowActive: reminderFreqType.isActive,
        reminderFrequencyType: reminderFreqType.type,
      }}
      defaultValue={{
        active: true,
        steps: steps,
        emailFlowActive: true,
        reminderFrequencyType: "default_frequency",
      }}
    >
      {saveError && (
        <APIError
          error={"Something went wrong! Please try again"}
          consentText=""
          refresh={() => {}}
        />
      )}
      <div>
        <div className="pb-8 w-full mb-7 bg-white rounded-lg">
          <div className="px-6 message-type-wrapper py-6 border-b border-light-grey">
            <div className="flex justify-between flex-row">
              <div className="flex flex-col">
                <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                  Set reminder options
                </p>
                <p className="text-10 text-dark-grey text-opacity-75">
                  Reminder frequency options offered to one-time customers after
                  their purchase
                </p>
              </div>
              <div className="flex flex-row pr-5 items-center">
                <span className="text-xs tracking-wider text-dark-grey text-opacity-75 mr-3">
                  Active
                </span>
                <div
                  className={!IsAdmin() ? "opacity-50 pointer-events-none" : ""}
                >
                  <Switch
                    state={active}
                    onClick={() => {
                      setActive(!active);
                    }}
                  />
                </div>
                <span className="text-xs tracking-wider text-dark-grey text-opacity-75 ml-3">
                  Off
                </span>
              </div>
            </div>
          </div>
          <div className="message-type-wrapper pl-6">
            {state.steps.map((step, index) => {
              const quantityDefaultValue = {
                label: `${step.frequency.quantity}`,
                value: `${step.frequency.quantity}`,
              };

              if (step._destroy) {
                return null;
              }

              let count = 0;

              state.steps.forEach((other) => {
                if (
                  step.frequency.quantity === other.frequency.quantity &&
                  step.frequency.quality === other.frequency.quality &&
                  !other._destroy
                ) {
                  count += 1;
                }
              });

              const isDuplicateFrequency = count >= 2;

              const qualityDefaultValue = {
                label: `${step.frequency.quality}s`,
                value: step.frequency.quality,
              };
              const isDefault = step.defaultStep;

              return (
                <div
                  key={`${index}_${step.id}`}
                  className={`${
                    state.steps.length - 1 !== index &&
                    "border-b border-light-grey pb-5"
                  } pt-5`}
                >
                  <div className="flex flex-row w-full items-center">
                    <div className="mr-2">
                      <OptionTypeSelector
                        placeholder=""
                        options={quantityOptions}
                        defaultValue={quantityDefaultValue}
                        handleChangeValue={onValueChange}
                        rowIndex={index}
                        type="quantity"
                        schedulerType=""
                        selectClasses="w-20 font-bold"
                      />
                    </div>
                    <OptionTypeSelector
                      placeholder=""
                      options={qualityOptions}
                      defaultValue={qualityDefaultValue}
                      handleChangeValue={onValueChange}
                      rowIndex={index}
                      type="quality"
                      schedulerType=""
                      selectClasses="font-bold"
                    />
                    <>
                      {isDefault ? (
                        <span className="align-center text-xs tracking-tracking_001 ml-7 text-grey-font">
                          Default
                        </span>
                      ) : (
                        <AdminProtectedContainer>
                          <span
                            onClick={() => updateDefaultStep(index)}
                            className="cursor-pointer align-center text-blue_default text-xs tracking-tracking_001 ml-7"
                          >
                            Set as default
                          </span>
                        </AdminProtectedContainer>
                      )}
                    </>
                    {!isDefault && (
                      <AdminProtectedContainer>
                        <button
                          onClick={() => onRemoveFrequency(index)}
                          className="absolute right-20"
                        >
                          <img
                            className="cursor-pointer"
                            src="/delete-icon.svg"
                            alt="close button"
                          ></img>
                        </button>
                      </AdminProtectedContainer>
                    )}
                  </div>
                  {isDuplicateFrequency && (
                    <p className="text-danger pt-1 text-red-500 text-xs">
                      Duplicate reminder frequency is not allowed
                    </p>
                  )}
                </div>
              );
            })}
            {state.steps.filter((step) => step?._destroy !== true).length <
              6 && (
              <AdminProtectedContainer>
                <button
                  onClick={() => onAddFrequency()}
                  className="text-blue_default text-xs tracking-tracking_001 mt-8 flex items-center"
                >
                  Add New Frequency
                </button>
              </AdminProtectedContainer>
            )}
          </div>
        </div>
      </div>

      <SetReminderOption
        setReminderFreqType={setReminderFreqType}
        reminderFreqType={reminderFreqType}
      />
    </MassEditHeader>
  );
};

export { MassEditReminderSection };

import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export function loginUser(email: string, password: string) {
  return axios.post(`${API_URL}/auth/login`, {
    email: email,
    password: password,
  });
}

export function forgotPasswordRequest(email: string) {
  return axios.post(`${API_URL}/auth/forgot`, {
    email: email,
  });
}

export function resetPasswordRequest(
  password: string,
  password_confirmation: string,
  confirmation_token: string | null
) {
  return axios.post(`${API_URL}/auth/reset`, {
    password: password,
    password_confirmation: password_confirmation,
    confirmation_token: confirmation_token,
  });
}

import React from "react";
import ForgetPasswordModal from "./ForgotPasswordModal";
const ForgotPasswordPage = () => {
  const [modalShow, setModalShow] = React.useState(true);

  return (
    <ForgetPasswordModal show={modalShow} onHide={() => setModalShow(false)} />
  );
};

export default ForgotPasswordPage;

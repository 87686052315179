import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export function getSubscriptionPlans(businessId: number) {
  return axios.get(`${API_URL}/businesses/${businessId}/plans`);
}

export function postSubscriptionPlans(businessId: number, id: number) {
  return axios.post(`${API_URL}/businesses/${businessId}/change_plan`, {
    plan_id: id,
  });
}

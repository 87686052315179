import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SiteConfiguration } from "../../pages/account/models/SiteConfigurationModel";
import { sanitizedObject } from "../../util/helpers";

export const initialSiteConfig = {
  id: 0,
  url: "",
  primaryColor: "#000000",
  secondaryColor: "#000000",
  imageUrl: "",
  brandName: "",
  brandSlug: "",
  emailReminderCount: 3,
  isEmailFlowEnabled: false,
  defaultSenderImageUrl: "",
  defaultSenderName: "",
  senderName: "",
  emailDeliveryMethod: "",
  invitationEmailEnabled: false,
  optInEnabled: false,
  senderEmail: "",
  smsDeliveryMethod: "",
  invitationEmailDeliveryMethod: "",
  withOutDiscountHeading: "",
  withOutDiscountSubheading: "",
  withDiscountHeading: "",
  withDiscountSubheading: "",
  customizeSmsReminder: "",
  klaviyoPublicKey: "",
  klaviyoPrivateKey: "",
  klaviyoConnected: false,
  postscriptConnected: false,
  postscriptXshopToken: "",
  postscriptKeyword: "",
  defaultSenderEmail: "",
  isWhiteLabel: false,
  termsOfServiceLink: "",
  privacyPolicyLink: "",
  merchantDomain: "",
};

export const siteSlice = createSlice({
  name: "site",
  initialState: {
    siteConfiguration: initialSiteConfig,
  },
  reducers: {
    setSiteConfiguration: (
      state: any,
      action: PayloadAction<SiteConfiguration>
    ) => {
      state.siteConfiguration = sanitizedObject(action.payload);
    },
  },
});

const { actions, reducer } = siteSlice;

export const { setSiteConfiguration } = actions;

export default reducer;

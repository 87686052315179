import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import { PageContentLoader } from "../../layout/Loaders";
import { SiteConfiguration } from "../account/models/SiteConfigurationModel";
import { sanitizedObject, validateValues } from "../../util/helpers";
import { updateSiteConfiguration } from "../account/requests/SiteConfigurationsRequests";
import { useGetShopifyConfiguration } from "../account/requests/ShopifyConfigurationsRequests";
import { GoLiveButton } from "../../components/GoLiveButton";
import { siteConfigSchema } from "../account/AccountPage";
import { AdminProtectedContainer } from "../../components/RoleProtectedContainer";
import {
  initialSiteConfig,
  setSiteConfiguration,
} from "../../redux/ducks/siteconfig.duck";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setSetupStatus } from "../../redux/ducks/setup.duck";
import { updateStepStatus } from "./requests/BusinessRequests";
import { setBusiness } from "../../redux/ducks/business.duck";
import { UploadLogo } from "../account/components/UploadLogo";
import { SiteConfigStartGuide } from "../account/components/SiteConfigStartGuide";
import { Plan } from "../account/models/PlansModels";
import UpgradePlanModel from "../../components/UpgradePlanModel";
import { ReminderStatus } from "./models/Business";

enum StepName {
  accountSettings = "accountSettings",
  setupReminders = "setupReminders",
  reviewSettings = "reviewSettings",
  smartCheckout = "smartCheckout",
  goLive = "goLive",
}

interface Step {
  name: string;
  id: StepName;
  order: number;
}
const steps: Step[] = [
  { name: "Setup your brand identity", id: StepName.accountSettings, order: 1 },
  { name: "Setup reminders", id: StepName.setupReminders, order: 2 },
  {
    name: "Review smart reminders settings",
    id: StepName.reviewSettings,
    order: 3,
  },
  {
    name: "Add smart checkouts",
    id: StepName.smartCheckout,
    order: 4,
  },
  { name: "Go live", id: StepName.goLive, order: 5 },
];

const initialStartGuide = {
  connectShopify: false,
  connectStripe: false,
  accountSettings: false,
  addProducts: false,
  setupReminders: false,
  reviewSettings: false,
  smartCheckout: false,
  goLive: false,
};

const StartGuidePage = () => {
  const dispatch = useAppDispatch();
  const businessData: any = useAppSelector((state) => state.business.business);
  const businessId = useAppSelector((state) => state.business.businessId);
  const refreshLayout = useAppSelector((state) => state.setup.refresh);
  const setupStatus = useAppSelector((state) => state.setup.setupStatus);
  const siteConfig: SiteConfiguration = useAppSelector(
    (state) => state.site.siteConfiguration
  );
  const { plans: subscription }: any = useAppSelector((state) => state.plans);

  // const { data: siteConfig } = useGetSiteConfiguration(businessId);
  const { data: shopifyConfig } = useGetShopifyConfiguration(businessId);

  const [activeStep, setActiveStep] = React.useState<string>("");
  const [showPlans, setShowPlans] = React.useState<boolean>(false);

  const [completedStep, setCompletedStep] = React.useState<{
    [key in StepName]: boolean;
  }>(initialStartGuide);

  const [siteConfigInitials, setSiteConfigInitials] =
    React.useState<SiteConfiguration>(initialSiteConfig);

  const setFieldValueSiteConfig = (fieldName: string, value: any) => {
    setSiteConfigInitials({ ...siteConfigInitials, [fieldName]: value });
  };

  const setStepComplete = (stepName: StepName, value: boolean) => {
    setCompletedStep({ ...completedStep, [stepName]: value });
    refreshLayout();
  };

  React.useEffect(() => {
    if (businessData) {
      dispatch(setSetupStatus(businessData?.setupStatus));
      dispatch(setBusiness(businessData));
    }
    // eslint-disable-next-line
  }, [businessData]);

  React.useEffect(() => {
    if (siteConfig) {
      setSiteConfigInitials(sanitizedObject(siteConfig));
    }
  }, [siteConfig, shopifyConfig, businessId]);

  React.useEffect(() => {
    if (!businessData) {
      return;
    }
    const completed = Object.values(completedStep).every(
      (isCompleted) => isCompleted
    );
    dispatch(setSetupStatus({ ...setupStatus, completed }));
    //eslint-disable-next-line
  }, [completedStep]);

  // React.useEffect(() => {
  //   if (
  //     activeStep === StepName.setupReminders &&
  //     setupStatus?.reminderStep?.status === "completed"
  //   ) {
  //     setStepComplete(StepName.setupReminders, true);
  //     setActiveStep(StepName.reviewSettings);
  //   }
  //   //eslint-disable-next-line
  // }, [setupStatus]);

  React.useEffect(() => {
    if (subscription) {
      const active = subscription?.plans?.find(
        (obj: Plan) =>
          obj.id === subscription?.businessCurrentActivePlan?.planId
      );
      let planName = active ? active?.name : "none";
      if (planName === "none") {
        setShowPlans(true);
      }
    } else {
      setShowPlans(true);
    }
    if (businessData) {
      const tempSteps: any = {
        accountSettings:
          businessData?.setupStatus?.site?.status === "completed"
            ? true
            : false,
        setupReminders:
          businessData?.setupStatus?.reminderStep?.status === "completed"
            ? true
            : false,
        reviewSettings:
          businessData?.setupStatus?.reminderStatus?.status === "completed"
            ? true
            : false,
        smartCheckout:
          businessData?.setupStatus?.smartCheckoutStatus?.status === "completed"
            ? true
            : false,
        goLive:
          businessData?.setupStatus?.goLive?.status === "completed"
            ? true
            : false,
      };
      setCompletedStep(tempSteps);
      if (Object.values(tempSteps).every((item) => !item)) {
        // set first step as active if none are completed
        setActiveStep(StepName.accountSettings);
      } else {
        const firstIncomplete = Object.keys(tempSteps).find(
          (item) => !tempSteps[item]
        );
        if (firstIncomplete && firstIncomplete in StepName) {
          setActiveStep(firstIncomplete);
        }
      }
    }
  }, [businessData, subscription]);

  const getStepComponent = (businessId: number, stepId: StepName) => {
    switch (stepId) {
      case StepName.accountSettings:
        return (
          <AccountSettingsStep
            onSave={setActiveStep}
            setStepComplete={setStepComplete}
            siteConfigInitials={siteConfigInitials}
            setFieldValueSiteConfig={setFieldValueSiteConfig}
            setSiteConfigData={setSiteConfigInitials}
            businessId={businessId}
          />
        );
      case StepName.setupReminders:
        return (
          <RemindersStep
            setStepComplete={async () => {
              await updateStepStatus(businessId, {
                step: "reminder_step",
              });
              setStepComplete(StepName.setupReminders, true);
              setActiveStep(StepName.reviewSettings);
            }}
          />
        );
      case StepName.reviewSettings:
        return (
          <ReviewSettings
            reviewStatus={businessData?.setupStatus?.reminderStatus || null}
            setStepComplete={async () => {
              await updateStepStatus(businessId, {
                step: "reminder_status",
              });
              setStepComplete(StepName.reviewSettings, true);
              setActiveStep(StepName.smartCheckout);
            }}
          />
        );
      case StepName.smartCheckout:
        return (
          <AddSmartCheckout
            setStepComplete={async () => {
              await updateStepStatus(businessId, {
                step: "smart_checkout_status",
              });
              setStepComplete(StepName.smartCheckout, true);
              setActiveStep(StepName.goLive);
            }}
          />
        );
      case StepName.goLive:
        return <GoLiveStep setStepComplete={setStepComplete} />;
    }
  };

  // if (error) {
  //   return (
  //     <APIError
  //       error={error}
  //       consentText="Consent to reading Customers"
  //       refresh={refresh}
  //     />
  //   );
  // }

  const previousStepCompleted = (currentStep: number) => {
    const previousStep = steps.find((step) => step.order === currentStep - 1);

    if (previousStep === undefined) {
      return true;
    } else {
      // Skip locking progress on the Stripe step
      return (
        previousStep.id === StepName.accountSettings ||
        completedStep[previousStep.id] === true
      );
    }
  };

  const activePlan = () => {
    if (subscription) {
      const active = subscription?.plans?.find(
        (obj: Plan) =>
          obj.id === subscription?.businessCurrentActivePlan?.planId
      );
      return active ? active?.name : "none";
    }
  };

  return (
    <>
      {businessData ? (
        <div className="w-full">
          <div className="title flex justify-between mb-6">
            <div className="flex">
              <h1 className="text-2xl font-medium text-dark-grey ">
                Startguide
              </h1>
            </div>
            <div className="text-13 font-medium tracking-tracking_001 text-black_shade/50">
              You&#8242;re on{" "}
              <span className="text-black_shade"> {activePlan()} </span>
              Plan.
              <span
                className="text-blue_default cursor-pointer"
                onClick={() => setShowPlans(true)}
              >
                {" "}
                See all plans
              </span>
            </div>
          </div>
          <div className="">
            {steps.map((item, index) => (
              <div key={`${item.id}_${activeStep}_${completedStep[item.id]}`}>
                <div
                  className={`step-wrap  w-full bg-white rounded mb-4 ${
                    completedStep[item.id] === true &&
                    "active border-0 border-t border-green_border"
                  }`}
                >
                  <div
                    className="relative p-6"
                    onClick={() => {
                      if (
                        previousStepCompleted(item.order) ||
                        completedStep[item.id]
                      ) {
                        setActiveStep(item.id);
                      }
                    }}
                  >
                    <h3 className="text-dark-grey text-opacity-75 uppercase text-8 leading-3 font-medium tracking-tracking_001">
                      Step {index + 1}
                    </h3>
                    <span className="text-13 tracking-tracking_001 font-medium text-black">
                      {item.name}
                    </span>
                    <img
                      className="absolute right-5 top-8"
                      alt="gray check mark"
                      src="/check-gray.png"
                    ></img>
                    {completedStep[item.id] === true && (
                      <img
                        className="absolute right-5 top-8"
                        alt="gray check mark"
                        src="/green-check.svg"
                      ></img>
                    )}
                  </div>
                  {activeStep === item.id && (
                    <div className="step-details p-6 border-t border-light-grey">
                      {getStepComponent(businessData.id, item.id)}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <PageContentLoader pageTitle="Startguide" />
      )}
      <UpgradePlanModel
        show={showPlans}
        onClose={() => setShowPlans(false)}
        subscription={subscription}
      />
    </>
  );
};

const AccountSettingsStep = ({
  onSave,
  setStepComplete,
  siteConfigInitials,
  setFieldValueSiteConfig,
  setSiteConfigData,
  businessId,
}: {
  onSave: (stepName: StepName) => void;
  setStepComplete: (stepName: StepName, value: boolean) => void;
  siteConfigInitials: SiteConfiguration;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
  setSiteConfigData: (data: SiteConfiguration) => void;
  businessId: number;
}) => {
  const dispatch = useAppDispatch();
  const [siteErrors, setSiteErrors] = React.useState<any>({});
  const [buttonDisabled, setButtonDisabled] = React.useState<boolean>(false);
  const colorRegex = /^#[A-Fa-f0-9]{6}$/;
  React.useEffect(() => {
    if (
      siteConfigInitials &&
      siteConfigInitials?.imageUrl &&
      siteConfigInitials.primaryColor.length === 7 &&
      siteConfigInitials.secondaryColor.length === 7 &&
      colorRegex.test(siteConfigInitials.primaryColor) &&
      colorRegex.test(siteConfigInitials.secondaryColor)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
    // eslint-disable-next-line
  }, [siteConfigInitials]);

  const saveSiteConfig = async () => {
    const hasErrors = validateValues(
      siteConfigInitials,
      siteConfigSchema(),
      setSiteErrors
    );
    if (!hasErrors) {
      try {
        setButtonDisabled(true);
        if (siteConfigInitials && siteConfigInitials?.imageUrl) {
          const { data } = await updateSiteConfiguration(
            siteConfigInitials.id,
            {
              brandName: siteConfigInitials.brandName,
              primaryColor: siteConfigInitials.primaryColor,
              secondaryColor: siteConfigInitials.secondaryColor,
              imageUrl: siteConfigInitials.imageUrl,
            }
          );
          setButtonDisabled(false);
          if (data["error"]) {
            setSiteErrors({ brandName: data["error"] });
          } else {
            setSiteConfigData(data);
            dispatch(setSiteConfiguration(data));
            onSave(StepName.setupReminders);
            setStepComplete(StepName.accountSettings, true);
          }
        }
      } catch (error) {
        setButtonDisabled(false);
        console.log(error);
      }
    }
  };

  return (
    <>
      <div className="pt-1 pb-6">
        <SiteConfigStartGuide
          siteErrors={siteErrors}
          setSiteErrors={setSiteErrors}
          onSave={() => {
            saveSiteConfig();
          }}
          siteConfigInitials={siteConfigInitials}
          setFieldValueSiteConfig={setFieldValueSiteConfig}
          setSiteConfigData={setSiteConfigData}
          businessId={businessId}
          saveDisabled={buttonDisabled}
          customization={false}
        />
      </div>
      <UploadLogo
        onUploadSuccess={(url) => {
          setFieldValueSiteConfig("imageUrl", url);
        }}
        imageUrl={siteConfigInitials?.imageUrl}
        businessId={businessId}
      />
      <div className="flex mt-2 justify-left items-center">
        <AdminProtectedContainer>
          <button
            className={`text-white text-xs rounded-md h-40px w-step1 ${
              buttonDisabled ? "bg-gray-500" : "bg-blue_default"
            }`}
            onClick={saveSiteConfig}
            disabled={buttonDisabled}
          >
            Continue
          </button>
        </AdminProtectedContainer>
      </div>
    </>
  );
};

const RemindersStep = ({
  setStepComplete,
}: {
  setStepComplete: () => void;
}) => {
  return (
    <>
      <p className="text-dark-grey text-xs tracking-tracking_001 opacity-80 mb-10">
        Add your products in smart reminders dashboard and setup reminders for
        each product
      </p>
      <div className="flex pb-1">
        <Link to="/smart-reminders">
          <button
            className="default-button text-[13px] !leading-[20px] py-2.5 px-5 font-medium mr-7 tracking-tracking_001"
            onClick={() => {
              setStepComplete();
            }}
          >
            Go to smart reminders
          </button>
        </Link>
        <button
          className="rounded-md text-blue_default text-[13px] addproduct-button font-medium py-2.5 px-[22px] tracking-tracking_001"
          onClick={() => {
            setStepComplete();
          }}
        >
          Skip
        </button>
      </div>
    </>
  );
};

const ReviewSettings = ({
  reviewStatus,
  setStepComplete,
}: {
  reviewStatus: ReminderStatus | null;
  setStepComplete: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="pb-7 pl-4">
        <ul className="list-disc text-xs !leading-[24px]">
          <li className="text-black_shade font-semibold tracking-tracking_001">
            <span className="text-black_shade/50 font-normal pr-1">
              Reminder opt-in form:
            </span>
            {reviewStatus?.smsReminderOptInType}
          </li>
          <li className="text-black_shade font-semibold tracking-tracking_001">
            <span className="text-black_shade/50 font-normal pr-1">
              Post purchase invitation email:
            </span>
            {reviewStatus?.invitationEmailEnabled ? "ON" : "OFF"}
          </li>
          <li className="text-black_shade font-semibold tracking-tracking_001">
            <span className="text-black_shade/50 font-normal pr-1">
              Email reminder flows delivery:
            </span>
            {reviewStatus?.emailDeliveryMethod}
          </li>
          <li className="text-black_shade font-semibold tracking-tracking_001">
            <span className="text-black_shade/50 font-normal pr-1">
              SMS recurring reminders delivery:
            </span>
            {reviewStatus?.smsDeliveryMethod}
          </li>
        </ul>
      </div>
      <div className="flex pb-1">
        <button
          className="default-button text-[13px] !leading-[20px] py-2.5 px-5 font-medium mr-7 tracking-tracking_001"
          onClick={() => {
            setStepComplete();
          }}
        >
          Looks good
        </button>
        <button
          className="rounded-md text-blue_default text-[13px] addproduct-button font-medium py-2.5 px-[22px] tracking-tracking_001"
          onClick={() => {
            setStepComplete();
            navigate("/customization#reminders-settings");
          }}
        >
          Change settings
        </button>
      </div>
    </>
  );
};

const AddSmartCheckout = ({
  setStepComplete,
}: {
  setStepComplete: () => void;
}) => {
  return (
    <>
      <p className="text-dark-grey text-xs tracking-tracking_001 opacity-80 mb-10">
        To add smart checkouts, first you need to connect your Email/SMS
        marketing platform
      </p>
      <div className="flex pb-1">
        <Link to="/integrations">
          <button
            className="default-button text-[13px] !leading-[20px] py-2.5 px-5 font-medium mr-7 tracking-tracking_001"
            onClick={() => {
              setStepComplete();
            }}
          >
            Go to integration dashboard
          </button>
        </Link>
        <button
          className="rounded-md text-blue_default text-[13px] addproduct-button font-medium py-2.5 px-[22px] tracking-tracking_001"
          onClick={() => {
            setStepComplete();
          }}
        >
          Skip
        </button>
      </div>
    </>
  );
};

const GoLiveStep = ({
  setStepComplete,
}: {
  setStepComplete: (stepName: StepName, value: boolean) => void;
}) => {
  return (
    <>
      <div>
        <AdminProtectedContainer>
          <p className="text-xs text-black_shade tracking-wide_0.01 mb-9">
            Press the button below to deploy your changes! 🎉
          </p>
        </AdminProtectedContainer>
        <GoLiveButton
          afterSubmit={() => setStepComplete(StepName.goLive, true)}
        />
      </div>
    </>
  );
};

export { StartGuidePage };

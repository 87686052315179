import { TextInput } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { forgotPasswordRequest } from "../login/requests/AuthRequests";

library.add(faEnvelope);
const ForgotFormInput = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    sendForgotPasswordRequest();
  };

  const sendForgotPasswordRequest = () => {
    setError("");
    forgotPasswordRequest(email)
      .then((response: any) => {
        if (response.status === 200) {
          window.location.href = "/login";
        } else {
          if (response.error) {
            setError(response.error);
          }
        }
      })
      .catch((e: any) => setError("User not found"));
  };

  return (
    <form
      className="flex flex-col gap-4 "
      id="reset-form"
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className="reset-form-main mb-4 mt-4">
        <TextInput
          id="inlineFormInputGroupUserConfirmPassword"
          placeholder="your email"
          required={true}
          addon={<FontAwesomeIcon icon="envelope" />}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      {error && (
        <div className="text-center">
          <span className="text-red-700">{error}</span>
        </div>
      )}
    </form>
  );
};
export default ForgotFormInput;

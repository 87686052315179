import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Business } from "../../pages/guides/models/Business";

const initialState = {
  businessId: -1,
  business: null,
  globalLoading: false,
};

export const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setBusinessId: (state: any, action: PayloadAction<number>) => {
      state.businessId = action.payload;
    },
    setBusiness: (state: any, action: PayloadAction<Business>) => {
      state.business = action.payload;
    },
    setGlobalLoading: (state: any, action: PayloadAction<boolean>) => {
      state.globalLoading = action.payload;
    },
    setBusinessDnsVerification: (
      state: any,
      action: PayloadAction<Business>
    ) => {
      state.business = action.payload;
    },
  },
});

const { actions, reducer } = businessSlice;

export const { setBusinessId, setBusiness, setGlobalLoading } = actions;

export default reducer;

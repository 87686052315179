import { useState } from "react";
import UpgradePlanModel from "../../../components/UpgradePlanModel";
import { useAppSelector } from "../../../redux/hooks";
import { Switch } from "./Switch";

const AddSubscriptionCart = ({
  active,
  setActive,
  activeDefaultSubscription,
  setDefaultSubscriptionActive,
}: {
  active: boolean;
  setActive: (flag: boolean) => void;
  activeDefaultSubscription: boolean;
  setDefaultSubscriptionActive: (flag: boolean) => void;
}) => {
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );
  const [showPlans, setShowPlans] = useState(false);
  const canSee = () => {
    return permission?.includes("products > cart_subscription_option");
  };

  return (
    <>
      <div className="pb-1 w-full mb-7 bg-white rounded-lg">
        <div
          className={`px-6 message-type-wrapper  ${
            active ? "border-b border-light-grey pt-[30px] pb-6 " : "py-[30px]"
          }`}
        >
          <div className="flex justify-between flex-row">
            <div className="flex flex-col">
              <p
                className={`tracking-tracking_001 text-base text-dark-grey font-medium mb-1 ${
                  !canSee() && "text-opacity-50"
                }`}
              >
                Add subscription options to the cart
                {!canSee() && (
                  <span
                    className="text-xs text-blue_default pl-11 cursor-pointer"
                    onClick={() => setShowPlans(true)}
                  >
                    Upgrade
                  </span>
                )}
              </p>
              <p
                className={`text-10 text-dark-grey text-opacity-75 ${
                  !canSee() && "opacity-50"
                }`}
              >
                Your customers can opt into the subscription in their reminder
                cart.
              </p>
            </div>
            <div className="flex flex-row pr-5 items-center">
              <span className="text-xs tracking-wider text-black_shade mr-3">
                Active
              </span>
              <div
                className={`${!canSee() && "opacity-50 pointer-events-none"}`}
              >
                <Switch
                  state={active}
                  onClick={() => {
                    setActive(!active);
                  }}
                />
              </div>
              <span className="text-xs tracking-wider text-black_shade ml-3">
                Off
              </span>
            </div>
          </div>
        </div>
        {active && (
          <div className="py-6 pr-7 ml-7">
            <div className="flex items-center">
              <Switch
                state={activeDefaultSubscription}
                onClick={() => {
                  setDefaultSubscriptionActive(!activeDefaultSubscription);
                }}
              />
              <span className="pl-3 text-xs font-normal">
                Set the subscription as the default option
              </span>
            </div>
          </div>
        )}
      </div>
      {subscription && (
        <UpgradePlanModel
          show={showPlans}
          onClose={() => setShowPlans(false)}
          subscription={subscription}
        />
      )}
    </>
  );
};

export default AddSubscriptionCart;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getBusiness,
  postGoLive,
} from "../pages/guides/requests/BusinessRequests";
import { setBusiness } from "../redux/ducks/business.duck";
import { setSetupStatus } from "../redux/ducks/setup.duck";
import { useAppSelector } from "../redux/hooks";
import Button from "./Button";
import { AdminProtectedContainer } from "./RoleProtectedContainer";

export const GoLiveButton = ({
  afterSubmit,
  goLiveText = "Go Live",
  showLogo = true,
}: {
  afterSubmit?: () => void;
  goLiveText?: string;
  showLogo?: boolean;
}) => {
  const dispatch = useDispatch();
  const businessId = useAppSelector((state) => state.business.businessId);
  const setupStatus = useAppSelector((state) => state.setup.setupStatus);
  // const { setupStatus } = useContext(SetupStatusContext);

  // const steps = [
  //   setupStatus.shopify,
  //   setupStatus.stripe,
  //   setupStatus.campaigns,
  //   setupStatus.product,
  //   setupStatus.site,
  // ];
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const goLive = (id: number) => {
    setLoading(true);
    postGoLive(id).then(() => {
      // if (setupStatus.goLive.status !== "completed") {
      getBusiness().then((res) => {
        if (res.data) {
          dispatch(setSetupStatus(res.data.setupStatus));
          dispatch(setBusiness(res.data));
        }
      });
      // }
      //setSuccess(true)
      setUpdated(true);
      setLoading(false);

      if (afterSubmit) {
        afterSubmit();
      }
    });
  };

  useEffect(() => {
    if (
      setupStatus.site.status !== "completed" ||
      setupStatus.reminderStep.status !== "completed"
    ) {
      setLoading(true);
      setDisabled(true);
    }
  }, [setupStatus]);

  useEffect(() => {
    if (updated) {
      const timer = setInterval(() => {
        setUpdated(false);
        setLoading(false);
      }, 2500);

      return () => clearInterval(timer);
    }
  }, [updated]);

  const canGoLive = () => {
    return (
      setupStatus.reminderStep.status === "completed" &&
      setupStatus.site.status === "completed"
    );
  };

  const GoLive = () => {
    return (
      <Button
        text={goLiveText}
        className={`h-10 px-4 rounded-md text-white flex items-center bg-blue_default text-13`}
        disabled={loading}
        onClick={() => goLive(businessId)}
        icon={
          <img
            className="mr-3"
            src="/go_live_assistalong_icon.svg"
            alt="go-live-icon"
          ></img>
        }
      />
    );
  };

  const Updating = () => {
    return (
      <Button
        text="Updating"
        className={`default-button items-center gap-2 text-13 text-white`}
        loading={true}
      />
    );
  };

  const Updated = () => {
    return (
      <Button
        text="Updated"
        className={`default-button items-center gap-2 text-13 text-white`}
        icon={<img src="/svg/ico_check_white.svg" alt="go-live-icon"></img>}
      />
    );
  };

  return (
    <AdminProtectedContainer>
      {canGoLive() ? (
        <>
          {!loading && !updated && <GoLive />}
          {loading && !updated && disabled && <GoLive />}
          {loading && !updated && !disabled && <Updating />}
          {updated && <Updated />}
        </>
      ) : (
        <Button
          text={goLiveText}
          className={`py-2 px-4 rounded-md text-white flex items-center bg-gray-500 text-13`}
          disabled={true}
          icon={
            <img
              className="mr-3"
              src="/go_live_assistalong_icon.svg"
              alt="go-live-icon"
            ></img>
          }
        />
      )}
    </AdminProtectedContainer>
  );
};

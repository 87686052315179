import { Navigate, Route, Routes } from "react-router";
import { Authenticate } from "../pages/auth/Authenticate";
import { SmartRemindersPage } from "../pages/smart_reminders/SmartRemindersPage";
import { OrdersPage } from "../pages/orders/OrdersPage";
import { CustomersPage } from "../pages/customers/CustomersPage";
import { StartGuidePage } from "../pages/guides/StartGuidePage";
import { InsightsPage } from "../pages/insights/InsightsPage";
import { LayoutProvider } from "../layout/LayoutProvider";
import { RequiresAuth } from "../pages/auth/RequiresAuth";
import ResetPassword from "../pages/reset_password/ResetPasswordPage";
import ForgetPasswordPage from "../pages/forgot_password/ForgotPasswordPage";
import FAQsPage from "../pages/FAQs/FAQsPage";
import { Login } from "../pages/login/Login";
import FormInput from "../pages/login/FormInput";
import Error404Page from "../pages/Error/Error404";
import Customization from "../pages/customization/CustomizationPage";
import IntegrationsPage from "../pages/integrations/IntegrationsPage";
import ProductDetailsPage from "../pages/smart_reminders/ProductDetailsPage";
import MassEditProductDetailsPage from "../pages/smart_reminders/MassEditProductDetailsPage";
import { AccountPage } from "../pages/account/AccountPage";
import SmartCheckoutsPage from "../pages/smart_checkouts/SmartCheckoutsPage";
import AddEditSmartCheckoutPage from "../pages/smart_checkouts/AddEditSmartCheckoutPage";

export function PrivateRoutes() {
  return (
    <Routes>
      <Route path="auth" element={<Authenticate />} />
      <Route
        path="login"
        element={
          <Login
            show={true}
            title="Log In"
            buttonText="Log In"
            formData={<FormInput />}
          />
        }
      />
      <Route element={<RequiresAuth />}>
        <Route path="/" element={<LayoutProvider title="AssistAlong" />}>
          <Route index element={<StartGuidePage />} />
          <Route path="smart-reminders" element={<SmartRemindersPage />} />
          <Route path="smart-reminders/:id" element={<ProductDetailsPage />} />
          <Route path="smart-checkouts" element={<SmartCheckoutsPage />} />
          <Route
            path="smart-checkouts/add"
            element={<AddEditSmartCheckoutPage />}
          />
          <Route
            path="smart-checkouts/edit/:id"
            element={<AddEditSmartCheckoutPage />}
          />
          <Route path="customization" element={<Customization />} />
          <Route path="integrations" element={<IntegrationsPage />} />
          <Route
            path="smart-reminders/bulk-edit/:title"
            element={<MassEditProductDetailsPage />}
          />
          <Route path="accounts" element={<AccountPage />} />
          <Route path="orders" element={<OrdersPage />} />
          <Route path="customers" element={<CustomersPage />} />
          <Route path="insights" element={<InsightsPage />} />
          <Route path="account" element={<AccountPage />} />
          <Route path="faqs" element={<FAQsPage />} />
        </Route>
      </Route>
      <Route path="/reset_password" element={<ResetPassword />} />
      <Route path="/forgot_password" element={<ForgetPasswordPage />} />
      <Route path="/404" element={<Error404Page />} />
      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
}

import axios from "axios";
import { RevisionType } from "../../smart_reminders/requests/ProductPageConfigurationRequests";
import {
  SiteConfiguration,
  UpdateSiteConfigurationRequest,
} from "../models/SiteConfigurationModel";

const API_URL = process.env.REACT_APP_API_URL;

const baseUrl = (businessId: number, revision: RevisionType = "working") =>
  `${API_URL}/businesses/${businessId}/site_configurations?revision=${revision}`;

function configUrl(id: number) {
  return `${API_URL}/site_configurations/${id}`;
}

export function getSiteConfiguration(
  businessId: number,
  revision: RevisionType = "working"
) {
  return axios.get<SiteConfiguration>(baseUrl(businessId, revision));
}

export function updateSiteConfiguration(
  id: number,
  request: UpdateSiteConfigurationRequest
) {
  return axios.patch<SiteConfiguration>(configUrl(id), {
    site: { working_revision_attributes: request },
  });
}

export function updateSiteConfigIntegration(
  id: number,
  request: UpdateSiteConfigurationRequest,
  siteConfigReq?: UpdateSiteConfigurationRequest
) {
  return axios.patch<SiteConfiguration>(configUrl(id), {
    site: {
      ...request,
      ...(siteConfigReq &&
        Object.keys(siteConfigReq).length > 0 && {
          working_revision_attributes: siteConfigReq,
        }),
    },
  });
}

import SubscriptionPlans from "../../../components/SubscriptionPlans";
import { Subscription } from "../models/PlansModels";

export const PlansAndPricing = ({
  subscription,
}: {
  subscription: Subscription;
}) => {
  return (
    <div className="mt-14 px-14">
      <div className="title-wrap">
        <h2 className="text-2xl font-medium tracking-tracking_001 mb-2.5 text-black_shade">
          Flexible Plans for Any Business
        </h2>
        <p className="text-13 text-dark-grey tracking-tracking_001 mb-16">
          Turn every shopper into a recurring customer
        </p>
      </div>
      <div className="flex -mx-2 mb-7 max-w-[896px] w-full">
        <SubscriptionPlans subscription={subscription} />
      </div>
      <div className="bg-[#F6FAFC] border border-blue_default py-[45px] pl-[84px] pr-[36px] max-w-[897px] w-full rounded-2xl -mx-2 mb-10">
        <div className="flex items-start justify-between">
          <div className="flex pr-4">
            <div className="pr-9">
              <h2 className="text-[24px] leading-[40px] tracking-[-1px] text-normal-blue font-bold">
                Enterprise
              </h2>
              <h3 className="text-[#BDE0F7] text-[22px] leading-[33px] tracking-[-1px] font-bold">
                Custom Pricing
              </h3>
            </div>
            <div>
              <ul className="list-disc text-xs text-normal-blue !leading-[24px] pl-4 font-medium">
                <li className="tracking-tracking_001">
                  Enterprise customer success manager
                </li>
                <li className="tracking-tracking_001">
                  Priority customer support
                </li>
                <li className="tracking-tracking_001">
                  High volume discounted pricing
                </li>
              </ul>
            </div>
          </div>
          <div className="pt-1">
            <button className="default-button text-base !leading-[20px] py-[18px] px-[48px] font-Gilroy_Bold tracking-tracking_001">
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

import { GetAccessTokenSilentlyOptions } from "../use-api";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../components/Button";

export const DangerAlert = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <div
      className="flex p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
      role="alert"
    >
      <svg
        className="inline flex-shrink-0 mr-3 w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <div>
        <span className="font-medium">Oops!</span> {errorMessage}
      </div>
    </div>
  );
};

const { REACT_APP_API_AUDIENCE } = process.env;

export const APIError = ({
  consentText,
  error,
  refresh,
  opts,
}: {
  consentText: string;
  error: any;
  refresh: () => void;
  opts?: GetAccessTokenSilentlyOptions;
}) => {
  const { getAccessTokenWithPopup, loginWithRedirect } = useAuth0();

  const getTokenAndTryAgain = async () => {
    await getAccessTokenWithPopup({ audience: REACT_APP_API_AUDIENCE });
    refresh();
  };

  return (
    <div className="flex flex-col w-full">
      <DangerAlert errorMessage={error?.message || error} />
      {error?.error === "consent_required" && (
        <Button
          text={consentText}
          className="default-button justify-center"
          onClick={getTokenAndTryAgain}
        />
      )}
      {error?.error === "login_required" && (
        <Button
          text="Login"
          className="default-button"
          onClick={loginWithRedirect}
        />
      )}
    </div>
  );
};

import React, { useCallback, useEffect, useState } from "react";
import {
  ProductPageConfiguration,
  QueryObject,
} from "../models/ProductPageConfigurationModel";
import { ShopifyProduct } from "../models/ShopifyProduct";
import debounce from "lodash.debounce";
import { useAppSelector } from "../../../redux/hooks";
// url: `${API_URL}/businesses/${businessId}/shopify_products?${qs}`
const AddSwitchProduct = ({
  show,
  onClose,
  shopifyProducts,
  onClickAddProduct,
  addedProducts,
  queryObj,
  setQueryObj,
  refreshProducts,
  shopifyLoading,
  setIsLoadMore,
  search,
  setSearch,
}: {
  show: boolean;
  onClose: (addedProductIds: number[]) => void;
  shopifyProducts: ShopifyProduct[] | null;
  onClickAddProduct: (product: ShopifyProduct) => void;
  addedProducts: ShopifyProduct[];
  queryObj: QueryObject;
  setQueryObj: (obj: QueryObject) => void;
  refreshProducts: (obj?: any) => void;
  shopifyLoading: Boolean;
  setIsLoadMore: (isLoadMore: Boolean) => void;
  search: string;
  setSearch: (search: string) => void;
}) => {
  const businessId = useAppSelector((state) => state.business.businessId);
  const shopifyProductsEndpoint = `${process.env.REACT_APP_API_URL}/businesses/${businessId}/shopify_products?`;
  const [addedProductsId] = useState<number[]>([]);
  const onScrollProducts = (e: any) => {
    if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
      if (queryObj?.next_page_info && queryObj?.has_next_page) {
        setIsLoadMore(true);
        refreshProducts();
      }
    }
  };

  const onSearch = (e: any) => {
    if (e.target.value.length > 2 || e.target.value.length === 0) {
      setQueryObj({
        next_page_info: "",
        has_next_page: false,
        search: e.target.value,
      });
      setIsLoadMore(false);
      refetchProducts({
        next_page_info: "",
        has_next_page: false,
        search: e.target.value,
      });
    }
  };

  // eslint-disable-next-line
  const debouncedChangeHandler = useCallback(debounce(onSearch, 200), [
    queryObj,
  ]);

  useEffect(() => {
    setQueryObj({
      next_page_info: "",
      has_next_page: false,
      search: "",
    });
    setSearch("");
    refetchProducts({
      next_page_info: "",
      has_next_page: false,
      search: "",
    });
    // eslint-disable-next-line
  }, []);

  const refetchProducts = (obj: QueryObject) => {
    const newObj = {
      search: obj.search,
      next_page_info: obj.next_page_info,
    };
    let qs = `revision=working`;
    if (queryObj) {
      qs += "&" + new URLSearchParams(newObj as any).toString();
    }
    refreshProducts({ url: `${shopifyProductsEndpoint}${qs}` });
  };

  return (
    <>
      <div
        className={` transition ease-linear duration-100 relative z-20 ${
          show ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <div
          className={`min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover transition ease-in-out duration-500 ${
            show ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <div
            className={`absolute bg-black  inset-0 z-0 transition ease-in-out duration-500 ${
              show ? "opacity-50" : "opacity-0"
            }`}
          ></div>
          <div
            className={`w-full max-w-lg relative mx-auto my-auto rounded-lg shadow-lg bg-white transition ease-in-out duration-500  ${
              show ? "opacity-100 visible" : "opacity-0 invisible"
            }`}
          >
            <button
              className="cursor-pointer bg-off_white w-8 h-8 shadow-btn_shadow flex items-center justify-center rounded-full absolute -left-3 -top-3"
              onClick={() => {
                onClose(addedProductsId);
              }}
            >
              <img src="/close-btn.svg" alt="close button"></img>
            </button>
            <div
              className={`flex justify-between items-center py-6 px-8 text-xs text-dark-grey font-medium ${
                shopifyProducts?.length ? "border-b border-light-grey" : ""
              }`}
            >
              <div className="flex items-center">
                <h2>Add Products</h2>
                {shopifyLoading && (
                  <img
                    src="/product-loader.svg"
                    alt="product-loader"
                    className="ml-4 animate-spin-slow"
                  />
                )}
              </div>
              <div>
                <input
                  type="text"
                  placeholder="search"
                  value={search}
                  className="pl-2.5 border rounded border-gray-400 text-xs"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    debouncedChangeHandler(e);
                  }}
                />
              </div>
            </div>
            <div className="relative max-h-540px">
              {shopifyLoading && (
                <div className="h-full w-full absolute opacity-50 bg-white"></div>
              )}
              <div
                className="product-list max-h-540px h-full overflow-y-auto"
                onScroll={(e) => onScrollProducts(e)}
              >
                <ul className="px-8">
                  {shopifyProducts &&
                    shopifyProducts.map((shopifyProduct) => {
                      const isProductAdded: boolean = addedProducts.some(
                        (
                          addedProduct:
                            | ProductPageConfiguration
                            | ShopifyProduct
                        ) => {
                          return addedProduct.id === shopifyProduct.id;
                        }
                      );
                      return (
                        <li
                          key={shopifyProduct.id}
                          className="py-5 border-b border-light-grey flex justify-between items-center"
                        >
                          <div className="flex items-center">
                            <img
                              className="w-6 h-6 mr-2"
                              src={shopifyProduct.imageUrl}
                              alt={`${shopifyProduct.title}`}
                            ></img>
                            <h3 className="text-xs text-dark-grey tracking-tracking_001">
                              {shopifyProduct.title}
                            </h3>
                          </div>
                          <button
                            onClick={() => {
                              onClickAddProduct(shopifyProduct);
                            }}
                            className={`text-blue_default text-xs tracking-tracking_001 ${
                              isProductAdded ? "text-opacity-25" : ""
                            }`}
                            disabled={isProductAdded}
                          >
                            {isProductAdded ? "Added" : "Add"}
                          </button>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AddSwitchProduct };

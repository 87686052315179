import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Auth0ProviderWithHistory from "./pages/auth/Auth0ProviderWithHistory";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// const clientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
//   const domain: string = process.env.REACT_APP_AUTH0_DOMAIN || "";
//   const audience: string = process.env.REACT_APP_API_AUDIENCE || "";
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_ENDPOINT,
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect } from "react";
import { SpinningLoader } from "../../layout/Loaders";
import axios from "axios";
import { setupAxios } from "../../setup/axios/SetupAxios";
import { useAppDispatch } from "../../redux/hooks";
import {
  resetUserAndToken,
  setToken,
  setUser,
} from "../../redux/ducks/user.duck";
import { useNavigate } from "react-router";

const SkeletonLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <div className="flex flex-grow items-center justify-center px-8 py-8 bg-neutral-200 min-h-screen">
      {children}
    </div>
  );
};

const Authenticate = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    resetTokenStates();
    getToken();
    // eslint-disable-next-line
  }, []);
  const navigate = useNavigate();

  const resetTokenStates = () => {
    dispatch(resetUserAndToken());
    // logout();
    localStorage.clear();
  };

  const getToken = async () => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    let request = {};
    if (params.has("business_id") && params.has("charge_id")) {
      request = {
        code: code,
        business_id: params.get("business_id"),
        charge_id: params.get("charge_id"),
      };
    } else {
      request = { code: code };
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/token`,
        request
      );
      setupAxios(axios, data.accessToken);
      dispatch(setUser(data.user));
      dispatch(setToken(data.accessToken));

      localStorage.setItem("auth_jwt", data.accessToken);
      localStorage.setItem("user", JSON.stringify(data.user));

      if (params.has("business_id")) {
        localStorage.setItem("selected_store", `${params.get("business_id")}`);
        await fetch(`${process.env.REACT_APP_API_URL}/users/${data.user.id}`, {
          method: "put",
          body: JSON.stringify({ default_business: params.get("business_id") }),
          headers: {
            authorization: data.accessToken,
            "content-type": "application/json",
          },
        });
        localStorage.setItem(
          "user",
          JSON.stringify({
            ...data.user,
            defaultBusiness: params.get("business_id"),
          })
        );
      } else {
        localStorage.setItem("selected_store", `${data.user.defaultBusiness}`);
      }
      window.location.href = "/";
      // navigate("/");
    } catch (error) {
      console.log({ error });
      navigate("/login");
    }
  };

  if (true) {
    return (
      <SkeletonLayout>
        <SpinningLoader fullHeight={false} />
      </SkeletonLayout>
    );
  }
};

export { Authenticate };

import { useAppSelector } from "../../../redux/hooks";
import { useApi } from "../../../use-api";
import { QueryObject } from "../models/ProductPageConfigurationModel";
import { ShopifyProductsResponse } from "../models/ShopifyProduct";

const API_URL = process.env.REACT_APP_API_URL;

export function useGetShopifyProducts(queryObj?: QueryObject | null) {
  const businessId = useAppSelector((state) => state.business.businessId);
  let qs = `revision=working`;
  if (queryObj) {
    qs += "&" + new URLSearchParams(queryObj as any).toString();
  }
  return useApi<ShopifyProductsResponse>({
    method: "get",
    url: `${API_URL}/businesses/${businessId}/shopify_products?${qs}`,
  });
}

const FAQsPage = () => {
  return (
    <div className="flex flex-col w-full">
      <div className="title flex justify-between mb-8 relative">
        <h1 className="text-2xl text-black_shade font-medium w-5/12">FAQs</h1>
      </div>
    </div>
  );
};

export default FAQsPage;

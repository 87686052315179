import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../components/Button";
import { AdminProtectedContainer } from "../../components/RoleProtectedContainer";
import { useAppSelector } from "../../redux/hooks";
import { validateValues } from "../../util/helpers";
import AddSubscriptionCart from "../smart_reminders/components/AddSubscriptionCart";
import BannerCart from "../smart_reminders/components/BannerCart";
import { UpsellProduct } from "../smart_reminders/components/UpsellProduct";
import * as yup from "yup";
import { ShopifyProduct } from "../smart_reminders/models/ShopifyProduct";
import {
  BannerFields,
  initialDiscountOptions,
} from "../smart_reminders/ProductDetailsPage";
import {
  createProductPageConfiguration,
  getProductPageConfiguration,
  ProductEnumType,
  updateProductPageConfiguration,
} from "../smart_reminders/requests/ProductPageConfigurationRequests";
import AddShippingDiscount from "./components/AddShippingDiscount";
import CheckoutLink from "./components/CheckoutLink";
import SelectCheckoutProduct from "./components/SelectCheckoutProduct";
import { APIError } from "../../layout/Error";
import { ProductPageConfiguration } from "../smart_reminders/models/ProductPageConfigurationModel";
import DeleteProductModal from "../smart_reminders/components/DeleteProductModal";
// import { PageContentLoader } from "../../layout/Loaders";
import CheckoutSlug from "./components/CheckoutSlug";
import {
  DiscountShippingInterface,
  SelectProduct,
} from "./models/SmartCheckout";
import _ from "lodash";

const checkoutSchema = (type: string) =>
  yup.object().shape({
    slug: yup
      .string()
      .trim()
      .matches(
        /^[a-zA-Z\d-_]+$/,
        "only Alphanumeric, Underscores and Dashes are supported."
      )
      .required("Slug is required"),
    discountTypeValue:
      type === "percentage"
        ? yup
            .number()
            .min(0, "Enter a discount percentage of at least 0%")
            .max(100, "Enter a discount percentage of at most 100%")
        : yup
            .number()
            .positive()
            .min(0, "Enter a value greater than or equal to 0"),
    discountMinPurchaseAmount: yup
      .number()
      .positive()
      .min(0, "Enter a value greater than or equal to 0"),
    discountShipMinPurchaseAmount: yup
      .number()
      .positive()
      .min(0, "Enter a value greater than or equal to 0"),
  });

const AddEditSmartCheckoutPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { businessId }: any = useAppSelector((state) => state.business);
  const [checkout, setCheckout] = useState<{
    product: ProductPageConfiguration | null;
    loading: boolean;
    error: any;
  }>({
    product: null,
    loading: false,
    error: null,
  });
  const [isDelete, setIsDelete] = useState(false);
  const [cancelChanges, setCancelChanges] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [save, setSave] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [defaultSubscriptionActive, setDefaultSubscriptionActive] =
    useState(false);
  const [bannerFields, setBannerFields] = useState<BannerFields>({
    isActive: false,
    text: "",
  });
  const [discountActive, setDiscountActive] = useState(false);
  // const [newDiscountCode, setNewDiscountCode] = useState<number>(0);
  // const [discountCodeError, setDiscountCodeError] = useState<boolean>(false);
  const [upsellProductToAdd, setUpsellProductToAdd] = useState<
    ShopifyProduct[]
  >([]);
  const [upsellProductToDelete, setUpsellProductToDelete] = useState<
    ShopifyProduct[]
  >([]);
  const [upsellText, setUpsellText] = useState<string>("");
  const [upsellProducts, setUpsellProducts] = useState<ShopifyProduct[]>([]);
  const [upsellActive, setUpsellActive] = useState(false);
  const [selectCheckoutProductFields, setSelectCheckoutProductFields] =
    useState<string>("static");
  const [selectProduct, setSelectProduct] = useState<SelectProduct[]>([]);
  const [slug, setSlug] = useState<string>("");
  const [staticProductError, setStaticProductError] = useState<boolean>(false);
  // const [slugError, setSlugError] = useState<string>("");
  const [selectProductDelete, setSelectProductDelete] = useState<
    SelectProduct[]
  >([]);
  const [selectProductAdd, setSelectProductAdd] = useState<SelectProduct[]>([]);
  const [errors, setErrors] = useState<any>({});

  const [discountOption, setDiscountOptions] =
    useState<DiscountShippingInterface>(initialDiscountOptions);

  const setDiscountValue = (fieldName: string, value: any) => {
    setDiscountOptions((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  const getSmartCheckoutDetails = async (): Promise<void> => {
    setCheckout({ ...checkout, loading: true });
    try {
      const res = await getProductPageConfiguration(
        Number(id),
        ProductEnumType.SMART
      );
      if (res.status === 200 && res?.data) {
        setCheckout({
          ...checkout,
          loading: false,
          product: {
            ...res?.data,
            ...(!Object.keys(res?.data?.staticProductQty).length && {
              staticProductQty: [],
            }),
          },
        });
      }
    } catch (error: any) {
      setCheckout({ ...checkout, error: error?.message, loading: false });
      console.log(error);
    }
  };

  const resetChanges = () => {
    if (id) {
      setValueFromApi();
    } else {
      reasetAllFields();
    }
    setErrors({});
    setStaticProductError(false);
    setCancelChanges(false);
  };

  const setValueFromApi = () => {
    const product: any = checkout?.product;
    if (product) {
      if (product.slug) {
        setSlug(product.slug);
      }
      if (product?.productType) {
        setSelectCheckoutProductFields(product?.productType);
      }
      if (product?.staticProductQty?.length > 0) {
        setSelectProduct([...product?.staticProductQty]);
      } else {
        setSelectProduct([]);
      }
      if (product.upsellText !== undefined) {
        setUpsellText(product.upsellText);
      }
      if (product.upsellProducts.length > 0) {
        setUpsellProducts([...product.upsellProducts]);
      } else {
        setUpsellProducts([]);
      }

      setDiscountOptions({
        discountTypeFlag: product?.discountTypeFlag,
        discountType: product?.discountType,
        discountTypeValue: product?.discountTypeValue,
        discountMinPurchaseType: product?.discountMinPurchaseType,
        discountMinPurchaseQty: product?.discountMinPurchaseQty,
        discountMinPurchaseAmount: product?.discountMinPurchaseAmount,
        discountShippingFlag: product?.discountShippingFlag,
        discountShipMinPurchaseType: product?.discountShipMinPurchaseType,
        discountShipMinPurchaseQty: product?.discountShipMinPurchaseQty,
        discountShipMinPurchaseAmount: product?.discountShipMinPurchaseAmount,
      });

      setDiscountActive(product.addDiscountFlag);
      // setAllDiscountActive(product.applyDiscountToFullCart);
      setUpsellActive(product.upsellProductFlag);
      setSubscriptionActive(product.isSubscriptionEnabled);
      setDefaultSubscriptionActive(product.isDefaultSubscription);
      setBannerFields({
        isActive: product.addBannerFlag,
        text: product.bannerMessage ?? "",
      });
    }
  };

  const reasetAllFields = () => {
    if (slug) {
      setSlug("");
    }
    if (selectCheckoutProductFields) {
      setSelectCheckoutProductFields("static");
      setSelectProduct([]);
    }
    if (discountActive) {
      setDiscountActive(false);
    }
    // setNewDiscountCode(0);
    if (bannerFields) {
      setBannerFields({
        isActive: false,
        text: "",
      });
    }
    if (defaultSubscriptionActive) {
      setDefaultSubscriptionActive(false);
    }
    setSubscriptionActive(false);
    if (upsellActive) {
      setUpsellActive(false);
    }
    setDiscountOptions(initialDiscountOptions);
    setUpsellText("");
    setUpsellProducts([]);
    setUpsellProductToAdd([]);
    setUpsellProductToDelete([]);
    setSelectProductAdd([]);
    setSelectProductDelete([]);
    // setSlugError("");
  };

  const flagsChanged = () => {
    if (id && checkout?.product) {
      return (
        checkout?.product?.addDiscountFlag !== discountActive ||
        checkout?.product?.upsellProductFlag !== upsellActive ||
        checkout?.product?.addBannerFlag !== bannerFields.isActive ||
        checkout?.product?.isSubscriptionEnabled !== subscriptionActive ||
        checkout?.product?.isDefaultSubscription !==
          defaultSubscriptionActive ||
        checkout?.product?.discountTypeFlag !==
          discountOption?.discountTypeFlag ||
        checkout?.product?.discountShippingFlag !==
          discountOption?.discountShippingFlag
      );
    } else {
      return (
        discountActive !== false ||
        upsellActive !== false ||
        bannerFields.isActive !== false ||
        subscriptionActive !== false ||
        defaultSubscriptionActive !== false ||
        discountOption?.discountTypeFlag !== false ||
        discountOption?.discountShippingFlag !== false
      );
    }
  };

  const canEnableSave = () => {
    if (id) {
      return (
        checkout?.product?.slug !== slug ||
        checkout?.product?.upsellText !== upsellText ||
        checkout?.product?.bannerMessage !== bannerFields.text ||
        checkout?.product?.productType !== selectCheckoutProductFields ||
        !_.isEqual(checkout?.product?.staticProductQty, selectProduct) ||
        checkout?.product?.discountType !== discountOption?.discountType ||
        checkout?.product?.discountTypeValue !==
          discountOption?.discountTypeValue ||
        checkout?.product?.discountMinPurchaseAmount !==
          discountOption?.discountMinPurchaseAmount ||
        checkout?.product?.discountMinPurchaseQty !==
          discountOption?.discountMinPurchaseQty ||
        checkout?.product?.discountMinPurchaseType !==
          discountOption?.discountMinPurchaseType ||
        checkout?.product?.discountShipMinPurchaseType !==
          discountOption?.discountShipMinPurchaseType ||
        checkout?.product?.discountShipMinPurchaseAmount !==
          discountOption?.discountShipMinPurchaseAmount ||
        checkout?.product?.discountShipMinPurchaseQty !==
          discountOption?.discountShipMinPurchaseQty ||
        selectProductAdd.length > 0 ||
        selectProductDelete.length > 0 ||
        upsellProductToDelete.length > 0 ||
        upsellProductToAdd.length > 0 ||
        (flagsChanged() && Object.keys(errors).length === 0)
      );
    } else {
      return (
        slug !== "" &&
        (upsellText !== "" ||
          bannerFields.text !== "" ||
          selectCheckoutProductFields !== "" ||
          selectProductDelete.length > 0 ||
          selectProductAdd.length > 0 ||
          upsellProductToDelete.length > 0 ||
          upsellProductToAdd.length > 0 ||
          discountOption?.discountTypeValue !==
            initialDiscountOptions?.discountTypeValue ||
          discountOption?.discountMinPurchaseAmount !==
            initialDiscountOptions?.discountMinPurchaseAmount ||
          discountOption?.discountMinPurchaseQty !==
            initialDiscountOptions?.discountMinPurchaseQty ||
          discountOption?.discountShipMinPurchaseAmount !==
            initialDiscountOptions?.discountShipMinPurchaseAmount ||
          discountOption?.discountShipMinPurchaseQty !==
            initialDiscountOptions?.discountShipMinPurchaseQty ||
          (flagsChanged() && Object(errors).length === 0))
        // newDiscountCode !== 0 ||
        // (flagsChanged() && !discountCodeError)
      );
    }
    //eslint-disable-next-line
  };

  useEffect(() => {
    if (canEnableSave()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    //eslint-disable-next-line
  }, [
    slug,
    checkout,
    discountOption,
    upsellText,
    upsellProductToDelete,
    upsellProductToAdd,
    // discountCodeError,
    // newDiscountCode,
    selectProduct,
    discountActive,
    selectCheckoutProductFields,
    selectProductDelete,
    selectProductAdd,
    // allDiscountActive,
    upsellActive,
    subscriptionActive,
    defaultSubscriptionActive,
    bannerFields,
    errors,
  ]);

  useEffect(() => {
    if (save) {
      if (selectProductCheck()) {
        onCheckoutProduct();
      } else {
        setStaticProductError(true);
        setSave(false);
      }
    }
    //eslint-disable-next-line
  }, [save]);

  useEffect(() => {
    if (!checkout?.loading) {
      setSave(false);
    }
    // eslint-disable-next-line
  }, [checkout?.loading]);

  useEffect(() => {
    if (id) {
      getSmartCheckoutDetails();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (isDelete) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isDelete]);

  useEffect(() => {
    if (checkout?.product) {
      setValueFromApi();
    }
    // eslint-disable-next-line
  }, [checkout?.product]);

  useEffect(() => {
    if (checkout?.error) {
      navigate("/smart-checkouts");
    }
    // eslint-disable-next-line
  }, [checkout?.error]);

  if (checkout?.error) {
    return (
      <APIError
        error={checkout?.error}
        consentText="Consent to reading Products"
        refresh={getSmartCheckoutDetails}
      />
    );
  }

  const onCheckoutProduct = async () => {
    try {
      setIsButtonDisabled(true);
      const validationObj = {
        ...discountOption,
        slug,
      };

      const hasErrors = validateValues(
        validationObj,
        checkoutSchema(discountOption?.discountType),
        setErrors
      );

      if (!hasErrors) {
        const apiPromises: any[] = [];

        const upsellUpdateRequest = {
          upsellText: upsellText,
          shopifyProductIds: upsellProducts.map((product) => product.id),
          bannerMessage: bannerFields.text,
        };

        if (upsellProductToDelete.length > 0 || upsellProductToAdd.length > 0) {
          setUpsellProductToAdd([]);
          setUpsellProductToDelete([]);
        }

        if (selectProductAdd.length > 0 || selectProductDelete.length > 0) {
          setSelectProductAdd([]);
          setSelectProductDelete([]);
        }

        if (id) {
          apiPromises.push(
            updateProductPageConfiguration(+id, {
              slug: slug,
              configurationType: ProductEnumType.SMART,
              productPageRevisionsAttributes: [upsellUpdateRequest],
              addDiscountFlag: discountActive,
              upsellProductFlag: upsellActive,
              isSubscriptionEnabled: subscriptionActive,
              addBannerFlag: bannerFields.isActive,
              staticProductQty: selectProduct,
              shopifyProductIds: selectProduct.map(
                (item: SelectProduct) => item.product.id
              ),
              productType: selectCheckoutProductFields,
              isDefaultSubscription: defaultSubscriptionActive,
              discountTypeFlag: discountOption?.discountTypeFlag,
              discountShippingFlag: discountOption?.discountShippingFlag,
              discountType: discountOption?.discountType,
              discountTypeValue: discountOption?.discountTypeValue,
              discountMinPurchaseType: discountOption?.discountMinPurchaseType,
              discountMinPurchaseQty: discountOption?.discountMinPurchaseQty,
              discountMinPurchaseAmount:
                discountOption?.discountMinPurchaseAmount,
              discountShipMinPurchaseType:
                discountOption?.discountShipMinPurchaseType,
              discountShipMinPurchaseQty:
                discountOption?.discountShipMinPurchaseQty,
              discountShipMinPurchaseAmount:
                discountOption?.discountShipMinPurchaseAmount,
            })
          );
        } else {
          apiPromises.push(
            createProductPageConfiguration(businessId, {
              configurationType: ProductEnumType.SMART,
              slug: slug,
              staticProductQty: selectProduct,
              shopifyProductIds: selectProduct.map(
                (item: SelectProduct) => item.product.id
              ),
              productType: selectCheckoutProductFields,
              productPageRevisionsAttributes: [upsellUpdateRequest],
              addDiscountFlag: discountActive,
              upsellProductFlag: upsellActive,
              discountTypeFlag: discountOption?.discountTypeFlag,
              discountShippingFlag: discountOption?.discountShippingFlag,
              // applyDiscountToFullCart: allDiscountActive,
              isSubscriptionEnabled: subscriptionActive,
              addBannerFlag: bannerFields.isActive,
              isDefaultSubscription: defaultSubscriptionActive,

              discountType: discountOption?.discountType,
              discountTypeValue: discountOption?.discountTypeValue,
              discountMinPurchaseType: discountOption?.discountMinPurchaseType,
              discountMinPurchaseQty: discountOption?.discountMinPurchaseQty,
              discountMinPurchaseAmount:
                discountOption?.discountMinPurchaseAmount,
              discountShipMinPurchaseType:
                discountOption?.discountShipMinPurchaseType,
              discountShipMinPurchaseQty:
                discountOption?.discountShipMinPurchaseQty,
              discountShipMinPurchaseAmount:
                discountOption?.discountShipMinPurchaseAmount,
            })
          );
        }

        if (apiPromises.length > 0) {
          const [res] = await Promise.all(apiPromises);

          if (res?.status === 201 && res?.data) {
            navigate("/smart-checkouts");
          } else if (res?.status === 200 && res?.data) {
            await getSmartCheckoutDetails();
          } else {
            if (res?.error?.slug) {
              setErrors({ slug: res?.error?.slug?.[0] });
            }
          }
        }
        setIsButtonDisabled(false);
      }
      setSave(false);
    } catch (error) {
      console.log(error);
    }
  };

  // const isEdit = () => {
  //   return (
  //     (slug !== checkout?.product?.slug ||
  //       selectCheckoutProductFields !== checkout?.product?.productType ||
  //       selectProductAdd.length > 0 ||
  //       selectProductDelete.length > 0 ||
  //       upsellProductToAdd.length > 0 ||
  //       upsellProductToDelete.length > 0 ||
  //       checkout?.product?.upsellText !== upsellText ||
  //       upsellActive !== checkout?.product.upsellProductFlag ||
  //       subscriptionActive !== checkout?.product.isSubscriptionEnabled ||
  //       defaultSubscriptionActive !== checkout?.product.isDefaultSubscription ||
  //       bannerFields.isActive !== checkout?.product.addBannerFlag ||
  //       bannerFields.text !== checkout?.product.bannerMessage ||
  //       flagsChanged()) &&
  //     Object(errors).length === 0
  //   );
  // };

  const selectProductCheck = () => {
    return (
      (selectCheckoutProductFields === "static" && selectProduct.length > 0) ||
      selectCheckoutProductFields === "dynamic"
    );
  };

  return (
    <>
      {/* {checkout?.loading ? (
        <PageContentLoader pageTitle="Smart Checkouts" />
      ) : ( */}
      <div className="flex flex-col w-full">
        {/* TODO: change this to flex row adn update according to design */}
        <div className="title flex justify-between py-4 mb-6">
          <div className="flex items-center">
            <h1 className="text-2xl text-black_shade font-medium pr-[40px]">
              Smart Checkouts
            </h1>
          </div>

          <AdminProtectedContainer>
            <div className="flex items-center gap-3">
              {id && (
                <Button
                  text="Delete Product"
                  className={`h-10 px-4 rounded-md text-white flex items-center bg-btnRed text-13`}
                  onClick={() => setIsDelete(true)}
                />
              )}
              <Button
                text="Cancel changes"
                className={`h-10 px-4 rounded-md text-[#192A3E] border border-[#192A3E] flex items-center text-13 tracking-wide `}
                disabled={isButtonDisabled || save}
                onClick={() => {
                  setCancelChanges(true);
                  resetChanges();
                }}
              />
              <Button
                text="Save"
                disabled={isButtonDisabled || cancelChanges || save}
                className={`h-10 px-6 rounded-md text-white flex items-center text-13 tracking-wide ${
                  isButtonDisabled || cancelChanges || save
                    ? "bg-gray-500"
                    : "bg-green-500"
                }`}
                onClick={() => setSave(true)}
                loading={save}
              />
            </div>
          </AdminProtectedContainer>
        </div>
        <div className="text-xs text-blue_default tracking-tracking_001 font-medium pb-7">
          <Link
            className="flex items-center cursor-pointer"
            to="/smart-checkouts"
          >
            <img className="mr-1" src="/back-arrow.svg" alt="back arrow" />
            Smart Checkouts
          </Link>
        </div>

        <CheckoutSlug slug={slug} setSlug={setSlug} errors={errors} />

        <SelectCheckoutProduct
          setSelectProductFields={setSelectCheckoutProductFields}
          selectProductFields={selectCheckoutProductFields}
          setSelectProduct={setSelectProduct}
          selectProduct={selectProduct}
          staticProductError={staticProductError}
          setStaticProductError={setStaticProductError}
          selectUpsellProducts={upsellProductToAdd}
          setSelectProductDelete={setSelectProductDelete}
          selectProductDelete={selectProductDelete}
          setSelectProductAdd={setSelectProductAdd}
          selectProductAdd={selectProductAdd}
        />

        <AddShippingDiscount
          active={discountActive}
          setActive={setDiscountActive}
          discountOption={discountOption}
          setDiscountValue={setDiscountValue}
          errors={errors}
          setError={setErrors}
        />

        <AddSubscriptionCart
          active={subscriptionActive}
          setActive={setSubscriptionActive}
          activeDefaultSubscription={defaultSubscriptionActive}
          setDefaultSubscriptionActive={setDefaultSubscriptionActive}
        />
        <BannerCart
          bannerFields={bannerFields}
          setBannerFields={setBannerFields}
        />

        <UpsellProduct
          save={save}
          active={upsellActive}
          setActive={setUpsellActive}
          setUpsellText={setUpsellText}
          upsellText={upsellText}
          upsellProducts={upsellProducts}
          setUpsellProducts={setUpsellProducts}
          upsellProductToAdd={upsellProductToAdd}
          setUpsellProductToAdd={setUpsellProductToAdd}
          upsellProductToDelete={upsellProductToDelete}
          setUpsellProductToDelete={setUpsellProductToDelete}
          staticProducts={selectProduct}
        />

        <CheckoutLink
          slug={slug}
          selectCheckoutProductFields={selectCheckoutProductFields}
        />
        {checkout?.product && (
          <DeleteProductModal
            show={isDelete}
            onClose={() => setIsDelete(false)}
            mainProduct={checkout?.product}
            type={ProductEnumType.SMART}
          />
        )}
      </div>
      {/* )} */}
    </>
  );
};

export default AddEditSmartCheckoutPage;

import { ModalContainer } from "../../../components/ModalContainer";

const WarningModal = ({
  show,
  onClose,
  onSubmit,
  message = "Are you sure you want to remove?",
}: {
  show: boolean;
  onClose: () => void;
  onSubmit: () => void;
  message: string;
}) => {
  return (
    <ModalContainer show={show} onClose={onClose}>
      <div className="product-list overflow-y-auto max-h-84vh">
        <div className="p-8">
          <div className="pb-5 flex justify-center items-center ">
            <div className="flex items-center">
              <img
                className="w-[85px] h-[85px] mr-2 object-center"
                src="/exclamation.png"
                alt="error"
              ></img>
            </div>
          </div>
          <div className="mt-4 mb-[54px]">
            <div className="text-base">{message}</div>
          </div>
          <div className="flex justify-between items-center">
            <button
              className="py-[9.5px] font-medium border border-gray-800 tracking-wide_0.01 px-[40px] min-w-[124px] w-[124px] text-13px  flex max-w-max text-black rounded-md float-left self-end"
              onClick={() => onClose()}
            >
              Cancel
            </button>
            <button
              className="py-[10px] font-medium border border-btnRed tracking-wide_0.01 px-[50px] min-w-[124px] w-[124px] text-13px  flex max-w-max text-white rounded-md float-left self-end bg-btnRed"
              onClick={onSubmit}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default WarningModal;

import axios from "axios";
import {
  ProductReminderFrequency,
  UpdateFrequencyRequest,
} from "../models/ShopifyProduct";

const API_URL = process.env.REACT_APP_API_URL;

const REMINDER_FREQUENCIES_URL = `${API_URL}/reminder_frequencies`;

export function updateReminderFrequency(
  freqId: number,
  req: UpdateFrequencyRequest
) {
  return axios.put<ProductReminderFrequency>(
    `${REMINDER_FREQUENCIES_URL}/${freqId}`,
    {
      reminderFrequency: req,
    }
  );
}

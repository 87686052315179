import { useEffect, useReducer } from "react";
import { OptionTypeSelector } from "../../../components/OptionTypeSelector";
import {
  ProductReminderFrequency,
  ShopifyProduct,
} from "../models/ShopifyProduct";
import { Switch } from "./Switch";
import {
  AddStepAction,
  DeleteStepAction,
  RenderableStep,
  ResetStepAction,
  StepState,
  UpdateDefaultAction,
  UpdateQualityAction,
  UpdateQuantityAction,
} from "../models/ReminderActionTypes";
import { IsAdmin, showHoursOption } from "../../../util/helpers";
import { AdminProtectedContainer } from "../../../components/RoleProtectedContainer";

type UpdateStepAction =
  | UpdateQualityAction
  | UpdateQuantityAction
  | UpdateDefaultAction;

function frequencyReducer(
  state: StepState,
  action: AddStepAction | DeleteStepAction | UpdateStepAction | ResetStepAction
): StepState {
  switch (action.type) {
    case "add":
      let count1 = 0;

      state.steps.forEach((other) => {
        if (
          action.step.frequency.quality === other.frequency.quality &&
          action.step.frequency.quantity === other.frequency.quantity
        ) {
          count1 += 1;
        }
      });

      return {
        steps: [...state.steps, { ...action.step, defaultStep: false }],
        hasDuplicates: count1 >= 1,
      };
    case "updateQuantity":
      let copy = [...state.steps];
      const tgt = copy[action.index];

      let count = 0;
      state.steps.forEach((other) => {
        if (
          action.step.quantity === other.frequency.quantity &&
          tgt.frequency.quality === other.frequency.quality
        ) {
          count += 1;
        }
      });
      copy.splice(action.index, 1, {
        ...tgt,
        frequency: { ...tgt.frequency, ...action.step },
      });
      return { steps: copy, hasDuplicates: count >= 2 };
    case "updateQuality":
      let copy2 = [...state.steps];
      const tgt2 = copy2[action.index];

      let count2 = 0;

      state.steps.forEach((other) => {
        if (
          action.step.quality === other.frequency.quality &&
          tgt2.frequency.quantity === other.frequency.quantity
        ) {
          count2 += 1;
        }
      });

      copy2.splice(action.index, 1, {
        ...tgt2,
        frequency: { ...tgt2.frequency, ...action.step },
      });
      return { steps: copy2, hasDuplicates: count2 >= 2 };
    case "updateDefault":
      let copy3 = [...state.steps];
      const existingDefault = copy3.findIndex(
        (step) => step.defaultStep === true
      );

      if (existingDefault !== -1) {
        copy3.splice(existingDefault, 1, {
          ...copy3[existingDefault],
          defaultStep: false,
        });
      }
      copy3.splice(action.index, 1, { ...copy3[action.index], ...action.step });
      return { steps: copy3, hasDuplicates: state.hasDuplicates };
    case "delete":
      let deleteCopy = [...state.steps];
      let target = deleteCopy[action.index];

      if (target.id !== undefined) {
        deleteCopy.splice(action.index, 1, {
          ...deleteCopy[action.index],
          _destroy: true,
        });
      } else {
        deleteCopy.splice(action.index, 1);
      }
      return { steps: deleteCopy, hasDuplicates: false };
    case "reset":
      return action.newState;
    default:
      throw new Error();
  }
}

const ReminderSection = ({
  active,
  setActive,
  shopifyProduct,
  hasDuplicateReminderFrequency,
  setHasDuplicateReminderFrequency,
  save,
  cancelChanges,
  setCancelChanges,
  setPendingUpdates,
  reminderFrequency,
  pendingUpdates,
  setReminderState,
}: {
  shopifyProduct: ShopifyProduct;
  hasDuplicateReminderFrequency: boolean;
  setHasDuplicateReminderFrequency: (value: boolean) => void;
  save: boolean;
  cancelChanges: boolean;
  setCancelChanges: (flag: boolean) => void;
  setPendingUpdates: (t: boolean) => void;
  reminderFrequency: ProductReminderFrequency;
  active: boolean;
  setActive: (flag: boolean) => void;
  pendingUpdates: boolean;
  setReminderState: (value: StepState) => void;
}) => {
  const qualityOptions = [
    { label: "days", value: "day" },
    { label: "weeks", value: "week" },
    { label: "months", value: "month" },
  ];

  if (showHoursOption) {
    qualityOptions.splice(0, 0, { label: "hours", value: "hour" });
  }
  const quantityOptions = Array(31)
    .fill(0, 1)
    .map((_, i) => ({ label: `${i}`, value: `${i}` }));

  const steps: RenderableStep[] = reminderFrequency.steps.map((step) => {
    return {
      ...step,
      defaultStep: step.id === shopifyProduct.reminderFrequency.defaultStepId,
    };
  });

  const [state, dispatch] = useReducer(frequencyReducer, {
    steps,
    hasDuplicates: false,
  });

  useEffect(() => {
    if (shopifyProduct || cancelChanges) {
      dispatch({
        type: "reset",
        newState: {
          ...state,
          steps: shopifyProduct.reminderFrequency.steps.map((step) => {
            return {
              ...step,
              defaultStep:
                step.id === shopifyProduct.reminderFrequency.defaultStepId,
            };
          }),
        },
      });
      setPendingUpdates(false);
      setCancelChanges(false);
    }
    // eslint-disable-next-line
  }, [shopifyProduct, cancelChanges]);

  // useEffect(() => {
  //   if (reminderFrequency.reminderFlag !== active) {
  //     setPendingUpdates(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [active]);

  useEffect(() => {
    if (state) {
      setReminderState(state);
      setHasDuplicateReminderFrequency(state.hasDuplicates);
    }
    //eslint-disable-next-line
  }, [state]);

  const onAddFrequency = () => {
    dispatch({
      type: "add",
      step: {
        frequency: {
          quantity: 1,
          quality: showHoursOption ? "hour" : "day",
        },
      },
    });
    setPendingUpdates(true);
  };

  const onRemoveFrequency = (index: number) => {
    dispatch({ type: "delete", index });
    // dispatch(setDeleteReminder({index}));
    setPendingUpdates(true);
  };

  const onValueChange = (
    e: any,
    rowIndex: number,
    type: string,
    schedulerType: string
  ) => {
    if (type === "quantity") {
      dispatch({
        type: "updateQuantity",
        index: rowIndex,
        step: { quantity: Number(e.value) },
      });
    }
    if (type === "quality") {
      dispatch({
        type: "updateQuality",
        index: rowIndex,
        step: { quality: e.value },
      });
    }
    setPendingUpdates(true);
  };

  const updateDefaultStep = (index: number) => {
    dispatch({
      type: "updateDefault",
      index: index,
      step: { defaultStep: true },
    });
    setPendingUpdates(true);
  };

  return (
    <div>
      <div className="pb-8 w-full mb-7 bg-white rounded-lg">
        <div className="px-6 message-type-wrapper py-6 border-b border-light-grey">
          <div className="flex justify-between flex-row">
            <div className="flex flex-col">
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                Set recurring SMS reminder options
              </p>
              <p className="text-10 text-dark-grey text-opacity-75">
                Reminder frequency options offered to one-time customers after
                their purchase
              </p>
            </div>
            <div className="flex flex-row pr-5 items-center">
              <span className="text-xs tracking-wider text-dark-grey text-opacity-75 mr-3">
                Active
              </span>
              <div
                className={
                  save || !IsAdmin() ? "opacity-50 pointer-events-none" : ""
                }
              >
                <Switch
                  state={active}
                  onClick={() => {
                    setActive(!active);
                  }}
                />
              </div>
              <span className="text-xs tracking-wider text-dark-grey text-opacity-75 ml-3">
                Off
              </span>
            </div>
          </div>
        </div>
        <div className="message-type-wrapper pl-6">
          {state.steps.map((step, index) => {
            const quantityDefaultValue = {
              label: `${step.frequency.quantity}`,
              value: `${step.frequency.quantity}`,
            };

            if (step._destroy) {
              return null;
            }

            let count = 0;

            state.steps.forEach((other) => {
              if (
                step.frequency.quantity === other.frequency.quantity &&
                step.frequency.quality === other.frequency.quality &&
                !other._destroy
              ) {
                count += 1;
              }
            });

            const isDuplicateFrequency = count >= 2;

            const qualityDefaultValue = {
              label: `${step.frequency.quality}s`,
              value: step.frequency.quality,
            };
            const isDefault = step.defaultStep;

            return (
              <div
                key={`${index}_${step.id}`}
                className={`${
                  state.steps.length - 1 !== index &&
                  "border-b border-light-grey pb-5"
                } pt-5`}
              >
                <div className="flex flex-row w-full items-center">
                  <div className="mr-2">
                    <OptionTypeSelector
                      placeholder=""
                      options={quantityOptions}
                      defaultValue={quantityDefaultValue}
                      handleChangeValue={onValueChange}
                      rowIndex={index}
                      type="quantity"
                      schedulerType=""
                      selectClasses="w-20 font-bold"
                    />
                  </div>
                  <OptionTypeSelector
                    placeholder=""
                    options={qualityOptions}
                    defaultValue={qualityDefaultValue}
                    handleChangeValue={onValueChange}
                    rowIndex={index}
                    type="quality"
                    schedulerType=""
                    selectClasses="font-bold"
                  />
                  <>
                    {isDefault ? (
                      <span className="align-center text-xs tracking-tracking_001 ml-7 text-grey-font">
                        Default
                      </span>
                    ) : (
                      <AdminProtectedContainer>
                        <span
                          onClick={() => updateDefaultStep(index)}
                          className="cursor-pointer align-center text-blue_default text-xs tracking-tracking_001 ml-7"
                        >
                          Set as default
                        </span>
                      </AdminProtectedContainer>
                    )}
                  </>
                  {!isDefault && (
                    <AdminProtectedContainer>
                      <button
                        onClick={() => onRemoveFrequency(index)}
                        className="absolute right-20"
                      >
                        <img
                          className="cursor-pointer"
                          src="/delete-icon.svg"
                          alt="close button"
                        ></img>
                      </button>
                    </AdminProtectedContainer>
                  )}
                </div>
                {isDuplicateFrequency && (
                  <p className="text-danger pt-1 text-red-500 text-xs">
                    Duplicate reminder frequency is not allowed
                  </p>
                )}
              </div>
            );
          })}
          {state.steps.filter((step) => step?._destroy !== true).length < 6 && (
            <AdminProtectedContainer>
              <button
                onClick={() => onAddFrequency()}
                className="text-blue_default text-xs tracking-tracking_001 mt-8 flex items-center"
              >
                Add New Frequency
              </button>
            </AdminProtectedContainer>
          )}
        </div>
      </div>
    </div>
  );
};

export { ReminderSection };

import * as React from "react";
import { SiteConfiguration } from "../models/SiteConfigurationModel";
import { getClass, onInputBlur } from "../../../util/helpers";

const SiteConfigStartGuide = ({
  onSave,
  siteConfigInitials,
  setFieldValueSiteConfig,
  setSiteConfigData,
  businessId,
  savingInParent,
  siteErrors,
  setSiteErrors,
  saveDisabled,
  customization,
}: {
  onSave?: () => void;
  siteConfigInitials: SiteConfiguration;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
  setSiteConfigData: (data: SiteConfiguration) => void;
  businessId: number;
  savingInParent?: boolean;
  siteErrors: any;
  setSiteErrors: (errors: any) => void;
  saveDisabled?: boolean;
  customization: boolean;
}) => {
  const [focusedFields, setFocusedFields] = React.useState<string[]>([]);

  const getColorValue = (primary = true) => {
    if (primary) {
      return siteConfigInitials?.primaryColor?.replace("#", "") || "";
    } else {
      return siteConfigInitials?.secondaryColor?.replace("#", "") || "";
    }
  };

  const checkColorCode = (value: string, primary = true) => {
    if (value.slice(0, 6)) {
      const colorRgx = /[a-fA-F0-9]{6}/;
      if (primary) {
        if (!colorRgx.test(value)) {
          setSiteErrors({ primaryColor: true });
        } else {
          setSiteErrors({ primaryColor: false });
        }
      } else {
        if (!colorRgx.test(value)) {
          setSiteErrors({ secondaryColor: true });
        } else {
          setSiteErrors({ secondaryColor: false });
        }
      }
    }
  };

  return (
    <div className="">
      {customization ? (
        <div className="flex xl:flex-row flex-row xl:items-center items-center mt-2 w-full border-b border-gray1 pb-5">
          <label className="text-xs tracking-wide_0.01 text-black_shade mr-[18px] mb-2 xl:mb-0">
            Store Name
          </label>
          <input
            className="rounded border text-xs font-normal border-gray2 px-3 !py-[15px] w-[277px] bg-white text-black_shade focus:ring-0"
            type="text"
            value={siteConfigInitials.brandName ?? ""}
            onChange={(e) =>
              setFieldValueSiteConfig("brandName", e.target.value)
            }
            onBlur={() =>
              onInputBlur(focusedFields, setFocusedFields, "brandName")
            }
          />
        </div>
      ) : (
        <div
          className={`field-wrap border-0 startguide-field w-[277px] ${getClass(
            "brandName",
            { brandName: siteConfigInitials.brandName },
            focusedFields
          )}`}
        >
          <label className="input-label translate-y-4 leading-[0.625rem] font-normal text-8">
            Store Name
          </label>
          <input
            required
            type="text"
            className={`z-0 border tracking-tracking_001 text-xs font-extrabold rounded border-gray2 border-gray2-imp pt-4 p-3 pl-2 h-50px text-black`}
            placeholder=""
            value={siteConfigInitials.brandName ?? ""}
            onChange={(e) =>
              setFieldValueSiteConfig("brandName", e.target.value)
            }
            onBlur={() =>
              onInputBlur(focusedFields, setFocusedFields, "brandName")
            }
          />
        </div>
      )}
      {siteErrors?.brandName && (
        <p className="scroll__error text-danger pt-1 text-red-500 text-xs">
          {siteErrors["brandName"]}
        </p>
      )}
      <div className="py-4 ">
        <p className="text-dark-grey text-10 leading-[0.9375rem] font-normal">
          Customize these to match your brand identity, colors can be previewed
          and changed later.
        </p>
      </div>
      <div
        className={`flex ${
          savingInParent ? "" : ""
        } justify-between pb-[26px] border-b border-gray1`}
      >
        <div className="flex flex-row items-center mt-2 w-full">
          <label className="text-xs tracking-wide_0.01 text-black_shade font-normal mr-4 mb-2 xl:mb-0 ">
            Primary Color
          </label>
          <div
            className={`border rounded ${
              siteErrors["primaryColor"]
                ? "border-red-600"
                : "border-border_gray"
            }  py-3 px-3 w-colorSelect h-full flex items-center`}
          >
            <input
              id="primary-color"
              className="w-6 h-6 rounded-full overflow-hidden bg-blue_default opacity-0 z-10 cursor-pointer border border-gray2"
              type="color"
              value={siteConfigInitials.primaryColor ?? ""}
              onChange={(e) => {
                setFieldValueSiteConfig("primaryColor", e.target.value);
              }}
              defaultValue={siteConfigInitials.primaryColor}
            />
            <label
              style={{ background: siteConfigInitials.primaryColor }}
              className="w-6 h-6 rounded-full overflow-hidden z-0 absolute border border-solid border-gray2"
              htmlFor="primary-color"
            ></label>
            <div className="colorPicker-wrapper relative before:content-['#'] before:absolute before:text-blue_default before:text-xs before:top-0">
              <input
                className="text-blue_default text-xs font-normal tracking-wide_0.01 absolute top-0 right-0 p-0 bg-transparent border-0 color-picker-input outline-none pl-2.5 focus:shadow-none"
                value={getColorValue(true)}
                type="text"
                onChange={(e) => {
                  checkColorCode(e.target.value, true);
                  setFieldValueSiteConfig(
                    "primaryColor",
                    "#" + e.target.value.slice(0, 6)
                  );
                }}
              />
            </div>
          </div>
          <p className="text-danger pt-1 text-red-500 text-xs">
            {siteErrors["primaryColor"]}
          </p>
          <label className="text-xs tracking-wide_0.01 text-black_shade font-normal mr-4 mb-2 xl:mb-0 ml-5">
            Secondary Color
          </label>
          <div
            className={`border rounded ${
              siteErrors["secondaryColor"]
                ? "border-red-600"
                : "border-border_gray"
            } py-3 px-3 w-colorSelect flex items-center h-full`}
          >
            <input
              id="secondary-color"
              className="w-6 h-6 rounded-full overflow-hidden opacity-0 z-10 cursor-pointer"
              type="color"
              value={siteConfigInitials.secondaryColor ?? ""}
              onChange={(e) => {
                setFieldValueSiteConfig("secondaryColor", e.target.value);
              }}
              defaultValue={siteConfigInitials.secondaryColor}
            />
            <label
              style={{ background: siteConfigInitials.secondaryColor }}
              className="w-6 h-6 rounded-full overflow-hidden z-0 absolute border border-solid border-gray2"
              htmlFor="secondary-color"
            ></label>
            <div className="colorPicker-wrapper relative before:content-['#'] before:absolute before:text-blue_default before:text-xs before:top-0">
              <input
                className="text-blue_default text-xs font-normal tracking-wide_0.01 absolute top-0 right-0 p-0 bg-transparent border-0 color-picker-input outline-none pl-2.5 focus:shadow-none"
                value={getColorValue(false)}
                type="text"
                onChange={(e) => {
                  checkColorCode(e.target.value, false);
                  setFieldValueSiteConfig(
                    "secondaryColor",
                    "#" + e.target.value.slice(0, 6)
                  );
                }}
              />
            </div>
          </div>
          <p className="text-danger pt-1 text-red-500 text-xs">
            {siteErrors["secondaryColor"]}
          </p>
          {/* {!error && products && products[0] && (
            <>
              <label className="text-xs tracking-wide_0.01 text-black_shade mr-3 ml-4">
                Preview On
              </label>

              <div className="flex flex-row items-center">
                <div className="btn-wrap flex mr-4">
                  <button
                    className="w-8 h-10 flex justify-center items-center"
                    onClick={() => {
                      setShowPreview(true);
                    }}
                  >
                    <img
                      src="/mobile_preview.svg"
                      alt="mobile-preview-icon"
                    ></img>
                  </button>
                </div>
              </div>
            </>
          )} */}
        </div>
      </div>
    </div>
  );
};

export { SiteConfigStartGuide };

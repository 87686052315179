import { Customer, Purchases } from "./models/CustomerModel";
import { useGetCustomers } from "./requests/CustomersRequests";
import { CSVLink } from "react-csv";
import { PageContentLoader } from "../../layout/Loaders";
import { APIError } from "../../layout/Error";
// import { GoLiveButton } from "../../components/GoLiveButton";
import { useAppSelector } from "../../redux/hooks";
import UnlockFeature from "../../components/UnlockFeature";
import { upgradePlan } from "../../util/helpers";
import Button from "../../components/Button";
import Pagination from "../../components/Pagination";
import { useState } from "react";

interface ExportData {
  joined: string;
  name: string;
  products: string;
  reminderSent: number;
  reminderType: string;
  status: string;
}

const CustomersPage = () => {
  const businessId = useAppSelector((state) => state.business.businessId);
  const [query, setQuery] = useState(() => {
    try {
      return {
        page: 1,
        limit: JSON.parse(localStorage.getItem("pagination") || "{customer:10}")
          .customer,
      };
    } catch (error) {
      return {
        page: 1,
        limit: 10,
      };
    }
  });

  const {
    loading,
    error,
    refresh,
    data: customers,
  } = useGetCustomers(businessId, {
    page: query?.page || 1,
    limit: query?.limit || 10,
  });

  if (error) {
    return (
      <APIError
        error={error}
        consentText="Consent to reading Customers"
        refresh={refresh}
      />
    );
  }

  return (
    <>
      {loading && <PageContentLoader pageTitle="Customers" />}

      {!loading &&
        customers &&
        (customers?.customers?.length > 0 || customers.totalCount > 0 ? (
          <CustomersTable
            customersData={customers}
            setQuery={setQuery}
            query={query}
            refresh={refresh}
          />
        ) : (
          <div className="flex flex-col w-full">
            <div className="title flex justify-between mb-6">
              <div className="flex items-center">
                <h1 className="text-2xl text-black_shade font-medium mr-10">
                  Customers
                </h1>
              </div>
            </div>
            <div className="bg-white flex justify-center items-center p-6 rounded">
              <span>There are no records to display</span>
            </div>
          </div>
        ))}
    </>
  );
};

const CustomersTable = ({
  customersData,
  setQuery,
  query,
  refresh,
}: {
  customersData: Customer;
  setQuery: (x: { page: number; limit: number }) => void;
  query: { page: number; limit: number };
  refresh: (obj?: any) => void;
}) => {
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );

  const businessId = useAppSelector((state) => state.business.businessId);
  const customerEndpoint = `${process.env.REACT_APP_API_URL}/businesses/${businessId}/customers?`;

  let customerName: any = {};

  const rowSpan: any = customersData.customers.reduce(
    (result: any, { name }: any, key) => {
      if (customerName[name.name] === undefined) {
        customerName[name.name] = key;
        result[key] = 1;
      } else {
        const firstIndex = customerName[name.name];
        if (
          firstIndex === key - 1 ||
          (name.name === customersData.customers[key - 1]?.name?.name &&
            result[key - 1] === 0)
        ) {
          result[firstIndex]++;
          result[key] = 0;
        } else {
          result[key] = 1;
          customerName[name.name] = key;
        }
      }
      return result;
    },
    []
  );

  const rowData = customersData.customers?.reduce(
    (a: any, { name, purchases, joined, url, status, remType }: any, key) => {
      if (purchases.length) {
        const rowSpan = purchases?.length;
        let [firstCell]: { txt: any; rowSpan: number }[] | null[] = [name].map(
          (txt: any) => ({
            txt: txt.name,
            id: "name",
            class: "pl-8",
            rowSpan,
          })
        );
        purchases?.forEach((ele: Purchases, i: number) => {
          if (i > 0) {
            firstCell = null;
          }

          const row = [
            firstCell,
            { txt: ele.remType, id: "remType" },
            { txt: ele.remCount, id: "remCount" },
            { txt: ele.name, id: "product" },
            { txt: ele.status, id: "status" },
            { txt: ele.startDate, id: "joined" },
            {
              txt: "View profile",
              id: "view",
              class: "view-link text-blue_default cursor-pointer",
              url,
            },
          ];
          a.push(row);
        });
      } else {
        let [firstCell]: { txt: any; rowSpan: number }[] | null[] = [name].map(
          (txt: any) => ({
            txt: txt.name,
            id: "name",
            class: "pl-8",
            rowSpan: rowSpan[key],
          })
        );

        if (rowSpan[key] === 0) {
          firstCell = null;
        }

        const row = [
          firstCell,
          { txt: remType, id: "remType" },
          { txt: 0, id: "remCount" },
          { txt: "", id: "product" },
          { txt: status, id: "status" },
          { txt: "", id: "joined" },
          {
            txt: "View profile",
            id: "view",
            class: "view-link text-blue_default cursor-pointer",
            url,
          },
        ];
        a.push(row);
      }

      return a;
    },
    []
  );
  const headers = [
    { label: "Name", key: "name" },
    { label: "Reminder Type", key: "reminderType" },
    { label: "Reminders Sent", key: "reminderSent" },
    { label: "Product", key: "products" },
    { label: "Status", key: "status" },
    { label: "Start Date", key: "joined" },
  ];

  const getExportData = () => {
    let exportData: ExportData[] = [];
    if (customersData?.customers.length > 0) {
      customersData?.customers?.forEach((obj) => {
        obj?.purchases.length > 0 &&
          obj?.purchases?.forEach((items: Purchases) => {
            exportData.push({
              name: obj?.name?.name,
              reminderType: items?.remType,
              reminderSent: items?.remCount,
              products: items?.name,
              status: items?.status,
              joined: items?.startDate,
            });
          });
      });
    }
    return exportData;
  };

  const onPageChanged = (page: number) => {
    setQuery({ ...query, page });
    refetchCustomer({ page: page, limit: query.limit });
  };

  const totalPageCount = () => {
    return Math.ceil(customersData?.totalCount / query.limit);
  };

  const setDataRowPerPage = (page: number) => {
    const data = localStorage.getItem("pagination");
    if (data) {
      const cutomerPage = JSON.parse(data);
      localStorage.setItem(
        "pagination",
        JSON.stringify({ ...cutomerPage, customer: page })
      );
      setQuery({ page: 1, limit: page });
      refetchCustomer({ page: 1, limit: page });
    } else {
      localStorage.setItem("pagination", JSON.stringify({ customer: page }));
      setQuery({ page: 1, limit: page });
      refetchCustomer({ page: 1, limit: page });
    }
  };

  const refetchCustomer = (obj: { page: number; limit: number }) => {
    const newObj = {
      page: obj.page,
      limit: obj.limit,
    };
    let qs = "";
    if (query) {
      qs = new URLSearchParams(newObj as any).toString();
    }
    refresh({ url: `${customerEndpoint}${qs}` });
  };

  return (
    <>
      {!upgradePlan(permission, "customers") ? (
        <>
          <h1 className="text-2xl text-black_shade font-medium mr-10">
            Customers
          </h1>
          <UnlockFeature subscription={subscription} />
        </>
      ) : (
        <div className="flex flex-col w-full">
          <div className="title flex justify-between mb-6">
            <div className="flex items-center">
              <h1 className="text-2xl text-black_shade font-medium mr-10">
                Customers
              </h1>
              <CSVLink
                data={getExportData()}
                headers={headers}
                filename="customers"
              >
                <Button
                  text="Export CSV"
                  className="h-10 px-4 text-blue_default text-xs addproduct-button flex items-center justify-center"
                  icon={
                    <img
                      className="mr-2"
                      src="/svg/export-blue.svg"
                      alt="back arrow"
                    ></img>
                  }
                />
              </CSVLink>
            </div>
            {/* <div className="flex gap-2">
              <GoLiveButton />
            </div> */}
          </div>
          <div className="table-container table-customers">
            <table className="w-full rounded bg-white border-b-2 border-light-grey text-xs !leading-[18px] text-black_shade table-auto tracking-tracking_001">
              <thead>
                <tr className="font-medium">
                  <th className="border-b border-1 border-light-grey font-medium py-5 pl-8 pr-4 text-left w-[225px]">
                    Name
                  </th>
                  <th className="border-b border-1 border-light-grey font-medium py-5 pl-7 pr-4 text-left">
                    Reminder Type
                  </th>
                  <th className="border-b border-1 border-light-grey font-medium py-5 pl-7 pr-4 text-left">
                    Reminders Sent
                  </th>
                  <th className="border-b border-1 border-light-grey font-medium py-5 pl-7 pr-4 text-left w-[275px]">
                    Products
                  </th>
                  <th className="border-b border-1 border-light-grey font-medium py-5 pl-7 pr-4 text-left ">
                    Status
                  </th>
                  <th className="border-b border-1 border-light-grey font-medium py-5 pl-7 pr-4 text-left ">
                    Start Date
                  </th>
                  <th className="border-b border-1 border-light-grey font-medium py-5 pl-7 pr-4 text-left"></th>
                </tr>
              </thead>
              <tbody>
                {rowData.map((cells: any, i: number) => (
                  <tr key={i} className="pl-8">
                    {cells.map(
                      (cell: any, j: number) =>
                        cell && (
                          <td
                            key={`${i}-${j}`}
                            rowSpan={
                              cell.rowSpan > 0 ? cell.rowSpan : undefined
                            }
                            id={cell.id}
                            className={`border-b border-1 border-light-grey
                        } font-normal py-[18px] pl-7 pr-4 text-left ${
                          cell.class ? cell.class : ""
                        }`}
                            onClick={() => {
                              cell.url && window.open(cell.url);
                            }}
                          >
                            <div className="row-info">{cell.txt}</div>
                          </td>
                        )
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              totalPages={totalPageCount()}
              currentPage={query.page}
              limit={query.limit}
              totalCount={customersData?.totalCount}
              onPageChange={(e) => onPageChanged(e)}
              onChangeRecordsPerPage={(e) => setDataRowPerPage(e)}
            />
          </div>
        </div>
      )}
    </>
  );
};

export { CustomersPage };

import { ModalContainer } from "../components/ModalContainer";

export const NotificationModel = ({
  isNotificationModalOpen,
  setIsNotificationModalOpen,
}: {
  isNotificationModalOpen: boolean;
  setIsNotificationModalOpen: (value: boolean) => void;
}) => {
  return (
    <>
      <div className="z-50">
        <ModalContainer
          show={isNotificationModalOpen}
          onClose={() => setIsNotificationModalOpen(false)}
        >
          <div className="py-6 px-6 text-xs text-dark-grey font-medium border-b border-light-grey">
            <img
              src="/Notification.svg"
              alt="Notification"
              className="inline pr-2"
            />
            Notification
          </div>
          <div className="form-wrap p-8 flex flex-col h-96 w-full">
            <p className="mb-5 pt-1 text-10">No message</p>
          </div>
        </ModalContainer>
      </div>
    </>
  );
};

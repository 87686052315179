import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Modal, Button } from "flowbite-react";
import ResetFormInput from "./ResetFormInput";

library.add(faChevronRight);

const ResetPasswordModal = (props: any) => {
  return (
    <div className="login-modal">
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        show={true}
        size="md"
        close={false}
      >
        <Modal.Header className="text-center">
          <div className="login-modal-title">
            <img src="https://i.ibb.co/ZBtnrdw/company-logo.png" alt="" />
            <h6>Reset Password</h6>
          </div>
        </Modal.Header>
        <Modal.Body className="space-y-6 p-6">
          <ResetFormInput />
        </Modal.Body>
        <Modal.Footer className="modal-footer">
          <Button className="login-button" form="reset-form" type="submit">
            Reset Password
            <FontAwesomeIcon className="login-icon pl-3" icon="chevron-right" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResetPasswordModal;

import { useCallback, useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { Mention, MentionsInput } from "react-mentions";
import UnlockFeature from "../../../components/UnlockFeature";
import { useAppSelector } from "../../../redux/hooks";
import { upgradePlan } from "../../../util/helpers";
import Select from "react-select";
import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";
import WarningModal from "./WarningModal";
import { Template } from "../models/Customization";
import { useDispatch } from "react-redux";
import {
  setSelectedEmailTemlate,
  setSelectedSmsTemplate,
} from "../../../redux/ducks/template.duck";

export const ReminderTemplate = ({
  selectedTemplate,
  setSelectedTemplate,
  setFieldValue,
  setChangeTemplate,
  setSmsTemplate,
  smsTemplate,
  checkTempChange,
  cancelChanges,
}: {
  selectedTemplate: Template;
  smsTemplate: Template;
  setSelectedTemplate: (value: Template) => void;
  setFieldValue: (fieldName: string, value: any) => void;
  setChangeTemplate: (flag: boolean) => void;
  setSmsTemplate: (smsTemplate: Template) => void;
  checkTempChange: { sms: boolean; email: boolean };
  cancelChanges: boolean;
}) => {
  const dispatch = useDispatch();
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );
  const { templates, selectedEmail, selectedSms }: any = useAppSelector(
    (state) => state.templates
  );
  const [selectEmailTab, setSelectEmailTab] = useState("no_discount");
  const [selectSmsTab, setSelectSmsTab] = useState("no_discount");
  const [isShowEditor, setIsShowEditor] = useState(false);
  const siteConfig: SiteConfiguration = useAppSelector(
    (state) => state.site.siteConfiguration
  );
  const [isChangeTemplate, setIsChangeTemplate] = useState({
    status: false,
    type: "sms",
  });

  const mentionsOption = [
    {
      id: "1",
      display: "store_name",
    },
    {
      id: "2",
      display: "first_name",
    },
    {
      id: "3",
      display: "personalized_reorder_link",
    },
    {
      id: "4",
      display: "personalized_reschedule_link",
    },
  ];

  const renderUserSuggestion = (entry: any) => {
    return (
      <div className="font-AvenirLTPro_Medium text-xs">
        <span>{entry.display}</span>
      </div>
    );
  };

  const updateTemplate = () => {
    if (isChangeTemplate.type === "sms") {
      setSmsTemplate(selectedSms);
    } else {
      setSelectedTemplate(selectedEmail);
      setChangeTemplate(false);
      setIsShowEditor(false);
    }
    setIsChangeTemplate({ ...isChangeTemplate, status: false });
  };

  useEffect(() => {
    if (cancelChanges) {
      if (checkTempChange.email) {
        setIsShowEditor(false);
        setIsChangeTemplate({ ...isChangeTemplate, status: false });
      }
    }
    // eslint-disable-next-line
  }, [cancelChanges]);

  useEffect(() => {
    if (!isShowEditor) {
      setTimeout(() => {
        setIsShowEditor(true);
      }, 500);
    }
  }, [isShowEditor]);

  return (
    <>
      {!upgradePlan(permission, "customization > reminder_template") ? (
        <UnlockFeature subscription={subscription} />
      ) : (
        <>
          <div className="mt-8 px-6 w-full h-full bg-white rounded-lg">
            <div className="message-type-wrapper py-6 border-b border-light-grey">
              <div className="flex justify-between flex-row">
                <div className="flex flex-col">
                  <p className="tracking-tracking_001 text-base text-black_shade font-medium mb-1">
                    Customize SMS reminder
                  </p>
                  <p className="text-10 tracking-tracking_001 text-dark-grey text-opacity-75 w-[478px]">
                    Customize the recurring SMS reminders sent to your customers
                  </p>
                </div>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,
                      boxShadow: "none",
                    }),
                  }}
                  onChange={(e: any) => {
                    if (checkTempChange.sms) {
                      setIsChangeTemplate({ type: "sms", status: true });
                    } else {
                      setSmsTemplate(e);
                    }
                    dispatch(setSelectedSmsTemplate(e));
                    // setFieldValue("templateType", e?.templateType);
                  }}
                  value={smsTemplate ? smsTemplate : {}}
                  getOptionValue={(item: any) => item.id}
                  getOptionLabel={(item: any) => {
                    const rmSms = item?.templateType.replace("sms_", "");
                    const templateName = (
                      rmSms.charAt(0).toUpperCase() + rmSms.slice(1)
                    ).replaceAll("_", " ");
                    return `SMS reminder (${templateName})`;
                  }}
                  options={templates?.filter((item: Template) =>
                    item?.templateType?.includes("sms")
                  )}
                  placeholder="Replenishment cycles"
                  isSearchable={false}
                  className="rounded w-[316px] border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50 select-48"
                />
              </div>
            </div>
            <div className="w-[448px] pt-9">
              <div className="">
                <div className="border-b-[1px] border-[#EBEFF2] max-w-[301px] mb-7">
                  <ul className="flex flex-wrap justify-center -mb-px font-normal text-center relative -bottom-[1px]">
                    <li
                      onClick={() => {
                        setSelectSmsTab("no_discount");
                      }}
                      className={`tab cursor-pointer mr-7 py-1 px-2.5 text-xs tracking-wide_0.01 ${
                        selectSmsTab === "no_discount"
                          ? "active font-medium text-blue_default border-b-4 border-blue_default"
                          : " text-black_33/50"
                      }`}
                    >
                      No Discount
                    </li>
                    <li
                      onClick={() => {
                        setSelectSmsTab("discount");
                      }}
                      className={`tab cursor-pointer  py-1 px-2.5 text-xs tracking-wide_0.01 ${
                        selectSmsTab === "discount"
                          ? "active font-medium text-blue_default border-b-4 border-blue_default"
                          : " text-black_33/50"
                      }`}
                    >
                      With Discount
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="flex justify-between text-xs pb-11">
              <div className="w-[274px] h-[368px] rounded-[10px] px-3 py-4 border border-border_gray">
                {smsTemplate?.templateType !== "sms_multiple_template" && (
                  <img
                    className="w-[124px] h-[88px] rounded-[10px] bg-[#F6FAFC] object-cover"
                    alt=""
                    src="https://www.ilsmart.com/hubfs/default-placeholder-image.png"
                  ></img>
                )}
                <div className="pt-3">
                  <MentionsInput
                    value={
                      selectSmsTab === "no_discount"
                        ? smsTemplate?.smsReminderTemplateWithNoDiscount || ""
                        : smsTemplate?.smsReminderTemplateWithDiscount || ""
                    }
                    placeholder="Set reminders to never run out"
                    className={`mentions rounded-[10px] ${
                      smsTemplate?.templateType !== "sms_multiple_template"
                        ? "h-[220px]"
                        : "h-72"
                    }  font-AvenirLTPro_Medium text-[#333333] leading-[19px] text-xs`}
                    onChange={(event) => {
                      setSmsTemplate(
                        selectSmsTab === "no_discount"
                          ? {
                              ...smsTemplate,
                              smsReminderTemplateWithNoDiscount:
                                event?.target?.value,
                            }
                          : {
                              ...smsTemplate,
                              smsReminderTemplateWithDiscount:
                                event?.target?.value,
                            }
                      );
                    }}
                  >
                    <Mention
                      trigger="{{"
                      data={
                        selectSmsTab === "no_discount"
                          ? mentionsOption
                          : [
                              ...mentionsOption,
                              {
                                id: "5",
                                display: "discount",
                              },
                            ]
                      }
                      markup="#__display__#"
                      displayTransform={(id, display) => `{{${display}}}`}
                      renderSuggestion={renderUserSuggestion}
                    />
                  </MentionsInput>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-[23px] pb-[126px] px-6 w-full h-full mb-7 bg-white rounded-lg">
            <div className="message-type-wrapper py-5 border-b border-light-grey">
              <div className="flex justify-between flex-row">
                <div className="flex flex-col">
                  <p className="tracking-tracking_001 text-base text-black_shade font-medium mb-1">
                    Customize Email
                  </p>
                  <p className="text-10 tracking-tracking_001 text-dark-grey text-opacity-75 w-[478px]">
                    Customize the email reminders sent to your customers
                  </p>
                </div>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,
                      boxShadow: "none",
                    }),
                  }}
                  onChange={(e: any) => {
                    if (checkTempChange.email) {
                      setIsChangeTemplate({ type: "email", status: true });
                    } else {
                      setIsShowEditor(false);
                      setSelectedTemplate(e);
                    }
                    dispatch(setSelectedEmailTemlate(e));
                    // setFieldValue("templateType", e?.templateType);
                  }}
                  value={selectedTemplate ? selectedTemplate : {}}
                  getOptionValue={(item: any) => item.id}
                  getOptionLabel={(item: any) => {
                    const templateType = (
                      item?.templateType.charAt(0).toUpperCase() +
                      item?.templateType.slice(1)
                    ).replaceAll("_", " ");
                    return `Reminder Email (${templateType})`;
                  }}
                  options={templates?.filter(
                    (item: Template) => !item?.templateType?.includes("sms")
                  )}
                  placeholder="Replenishment cycles"
                  isSearchable={false}
                  className="rounded w-[316px] border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50 select-48"
                  // components={{ ValueContainer: SelectValueContainer }}
                  //   onChange={(e) =>
                  //     setReminderDelivery({
                  //       ...remindDeliver,
                  //       smsReminder: e?.label,
                  //     })
                  //   }
                />
              </div>
            </div>
            <div className="w-[448px] pt-9">
              <div className="">
                <div className="border-b-[1px] border-[#EBEFF2] max-w-[301px] mb-7">
                  <ul className="flex flex-wrap justify-center -mb-px font-normal text-center relative -bottom-[1px]">
                    <li
                      onClick={() => {
                        setSelectEmailTab("no_discount");
                        setIsShowEditor(false);
                      }}
                      className={`tab cursor-pointer mr-7 py-1 px-2.5 text-xs tracking-wide_0.01 ${
                        selectEmailTab === "no_discount"
                          ? "active font-medium text-blue_default border-b-4 border-blue_default"
                          : " text-black_33/50"
                      }`}
                    >
                      No Discount
                    </li>
                    <li
                      onClick={() => {
                        setSelectEmailTab("discount");
                        setIsShowEditor(false);
                      }}
                      className={`tab cursor-pointer  py-1 px-2.5 text-xs tracking-wide_0.01 ${
                        selectEmailTab === "discount"
                          ? "active font-medium text-blue_default border-b-4 border-blue_default"
                          : " text-black_33/50"
                      }`}
                    >
                      With Discount
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {selectedTemplate &&
              templates?.length > 0 &&
              (selectEmailTab === "discount" ? (
                <EmailTemplate
                  subject={selectedTemplate?.emailWithDiscountSubject}
                  preHeader={selectedTemplate?.emailWithDiscountPreheader}
                  emailBodyJson={selectedTemplate?.emailWithDiscountJsonBody}
                  type="discount"
                  setFieldValue={setFieldValue}
                  isShowEditor={isShowEditor}
                  setChangeTemplate={setChangeTemplate}
                  siteConfig={siteConfig}
                />
              ) : (
                <EmailTemplate
                  subject={selectedTemplate?.emailNoDiscountSubject}
                  preHeader={selectedTemplate?.emailNoDiscountPreheader}
                  emailBodyJson={selectedTemplate?.emailWithNoDiscountJsonBody}
                  type="no_discount"
                  setFieldValue={setFieldValue}
                  isShowEditor={isShowEditor}
                  setChangeTemplate={setChangeTemplate}
                  siteConfig={siteConfig}
                />
              ))}
          </div>
          <WarningModal
            show={isChangeTemplate.status}
            onClose={() =>
              setIsChangeTemplate({ ...isChangeTemplate, status: false })
            }
            onSubmit={updateTemplate}
            message="You have some unsaved changes, are you sure you want to continue?"
          />
        </>
      )}
    </>
  );
};

const EmailTemplate = ({
  subject,
  preHeader,
  emailBodyJson,
  type,
  setFieldValue,
  isShowEditor,
  setChangeTemplate,
  siteConfig,
}: {
  subject: string;
  preHeader: string;
  emailBodyJson: any;
  type: string;
  setFieldValue: (name: string, value: any) => void;
  isShowEditor: boolean;
  setChangeTemplate: (flag: boolean) => void;
  siteConfig: SiteConfiguration;
}) => {
  const emailEditorRef: any = useRef(null);
  const renderUserSuggestion = (entry: any) => {
    return (
      <div className="font-AvenirLTPro_Medium text-xs">
        <span>{entry.display}</span>
      </div>
    );
  };

  const onLoadEditor = useCallback(() => {
    const timer = setInterval(function () {
      if (emailEditorRef?.current) {
        const emailEditor = emailEditorRef?.current as any;
        // emailEditor.loadDesign(JSON.parse(emailBodyJson));
        // emailEditor.loadDesign(JSON.parse(templateJSON));
        // emailEditor.loadDesign(templateJSON);
        // console.log((emailBodyJson.match(/#085C3F/g) || []).length, (emailBodyJson.match(/#085c3f/g) || []).length);
        // console.log((emailBodyJson.match(/#085C3F/g) || []).length);
        // const primaryReplaced = emailBodyJson
        //   .replaceAll("#085C3F", siteConfig.primaryColor)
        //   .replaceAll("#085c3f", siteConfig.primaryColor);
        // const secondaryReplaced = primaryReplaced
        //   .replaceAll("#F5FAF5", siteConfig.secondaryColor)
        //   .replaceAll("#f5faf5", siteConfig.secondaryColor);

        // const primaryReplaced = JSON.stringify(templateJSON)
        const primaryReplaced = emailBodyJson
          .replace(new RegExp("#085C3F", "g"), siteConfig.primaryColor)
          .replace(new RegExp("#085c3f", "g"), siteConfig.primaryColor);

        const secondaryReplaced = primaryReplaced
          .replace(/#F5FAF5/g, siteConfig.secondaryColor)
          .replace(/#f5faf5/g, siteConfig.secondaryColor);

        emailEditor.loadDesign(JSON.parse(secondaryReplaced));
        clearInterval(timer);
      }
    }, 500);
    // eslint-disable-next-line
  }, [emailEditorRef, emailBodyJson]);

  const onReady = () => {
    if (emailEditorRef && emailEditorRef?.current) {
      const emailEditor = emailEditorRef.current;
      emailEditor.addEventListener("design:updated", function (updates: any) {
        // Design is updated by the user
        emailEditor.exportHtml(function (data: any) {
          var json = data.design; // design json
          var html = data.html; // design html

          setFieldValue(
            type === "no_discount"
              ? "emailNoDiscountBody"
              : "emailWithDiscountBody",
            html
          );

          setTimeout(() => {
            let jsonTemplate = JSON.stringify(json);
            jsonTemplate = jsonTemplate.replaceAll(
              siteConfig.primaryColor,
              "#085C3F"
            );
            jsonTemplate = jsonTemplate.replaceAll(
              siteConfig.secondaryColor,
              "#F5FAF5"
            );

            setFieldValue(
              type === "no_discount"
                ? "emailWithNoDiscountJsonBody"
                : "emailWithDiscountJsonBody",
              jsonTemplate
            );
          }, 500);
          setChangeTemplate(true);
        });
      });
    }
  };

  return (
    <div className="">
      <div className="w-full">
        <span className="text-xs tracking-tracking_001 font-normal text-black_shade mr-4 xl:mb-0 min-w-[140px]">
          Subject:
        </span>
        <div className="flex justify-between text-xs  pt-2">
          <div className="w-full mb-5">
            {type === "no_discount" ? (
              <textarea
                className="rounded-[10px] font-AvenirLTPro_Light border border-border_gray text-[#333333] w-[301px] h-[61px] px-3 py-4 leading-[14px] text-xs font-normal"
                // placeholder="Set reminders to never run out"
                name="body"
                value={subject || ""}
                onChange={(event) =>
                  setFieldValue("emailNoDiscountSubject", event.target.value)
                }
              ></textarea>
            ) : (
              <MentionsInput
                value={subject || ""}
                placeholder="Set reminders to never run out"
                className="mentions rounded-[10px] font-AvenirLTPro_Medium border border-border_gray text-[#333333] w-[301px] h-[66px] leading-[19px] text-xs"
                onChange={(event) =>
                  setFieldValue("emailWithDiscountSubject", event.target.value)
                }
              >
                <Mention
                  trigger="{{"
                  data={[
                    {
                      id: "1",
                      display: "DISCOUNT",
                    },
                  ]}
                  markup="#__display__#"
                  displayTransform={(id, display) => `{{${display}}}`}
                  renderSuggestion={renderUserSuggestion}
                />
              </MentionsInput>
            )}
          </div>
        </div>
      </div>
      <div className="">
        <span className="text-xs tracking-tracking_001 font-normal text-black_shade mr-4 xl:mb-0 min-w-[140px]">
          Preheader:
        </span>
        <div className="flex justify-between text-xs pt-2">
          <div className="mb-5 w-full">
            {type === "no_discount" ? (
              <textarea
                className="rounded-[10px] font-AvenirLTPro_Light w-[301px] h-[57px] border border-border_gray px-3 py-4 text-xs leading-[14px] font-[350] text-opacity-50 tracking-wide"
                // placeholder="We will get your order ready and send you a reminder. You confirm it with one tap. No need to enter your information again."
                rows={7}
                name="body"
                value={preHeader || ""}
                onChange={(event) =>
                  setFieldValue("emailNoDiscountPreheader", event.target.value)
                }
              ></textarea>
            ) : (
              <MentionsInput
                value={preHeader || ""}
                placeholder="Set reminders to never run out"
                className="mentions rounded-[10px] font-AvenirLTPro_Medium border border-border_gray text-[#333333] w-[301px] h-[66px] leading-[19px] text-xs"
                onChange={(event) =>
                  setFieldValue(
                    "emailWithDiscountPreheader",
                    event.target.value
                  )
                }
              >
                <Mention
                  trigger="{{"
                  data={[
                    {
                      id: "1",
                      display: "DISCOUNT",
                    },
                  ]}
                  markup="#__display__#"
                  displayTransform={(id, display) => `{{${display}}}`}
                  renderSuggestion={renderUserSuggestion}
                />
              </MentionsInput>
            )}
          </div>
        </div>
      </div>
      <div className="body-mail mt-10">
        <span className="text-xs tracking-tracking_001 font-normal block text-black_shade mr-4 xl:mb-0 pb-2">
          Body:
        </span>
        <div className="flex h-[600px] pt-[10px]">
          {isShowEditor && (
            <EmailEditor
              onLoad={onLoadEditor}
              appearance={{
                panels: {
                  tools: {
                    dock: "left",
                  },
                },
              }}
              tools={{
                "custom#product": {
                  properties: {
                    productName: { value: "{{PRODUCTNAME}}" },
                    productImg: {
                      value:
                        "https://www.ilsmart.com/hubfs/default-placeholder-image.png",
                    },
                    bubbleColor: { value: siteConfig?.primaryColor },
                  },
                },
                html: { enabled: true },
              }}
              projectId={142653}
              // projectId={1}
              options={{
                displayMode: "email",
                customJS: [
                  window.location.protocol +
                    "//" +
                    window.location.host +
                    "/unlayer/custom.js",
                ],
              }}
              onReady={onReady}
              ref={emailEditorRef}
            />
          )}
        </div>
      </div>
    </div>
  );
};

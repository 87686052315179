import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AdminProtectedContainer } from "../../components/RoleProtectedContainer";
import { APIError } from "../../layout/Error";
import { PageContentLoader } from "../../layout/Loaders";
import { useAppSelector } from "../../redux/hooks";
import DeleteProductModal from "../smart_reminders/components/DeleteProductModal";
import { Switch } from "../smart_reminders/components/Switch";
import { ProductPageConfiguration } from "../smart_reminders/models/ProductPageConfigurationModel";
import {
  ProductEnumType,
  updateProductPageConfiguration,
  useGetProductPageConfigurations,
} from "../smart_reminders/requests/ProductPageConfigurationRequests";
export interface SelectCheckoutFields {
  selectProductType: string;
  product: number[] | null;
}

export interface DeletedFields {
  status: boolean;
  product: ProductPageConfiguration | null;
}

const SmartCheckoutsPage = () => {
  const businessId = useAppSelector((state) => state.business.businessId);
  const {
    loading,
    error,
    refresh,
    data: products,
  } = useGetProductPageConfigurations(businessId, ProductEnumType.SMART);
  const [slugSwitch, setSlugSwitch] = useState<any>([]);
  const [isDelete, setIsDelete] = useState<DeletedFields>({
    status: false,
    product: null,
  });
  useState<SelectCheckoutFields>({
    selectProductType: "static",
    product: null,
  });

  useEffect(() => {
    if (products) {
      const switchProduct: any = products.map((obj) => ({
        [obj.id]: obj.isActive,
      }));
      setSlugSwitch(switchProduct);
    }
  }, [products]);

  if (error) {
    return (
      <APIError
        error={error}
        consentText="Consent to reading Products"
        refresh={refresh}
      />
    );
  }

  const updateSmartCheckout = async (id: number, value: boolean) => {
    await updateProductPageConfiguration(id, {
      configurationType: ProductEnumType.SMART,
      isActive: value,
    });
  };

  return (
    <>
      {loading && !products ? (
        <PageContentLoader pageTitle="Smart Checkouts" />
      ) : (
        <div className="flex flex-col w-full">
          {/* TODO: change this to flex row adn update according to design */}
          <div className="title flex justify-between py-4 mb-6">
            <div className="flex items-center">
              <h1 className="text-2xl text-black_shade font-medium pr-[40px]">
                Smart Checkouts
              </h1>
            </div>
          </div>
          <div>
            <div className="pb-8 w-full mb-7 bg-white rounded-lg">
              {products?.map(
                (product: ProductPageConfiguration, index: number) => (
                  <div
                    className="border-b border-light-grey pl-7 pb-[21px] pt-[25px]"
                    key={index}
                  >
                    <div className="flex flex-row w-full items-center">
                      <div className="mr-4">
                        <Switch
                          key={product.id}
                          state={slugSwitch?.[index]?.[product?.id]}
                          onClick={() => {
                            updateSmartCheckout(
                              product.id,
                              !slugSwitch[index][product?.id]
                            );
                            setSlugSwitch(
                              slugSwitch?.map((item: any) =>
                                Number(Object.keys(item)[0]) ===
                                Number(product?.id)
                                  ? {
                                      [product?.id]:
                                        !slugSwitch?.[index]?.[product?.id],
                                    }
                                  : item
                              )
                            );
                          }}
                        />
                      </div>
                      <Link
                        className="cursor-pointer text-xs tracking-tracking_001 font-medium"
                        to={`/smart-checkouts/edit/${product?.id}`}
                      >
                        <span>{product?.slug}</span>
                      </Link>
                      <AdminProtectedContainer>
                        <button
                          onClick={() =>
                            setIsDelete({
                              status: true,
                              product: product,
                            })
                          }
                          className="absolute right-20"
                        >
                          <img
                            className="cursor-pointer"
                            src="/delete-icon.svg"
                            alt="close button"
                          ></img>
                        </button>
                      </AdminProtectedContainer>
                    </div>
                  </div>
                )
              )}
              <Link
                className="text-blue_default text-xs tracking-tracking_001 pt-8 pl-7 flex items-center"
                to="/smart-checkouts/add"
              >
                Add a checkout
              </Link>
            </div>
          </div>
          {isDelete.product && isDelete.status && (
            <DeleteProductModal
              show={isDelete.status}
              onClose={() => {
                setIsDelete({ status: false, product: null });
                refresh();
              }}
              mainProduct={isDelete.product}
              type={ProductEnumType.SMART}
            />
          )}
        </div>
      )}
    </>
  );
};

export default SmartCheckoutsPage;

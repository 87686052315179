import Select from "react-select";
import { quantityOptions } from "../constants/options";

const MinPurchase = ({
  discountOption,
  setDiscountValue,
  minPurchaseoptions,
  shipMinPurchase,
  errors,
  setError,
}: {
  discountOption: any;
  setDiscountValue: (
    fieldName: string,
    value: string | boolean | number
  ) => void;
  minPurchaseoptions: { [key: string]: string | number }[];
  shipMinPurchase: boolean;
  errors: any;
  setError: (error: { [key: string]: string }) => void;
}) => {
  const minOptionValueChange = () => {
    if (shipMinPurchase) {
      return discountOption?.discountShipMinPurchaseType === "ship_quantity";
    }
    return discountOption?.discountMinPurchaseType === "quantity";
  };

  const checkError = () => {
    if (shipMinPurchase) {
      return errors?.discountShipMinPurchaseAmount !== "";
    }
    return errors?.discountMinPurchaseAmount !== "";
  };
  return (
    <div className="flex items-center w-1/2 flex-wrap">
      <span className="text-xs font-normal tracking-tracking_001 text-black_shade">
        Min purchase:
      </span>
      <div className="flex">
        <div className="px-5 dropdown-select-wrap">
          <div className="w-[137px]">
            <Select
              styles={{
                control: (base) => ({
                  ...base,
                  height: "48px",
                  boxShadow: "none",
                }),
                placeholder: (base) => ({
                  ...base,
                  height: "44px",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  zIndex: 0,
                  fontFamily: "AvenirLTPro-Black",
                  justifyContent: "center",
                  fontSize: "12px",
                  alignItems: "start",
                  letterSpacing: "0.4px",
                }),
                valueContainer: (base) => ({
                  ...base,
                  height: "48px",
                  textAlign: "center",
                }),
              }}
              value={minPurchaseoptions?.filter(
                (option) =>
                  option.value ===
                  (shipMinPurchase
                    ? discountOption?.discountShipMinPurchaseType
                    : discountOption?.discountMinPurchaseType)
              )}
              options={minPurchaseoptions}
              isSearchable={false}
              className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
              onChange={(e: any) => {
                if (shipMinPurchase) {
                  setError({ discountShipMinPurchaseAmount: "" });
                } else {
                  setError({ discountMinPurchaseAmount: "" });
                }
                setDiscountValue(
                  shipMinPurchase
                    ? "discountShipMinPurchaseType"
                    : "discountMinPurchaseType",
                  e?.value
                );
              }}
            />
          </div>
        </div>
        {minOptionValueChange() ? (
          <div className="dropdown-select-wrap">
            <div className="w-[137px]">
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    boxShadow: "none",
                    height: "48px",
                  }),
                  placeholder: (base) => ({
                    ...base,
                    height: "44px",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    zIndex: 0,
                    fontFamily: "AvenirLTPro-Black",
                    justifyContent: "center",
                    fontSize: "12px",
                    alignItems: "start",
                    letterSpacing: "0.4px",
                  }),
                  valueContainer: (base) => ({
                    ...base,
                    height: "48px",
                    paddingLeft: "20px",
                  }),
                }}
                value={quantityOptions()?.filter(
                  (option) =>
                    option.value ===
                    (shipMinPurchase
                      ? discountOption?.discountShipMinPurchaseQty
                      : discountOption?.discountMinPurchaseQty)
                )}
                options={quantityOptions()}
                isSearchable={false}
                className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
                onChange={(e: any) => {
                  setDiscountValue(
                    shipMinPurchase
                      ? "discountShipMinPurchaseQty"
                      : "discountMinPurchaseQty",
                    Number(e?.value)
                  );
                }}
              />
            </div>
          </div>
        ) : (
          <>
            <input
              type="text"
              className={`w-9 !py-[15px] px-1 border border-r-0 text-xs text-center rounded-l bg-gray-100 text-gray-500`}
              disabled
              value="$"
            />
            <input
              className={`text-gray-900 w-[100px] rounded-r border border-l-0 text-xs  tracking-tracking_001 bg-white focus:ring-0 !py-[15px] px-3 block dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none`}
              inputMode="numeric"
              type={"text"}
              value={
                shipMinPurchase
                  ? discountOption?.discountShipMinPurchaseAmount
                  : discountOption?.discountMinPurchaseAmount
              }
              onChange={(e) => {
                setDiscountValue(
                  shipMinPurchase
                    ? "discountShipMinPurchaseAmount"
                    : "discountMinPurchaseAmount",
                  !isNaN(Number(e?.target?.value))
                    ? Number(e?.target?.value)
                    : 0
                );
              }}
            />
          </>
        )}
      </div>
      {checkError() && (
        <p className="text-danger pt-1 text-red-500 text-xs text-left w-full mt-1 pl-[104px]">
          {shipMinPurchase
            ? errors?.discountShipMinPurchaseAmount
            : errors?.discountMinPurchaseAmount}
        </p>
      )}
    </div>
  );
};
export default MinPurchase;

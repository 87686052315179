import { useEffect, useState } from "react";
import { AdminProtectedContainer } from "../../../components/RoleProtectedContainer";
import { QueryObject } from "../../smart_reminders/models/ProductPageConfigurationModel";
import { ShopifyProduct } from "../../smart_reminders/models/ShopifyProduct";
import { useGetShopifyProducts } from "../../smart_reminders/requests/ShopifyProductRequests";
import { AddProduct } from "../../smart_reminders/components/AddProduct";
import Select from "react-select";
import { SelectProduct } from "../models/SmartCheckout";

const configOptions = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
    // subTitle: "(recommended)",
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
];

const SelectCheckoutProduct = ({
  selectProductFields,
  setSelectProductFields,
  setSelectProduct,
  selectProduct,
  staticProductError,
  setStaticProductError,
  selectUpsellProducts,
  setSelectProductDelete,
  selectProductDelete,
  selectProductAdd,
  setSelectProductAdd,
}: {
  selectProductFields: string;
  setSelectProductFields: (data: string) => void;
  setSelectProduct: React.Dispatch<React.SetStateAction<SelectProduct[]>>;
  selectProduct: SelectProduct[];
  staticProductError: boolean;
  setStaticProductError: (flag: boolean) => void;
  selectUpsellProducts?: ShopifyProduct[];
  setSelectProductDelete: (data: SelectProduct[]) => void;
  selectProductDelete: SelectProduct[];
  setSelectProductAdd: (data: SelectProduct[]) => void;
  selectProductAdd: SelectProduct[];
}) => {
  const [queryObj, setQueryObj] = useState<QueryObject>({
    has_next_page: false,
    next_page_info: "",
    search: "",
  });

  const {
    loading: shopifyLoading,
    refresh: shopifyRefresh,
    data: shopifyProducts,
  } = useGetShopifyProducts({
    search: queryObj.search,
    next_page_info: queryObj.next_page_info,
  });

  //eslint-disable-next-line
  const [isLoadMore, setIsLoadMore] = useState<Boolean>(false);
  const [search, setSearch] = useState("");
  const [showProductModal, setShowProductModal] = useState(false);
  const [shopifyProductList, setShopifyProductList] = useState<
    ShopifyProduct[] | null
  >(null);

  useEffect(() => {
    if (shopifyProducts) {
      let tempProducts: ShopifyProduct[];
      if (selectUpsellProducts && selectUpsellProducts?.length > 0) {
        tempProducts = [...shopifyProducts.products].filter(
          (item) =>
            !selectUpsellProducts.some(
              (upsellItem) => upsellItem.productId === item.productId
            )
        );
      } else {
        tempProducts = [...shopifyProducts.products];
      }
      // let tempProducts: ShopifyProduct[] = [...shopifyProducts.products];
      if (shopifyProductList) {
        if (isLoadMore) {
          tempProducts = [...shopifyProductList, ...shopifyProducts.products];
        }
      }
      // removing main product from upsell products list
      setShopifyProductList(tempProducts);
      setQueryObj({
        ...queryObj,
        next_page_info: shopifyProducts ? shopifyProducts.nextPageInfo : "",
        has_next_page: shopifyProducts ? shopifyProducts.hasNextPage : false,
      });
    }
    //eslint-disable-next-line
  }, [shopifyProducts]);

  const closeModal = () => {
    setShowProductModal(false);
    setShopifyProductList([]);
  };

  const onClickAddProduct = (product: ShopifyProduct) => {
    try {
      const customProduct = {
        id: product?.id,
        imageUrl: product?.imageUrl,
        title: product?.title,
      };
      setSelectProduct([...selectProduct, { product: customProduct, qty: 1 }]);
      setSelectProductAdd([
        ...selectProductAdd,
        { product: customProduct, qty: 1 },
      ]);
      setStaticProductError(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onRemoveProduct = async (product: any) => {
    try {
      if (
        selectProduct.some(
          (productInner) => productInner?.product?.id === product?.id
        )
      ) {
        setSelectProduct([
          ...selectProduct.filter(
            (productInner) => productInner?.product?.id !== product?.id
          ),
        ]);
      }
      if (
        selectProductAdd.some(
          (productInner) => productInner?.product?.id === product?.id
        )
      ) {
        const productAdd: SelectProduct[] = selectProduct.filter(
          (productInner) => productInner?.product?.id !== product?.id
        );
        setSelectProductAdd(productAdd);
      }
      if (
        selectProduct?.some(
          (productInner) => product?.id === productInner?.product?.id
        ) &&
        !selectProductDelete.some(
          (productInner) => productInner?.product?.id !== product?.id
        )
      ) {
        setSelectProductDelete([...selectProductDelete, product]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (showProductModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [showProductModal]);

  return (
    <>
      <div className="mb-7 w-full bg-white rounded-lg">
        <div className="message-type-wrapper py-6 px-6 border-b border-light-grey">
          <div className="flex justify-between flex-row">
            <div>
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                Select products
              </p>
              <p className="text-10 text-dark-grey text-opacity-75">
                Select products sold through the checkout
              </p>
            </div>
          </div>
        </div>

        <div className="pt-6 pb-7 pl-6">
          <div className="flex flex-wrap pr-6 pb-3 ">
            <input
              type="radio"
              //   key={siteConfigInitials.smsReminderOptInType + "1"}
              className="mt-1"
              id="dynamic"
              name="select_checkout"
              checked={selectProductFields === "dynamic"}
              onChange={() => setSelectProductFields("dynamic")}
            />
            <div className="flex flex-col pl-6 w-[450px]">
              <p className="text-sm text-dark-grey font-medium mb-1">Dynamic</p>
              <p className="text-10 font-normal tracking-tracking_001 leading-4 text-dark-grey text-opacity-75">
                The product will be decided by customers action and your
                marketing flow
              </p>
            </div>
          </div>
          <div
            className={`flex flex-wrap pt-3 pr-6 ${
              selectProductFields === "static"
                ? "border-b border-gray-200 pb-[31px]"
                : "pb-3"
            }`}
          >
            <input
              type="radio"
              // key={siteConfigInitials.smsReminderOptInType + "2"}
              className="mt-1"
              id="static"
              name="select_checkout"
              checked={selectProductFields === "static"}
              onChange={() => setSelectProductFields("static")}
            />
            <div className="flex flex-col pl-6 w-[450px]">
              <p className="text-sm text-dark-grey tracking-tracking_001 font-medium mb-1">
                Static
              </p>
              <p className="text-10 font-normal tracking-tracking_001 leading-4 text-dark-grey text-opacity-75">
                Select specific products
              </p>
            </div>
          </div>
        </div>
        {selectProductFields === "static" && (
          <>
            <div className="px-6">
              <AdminProtectedContainer>
                <button
                  onClick={() => setShowProductModal(true)}
                  className="tracking-tracking_001 text-blue-600 text-xs font-medium"
                >
                  Add Product
                </button>
              </AdminProtectedContainer>
            </div>
            {selectProduct?.length ? (
              <>
                {selectProduct?.map(({ product, qty }, index) => {
                  return (
                    <div
                      className={`pt-2 pr-9 ml-6 ${
                        index === selectProduct?.length - 1 ? "pb-9" : "pb-2"
                      }  flex items-center`}
                      key={index}
                    >
                      <div className="flex items-center w-[625px]">
                        <img
                          className="w-6 h-6 mr-2"
                          src={product?.imageUrl}
                          alt=""
                        ></img>
                        <h3 className="text-xs text-dark-grey tracking-tracking_001 truncate max-w-[674px]">
                          {product?.title}
                        </h3>
                      </div>
                      <div className="flex items-center pl-4 w-[57%]">
                        <h3 className="text-xs text-dark-grey tracking-tracking_001">
                          Default Quantity:
                        </h3>
                        <div className="px-6 dropdown-select-wrap">
                          <div className="w-[104px]">
                            <Select
                              styles={{
                                control: (base) => ({
                                  ...base,
                                  height: "48px",
                                  boxShadow: "none",
                                }),
                                placeholder: (base) => ({
                                  ...base,
                                  height: "44px",
                                  display: "flex",
                                  flexDirection: "column",
                                  position: "relative",
                                  zIndex: 0,
                                  fontFamily: "AvenirLTPro-Black",
                                  justifyContent: "center",
                                  fontSize: "12px",
                                  alignItems: "start",
                                  letterSpacing: "0.4px",
                                }),
                                valueContainer: (base) => ({
                                  ...base,
                                  height: "48px",
                                  textAlign: "center",
                                }),
                              }}
                              value={configOptions?.filter(
                                (option) => option.value === qty
                              )}
                              options={configOptions}
                              isSearchable={false}
                              className="rounded w-full border-0.5 border-borderGrey-greyBorder text-xs border-opacity-50"
                              onChange={(e: any) => {
                                setSelectProduct((prvState) => {
                                  return prvState.map((obj: SelectProduct) => {
                                    if (obj?.product?.id === product?.id) {
                                      return { ...obj, qty: +e?.value };
                                    }
                                    return obj;
                                  });
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => onRemoveProduct(product)}
                        className="text-blue_default text-xs tracking-tracking_001 flex items-center w-[2%]"
                      >
                        <img
                          className="w-4 h-4 mr-2"
                          src="/delete-icon.svg"
                          alt="trash icon"
                        ></img>
                      </button>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="px-6 pb-12 pt-6">
                <div className="text-dark-grey text-10 tracking-tracking_001 font-normal">
                  No product is selected
                </div>
                {staticProductError && (
                  <p className="text-danger pt-4 text-red-500 text-xs tracking-tracking_001 font-normal">
                    Please select product.
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {showProductModal && (
        <AddProduct
          show={showProductModal}
          onClose={() => closeModal()}
          shopifyProducts={shopifyProductList}
          onClickAddProduct={(product: ShopifyProduct) =>
            onClickAddProduct(product)
          }
          addedProducts={selectProduct ?? []}
          setQueryObj={setQueryObj}
          shopifyLoading={shopifyLoading}
          queryObj={queryObj}
          refreshProducts={shopifyRefresh}
          setIsLoadMore={setIsLoadMore}
          search={search}
          setSearch={setSearch}
          isUpsell={true}
        />
      )}
    </>
  );
};

export default SelectCheckoutProduct;

import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { APIError } from "../../layout/Error";
import { ProductEnumType } from "./requests/ProductPageConfigurationRequests";
import { ReminderSection } from "./components/ReminderSection";
import { UpsellProduct } from "./components/UpsellProduct";
import {
  ProductReminderFrequency,
  ShopifyProduct,
  UpdateFrequencyRequest,
} from "./models/ShopifyProduct";
import {
  updateProductPageConfiguration,
  useGetProductPageConfiguration,
} from "./requests/ProductPageConfigurationRequests";
import DeleteProductModal from "./components/DeleteProductModal";
import { AdminProtectedContainer } from "../../components/RoleProtectedContainer";
import BannerCart from "./components/BannerCart";
import SwitchProductReminder from "./components/SwitchProductReminder";
import { StepState } from "./models/ReminderActionTypes";
import { useDispatch } from "react-redux";
import { updateReminderFrequency } from "./requests/ReminderFrequencyRequests";
import { getBusiness, postGoLive } from "../guides/requests/BusinessRequests";
import { setSetupStatus } from "../../redux/ducks/setup.duck";
import { setBusiness } from "../../redux/ducks/business.duck";
import { useAppSelector } from "../../redux/hooks";
import AddSubscriptionCart from "./components/AddSubscriptionCart";
import Button from "../../components/Button";
// import FollowupReminder from "./components/FollowupReminder";
import SetReminderOption from "./components/SetReminderOption";
import AddShippingDiscount from "../smart_checkouts/components/AddShippingDiscount";
import { DiscountShippingInterface } from "../smart_checkouts/models/SmartCheckout";
import { validateValues } from "../../util/helpers";

export const discountValidationSchema = (type: string) =>
  yup.object().shape({
    discountTypeValue:
      type === "percentage"
        ? yup
            .number()
            .min(0, "Enter a discount percentage of at least 0%")
            .max(100, "Enter a discount percentage of at most 100%")
        : yup
            .number()
            .positive()
            .min(0, "Enter a value greater than or equal to 0"),
    discountMinPurchaseAmount: yup
      .number()
      .positive()
      .min(0, "Enter a value greater than or equal to 0"),
    discountShipMinPurchaseAmount: yup
      .number()
      .positive()
      .min(0, "Enter a value greater than or equal to 0"),
  });

export interface BannerFields {
  isActive: boolean;
  text: string;
}

export interface SwitchProductFields {
  isActive: boolean;
  product: number | null;
}

export interface ReorderFields {
  isActive: boolean;
  text: string;
}

export interface SwitchProductFields {
  isActive: boolean;
  product: number | null;
}

export interface ReminderFreqOption {
  isActive: boolean;
  type: string;
}

export const initialDiscountOptions: DiscountShippingInterface = {
  discountTypeFlag: true,
  discountType: "percentage",
  discountTypeValue: 0,
  discountMinPurchaseType: "quantity",
  discountMinPurchaseQty: 0,
  discountMinPurchaseAmount: 0,
  discountShippingFlag: true,
  discountShipMinPurchaseType: "ship_quantity",
  discountShipMinPurchaseQty: 0,
  discountShipMinPurchaseAmount: 0,
};

const ProductDetailsPage = () => {
  const { id } = useParams();
  const {
    loading,
    error,
    refresh,
    data: product,
  } = useGetProductPageConfiguration(Number(id), ProductEnumType.PRODUCT);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { businessId }: any = useAppSelector((state) => state.business);

  const [upsellProductToAdd, setUpsellProductToAdd] = useState<
    ShopifyProduct[]
  >([]);
  const [upsellProductToDelete, setUpsellProductToDelete] = useState<
    ShopifyProduct[]
  >([]);
  const [hasDuplicateReminderFrequency, setHasDuplicateReminderFrequency] =
    useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [upsellText, setUpsellText] = useState<string>("");
  const [upsellProducts, setUpsellProducts] = useState<ShopifyProduct[]>([]);
  const [pending, setPending] = useState(false);
  const [save, setSave] = useState(false);
  const [defaultStepId, setdefaultStepId] = useState<number>(0);
  const [isDelete, setIsDelete] = useState(false);
  const [reminderFreqType, setReminderFreqType] = useState<ReminderFreqOption>({
    isActive: false,
    type: "",
  });

  const [freq, setFreq] = useState<ProductReminderFrequency>();
  const [freqActive, setFreqActive] = useState(false);
  const [campaignActive, setCampaignActive] = useState(false);
  const [discountActive, setDiscountActive] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [discountOption, setDiscountOptions] =
    useState<DiscountShippingInterface>(initialDiscountOptions);
  const [upsellActive, setUpsellActive] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  // const [followUp, setFollowUp] = useState({
  //   isActive: false,
  //   days: 0,
  // });
  const [defaultSubscriptionActive, setDefaultSubscriptionActive] =
    useState<boolean>(false);
  const [cancelChanges, setCancelChanges] = useState<boolean>(false);
  const [bannerFields, setBannerFields] = useState<BannerFields>({
    isActive: false,
    text: "",
  });
  // const [reorderFields, setReorderFields] = useState<ReorderFields>({
  //   isActive: false,
  //   text: "",
  // });
  const [switchProductFields, setSwitchProductFields] =
    useState<SwitchProductFields>({
      isActive: false,
      product: null,
    });
  const [reminderState, setReminderState] = useState<StepState>({
    steps: [],
    hasDuplicates: false,
  });
  const setupStatus = useAppSelector((state) => state.setup.setupStatus);
  const setDiscountValue = (fieldName: string, value: any) => {
    setDiscountOptions((prevState) => ({ ...prevState, [fieldName]: value }));
  };

  useEffect(() => {
    if (isNaN(Number(id))) {
      navigate("/smart-reminders");
    }
  }, [id, navigate]);

  useEffect(() => {
    setValuesFromApi();
    //eslint-disable-next-line
  }, [product]);

  const setValuesFromApi = () => {
    if (product) {
      if (product.upsellText !== undefined) {
        setUpsellText(product.upsellText);
      }
      if (product.upsellProducts.length > 0) {
        setUpsellProducts([...product.upsellProducts]);
      } else {
        setUpsellProducts([]);
      }
      if (product.product.reminderFrequency) {
        setdefaultStepId(product.product.reminderFrequency.defaultStepId);
        setFreq(product.product.reminderFrequency);
        setFreqActive(product.product.reminderFrequency.reminderFlag);
      }
      if (product.product.reminderCampaign) {
        setCampaignActive(product.product.reminderCampaign.reminderFlag);
      }
      // setFollowUp({
      //   isActive: product.followUpReminderFlag,
      //   days: product.followUpReminderDays,
      // });
      setDiscountActive(product.addDiscountFlag);
      setUpsellActive(product.upsellProductFlag);
      setSubscriptionActive(product.isSubscriptionEnabled);
      setDefaultSubscriptionActive(product.isDefaultSubscription);
      setBannerFields({
        isActive: product.addBannerFlag,
        text: product.bannerMessage,
      });
      setReminderFreqType({
        isActive: product.emailFlowReminderFlag,
        type: product?.reminderFrequencyType || "default_frequency",
      });

      if (
        product.discountTypeFlag !== undefined &&
        product.discountType !== undefined &&
        product.discountTypeValue !== undefined &&
        product.discountMinPurchaseType !== undefined &&
        product.discountMinPurchaseQty !== undefined &&
        product.discountMinPurchaseAmount !== undefined &&
        product.discountShippingFlag !== undefined &&
        product.discountShipMinPurchaseType !== undefined &&
        product.discountShipMinPurchaseQty !== undefined &&
        product.discountShipMinPurchaseAmount !== undefined
      ) {
        setDiscountOptions({
          discountTypeFlag: product.discountTypeFlag,
          discountType: product.discountType,
          discountTypeValue: product.discountTypeValue,
          discountMinPurchaseType: product.discountMinPurchaseType,
          discountMinPurchaseQty: product.discountMinPurchaseQty,
          discountMinPurchaseAmount: product.discountMinPurchaseAmount,
          discountShippingFlag: product.discountShippingFlag,
          discountShipMinPurchaseType: product.discountShipMinPurchaseType,
          discountShipMinPurchaseQty: product.discountShipMinPurchaseQty,
          discountShipMinPurchaseAmount: product.discountShipMinPurchaseAmount,
        });
      }

      // setReorderFields({
      //   isActive: product.addReorderPrompt,
      //   text: product.reorderPromptText,
      // });
      setSwitchProductFields({
        isActive: product.addSwitchProductFlag,
        product: product?.switchableShopifyProductId?.id ?? null,
      });
    }
  };

  useEffect(() => {
    if (canEnableSave()) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
    //eslint-disable-next-line
  }, [
    product,
    reminderFreqType,
    discountOption,
    pending,
    upsellText,
    upsellProductToDelete,
    upsellProductToAdd,
    defaultStepId,
    hasDuplicateReminderFrequency,
    freqActive,
    campaignActive,
    discountActive,
    upsellActive,
    switchProductFields,
    subscriptionActive,
    defaultSubscriptionActive,
    bannerFields,
    // followUp,
  ]);

  const flagsChanged = () => {
    if (product) {
      return (
        (product.product.reminderFrequency &&
          product.product.reminderFrequency.reminderFlag !== freqActive) ||
        product.emailFlowReminderFlag !== reminderFreqType.isActive ||
        // product.followUpReminderFlag !== followUp.isActive ||
        product.addDiscountFlag !== discountActive ||
        product.upsellProductFlag !== upsellActive ||
        product.addBannerFlag !== bannerFields.isActive ||
        product?.addSwitchProductFlag !== switchProductFields.isActive ||
        product?.isSubscriptionEnabled !== subscriptionActive ||
        product?.isDefaultSubscription !== defaultSubscriptionActive ||
        product?.discountTypeFlag !== discountOption?.discountTypeFlag ||
        product?.discountShippingFlag !== discountOption?.discountShippingFlag
      );
    }
    return false;
  };

  const canEnableSave = () => {
    return (
      pending ||
      product?.upsellText !== upsellText ||
      // product.followUpReminderDays !== followUp.days ||
      product?.bannerMessage !== bannerFields.text ||
      product?.reminderFrequencyType !== reminderFreqType.type ||
      product?.discountTypeValue !== discountOption?.discountTypeValue ||
      product?.discountMinPurchaseAmount !==
        discountOption?.discountMinPurchaseAmount ||
      product?.discountMinPurchaseQty !==
        discountOption?.discountMinPurchaseQty ||
      product?.discountMinPurchaseType !==
        discountOption?.discountMinPurchaseType ||
      product?.discountShipMinPurchaseType !==
        discountOption?.discountShipMinPurchaseType ||
      product?.discountShipMinPurchaseAmount !==
        discountOption?.discountShipMinPurchaseAmount ||
      product?.discountShipMinPurchaseQty !==
        discountOption?.discountShipMinPurchaseQty ||
      (product?.switchableShopifyProductId
        ? product?.switchableShopifyProductId?.id !==
          (switchProductFields?.product && switchProductFields?.product)
        : product?.switchableShopifyProductId !==
          switchProductFields?.product) ||
      upsellProductToDelete.length > 0 ||
      upsellProductToAdd.length > 0 ||
      flagsChanged() ||
      (product.product.reminderFrequency &&
        defaultStepId !== product.product.reminderFrequency.defaultStepId &&
        !hasDuplicateReminderFrequency &&
        Object.keys(errors).length === 0)
    );
    //eslint-disable-next-line
  };

  useEffect(() => {
    if (!loading) {
      setSave(false);
    }
  }, [loading]);

  useEffect(() => {
    const update = async () => {
      if (save && product) {
        if (
          pending ||
          product?.product?.reminderFrequency?.reminderFlag !== freqActive
        ) {
          await onUpdateReminder();
        }
        if (upsellChanged() || discountActive !== product?.addDiscountFlag) {
          await onUpdate();
        }
      }
    };
    update();
    //eslint-disable-next-line
  }, [save]);

  const checkAndSetReminderStep = () => {
    if (freq && freq.reminderFlag !== freqActive) {
      if (freq.reminderFlag && !freqActive) {
        getBusiness().then((res: any) => {
          if (res.data) {
            dispatch(setSetupStatus(res.data.setupStatus));
            dispatch(setBusiness(res.data));
          }
        });
      }
      if (!freq.reminderFlag && freqActive) {
        getBusiness().then((res) => {
          if (res.data) {
            dispatch(setSetupStatus(res.data.setupStatus));
            dispatch(setBusiness(res?.data));
          }
        });
      }
    }
  };

  const onUpdateReminder = async () => {
    setIsButtonDisabled(true);
    try {
      if (product && reminderState) {
        const req: UpdateFrequencyRequest = {
          reminderFlag: freqActive,
          workingRevisionAttributes: {
            reminderFrequencyStepsAttributes: reminderState?.steps.map(
              (step) => {
                return {
                  ...step,
                  frequencyAttributes: step.frequency,
                  isDefault: step.defaultStep,
                };
              }
            ),
          },
        };
        await updateReminderFrequency(product.product.reminderFrequency.id, req)
          .then(() => {
            checkAndSetReminderStep();
            refresh().then(() => {
              setSave(false);
              if (setupStatus.goLive.status === "completed") {
                postGoLive(businessId);
              }
            });
          })
          .catch((err) => {
            console.log(err);
            setSave(false);
          });
      }
      setPending(false);
      setHasDuplicateReminderFrequency(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isDelete) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isDelete]);

  const onUpdate = async () => {
    setIsButtonDisabled(true);
    try {
      const apiPromises: any[] = [];

      const hasErrors = validateValues(
        discountOption,
        discountValidationSchema(discountOption?.discountType),
        setErrors
      );

      if (product && !hasErrors) {
        // Upsell text update
        if (
          product.upsellText !== upsellText ||
          product.bannerMessage !== bannerFields.text ||
          // product.followUpReminderDays !== followUp.days ||
          product?.switchableShopifyProductId?.id !==
            (switchProductFields.product && switchProductFields.product) ||
          upsellProductToDelete.length > 0 ||
          upsellProductToAdd.length > 0 ||
          flagsChanged()
        ) {
          const upsellUpdateRequest = {
            upsellText: product.upsellText,
            shopifyProductIds: product.upsellProducts.map(
              (product) => product.id
            ),
            bannerMessage: bannerFields.text,
            // followUpReminderDays: followUp.days,
            // reorderPromptText: reorderFields.text,
            switchableShopifyProductId: switchProductFields.product,
          };
          if (product.upsellText !== upsellText) {
            upsellUpdateRequest.upsellText = upsellText;
          }

          if (
            upsellProductToDelete.length > 0 ||
            upsellProductToAdd.length > 0
          ) {
            upsellUpdateRequest.shopifyProductIds = upsellProducts.map(
              (product) => product.id
            );
            setUpsellProductToAdd([]);
            setUpsellProductToDelete([]);
          }

          apiPromises.push(
            updateProductPageConfiguration(product.id, {
              configurationType: ProductEnumType.PRODUCT,
              productPageRevisionsAttributes: [upsellUpdateRequest],
              addDiscountFlag: discountActive,
              upsellProductFlag: upsellActive,
              emailFlowReminderFlag: reminderFreqType?.isActive,
              // followUpReminderFlag: followUp.isActive,
              reminderFrequencyType: reminderFreqType?.type,
              // applyDiscountToFullCart: allDiscountActive,
              addSwitchProductFlag: switchProductFields.isActive,
              isSubscriptionEnabled: subscriptionActive,
              isDefaultSubscription: defaultSubscriptionActive,
              addBannerFlag: bannerFields.isActive,
              discountTypeFlag: discountOption?.discountTypeFlag,
              discountShippingFlag: discountOption?.discountShippingFlag,
              discountType: discountOption?.discountType,
              discountTypeValue: discountOption?.discountTypeValue,
              discountMinPurchaseType: discountOption?.discountMinPurchaseType,
              discountMinPurchaseQty: discountOption?.discountMinPurchaseQty,
              discountMinPurchaseAmount:
                discountOption?.discountMinPurchaseAmount,
              discountShipMinPurchaseType:
                discountOption?.discountShipMinPurchaseType,
              discountShipMinPurchaseQty:
                discountOption?.discountShipMinPurchaseQty,
              discountShipMinPurchaseAmount:
                discountOption?.discountShipMinPurchaseAmount,
            })
          );
        }
        await Promise.all(apiPromises).then(() => {
          refresh().then(() => {
            if (setupStatus.goLive.status === "completed") {
              postGoLive(businessId);
            }
          });
        });
      }
      setSave(false);
      setIsButtonDisabled(false);
      // setDiscountCodeError(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (isDelete) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isDelete]);

  useEffect(() => {
    if (error) {
      navigate("/smart-reminders");
    }
    // eslint-disable-next-line
  }, [error]);

  if (error) {
    return (
      <APIError
        error={error}
        consentText="Consent to reading Products"
        refresh={refresh}
      />
    );
  }

  const upsellChanged = () => {
    return (
      upsellProductToAdd.length > 0 ||
      upsellProductToDelete.length > 0 ||
      product?.upsellText !== upsellText ||
      upsellActive !== product.upsellProductFlag ||
      switchProductFields.isActive !== product.addSwitchProductFlag ||
      switchProductFields.product !== product.switchableShopifyProductId ||
      reminderFreqType.isActive !== product.emailFlowReminderFlag ||
      reminderFreqType.type !== product.reminderFrequencyType ||
      subscriptionActive !== product.isSubscriptionEnabled ||
      defaultSubscriptionActive !== product.isDefaultSubscription ||
      bannerFields.isActive !== product.addBannerFlag ||
      bannerFields.text !== product.bannerMessage ||
      // followUp.days !== product.followUpReminderDays ||
      // followUp.isActive !== product.followUpReminderFlag ||
      product?.discountTypeValue !== discountOption?.discountTypeValue ||
      product?.discountMinPurchaseAmount !==
        discountOption?.discountMinPurchaseAmount ||
      product?.discountMinPurchaseQty !==
        discountOption?.discountMinPurchaseQty ||
      product?.discountMinPurchaseType !==
        discountOption?.discountMinPurchaseType ||
      product?.discountShipMinPurchaseType !==
        discountOption?.discountShipMinPurchaseType ||
      product?.discountShipMinPurchaseAmount !==
        discountOption?.discountShipMinPurchaseAmount ||
      product?.discountShipMinPurchaseQty !==
        discountOption?.discountShipMinPurchaseQty
    );
  };

  return (
    <>
      {/* {loading && <PageContentLoader pageTitle="Products" />} */}
      {product && (
        <div className="flex flex-col w-full">
          {/* TODO: change this to flex row adn update according to design */}
          <div className="text-xs text-blue_default tracking-tracking_001 font-medium">
            <Link className="flex items-center" to="/smart-reminders">
              <img className="mr-1" src="/back-arrow.svg" alt="back arrow" />
              Smart reminders
            </Link>
          </div>

          <div className="title flex justify-between py-4 mb-6">
            <div className="flex items-center">
              <h1 className="text-2xl text-black_shade font-medium pr-[40px]">
                {product.originalTitle
                  ? product.originalTitle
                  : product.product.title}
              </h1>
              {/* {puaseReminders && (
                <div className="mr-auto px-[24px] text-[#E50000] py-[10px] text-[13px] leading-[19.5px] rounded-full align-middle bg-[#E50000]/10">
                  Paused
                </div>
              )} */}
            </div>

            <AdminProtectedContainer>
              <div className="flex items-center justify-end gap-3 min-w-[400px]">
                {/* <button
                  className={`py-2 px-4 rounded-md text-[#E50000] border border-btnRed flex items-center text-13`}
                  onClick={() => setPauseReminderModel(true)}
                >
                  {!puaseReminders ? "Pause reminders" : "Resume reminders"}
                </button> */}
                <Button
                  text="Delete Product"
                  className={`h-10 px-4 rounded-md text-white flex items-center bg-btnRed text-13`}
                  onClick={() => setIsDelete(true)}
                />
                <Button
                  text="Cancel changes"
                  className={`h-10 px-4 rounded-md text-[#192A3E] border border-[#192A3E] flex items-center text-13 tracking-wide `}
                  disabled={isButtonDisabled || save}
                  onClick={() => {
                    setCancelChanges(true);
                    setValuesFromApi();
                    if (
                      upsellProductToAdd.length > 0 ||
                      upsellProductToDelete.length > 0
                    ) {
                      setUpsellProductToAdd([]);
                      setUpsellProductToDelete([]);
                    }
                  }}
                />
                <Button
                  text="Save"
                  className={`h-10 px-6 rounded-md text-white flex items-center text-13 tracking-wide ${
                    isButtonDisabled || cancelChanges || save
                      ? "bg-gray-500"
                      : "bg-green-500"
                  }`}
                  disabled={isButtonDisabled || cancelChanges || save}
                  loading={save}
                  onClick={() => setSave(true)}
                />
              </div>
            </AdminProtectedContainer>
          </div>
          {freq && (
            <ReminderSection
              pendingUpdates={pending}
              active={freqActive}
              setActive={setFreqActive}
              shopifyProduct={product.product}
              hasDuplicateReminderFrequency={hasDuplicateReminderFrequency}
              setHasDuplicateReminderFrequency={
                setHasDuplicateReminderFrequency
              }
              save={save}
              cancelChanges={cancelChanges}
              setCancelChanges={setCancelChanges}
              setPendingUpdates={setPending}
              reminderFrequency={freq}
              setReminderState={setReminderState}
            />
          )}

          <SetReminderOption
            setReminderFreqType={setReminderFreqType}
            reminderFreqType={reminderFreqType}
          />

          {/* <FollowupReminder followUp={followUp} setFollowUp={setFollowUp} /> */}

          <AddShippingDiscount
            active={discountActive}
            setActive={setDiscountActive}
            discountOption={discountOption}
            setDiscountValue={setDiscountValue}
            errors={errors}
            setError={setErrors}
          />

          <AddSubscriptionCart
            active={subscriptionActive}
            setActive={setSubscriptionActive}
            activeDefaultSubscription={defaultSubscriptionActive}
            setDefaultSubscriptionActive={setDefaultSubscriptionActive}
          />

          <BannerCart
            bannerFields={bannerFields}
            setBannerFields={setBannerFields}
          />

          <UpsellProduct
            save={save}
            active={upsellActive}
            setActive={setUpsellActive}
            setUpsellText={setUpsellText}
            upsellText={upsellText}
            upsellProducts={upsellProducts}
            setUpsellProducts={setUpsellProducts}
            initialUpsellProducts={product.upsellProducts}
            upsellProductToAdd={upsellProductToAdd}
            setUpsellProductToAdd={setUpsellProductToAdd}
            upsellProductToDelete={upsellProductToDelete}
            setUpsellProductToDelete={setUpsellProductToDelete}
            mainProduct={product.product}
          />

          <SwitchProductReminder
            configs={upsellProducts}
            mainProduct={product}
            cancelChanges={cancelChanges}
            setCancelChanges={setCancelChanges}
            switchProductFields={switchProductFields}
            setSwitchProductFields={setSwitchProductFields}
          />

          <DeleteProductModal
            show={isDelete}
            onClose={() => setIsDelete(false)}
            mainProduct={product}
            type={ProductEnumType.PRODUCT}
          />
        </div>
      )}
    </>
  );
};

export default ProductDetailsPage;

import React from "react";

const Switch = ({
  state,
  onClick,
}: {
  state: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={`toggle w-10 h-5 rounded-full relative cursor-pointer ${
        state ? "bg-green_success" : "bg-gray-400"
      }`}
      onClick={onClick}
    >
      <span
        className={` w-4 h-4 rounded-full bg-white absolute  top-1/2 transform -translate-y-1/2 shadow-toggle_shadow transition-all ${
          state ? "left-1" : "left-5"
        } `}
      ></span>
    </div>
  );
};

export { Switch };

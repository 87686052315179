import { APIError } from "../layout/Error";
import { SpinningLoader } from "../layout/Loaders";
import { PresignedPost } from "../pages/account/models/SiteConfigurationModel";
import { useGetFileUpload } from "../pages/account/requests/FileUploadsRequests";
import { useEffect, useState } from "react";

export const PresignedS3FileInput = ({
  defaultImageURL,
  onSubmit,
  businessId,
}: {
  defaultImageURL: string | undefined;
  onSubmit: (url: string) => void;
  businessId: number;
}) => {
  const { error, refresh, data: presigned } = useGetFileUpload(businessId);

  if (error) {
    return (
      <APIError
        error={error}
        consentText="Consent to uploading file"
        refresh={refresh}
      />
    );
  }

  if (presigned) {
    return (
      <FileInputForm
        presigned={presigned}
        defaultImageURL={defaultImageURL}
        onSubmit={onSubmit}
      />
    );
  }

  return (
    <SpinningLoader fullHeight={false} sizeClasses="w-16 h-16 mt-14 mb-8" />
  );
};

const FileInputForm = ({
  presigned,
  defaultImageURL,
  onSubmit,
}: {
  presigned: PresignedPost;
  defaultImageURL: string | undefined;
  onSubmit: (url: string) => void;
}) => {
  const [image, setImage] = useState<string | undefined>(defaultImageURL);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (files: FileList | null) => {
    if (!files || files.length === 0) {
      return;
    }
    if (!files[0].type.includes("image/")) {
      return;
    }
    setLoading(true);
    var data = new FormData();
    Object.entries(presigned.fields).map(([key, value]) =>
      data.append(key, value)
    );
    data.append("file", files[0]);
    data.append("Content-Type", files[0].type);

    fetch(presigned.url, { method: "POST", body: data })
      .then((resp) => resp.text())
      .then((xml) => {
        const parser = new DOMParser();
        const parsed = parser.parseFromString(xml, "application/xml");

        const location = parsed.querySelector("Location");
        if (location?.textContent) {
          setImage(location?.textContent);
          onSubmit(location?.textContent);
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    setImage(defaultImageURL);
  }, [defaultImageURL]);

  return (
    <div className="fileupld border-2 border-dashed rounded-lg border-grayBorder max-w-xs text-center mt-4 flex flex-col relative">
      {loading && (
        <div className="text-center w-full bg-gray-800 bg-opacity-30 h-full absolute left-0 top-0 flex items-center justify-center">
          <SpinningLoader fullHeight={false} />
        </div>
      )}
      <div
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.dataTransfer.files && e.dataTransfer.files.length) {
            const file = e.dataTransfer.files[0];
            if (file.type.includes("image/")) {
              handleChange(e.dataTransfer.files);
            }
          }
        }}
      >
        {!image && (
          <label className="w-10 h-10 mx-auto flex mt-6">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40 20C40 31.0457 31.0457 40 20 40C8.95431 40 0 31.0457 0 20C0 8.95431 8.95431 0 20 0C31.0457 0 40 8.95431 40 20ZM10.5858 16.5858L18.5858 8.58579C19.3668 7.80474 20.6332 7.80474 21.4142 8.58579L29.4142 16.5858C30.1953 17.3668 30.1953 18.6332 29.4142 19.4142C28.6332 20.1953 27.3668 20.1953 26.5858 19.4142L22 14.8284V30C22 31.1046 21.1046 32 20 32C18.8954 32 18 31.1046 18 30V14.8284L13.4142 19.4142C12.6332 20.1953 11.3668 20.1953 10.5858 19.4142C9.80474 18.6332 9.80474 17.3668 10.5858 16.5858Z"
                fill="#5C5F62"
              />
            </svg>
          </label>
        )}
        {image && (
          <div className="p-4 pb-0 h-28 w-48 mx-auto">
            <img
              className="mx-auto object-contain h-full w-full"
              src={image}
              alt="Brand logo"
            />
          </div>
        )}

        <label
          htmlFor="upload-file"
          className="cursor-pointer text-black1 text-sm font-medium border shadow-sm rounded mt-5 py-2 px-4 mb-4 flex justify-center w-24 mx-auto"
        >
          {image ? "Replace" : "Add file"}
        </label>
        <input
          className="absolute left-28 top-0 opacity-0 h-10 cursor-auto"
          type="file"
          name="file"
          id="upload-file"
          onChange={(e) => handleChange(e.target.files)}
          accept=".png, .svg"
        />

        <p className="text-gray4 text-sm pb-8">or drop files to upload</p>
      </div>
    </div>
  );
};

import { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { StartGuideBanner } from "../components/StartGuideBanner";
import { useGetBusiness } from "../pages/guides/requests/BusinessRequests";
import { APIError } from "./Error";
import { SpinningLoader } from "./Loaders";
import { Menu } from "./Menu";
import { SiteConfiguration } from "../pages/account/models/SiteConfigurationModel";
import { getSiteConfiguration } from "../pages/account/requests/SiteConfigurationsRequests";
import { getSelf } from "../pages/account/requests/UserRequests";
import { setupAxios } from "../setup/axios/SetupAxios";
import axios from "axios";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setUser } from "../redux/ducks/user.duck";
import { setBusiness, setBusinessId } from "../redux/ducks/business.duck";
import {
  setFetching,
  setRefresh,
  setSetupStatus,
} from "../redux/ducks/setup.duck";
import { setSiteConfiguration } from "../redux/ducks/siteconfig.duck";
import { User } from "../pages/account/models/UserModel";
import { NotificationModel } from "./NotificationModal";
import { ContactUsModal } from "./ContactUsModal";
import { ContactSuccessModel } from "../components/ContactSuccessModel";
import { setPermission, setPlans } from "../redux/ducks/plans.duck";
import { Plan } from "../pages/account/models/PlansModels";
import { getSubscriptionPlans } from "../pages/account/requests/PlansRequests";

const LayoutProvider = ({ title }: { title: string }) => {
  const dispatch = useAppDispatch();

  // const user: any = useAppSelector((state) => state.user.user);
  const user = localStorage.getItem("user");
  const setupStatus = useAppSelector((state) => state.setup.setupStatus);
  const globalLoading = useAppSelector((state) => state.business.globalLoading);
  const businessSelector: any = useAppSelector(
    (state) => state.business.business
  );
  const { plans } = useAppSelector((state) => state.plans);
  const { data: business, error, refresh } = useGetBusiness();

  const [isContactUsModalOpen, setIsContactUsModalOpen] =
    useState<boolean>(false);

  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  const [businessData, setBusinessData] = useState([]);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);

  const [self, setSelf] = useState<User>();
  const getSiteConfigData = async (bizId: number) => {
    try {
      const { data }: { data: SiteConfiguration } = await getSiteConfiguration(
        bizId
      );
      dispatch(setSiteConfiguration(data));
    } catch (error: any) {
      console.log(error);
    }
  };

  const getPlans = async (businessId: number) => {
    const res = await getSubscriptionPlans(businessId);
    if (res && res.data) {
      const sort = res?.data?.plans?.sort((a: Plan, b: Plan) => a.id - b.id);
      const subscriptionPlan = { ...res.data, plans: sort };
      dispatch(setPlans(subscriptionPlan));
      dispatch(setPermission(JSON.parse(subscriptionPlan.planPermissions)));
    }
    return true;
  };

  // const [user, setUser] = useState();

  // const { user, getAccessTokenSilently, isLoading } = useAuth0();

  useEffect(() => {
    if (user) {
      (async () => {
        const getAccessTokenSilently = localStorage.getItem("auth_jwt");
        if (getAccessTokenSilently) {
          const userData = JSON.parse(user);
          setupAxios(axios, getAccessTokenSilently);
          if (userData.email) {
            const { data: newSelf } = await getSelf(userData.email);
            dispatch(setUser(newSelf));
            setSelf(newSelf);
          }
        }
      })();
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    (async () => {
      dispatch(setFetching(true));
      if (business) {
        dispatch(setRefresh(refresh));
        dispatch(setBusinessId(business.id));
        getSiteConfigData(business.id);
        dispatch(setSetupStatus(business.setupStatus));
        dispatch(setBusiness(business));
        if (plans === null) {
          await getPlans(business?.id);
        }
        setLoading(false);
        dispatch(setFetching(false));
      }
    })();
    // eslint-disable-next-line
  }, [business]);

  useEffect(() => {
    if (businessSelector?.ownerStores) {
      setBusinessData(businessSelector?.ownerStores);
    }
  }, [businessSelector]);

  return (
    <div className="flex flex-row min-h-screen">
      {globalLoading ? (
        <div className="flex flex-grow px-8 py-8 bg-[#F5F5F5] min-h-screen flex-col">
          <SpinningLoader />
        </div>
      ) : (
        <>
          <Menu
            stores={businessData || []}
            setIsNotificationModalOpen={setIsNotificationModalOpen}
            setIsContactUsModalOpen={setIsContactUsModalOpen}
            setupStatus={setupStatus}
          />
          <div className="flex flex-grow px-8 py-8 bg-[#F5F5F5] min-h-screen flex-col overflow-x-hidden">
            {error && (
              <APIError error={error} consentText="" refresh={refresh} />
            )}
            {business && (
              <StartGuideBanner business={business} refreshBusiness={refresh} />
            )}
            {loading ? <SpinningLoader /> : <Outlet />}
          </div>
          {isNotificationModalOpen && (
            <NotificationModel
              setIsNotificationModalOpen={setIsNotificationModalOpen}
              isNotificationModalOpen={isNotificationModalOpen}
            />
          )}
          {isContactUsModalOpen && (
            <ContactUsModal
              user={self}
              isContactUsModalOpen={isContactUsModalOpen}
              setIsContactUsModalOpen={setIsContactUsModalOpen}
              setIsSubmit={setIsSubmit}
            />
          )}

          {isSubmit && (
            <ContactSuccessModel
              show={isSubmit}
              onClose={() => setIsSubmit(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

export { LayoutProvider };

import { useState } from "react";
import { Subscription } from "../pages/account/models/PlansModels";
import Button from "./Button";
import UpgradePlanModel from "./UpgradePlanModel";

const UnlockFeature = ({ subscription }: { subscription: Subscription }) => {
  const [upgradePlan, setUpgradePlan] = useState(false);

  return (
    <>
      <div className="mx-auto mt-[117px] min-h-[523px] bg-white border border-border_gray rounded-[10px] w-full max-w-[867px] py-[75px] px-[170px] shadow-[0_4px_4px_rgba(0,0,0,0.25)]">
        <h2 className="text-black_shade font-bold text-2xl !leading-[36px] tracking-tracking_001 mb-16">
          Upgrade and Unlock advanced features
        </h2>

        <ul className="text-black_shade text-sm tracking-tracking_001 !leading-[21px]">
          <li className="flex items-center mb-5">
            <img
              className="mr-5"
              alt="ico_tick_green"
              src="/svg/ico_tick_green.svg"
            />
            Increase your conversion by customizing reminders
          </li>
          <li className="flex items-center mb-5">
            <img
              className="mr-5"
              alt="ico_tick_green"
              src="/svg/ico_tick_green.svg"
            />
            Integrate fully with your marketing stack
          </li>
          <li className="flex items-center mb-5">
            <img
              className="mr-5"
              alt="ico_tick_green"
              src="/svg/ico_tick_green.svg"
            />
            Convert one-time shoppers into subscribers
          </li>
        </ul>
        <Button
          className={`mt-7 max-w-[231px] w-full py-[10px] px-[48px] text-white bg-normal-blue font-medium rounded-md text-center text-[13px] !leading-[20px] tracking-tracking_001 inline-block`}
          text="Upgrade"
          onClick={() => setUpgradePlan(true)}
        />
      </div>
      {upgradePlan && (
        <UpgradePlanModel
          show={upgradePlan}
          onClose={() => setUpgradePlan(false)}
          subscription={subscription}
        />
      )}
    </>
  );
};

export default UnlockFeature;

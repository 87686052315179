import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SetupStatus } from "../../pages/guides/models/Business";

export const initialSetupStatus: SetupStatus = {
  site: {
    message: "",
    status: "pending",
  },
  reminderStep: {
    message: "",
    status: "pending",
  },
  reminderStatus: {
    emailDeliveryMethod: "",
    invitationEmailEnabled: false,
    smsDeliveryMethod: "",
    smsReminderOptInType: "",
    status: "pending",
  },
  smartCheckoutStatus: {
    message: "",
    status: "pending",
  },
  goLive: {
    message: "",
    status: "pending",
  },
  completed: false,
};

export const tokenSlice = createSlice({
  name: "setup",
  initialState: {
    setupStatus: initialSetupStatus,
    isFetching: true,
    refresh: () => {},
  },
  reducers: {
    setSetupStatus: (state: any, action: PayloadAction<SetupStatus>) => {
      state.setupStatus = action.payload;
    },
    setFetching: (state: any, action: PayloadAction<Boolean>) => {
      state.isFetching = action.payload;
    },
    setRefresh: (state: any, action: PayloadAction<any>) => {
      state.refresh = action.payload;
    },
  },
});

const { actions, reducer } = tokenSlice;

export const { setSetupStatus, setRefresh, setFetching } = actions;

export default reducer;

import { isNull } from "lodash";
import * as yup from "yup";
import { store } from "../redux/store";
export const onInputFocus = (
  fields: string[],
  setFields: (data: string[]) => void,
  name: string
) => {
  setFields([...fields, name]);
};

export const onInputBlur = (
  fields: string[],
  setFields: (data: string[]) => void,
  name: string
) => {
  const tempFields = [...fields];
  if (tempFields.indexOf(name) > -1) {
    tempFields.splice(tempFields.indexOf(name), 1);
    setFields(tempFields);
  }
};

export const getClass = (
  fieldName: any,
  values: any,
  focusedFields: string[]
) => {
  const classes = [];
  if (focusedFields.includes(fieldName)) {
    classes.push("focused ");
  }
  if (values && values[fieldName]) {
    classes.push("has-value");
  }
  return classes.join(" ");
};

export const validateValues = (
  values: any,
  schema: yup.AnyObjectSchema,
  setErrors: (errors: any) => void
) => {
  const tempErrors: any = {};
  try {
    schema.validateSync(values, { abortEarly: false });
  } catch (e: any) {
    e.inner.forEach((item: yup.ValidationError) => {
      if (item.path) {
        tempErrors[item.path] = item.message;
      }
    });
  }
  setErrors(tempErrors);
  return Object.keys(tempErrors).length > 0;
};

export const sanitizedObject = (data: any): any => {
  const dataObj = { ...data };
  Object.keys(dataObj).forEach((field: string) => {
    dataObj[field] = isNull(data[field]) ? "" : data[field];
  });
  return dataObj;
};

export const formatNumber = (num: number) =>
  (Math.round(num * 100) / 100).toFixed(2);

export const IsAdmin = () => {
  const state = store.getState();
  const user: any = state.user.user;
  if (["Admin", "Billing Admin"].find((role) => role === user?.role)) {
    return true;
  } else {
    return false;
  }
};

export const upgradePlan = (permission: string[], access: string) => {
  if (permission?.length > 0) {
    return permission.includes(access);
  } else {
    return false;
  }
};

export const diffBetweenTwoObj = (newObj: any, oldObj: any) => {
  let keyFound: any;
  Object.keys(newObj).forEach((key) => {
    if (newObj[key] !== oldObj[key]) {
      keyFound = { ...keyFound, [key]: newObj[key] };
    }
  });
  return keyFound || -1;
};

export const showHoursOption = process.env.REACT_APP_HOURS_OPTION === "true";

export const isStaging = process.env.REACT_APP_IS_STAGING === "true";

export const recordPerPage = [10, 25, 50];

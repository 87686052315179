import moment from "moment";
import { UseAPI, useApi } from "../../../use-api";

const API_URL = process.env.REACT_APP_API_URL;

export interface ProductQueryDataset {
  label: string;
  orderRevenue: number[];
  orderCount: number[];
  reminderSentCount: number[];
  customerCount: number[];
  noOfCustomersEnrolledSmsCount: number[];
  numberOfActiveCustomersCount: number[];
  percentageLabels: number[];
  customerActive: number[];
  reminderEmailSentCount: number[];
  reminderSmsSentCount: number[];
  noOfCustomersOptInForSubscriptionCount: number[];
  noOfCustomersConvertedToSubscriptionCount: number[];
  totalNoOfCustomersOptInForSubscription: number;
  totalNoOfCustomersConvertedToSubscription: number;
  numberOfReminderSent: number;
  totalCustomers: number;
  totalRevenue: number;
  totalOrders: number;
  totalPercentageOfCustomersEnrolled: number;
  numberOfEmailReminderSent: number;
  numberOfSmsReminderSent: number;
  noOfCustomersEnrolledSms: number;
  numberOfActiveCustomers: number;
}
export interface ProductQueryResult {
  labels: string[];
  product: string;
  datasets: ProductQueryDataset[];
  totalRevenue: number;
}
export function useQueryProduct(
  productId: number,
  from: Date,
  to: Date,
  businessId: number,
  prevAc?: AbortController | null
): UseAPI<ProductQueryResult> {
  const url = new URL(`${API_URL}/shopify_products/${productId}/query`);
  url.searchParams.append(
    "from",
    moment(from).utc().format("YYYY-MM-DDT00:00:00.000") + "Z"
  );
  url.searchParams.append(
    "to",
    moment(to).utc().format("YYYY-MM-DDT23:59:59.000") + "Z"
  );
  url.searchParams.append(
    "tz_difference",
    (new Date().getTimezoneOffset() * -1).toString()
  );
  url.searchParams.append(
    "timezone_name",
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  url.searchParams.append("business_id", businessId.toString());

  return useApi<ProductQueryResult>({
    method: "get",
    url: url.toString(),
    signal: prevAc?.signal,
  });
}

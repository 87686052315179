import Select from "react-select";

export interface OptionType {
  value: string;
  label: string;
}

export const OptionTypeSelector = ({
  options,
  placeholder,
  defaultValue,
  handleChangeValue,
  rowIndex,
  schedulerType,
  type,
  selectClasses,
}: {
  options: OptionType[];
  placeholder: string;
  defaultValue: OptionType | undefined;
  handleChangeValue: (
    e: OptionType,
    rowIndex: number,
    type: string,
    schedulerType: string
  ) => void;
  rowIndex: number | undefined;
  type: string;
  schedulerType: string;
  selectClasses?: string;
}) => {
  const handleChange = (selectedOptions: OptionType | any) => {
    rowIndex !== undefined &&
      handleChangeValue(selectedOptions, rowIndex, type, schedulerType);
  };
  return (
    <div className={`dropdown-wrap sm:mt-0 mt-1`}>
      <Select
        styles={{
          control: (base) => ({
            ...base,
            boxShadow: "none",
            border: "1px solid #898989",
          }),
          valueContainer: (base) => ({
            ...base,
            padding: "16px 10px",
            textAlign: "center",
          }),
          singleValue: (base) => ({
            ...base,
            fontWeight: "bold",
            marginLeft: "0",
            marginRight: "0",
          }),
        }}
        isSearchable={false}
        className={`rounded w-28 ${selectClasses} text-xs border-opacity-50 border-border_gray`}
        classNamePrefix="dp"
        options={options}
        placeholder={placeholder}
        onChange={handleChange}
        value={defaultValue}
      />
    </div>
  );
};

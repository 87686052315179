import { useNavigate } from "react-router";

const Error404Page = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex min-h-screen w-full flex-col justify-center items-center px-4 xl:px-0">
        <div className="">
          <div className="text-2xl md:!text-6xl font-semibold mb-6 xl:!mb-14">
            404 - Page not found
          </div>
          <div className="text-base md:!text-xl font-medium mb-6 xl:!mb-14 ">
            The page you are looking for cannot be found
          </div>
          <div className="">
            <button
              className="text-sm rounded py-2 px-4 bg-blue_default hover:bg-blue-800 text-white font-medium"
              onClick={() => navigate("/")}
            >
              Homepage
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404Page;

import { useEffect, useState } from "react";
import UpgradePlanModel from "../../../components/UpgradePlanModel";
import { useAppSelector } from "../../../redux/hooks";
import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";
import { Switch } from "../../smart_reminders/components/Switch";

const WhiteLabel = ({
  siteConfigInitials,
  setFieldValueSiteConfig,
  siteErrors,
}: {
  siteErrors: any;
  siteConfigInitials: SiteConfiguration;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
}) => {
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );
  const [showPlans, setShowPlans] = useState<boolean>(false);
  const [copyText, setCopyText] = useState({
    host: false,
    value: false,
  });

  const canSee = () => {
    return permission?.includes("whitelabel");
  };

  useEffect(() => {
    if (copyText.host || copyText.value) {
      window.setTimeout(() => {
        setCopyText({ host: false, value: false });
      }, 500);
    }
  }, [copyText]);

  return (
    <>
      <div className="px-6 w-full bg-white rounded-lg">
        <div
          className={`message-type-wrapper  ${
            siteConfigInitials?.isWhiteLabel && canSee()
              ? "border-b border-light-grey py-[30px]"
              : "py-[26px]"
          }`}
        >
          <div className="flex justify-between flex-row">
            <div className="flex flex-col">
              <p
                className={`tracking-tracking_001 text-sm text-dark-grey font-medium mb-3 ${
                  !canSee() && "text-opacity-50"
                }`}
              >
                White Label
                {!canSee() && (
                  <span
                    className="text-xs text-blue_default pl-11 cursor-pointer"
                    onClick={() => setShowPlans(true)}
                  >
                    Upgrade
                  </span>
                )}
              </p>
              <p
                className={`text-10 text-dark-grey text-opacity-75 ${
                  !canSee() && "opacity-50"
                }`}
              >
                Customize the entire customer experience by remove AssistAlong
                logo and name from all pages and URLs.
              </p>
            </div>
            <div className="flex flex-row pr-5 items-center">
              <span className="text-xs tracking-wider text-black_shade mr-3">
                Active
              </span>
              <div
                className={`${!canSee() && "opacity-50 pointer-events-none"}`}
              >
                <Switch
                  state={siteConfigInitials?.isWhiteLabel && canSee()}
                  onClick={() => {
                    setFieldValueSiteConfig(
                      "isWhiteLabel",
                      !siteConfigInitials?.isWhiteLabel
                    );
                  }}
                />
              </div>
              <span className="text-xs tracking-wider text-black_shade ml-3">
                Off
              </span>
            </div>
          </div>
        </div>
        {siteConfigInitials?.isWhiteLabel && canSee() && (
          <>
            <div className="pt-7 pr-7 border-b border-light-grey">
              <span className="text-xs text-dark-grey/75 font-normal tracking-tracking_001">
                Step 1. Enter the link to your terms of service and privacy
                policy:
              </span>
              <div className="pt-[30px] pb-5">
                <div className="w-full pb-4">
                  <div className="flex xl:flex-row flex-row xl:items-center items-center w-full">
                    <div className="tracking-wide_0.01 text-black_shade w-[135px] text-xs">
                      Your domain:
                    </div>
                    <div className="flex items-center">
                      <input
                        type="text"
                        className={`w-24 !py-[15px] px-1 border border-r-0 text-xs text-center rounded-l ${
                          siteErrors.merchantDomain
                            ? "border-red-600"
                            : "!border-gray2"
                        } bg-gray-100 text-gray-500`}
                        disabled
                        value="https://"
                        // merchant_domain
                      />
                      <input
                        className={`text-gray-900 w-[429px] rounded-r border border-l-0 text-xs tracking-tracking_001 bg-white focus:ring-0 ${
                          siteErrors.merchantDomain
                            ? "border-red-600"
                            : "!border-gray2"
                        } !py-[15px] px-3 block dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:outline-none`}
                        inputMode="text"
                        value={siteConfigInitials.merchantDomain}
                        onChange={(e) => {
                          setFieldValueSiteConfig(
                            "merchantDomain",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  {siteErrors.merchantDomain && (
                    <p className="scroll__error text-danger pt-1 text-red-500 text-xs pl-[138px]">
                      {siteErrors["merchantDomain"]}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="pt-7">
              <span className="text-xs text-dark-grey/75 font-normal tracking-tracking_001">
                Step 2. Add the following record to your domain:
              </span>
              <div className="py-8">
                <div className="pb-3 flex items-center text-10 text-dark-grey tracking-tracking_001 font-normal">
                  <span className="w-14">Type:</span>
                  <span className="truncate">CNAME</span>
                </div>
                <div className="pb-3 flex items-center text-10 text-dark-grey tracking-tracking_001 font-normal">
                  <span className="w-14">Host:</span>
                  <div className="w-[calc(100%_-_54px)] flex">
                    <span className="min-w-[182px] max-w-[calc(100%_-_48px)] block truncate">
                      {/* {"checkout.{brands domain}"} */}
                      {siteConfigInitials.merchantDomain &&
                        `${siteConfigInitials.merchantDomain}`}
                    </span>
                    <span
                      className="cursor-pointer w-12 text-right text-blue_default"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          siteConfigInitials.merchantDomain
                            ? siteConfigInitials.merchantDomain
                            : ""
                        );
                        setCopyText({ ...copyText, host: true });
                      }}
                    >
                      {copyText.host ? "copied" : "copy"}
                    </span>
                  </div>
                </div>
                <div className="pb-3 flex items-center text-10 text-dark-grey tracking-tracking_001 font-normal">
                  <span className="w-14">Value:</span>
                  <div className="w-[calc(100%_-_54px)] flex">
                    <span className="min-w-[182px] max-w-[calc(100%_-_48px)] block truncate">
                      {siteConfigInitials?.url.replace("https://", "")}
                    </span>
                    <span
                      className="cursor-pointer w-12 text-right text-blue_default"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          siteConfigInitials?.url.replace("https://", "")
                        );
                        setCopyText({ ...copyText, value: true });
                      }}
                    >
                      {copyText.value ? "copied" : "copy"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      {subscription && (
        <UpgradePlanModel
          show={showPlans}
          onClose={() => setShowPlans(false)}
          subscription={subscription}
        />
      )}
    </>
  );
};

export default WhiteLabel;

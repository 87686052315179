import { combineReducers } from "redux";

import usersReducer from "./ducks/user.duck";
import businessReducer from "./ducks/business.duck";
import setupReducer from "./ducks/setup.duck";
import siteConfigReducer from "./ducks/siteconfig.duck";
import plansReducer from "./ducks/plans.duck";
import templatesReducer from "./ducks/template.duck";

const rootReducer = combineReducers({
  user: usersReducer,
  business: businessReducer,
  setup: setupReducer,
  site: siteConfigReducer,
  plans: plansReducer,
  templates: templatesReducer,
});

export default rootReducer;

import UnlockFeature from "../../components/UnlockFeature";
import { useAppSelector } from "../../redux/hooks";
import { upgradePlan } from "../../util/helpers";
import { AttentiveConnect } from "./components/AttentiveConnect";
import { KlaviyoConnect } from "./components/KlaviyoConnect";
import { PostscriptConnect } from "./components/PostscriptConnect";

export const connectOptions = [
  {
    label: "Update",
    value: "update",
  },
  {
    label: "Sync",
    value: "sync",
  },
  {
    label: "Edit Credentials",
    value: "edit_credentials",
  },
  {
    label: "Disconnect",
    value: "disconnect",
  },
];

const IntegrationsPage = () => {
  const { plans: subscription, permission }: any = useAppSelector(
    (state) => state.plans
  );

  return (
    <>
      {!upgradePlan(permission, "integration") ? (
        <>
          <h1 className="text-2xl text-black_shade font-medium mr-10">
            Integrations
          </h1>
          <UnlockFeature subscription={subscription} />
        </>
      ) : (
        <div className="flex flex-col w-full">
          <div className="title flex justify-between mb-12">
            <h1 className="text-2xl text-black_shade font-medium">
              Integrations
            </h1>
          </div>

          <KlaviyoConnect />
          <PostscriptConnect />
          <AttentiveConnect />
        </div>
      )}
    </>
  );
};
export default IntegrationsPage;

import { useDispatch } from "react-redux";
import { Business } from "../pages/guides/models/Business";
import {
  getBusiness,
  postGoLive,
} from "../pages/guides/requests/BusinessRequests";
import { setBusiness } from "../redux/ducks/business.duck";
import { setSetupStatus } from "../redux/ducks/setup.duck";
import { useAppSelector } from "../redux/hooks";
import Button from "./Button";

export const StartGuideBanner = ({
  business,
  refreshBusiness,
}: {
  business: Business;
  refreshBusiness: () => void;
}) => {
  const setupStatus = useAppSelector((state) => state.setup.setupStatus);
  const isFetching = useAppSelector((state) => state.setup.isFetching);
  const dispatch = useDispatch();
  const handleGoLive = () => {
    // setGoLive(false);
    postGoLive(business.id).then(() => {
      getBusiness().then((res) => {
        if (res.data) {
          dispatch(setSetupStatus(res.data.setupStatus));
          dispatch(setBusiness(res.data));
        }
      });
    });
  };

  const buttonDisabled = () => {
    return (
      setupStatus.reminderStep.status !== "completed" ||
      setupStatus.site.status !== "completed"
    );
  };

  return (
    <>
      {setupStatus.goLive.status !== "completed" && !isFetching && (
        <div className="startguide-strip p-5 bg-orange w-full bg-stripOrange rounded-lg flex justify-between items-center mb-5">
          <div className="left-wrap flex w-full">
            <img src="/startguide-icon.svg" alt="startguide-icon"></img>
            <div className="ml-4">
              <h2 className="uppercase text-sm font-medium tracking-tracking_001 text-white">
                PRESS “GO LIVE” AFTER SETTING UP YOUR ACCOUNT
              </h2>
              <p className="text-xs text-white tracking-tracking_001">
                The changes will not be deployed on your store untill you press
                “Go Live”
              </p>
            </div>
          </div>
          <div className="btn-wrap w-48">
            <Button
              text="Go Live"
              disabled={buttonDisabled()}
              onClick={handleGoLive}
              className={`flex flex-row bg-white text-black_shade text-13 font-medium items-center px-4 py-3 rounded shadow-sm float-right ${
                buttonDisabled() && "cursor-not-allowed"
              }`}
              icon={
                <img
                  className="mr-2"
                  src="/aa_black_logo.svg"
                  alt="startguide-icon"
                ></img>
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

import { UploadLogo } from "../pages/account/components/UploadLogo";
import { SiteConfiguration } from "../pages/account/models/SiteConfigurationModel";
import Button from "./Button";

const UploadImage = ({
  show,
  onClose,
  siteConfigInitials,
  setFieldValueSiteConfig,
  businessId,
}: {
  show: boolean;
  onClose: () => void;
  siteConfigInitials: SiteConfiguration;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
  businessId: number;
}) => {
  return (
    <div
      className={` transition ease-linear duration-100 relative z-20 ${
        show ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div
        className={`min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover transition ease-in-out duration-500 ${
          show ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <div
          className={`absolute bg-black  inset-0 z-0 transition ease-in-out duration-500 ${
            show ? "opacity-50" : "opacity-0"
          }`}
        ></div>
        <div
          className={`w-full max-w-[750px] relative mx-auto my-auto rounded-lg shadow-lg bg-white transition ease-in-out duration-500  ${
            show ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          <Button
            onClick={() => onClose()}
            className="cursor-pointer bg-off_white w-8 h-8 shadow-btn_shadow flex items-center justify-center rounded-full absolute -left-3 -top-3"
            icon={<img src="/close-btn.svg" alt="close button"></img>}
          />
          <div className="overflow-y-auto max-h-84vh  pt-[55px] pl-[100px] pr-[65px] pb-[94px]">
            <UploadLogo
              imageUrl={siteConfigInitials?.defaultSenderImageUrl}
              onUploadSuccess={(url) => {
                setFieldValueSiteConfig("defaultSenderImageUrl", url);
              }}
              businessId={businessId}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadImage;

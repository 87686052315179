import * as React from "react";
import { useState } from "react";
import { Subscription } from "../pages/account/models/PlansModels";
import Button from "./Button";
import SubscriptionPlans from "./SubscriptionPlans";
import { Plan } from "../pages/account/models/PlansModels";

const UpgradePlanModel = ({
  show,
  onClose,
  subscription,
}: {
  show: boolean;
  onClose: () => void;
  subscription: Subscription;
}) => {
  const [selectPlan, setSelectPlan] = useState(false);
  const [showCloseIcon, setShowCloseIcon] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (subscription) {
      const active = subscription?.plans?.find(
        (obj: Plan) =>
          obj.id === subscription?.businessCurrentActivePlan?.planId
      );
      let planName = active ? active?.name : "none";
      if (planName === "none") {
        setShowCloseIcon(false);
      }
    } else {
      setShowCloseIcon(false);
    }
  }, [subscription]);
  return (
    <div
      className={` transition ease-linear duration-100 relative z-20 ${
        show ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      <div
        className={`min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover transition ease-in-out duration-500 ${
          show ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <div
          className={`absolute bg-black  inset-0 z-0 transition ease-in-out duration-500 ${
            show ? "opacity-50" : "opacity-0"
          }`}
        ></div>
        <div
          className={`w-[98%] max-w-max relative mx-auto my-auto rounded-lg shadow-lg bg-white transition ease-in-out duration-500  ${
            show ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        >
          {showCloseIcon ? (
            <Button
              className={`cursor-pointer bg-off_white w-8 h-8 shadow-btn_shadow flex items-center justify-center rounded-full absolute -left-3 -top-3`}
              onClick={() => {
                onClose();
                setSelectPlan(false);
              }}
              icon={<img src="/close-btn.svg" alt="close button"></img>}
            />
          ) : (
            <span></span>
          )}
          <div className="product-list overflow-y-auto max-h-84vh">
            {selectPlan ? (
              <div className="p-12 text-center min-h-[600px] flex flex-col justify-center items-center">
                <h2 className="text-black_shade font-bold text-2xl !leading-[36px] tracking-tracking_001 flex items-center justify-center">
                  <img
                    className="mr-5"
                    alt="ico_tick_green"
                    src="/svg/ico_tick_green.svg"
                    style={{ width: "58px", height: "58px" }}
                  />
                  Your plan is upgraded.
                </h2>
                <Button
                  text="Go back to the dashboard"
                  className={`ml-[20px] mt-12 max-w-[231px] w-full py-[10px] px-2 text-white bg-normal-blue font-medium rounded-md text-center text-[13px] !leading-[20px] tracking-tracking_001 inline-block`}
                  onClick={() => onClose()}
                />
              </div>
            ) : (
              <div className="p-12">
                <div className="pb-16">
                  <h2 className="text-black_shade font-bold text-2xl !leading-[36px] tracking-tracking_001">
                    Upgrade and Unlock advanced features
                  </h2>
                </div>
                <SubscriptionPlans subscription={subscription} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanModel;

import { useState } from "react";
import UploadImage from "../../../components/UploadImage";
import { SiteConfiguration } from "../../account/models/SiteConfigurationModel";
// import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

const SenderInfo = ({
  siteConfigInitials,
  setFieldValueSiteConfig,
  showSenderInfo,
}: {
  siteConfigInitials: SiteConfiguration;
  setFieldValueSiteConfig: (fieldName: string, value: any) => void;
  showSenderInfo: boolean;
}) => {
  const [active, setActive] = useState(false);
  // const dispatch = useAppDispatch();
  // const businessId = useAppSelector((state) => state.business.businessId);

  // const verify = async () => {
  //   const { data }: { data: SiteConfiguration } = await getSiteConfiguration(
  //     businessId
  //   );
  //   dispatch(setSiteConfiguration(data));
  // };

  return (
    <>
      <div className="pb-8 w-full mb-5 bg-white rounded-lg reminder-delivery-section">
        <div className="px-6 message-type-wrapper py-6 border-b border-light-grey">
          <div className="flex justify-between flex-row">
            <div className="flex flex-col">
              <p className="tracking-tracking_001 text-base text-dark-grey font-medium mb-1">
                Sender Profile of Email Reminder Flows
              </p>
              <p className="text-10 tracking-tracking_001 text-dark-grey text-opacity-75 w-[478px]">
                Customize the sender information for your email reminder
              </p>
            </div>
          </div>
        </div>
        <div className="px-6">
          {/* <div className="flex w-full py-6 border-b border-light-grey items-center">
            <span className="text-xs font-normal text-black_shade tracking-tracking_001 mr-4 xl:mb-0 min-w-[80px]">
              Profile picture:
            </span>
            <div className="flex justify-between text-xs place-items-center ml-4">
              <div className="w-[50px] h-[50px] border overflow-hidden rounded-full mr-10 bg-[#F0F0F0] border-gray2">
                {siteConfigInitials?.defaultSenderImageUrl && (
                  <img
                    className="w-[50px] h-[50px] object-contain"
                    alt=""
                    src={siteConfigInitials?.defaultSenderImageUrl}
                  ></img>
                )}
              </div>
              <div className="relative">
                <button
                  disabled={showSenderInfo}
                  className="rounded-md text-blue_default text-[13px] addproduct-button font-medium py-[11px] px-[30px] tracking-tracking_001"
                  onClick={() => setActive(true)}
                >
                  Upload
                </button>
              </div>
            </div>
          </div> */}

          <div
            className={`flex w-full py-6 border-b border-light-grey items-center ${
              showSenderInfo && "opacity-50"
            }`}
          >
            <span className="text-xs font-normal tracking-tracking_001 text-black_shade mr-4 xl:mb-0 min-w-[80px]">
              From name
            </span>
            <div className="flex justify-between text-xs w-[277px]">
              <div className="w-full">
                <input
                  disabled={showSenderInfo}
                  className="rounded border text-xs tracking-tracking_001 border-gray2 !py-[11px] px-3 w-full bg-white text-black_shade focus:ring-0"
                  type="text"
                  value={siteConfigInitials.senderName}
                  onChange={(e) =>
                    setFieldValueSiteConfig("senderName", e.target.value)
                  }
                  // onBlur={() =>
                  //   onInputBlur(focusedFields, setFocusedFields, "brandName")
                  // }
                />
              </div>
            </div>
          </div>

          <div className="flex w-full py-6 items-center opacity-50">
            <span className="text-xs font-normal tracking-tracking_001 text-black_shade mr-4 xl:mb-0 min-w-[80px]">
              From email
            </span>
            <div className="flex justify-between text-xs w-72">
              <div className="w-full">
                <input
                  readOnly
                  disabled={true}
                  className="rounded border border-gray2 text-xs tracking-tracking_001 !py-[11px] px-3 w-[270px] bg-white text-black_shade focus:ring-0"
                  type="text"
                  value={siteConfigInitials.senderEmail}
                  onChange={(e) =>
                    setFieldValueSiteConfig("senderEmail", e.target.value)
                  }
                  // value={siteConfigInitials.brandName}
                  // onChange={(e) =>
                  //   setFieldValueSiteConfig("brandName", e.target.value)
                  // }
                  // onBlur={() =>
                  //   onInputBlur(focusedFields, setFocusedFields, "brandName")
                  // }
                />
              </div>
            </div>
          </div>
          {/* <DnsInfo dnsInfo={siteConfigInitials.dnsInfo} onVerify={verify} /> */}
          {/* <div className="flex w-full py-6  border-light-grey items-center ">
            <span className="text-xs font-normal tracking-tracking_001 text-black_shade mr-4 xl:mb-0 min-w-[80px]">
              Reply email
            </span>
            <div className="flex justify-between text-xs w-72">
              <div className="w-full">
                <input
                  className="rounded border border-gray2 !py-[11px] px-3 w-[270px] bg-white text-black_shade focus:ring-0"
                  type="text"
                // value={siteConfigInitials.brandName}
                // onChange={(e) =>
                //   setFieldValueSiteConfig("brandName", e.target.value)
                // }
                // onBlur={() =>
                //   onInputBlur(focusedFields, setFocusedFields, "brandName")
                // }
                />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <UploadImage
        show={active}
        onClose={() => setActive(false)}
        siteConfigInitials={siteConfigInitials}
        setFieldValueSiteConfig={setFieldValueSiteConfig}
        businessId={1}
      />
    </>
  );
};

export default SenderInfo;
